import React, { Component, Fragment } from "react";

import TabsetChangePass from "./tabset-changepass";
import Breadcrumb from "../common/breadcrumb";
import { translations } from "../../constants/translations";

export class ChangePassword extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumb
          title={translations.Profile.ChangePassword}
          parent="Settings"
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4">
              <div className="card profile-card">
                <div className="card-body">
                  <TabsetChangePass />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ChangePassword;
