import React, { useEffect, useReducer } from 'react'
import { getArticle } from '../../services/articles'
import get from 'lodash/get'
import Breadcrumb from "../common/breadcrumb";
import { captalizeFirstChar } from '../../utils/stringHelper'
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../hooks/apiStateHandler'
import styles from './articles.module.css'
import { createImageUrl } from '../../utils/imageHelper'
import { LoaderComp, Button } from '../shared';
import { replaceWordpressImage } from '../../utils/image';

const ViewArticleDetail = (props) => {
    const { match, history } = props
    const [articleState, articleDipatch] = useReducer(ApiStateReducer, initialState)
    useEffect(() => {
        const { articleType, articleId } = match.params
        getArticleHandler({ articleType, articleId })
    }, [match])
    const getArticleHandler = async (params) => {
        articleDipatch({ type: ApiStateEvents.FETCHING })
        const res = await getArticle(params)
        if (res.success) {
            articleDipatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.article', {}) })
        } else {
            articleDipatch({ type: ApiStateEvents.ERROR, data: {} })
        }
    }
    if(articleState)
        articleState.data.description=replaceWordpressImage(articleState.data.description)
    return <>
        <Breadcrumb title={captalizeFirstChar(match?.params?.articleType || '')} />
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5><Button className={styles.backButton} clicked={() => history.push(`/articles/${match?.params?.articleType}/list`)}><i title="Back" class="fa fa-angle-left pull-left"></i></Button> {`${get(articleState, 'data.title', '')}`}</h5>

                        </div>
                        <div className="card-body">
                            {!get(articleState, 'loading', false) ? <div className="table-responsive profile-table">
                                <table className="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <td width="120">Title:</td>
                                            <td>{get(articleState, 'data.title', '')}</td>
                                        </tr>
                                        <tr>
                                            <td width="120">Description:</td>
                                            <td dangerouslySetInnerHTML={{ __html: get(articleState, 'data.description', 'N/A') }}></td>
                                        </tr>
                                        <tr>
                                            <td width="120">MD Title:</td>
                                            <td dangerouslySetInnerHTML={{ __html: get(articleState, 'data.metadataTitle', 'N/A') }}></td>
                                        </tr>
                                        <tr>
                                            <td width="120">MD Description:</td>
                                            <td dangerouslySetInnerHTML={{ __html: get(articleState, 'data.metadataDescription', 'N/A') }}></td>
                                        </tr>
                                        <tr>
                                            <td width="120">Image alt:</td>
                                            <td dangerouslySetInnerHTML={{ __html: get(articleState, 'data.imageAlt', 'N/A') }}></td>
                                        </tr>
                                        <tr>
                                            <td width="120">Article slug:</td>
                                            <td dangerouslySetInnerHTML={{ __html: get(articleState, 'data.articleSlug', 'N/A') }}></td>
                                        </tr>
                                        <tr>
                                            <td width="120">Comments:</td>
                                            <td>{get(articleState, 'data.comments.length', 0)}</td>
                                        </tr>
                                        <tr>
                                            <td width="120">Likes:</td>
                                            <td>{get(articleState, 'data.likes.length', 0)}</td>
                                        </tr>
                                        {get(articleState, 'data.picUrl.pictureUrl', null) && <tr>
                                            <td width="120">Image:</td>
                                            <td><img width={500} height={200} alt="Not Found" src={createImageUrl(get(articleState, 'data.picUrl.pictureUrl', null))} /></td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div> : <LoaderComp  loading />}
                        </div>
                    </div>
                </div>
            </div>
        </div></>
}

export default ViewArticleDetail