import APIHandler from './apiHandler'

const getMessageResponse = () => APIHandler('messageResponse/list', 'get')

const updateMessageResponse = (params) => APIHandler('messageResponse/update', 'put', params)

const insertMessageResponse = (params) => APIHandler('messageResponse/blank', 'put', params)

const deleteMessageResponse = (params) => APIHandler('messageResponse/delete', 'delete', params)


export { getMessageResponse, updateMessageResponse, insertMessageResponse,deleteMessageResponse }