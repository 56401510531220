import React from 'react'
import PropTypes from 'prop-types'

const Button = (props) => {
    const { loading, children, loadingText, className, clicked, type, disabled, title } = props
    return <button title={title} type={type} className={className} style={{ cursor: 'pointer' }} onClick={(e) => clicked(e)} disabled={loading || disabled}>{!loading ? children : loadingText}</button>
}

Button.defaultProps = {
    loading: false,
    children: null,
    loadingText: 'Loading',
    className: 'btn',
    type: 'button',
    title: null,
    clicked: () => null
}

Button.propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.element.isRequired,
    loadingText: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    clicked: PropTypes.func.isRequired,
}
export default Button