import React, { useEffect, useReducer, useState } from 'react'
import Breadcrumb from '../../common/breadcrumb'
import { TableComp, ToolTip } from '../../shared'
import get from 'lodash/get'
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../../hooks/apiStateHandler'
import { toast } from 'react-toastify';
import { SOMETING_WENT_WRONG, TOTAL_RECORDS } from '../../../constants/translations';
import { getUserCommentsList, getSingleCommentsBy, allowMoreConversations, deleteMemberMessages } from '../../../services/helpdesk'
import { blockUser } from '../../../services/endUserManagement'
import { captalizeFirstChar } from '../../../utils/stringHelper'
import moment from 'moment'
import CommentModal from './comment-modal'
import MassMemberSeach from './search'
import styles from '../helpdesk.module.css'

//const editValuesInitial = { showModal: false, values: {}, updating: false, error: null }

const UserComments = () => { 
    const [userCommentsState, userCommentsDispatch] = useReducer(ApiStateReducer, initialState)
    const [actionPerforming, setActionPerforming] = useState(null)
    const [showModal, setShowModal] = useState( false ) ;  
    const [showLoader, setShowLoader] = useState( false ) ; 
    const [ singleComments, setSingleComments ] = useState()
    const [appliedfilters, setAppliedFilters] = useState({})
    const [allfilters, setAllFilters] = useState({})

    useEffect(() => {
        getMassMessageHandler({ offset: 0, limit: get(userCommentsState, 'limit', 20), })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getMassMessageHandler = async (params) => {
        try {
            userCommentsDispatch({ type: ApiStateEvents.FETCHING })
            const res = await getUserCommentsList(params)
            if (res.success) {
                userCommentsDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.userComments', []), offset: params.offset, totalRecords: get(res, 'data.total') })
            } else {
                toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
                userCommentsDispatch({ type: ApiStateEvents.ERROR, data: [], totalRecords: get(res, 'data.total') })
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
            userCommentsDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }
    const onSort = ({ fieldName, sortOrder }) => {
        userCommentsDispatch({
            type: ApiStateEvents.ADD_SORTING, sorting: {
                sortField: fieldName,
                sortOrder: sortOrder,
            }
        })
        userCommentsDispatch({
            offset: 0,
            limit: get(userCommentsState, 'limit', 20),
            sortField: fieldName,
            sortOrder: sortOrder,
        })
    }
    const [ currentPage, setCurrentPage ] = useState(1)
    const onPageChange = (page) => {
        setCurrentPage(page)
        getMassMessageHandler({
            offset: get(userCommentsState, 'limit', 20) * (page - 1),
            limit: get(userCommentsState, 'limit', 20),
            sortField: get(userCommentsState, 'sorting.sortField'),
            sortOrder: get(userCommentsState, 'sorting.sortOrder'),
        })
    }
    const suspendUserClickHandler = async ( id ) => {
        const offset = get(userCommentsState, 'limit', 20) * (currentPage - 1)
        setActionPerforming("suspendingUser")
        const blocked = 'true'
        const res = await blockUser({ userId: id, blocked })
        if (res && res?.success) {
            toast.success(res?.data?.message)
            getMassMessageHandler({ offset: offset , limit: get(userCommentsState, 'limit', 20), })
        } else {
            toast.error(SOMETING_WENT_WRONG)
        }
        setActionPerforming(null)
    }
    const viewCommentsHandler = async ( id ) => {
        setActionPerforming("fetcingSingleMessages")
        setShowLoader(true)
        const res = await getSingleCommentsBy ({ id })
        if (res && res?.success) {
            setShowLoader(false)
            setSingleComments(res?.data?.userComments)
             setShowModal( true )
        } else {
            toast.error(SOMETING_WENT_WRONG)
        }
        setActionPerforming(null)
    }

    const columns = [
        {
            name: "First/Last Name",
            selector: "First/Last Name",
            cellRenderer: (row) => <div>{captalizeFirstChar(get(row, 'user.firstName', 'N/A')) + ' ' + captalizeFirstChar(get(row, 'user.lastName', 'N/A'))}</div>,
            //sortable: true
        },
        {
            name: "Username",
            selector: "Username",
            cellRenderer: (row) => <div>{get(row, 'user.username', 'N/A')}</div>,
            //sortable: true
        },
        {
            name: "Comments in 48H",
            cellRenderer: ( row ) => <div style={{
                cursor: "pointer",
                textDecoration: "underline"
            }}class="makeLinK" onClick={ ()=>viewCommentsHandler( row.user._id)}> View Comments</div>
        },
        {
            name: "Message Count",
            selector: "count",
            width: "10%",
            cellRenderer: (row) => (<div dangerouslySetInnerHTML={{ __html: row.totalComments }} ></div>),
            //sortable: true
        },
        // {
        //     name: "is Suspended",
        //     selector: "isUserSuspended",
        //     cellRenderer: (row) => (<div dangerouslySetInnerHTML={{ __html: row && row.userId && row.userId.isBlocked ? row && row.userId && row.userId.isBlocked: 'false'}} ></div>),
        //     //sortable: true
        // },
        {
            name: 'Actions',
            cellRenderer: (row) => (<>
                {
                    row && row.user && row.user.isBlocked === false && ( <><ToolTip tip="Suspend User" id="suspendUser" />
                    <span
                        data-tip
                        data-for="suspendUser"
                        style={{ cursor: "pointer" }}
                        title="Suspend User"
                        // onClick={ ()=>suspendUserClickHandler( row.userId._id )}
                        onClick={() => {
                            //history.push(`/users/${UPDATE}/${row._id}`)
                            const win = window.open(`/users/update/${row.user._id}`, "_blank")
                            win.focus()
                        }}>
                        <i
                            className="fa fa-user-times"
                            style={{
                                width: 35,
                                fontSize: 18,
                                padding: 10,
                                color: "rgb(40, 167, 69)",
                            }}
                        ></i>
                    </span> </>)
                }
                <ToolTip tip="View Profile" id="viewprofile" />
                    <span
                        data-tip
                        data-for="viewprofile"
                        style={{ cursor: "pointer" }}
                        title="View Profile"
                        onClick={() => {
                            //history.push(`/users/${UPDATE}/${row._id}`)
                            const win = window.open(`/users/details/${row.user._id}`, "_blank")
                            win.focus()
                        }}
                    >
                        <i
                            className="fa fa-eye"
                            style={{
                                width: 35,
                                fontSize: 18,
                                padding: 10,
                                color: "rgb(0, 191, 255)",
                            }}
                        ></i>
                    </span>
                <ToolTip tip="View User on Frontend" id="viewOnFrontend" />
                    <span
                        data-tip
                        data-for="viewOnFrontend"
                        style={{ cursor: "pointer" }}
                        title="View User on Frontend"
                        onClick={() => {
                            //history.push(`/users/${UPDATE}/${row._id}`)
                            const win = window.open(`https://artmo.com/user/${row.user.username}`, "_blank")
                            win.focus()
                        }}
                    >
                        <i
                            className="fa fa-user"
                            style={{
                                width: 35,
                                fontSize: 18,
                                padding: 10,
                                color: "rgb(0, 191, 255)",
                            }}
                        ></i>
                    </span>
                <ToolTip tip="Delete Row" id="deleteRow" />
            </>)
        }
    ]

    const applyFilters = (filt) => {
        //history.push('/help-desk')
        setAppliedFilters(filt)
        setAllFilters(filt)
        userCommentsDispatch({ type: ApiStateEvents.SUCCESS, data: [], offset: 0, totalRecords: 0 })
        getMassMessageHandler({ limit: get(userCommentsState, 'limit', 20), offset: 0, filters: filt })
    }

    return <>
    {
        showLoader && 
        (
            <div className={styles.loaderDiv}>
                <div className={styles.loader}></div>
            </div>
        )
    }
    
    <Breadcrumb title='Member Mass Messaging' />
    <div className="container-fluid">
        <div className="card">
            <div className="card-header">
                <h5 className="BackButtonHeading">
                    Member Mass Messaging
                </h5>
            </div>
            <div>
                <MassMemberSeach addFilter={(f) => setAllFilters({ ...allfilters, ...f })} appliedFilters={appliedfilters} allfilters={allfilters} applyFilters={(f) => applyFilters(f)} />
            </div>
            <div style={{ margin: "0 30px 15px" }}>
                {!get(userCommentsState, 'loading') && TOTAL_RECORDS + get(userCommentsState, 'totalRecords', 0)}
            </div>
            <div className="card-body">
                <TableComp
                    onPageChange={(p) => onPageChange(p)}
                    page={(get(userCommentsState, 'offset', 0) / get(userCommentsState, 'limit', 20) + 1)}
                    limit={get(userCommentsState, 'limit', 20)}
                    totalRecords={get(userCommentsState, 'totalRecords', 0)}
                    loading={get(userCommentsState, 'loading')}
                    data={get(userCommentsState, 'data', [])}
                    columns={columns}
                    onSort={onSort}
                    sortField={get(userCommentsState, 'sorting.sortField', null)}
                    sortOrder={get(userCommentsState, 'sorting.sortOrder', null)}
                />
            </div>
            {showModal  && <CommentModal
                    //loading={false}
                    onCloseModal={() => setShowModal(false)}
                    mopen="true"
                    values={singleComments}
                    title="Comments By User"
                />}
        </div>
    </div>
</>
}

export default UserComments