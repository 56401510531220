import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './showMore.module.css'

const ShowMoreViewer = (props) => {
    const { type, text, defaultLength, showMoreLength, className } = props
    const [showLength, setShowLength] = useState(500)
    useEffect(() => {
        setShowLength(defaultLength)
    }, [defaultLength])
    //console.log({ text, l: text.length })
    return <>{type === 'html' ? <div dangerouslySetInnerHTML={{ __html: text.substring(0, showLength) }} className={className}></div> : <div>{text.substring(0, showLength)}</div>}
        {showLength < (text?.length || 0) && <p className={styles.showMore} onClick={() => setShowLength(showLength + showMoreLength)} >Show more</p>}
        {showLength > defaultLength && <p className={styles.showLess} onClick={() => setShowLength(defaultLength)} >Show less</p>}
    </>
}

ShowMoreViewer.defaultProps = {
    type: 'text',
    text: "",
    defaultLength: 100,
    showMoreLength: 100,
    className: ''
}


ShowMoreViewer.propTypes = {
    type: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    defaultLength: PropTypes.number.isRequired,
    showMoreLength: PropTypes.number.isRequired,
    className: PropTypes.string
}

export default ShowMoreViewer