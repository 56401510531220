import React from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import { Button } from '../shared'
import { responseMessageValidationSchema } from '../common/validations'

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
    },
};


const ResponseMessageModal = (props) => {
    const {
        mopen,
        onCloseModal,
        submitHandler,
        title,
        values,
        loading
    } = props


    const formik = useFormik({
        initialValues: { ...values },
        validationSchema: () => responseMessageValidationSchema,
        onSubmit: v => {
            submitHandler(v)
        },
    });

    const { title: MessageTitle, message } = formik.values

    return (
        <Modal
            isOpen={mopen}
            onClose={onCloseModal}
            onRequestClose={onCloseModal}
            style={customStyles}
            className="ModalContent"
            overlayClassName="OverlayContent"
        >
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    {title}
                </h5>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <label htmlFor="firstName">Title</label>
                    <input value={MessageTitle} className="form-control" id="title" maxLength={100} onChange={formik.handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="firstName">Message</label>
                    <textarea
                        className="form-control textareaExpand"
                        as="textarea"
                        onChange={formik.handleChange}
                        rows={10}
                        cols={20}
                        id="message"
                        value={message}
                        name="message"
                        maxLength={50000}
                        placeholder="Type message here"
                    />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="firstName">{formik?.errors?.selectedResponse}</label>
            </div>
            <div className="modal-footer">
                <Button loading={loading} type="submit" clicked={formik.handleSubmit} className="btn btn-primary">
                    Save
                        </Button>
                <Button
                    type="button"
                    className="btn btn-light"
                    clicked={() => onCloseModal()}
                >
                    Close
                        </Button>
            </div>
        </Modal >
    );
};

export default ResponseMessageModal;