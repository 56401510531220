import React, { useEffect, useReducer, useState } from 'react'
import Breadcrumb from "../common/breadcrumb";
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../hooks/apiStateHandler'
import { getActivities, deleteActivity } from '../../services/activities'
import Gallery from 'react-grid-gallery'
import { get } from 'lodash-es';
import ReactPlayer from 'react-player/lazy'
import { createImageUrl } from '../../utils/imageHelper'
import { TableComp } from '../shared';
import styled from "styled-components";
import { Button, } from '../shared';
import { TOTAL_RECORDS } from '../../constants/translations';
import { toast } from 'react-toastify';
import ActivitySearch from './components/search'


const AuthorName = styled.div`
    p {
        color: rgba(0,0,0,0.87);
    }
`

const SocialActivitiesList = (props) => {
    const { match, history } = props
    const [activitiesState, activitiesDispatch] = useReducer(ApiStateReducer, initialState)

    const [appliedfilters, setAppliedFilters] = useState({ status: [0, 1] })
    const [selectedUser, setSelectedUser] = useState(null)



    useEffect(() => {
        getActivitiesHandler({ activityType: match?.params?.type, offset: 0, limit: get(activitiesState, 'limit', 20) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match?.params?.type])

    const getActivitiesHandler = async (params) => {
        try {
            //const params = { ...params }
            activitiesDispatch({ type: ApiStateEvents.FETCHING })
            const res = await getActivities(params)
            if (res.success) {
                activitiesDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.activities', []), offset: params.offset, totalRecords: get(res, 'data.total') })
            } else {
                activitiesDispatch({ type: ApiStateEvents.ERROR, data: [], totalRecords: get(res, 'data.total') })
            }
        } catch (error) {
            activitiesDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }

    const updateActivityHandler = (articleId, field, value) => {
        const allActivities = get(activitiesState, 'data', [])
        const articleIndex = allActivities.findIndex(o => o._id === articleId)
        if (articleIndex !== -1) {
            allActivities[articleIndex][field] = value
            activitiesDispatch({ type: ApiStateEvents.SUCCESS, data: allActivities })
        }
    }

    const deleteActivityHandler = async (activityId, flag) => {
        updateActivityHandler(activityId, 'loading', true)
        const res = await deleteActivity({ activityType: match?.params?.type, activityId, flag })
        if (res.success) {
            toast.success(get(res, 'data.message'))
            updateActivityHandler(activityId, 'isDeleted', true)
        } else {
            toast.error(get(res, 'err.response.data.message') || 'Something went wrong')
        }
        updateActivityHandler(activityId, 'loading', false)
    }

    const contentRenderer = (contentType, data) => {
        if (contentType === 'albums') {
            const images = get(data, 'pictures', []).map(pic => ({
                src: createImageUrl(pic.pictureUrl),
                thumbnail: createImageUrl(pic.pictureUrl),
                thumbnailWidth: 320,
                thumbnailHeight: 200,
                isSelected: false,
                caption: pic.pictureName
            }))
            return <div style={{
                display: "block",
                minHeight: "1px",
                margin: '10px 0',
                width: "80%",
                overflow: "auto"
            }}>
                {images.length ? <Gallery
                    images={images}
                    enableLightbox={true}
                    enableImageSelection={false} /> : 'No pictures found'}
            </div>
        } else if (contentType === 'posts') {
            const images = [{
                src: createImageUrl(data?.picUrl?.pictureUrl),
                thumbnail: createImageUrl(data?.picUrl?.pictureUrl),
                thumbnailWidth: 320,
                thumbnailHeight: 200,
                isSelected: false,
                caption: data?.picUrl?.pictureName
            }]
            return <div style={{
                display: "block",
                minHeight: "1px",
                margin: '10px 0',
                width: "80%",
                overflow: "auto"
            }}>
                {data?.picUrl ? <Gallery
                    images={images}
                    enableLightbox={true}
                    enableImageSelection={false} /> : 'No picture found'}
            </div>
        } else if (contentType === 'videos') {
            const src = get(data, 'url', null)
            const canPlay = ReactPlayer.canPlay(src)
            return <div style={{
                display: "block",
                minHeight: "1px",
                margin: '10px 0',
                width: "80%",
                overflow: "auto"
            }}>{src && canPlay ? <ReactPlayer width={500} height={300} light url={src} /> : <>Invalid url</>}</div>
        }
        return <div></div>
    }

    const columns = [
        {
            name: "Author",
            cellRenderer: (row) => (<AuthorName><p onClick={() => history.push(`/users/details/${row?.userId._id}`)}>{`${get(row, 'userId.firstName', '')} ${get(row, 'userId.lastName', '')}`}</p></AuthorName>),
            width: '10%'
        },
        {
            name: "Description",
            selector: match?.params?.type === "posts" ? "title" : "description",
            sortable: true,
            width: '20%'
        },
        {
            name: "Content",
            cellRenderer: (row) => contentRenderer(match?.params?.type, row),
            width: '40%'
        },
        {
            name: "Likes",
            cellRenderer: (row) => (<>{get(row, 'likes.length', 0)}</>),
            width: '8%'
        },
        {
            name: "Comments",
            cellRenderer: (row) => (<>{get(row, 'comments.length', 0)}</>),
            width: '8%'
        },
        {
            name: "Actions",
            cellRenderer: (row) => (<Button title="Delete Social Activity" loading={row.loading} clicked={() => deleteActivityHandler(row._id, 1)}>Delete</Button>),
            width: '10%'
        },
    ]

    const getSocialActivityType = (type = '') => {
        return type.charAt(0).toUpperCase() + type.slice(1);
    }

    const appliedFiltersHandler = (filtersParams, type='') => {
        //localStorage.setItem('filterObj' , JSON.stringify(filtersParams) )
        // if ( type !== 'reset' ) {
        //     const currentURL = history.location.pathname
        //     window.open(currentURL, "_blank")
        // } else {
            //getArtworksHandler({ offset: 0, limit: get(artworksState, 'limit', 20), filters: { ...filtersParams } })
            getActivitiesHandler({ activityType: match?.params?.type, offset: 0, limit: get(activitiesState, 'limit', 20), filters: { ...filtersParams } })
            setAppliedFilters(filtersParams)
        // }
        
        
    }

    const onPageChange = (page) => {
        getActivitiesHandler({
            activityType: match?.params?.type,
            offset: get(activitiesState, 'limit', 20) * (page - 1),
            limit: get(activitiesState, 'limit', 20),
            sortField: get(activitiesState, 'sorting.sortField'),
            sortOrder: get(activitiesState, 'sorting.sortOrder'),
        })
    }

    const onSort = ({ fieldName, sortOrder }) => {
        activitiesDispatch({
            type: ApiStateEvents.ADD_SORTING, sorting: {
                sortField: fieldName,
                sortOrder: sortOrder,
            }
        })
        getActivitiesHandler({
            activityType: match?.params?.type,
            offset: 0,
            limit: get(activitiesState, 'limit', 20),
            sortField: fieldName,
            sortOrder: sortOrder,
        })
    }

    return <>
        <Breadcrumb title={getSocialActivityType(match?.params?.type || '')} parent="Social Activities" />
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h5 className="BackButtonHeading">
                        {`Social Activities - ${getSocialActivityType(match?.params?.type || '')}`}
                    </h5>
                </div>
                <div style={{ margin: "15px 30px 15px" }}>
                    {!get(activitiesState, 'loading') && TOTAL_RECORDS + get(activitiesState, 'totalRecords', 0)}
                </div>
                <div style={{ margin: "15px 30px 15px" }}>
                    <ActivitySearch selectedUser={selectedUser} applyFilters={(filters, type) => appliedFiltersHandler(filters, type)} allfilters={appliedfilters} />
                </div>
                <div className="card-body">
                    <TableComp
                        page={(get(activitiesState, 'offset', 1) / get(activitiesState, 'limit', 1)) + 1}
                        limit={get(activitiesState, 'limit', 20)}
                        totalRecords={get(activitiesState, 'totalRecords', 0)}
                        onPageChange={(p) => onPageChange(p)}
                        loading={get(activitiesState, 'loading')}
                        data={get(activitiesState, 'data', [])}
                        columns={columns}
                        onSort={onSort}
                        sortField={get(activitiesState, 'sorting.sortField', null)}
                        sortOrder={get(activitiesState, 'sorting.sortOrder', null)} />
                </div>
            </div>
        </div>
    </>
}

export default SocialActivitiesList