import APIHandler from './apiHandler'

const getArticles = (params) => APIHandler('article/list', 'get', params)

const acceptArticle = (params) => APIHandler('article/accept', 'put', params)

const fetaureArticle = (params) => APIHandler('article/fetaure', 'put', params)

const getArticle = (params) => APIHandler('/article', 'get', params)

const updateArticle = (params) => APIHandler('article/update', 'put', params)

const deleteArticle = (params) => APIHandler('article/delete', 'delete', params)

const AdminAssignArticle = (params) => APIHandler('article/assignToAdmin', 'put', params)

export { getArticles, acceptArticle, fetaureArticle, getArticle, updateArticle, deleteArticle,AdminAssignArticle }