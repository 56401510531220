import ReactTooltip from 'react-tooltip';
import React from 'react'

const ToolTip = props => {
    const { tip, id } = props
    return <ReactTooltip id={id} place="top" effect="solid">
        {tip}
    </ReactTooltip>
}

export default ToolTip