export const subRegionData = [
    { label: 'Australia and New Zealand', value: 'Australia and New Zealand', region: 'Oceania' },
    { label: 'Central Asia', value: 'Central Asia', region: 'Asia' },
    { label: 'Eastern Asia', value: 'Eastern Asia', region: 'Asia' },
    { label: 'Eastern Europe', value: 'Eastern Europe', region: 'Europe' },
    { label: 'Latin America and the Caribbean', value: 'Latin America and the Caribbean', region: 'Americas' },
    { label: 'Melanesia', value: 'Melanesia', region: 'Oceania' },
    { label: 'Micronesia', value: 'Micronesia', region: 'Oceania' },
    { label: 'Northern Africa', value: 'Northern Africa', region: 'Africa' },
    { label: 'Northern America', value: 'Northern America', region: 'Americas' },
    { label: 'Northern Europe', value: 'Northern Europe', region: 'Europe' },
    { label: 'Polynesia', value: 'Polynesia', region: 'Oceania' },
    { label: 'South-eastern Asia', value: 'South-eastern Asia', region: 'Asia' },
    { label: 'Southern Asia', value: 'Southern Asia', region: 'Asia' },
    { label: 'Southern Europe', value: 'Southern Europe', region: 'Europe' },
    { label: 'Sub-Saharan Africa', value: 'Sub-Saharan Africa', region: 'Africa' },
    { label: 'Western Asia', value: 'Western Asia', region: 'Asia' },
    { label: 'Western Europe', value: 'Western Europe', region: 'Europe' },
]
