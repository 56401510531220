import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUniqueIds } from '../../services/emailTemplates'

const initialState = {
    allUniqueIds: [],
    loadingUniqeIds: false,
};
/**
 * Fetch uniqueIds list
 */
export const fetchUniqueIds = createAsyncThunk(
    "uniqueIds/list",
    async (params, thunkAPI) => {
        try {
            const response = await getUniqueIds(params)
            const { data, success, err } = response;

            if (success) {
                return data.uniqueIds;
            } else {
                return thunkAPI.rejectWithValue(err);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);



export const emailTemplateSlice = createSlice({
    name: "emailTemplates",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchUniqueIds.pending]: (state, action) => {
            state.loadingUniqeIds = true;
        },
        [fetchUniqueIds.fulfilled]: (state, action) => {
            state.loadingUniqeIds = false;
            state.allUniqueIds = action.payload;
        },
        [fetchUniqueIds.rejected]: (state, action) => {
            state.loadingUniqeIds = false;
            state.error = action.error.message;
        },
    },
});

export default emailTemplateSlice.reducer;

