import React from 'react'
import styles from './checkbox.module.css'
import PropTypes from 'prop-types'

const CheckboxComp = (props) => {
    const { checked, onChange, label } = props
    return <label className={styles.checkboxContainer}><div>{label}</div>
        <input onChange={(e) => onChange(e)} type="checkbox" checked={checked} />
        <span className={`${styles.checkmark} ${checked && styles.checked}`}></span>
    </label>
}

CheckboxComp.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
}

CheckboxComp.defaultProps = {
    checked: false,
    onChange: () => console.error('onChange missing for CheckboxComp')
}



export default CheckboxComp