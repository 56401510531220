import React, { useEffect, useReducer, useState } from 'react'
import Breadcrumb from '../../common/breadcrumb'
import { TableComp, Button } from '../../shared'
import get from 'lodash/get'
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../../hooks/apiStateHandler'
import { getStaticMessage, updateStaticMessages, addStaticMesssage } from '../../../services/emailTemplates'
import moment from "moment";
import { EditStaticMessageModal } from '../../modals';
import { toast } from 'react-toastify';
import { SOMETING_WENT_WRONG, TOTAL_RECORDS } from '../../../constants/translations';
import { fetchUniqueIds } from '../../../features/email-templates/emailTemplatesSlice'
import { useDispatch } from 'react-redux';
import ModalAddStaticMessage from './create_static_messages'

const editValuesInitial = { showModal: false, values: {}, updating: false, error: null }

const StaticMesssage = () => {
    const dispatch = useDispatch()
    const [staticMessageState, staticMessagesDispatch] = useReducer(ApiStateReducer, initialState)
    const [editValues, setEditValues] = useState(editValuesInitial)

    useEffect(() => {
        dispatch(fetchUniqueIds())
        getStaticMessagesHandler({ offset: 0, limit: get(staticMessageState, 'limit', 20), })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        {
            name: "Unique Id",
            sortable: true,
            selector: "uniqueId",
        },
        {
            name: "Content",
            selector: "content",
            cellRenderer: (row) => (<div dangerouslySetInnerHTML={{ __html: row.content }} ></div>)
        },
        {
            name: "Date",
            sortable: true,
            selector: "dateUpdated",
            cellRenderer: (row) => (<div >{moment(row.dateUpdated).format("DD MMM, YYYY h:mm:ss a")}</div>)
        },
        {
            name: 'Actions',
            cellRenderer: (row) => (<>
                <Button title="Edit Static Message" clicked={() => setEditValues({ showModal: true, values: row })} className="IconButon"><i
                    className="fa fa-pencil"
                    style={{
                        width: 'auto',
                        fontSize: 18,
                        padding: 0,
                        color: "rgb(40, 167, 69)",
                    }}
                ></i></Button>
            </>)
        }
    ]

    const getStaticMessagesHandler = async (params) => {
        try {
            staticMessagesDispatch({ type: ApiStateEvents.FETCHING })
            const res = await getStaticMessage(params)
            if (res.success) {
                staticMessagesDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.staticMessage', []), offset: params.offset, totalRecords: get(res, 'data.total') })
            } else {
                toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
                staticMessagesDispatch({ type: ApiStateEvents.ERROR, data: [], totalRecords: get(res, 'data.total') })
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
            staticMessagesDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }

    const onPageChange = (page) => {
        getStaticMessagesHandler({
            offset: get(staticMessageState, 'limit', 20) * (page - 1),
            limit: get(staticMessageState, 'limit', 20),
            sortField: get(staticMessageState, 'sorting.sortField'),
            sortOrder: get(staticMessageState, 'sorting.sortOrder'),
        })
    }

    const onSort = ({ fieldName, sortOrder }) => {
        staticMessagesDispatch({
            type: ApiStateEvents.ADD_SORTING, sorting: {
                sortField: fieldName,
                sortOrder: sortOrder,
            }
        })
        getStaticMessagesHandler({
            offset: 0,
            limit: get(staticMessageState, 'limit', 20),
            sortField: fieldName,
            sortOrder: sortOrder,
        })
    }

    const staticMessageUpdateHandler = async (values) => {
        try {
            setEditValues({ ...editValues, updating: true })
            const res = await updateStaticMessages({ ...values })
            if (res.success) {
                const clonned = get(staticMessageState, 'data', [])
                const index = clonned.findIndex(o => o._id === values._id)
                if (index !== -1 && res.data.staticMessage) {
                    clonned[index] = res.data.staticMessage
                    staticMessagesDispatch({ type: ApiStateEvents.SUCCESS, data: clonned })
                    setEditValues(editValuesInitial)
                } else {
                    toast.error(SOMETING_WENT_WRONG)
                }
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
        }
    }
    //Add new static message settings
    const [mopen, setMopen] = useState(false);
    const [updating, setUpdating] = useState(false)
    const onOpenModal = () => {
        setMopen(true);
      };
    const onCloseModal = () => {
        setMopen(false);
    };
    const submitHandler = async (data) => {
        setUpdating(true)
        const res = await addStaticMesssage({uniqueId: data})
        if (res && res?.success) {
            toast.success(res?.data?.message)
            staticMessagesDispatch({ type: ApiStateEvents.SUCCESS})
            getStaticMessagesHandler({ offset: 0, limit: get(staticMessageState, 'limit', 20), })
        } else {
            toast.error(SOMETING_WENT_WRONG)
            getStaticMessagesHandler({ offset: 0, limit: get(staticMessageState, 'limit', 20), })
        }
        setMopen(false)
        setUpdating(false)
    };
    return <>
        <Breadcrumb title='Static Messages' />
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h5 className="BackButtonHeading">
                        Static Messages
                    </h5>
                </div>
                <div style={{ margin: "0 30px 15px" }}>
                    {!get(staticMessageState, 'loading') && TOTAL_RECORDS + get(staticMessageState, 'totalRecords', 0)}
                </div>
                <div className="card-body">
                    <ModalAddStaticMessage
                        mopen={mopen}
                        submitHandler={submitHandler}
                        onOpenModal={onOpenModal}
                        onCloseModal={onCloseModal}
                        updating={updating}
                    />
                    <TableComp
                        onPageChange={(p) => onPageChange(p)}
                        page={(get(staticMessageState, 'offset', 0) / get(staticMessageState, 'limit', 20) + 1)}
                        limit={get(staticMessageState, 'limit', 20)}
                        totalRecords={get(staticMessageState, 'totalRecords', 0)}
                        loading={get(staticMessageState, 'loading')}
                        data={get(staticMessageState, 'data', [])}
                        columns={columns}
                        onSort={onSort}
                        sortField={get(staticMessageState, 'sorting.sortField', null)}
                        sortOrder={get(staticMessageState, 'sorting.sortOrder', null)}
                    />
                </div>
            </div>
            {editValues.showModal && <EditStaticMessageModal
                values={editValues.values}
                mopen={true}
                submitHandler={(values) => staticMessageUpdateHandler(values)}
                loading={editValues.updating}
                error={editValues.error}
                title={"Edit Static Message"}
                onCloseModal={() => setEditValues({ showModal: false, values: {} })}
            />}
        </div>
    </>
}

export default StaticMesssage