import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { withRouter } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form } from "formik";
import { FormInput } from "../common/Form/input";
import { forgotValidationSchema } from "../common/validations";
import { translations } from "../../constants/translations";

import {
  forgotPassAdmin,
  userSelector,
  clearState,
} from "../../features/auth/authSlice";
import { Button } from "../shared";

const ForgotTabset = (props) => {
  const dispatch = useDispatch();

  const {
    isFetching,
    isSuccess,
    isError,
    successMessageForgot,
    errorMessageForgot,
  } = useSelector(userSelector);

  const submitHandler = (data) => {
    dispatch(forgotPassAdmin(data));
  };

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess, dispatch]);


  useEffect(() => {
    if (isError) {
      toast.error(errorMessageForgot);
      dispatch(clearState());
    }

    if (isSuccess) {
      toast.success(successMessageForgot);

      dispatch(clearState());
    }
  }, [isError, isSuccess, dispatch]);

  return (
    <Fragment>
      <ToastContainer autoClose={3000} />
      <Tabs>
        <TabList className="nav nav-tabs tab-coupon">
          <Tab className="nav-link">
            <User />
            {translations.Login.ForgotPassword}
          </Tab>
        </TabList>

        <TabPanel>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={forgotValidationSchema}
            onSubmit={(values, { resetForm }) => submitHandler(values)}
          >
            {({ values, isSubmitting }) => (
              <Form id="frmLogin" className="form-horizontal auth-form">
                <div className="form-group">
                  <FormInput
                    id="email"
                    type="email"
                    className="form-control"
                    name="email"
                    value={values.email}
                    placeholder="jane.doe@example.com"
                    maxLength="50"
                  />
                </div>

                <div className="form-button">
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    loading={isFetching}
                  >
                    {translations.Login.SendResetEmail}
                  </Button>
                  <Link to="/login" className="ml-1 pull-right">
                    {translations.Login.Login}
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};

export default withRouter(ForgotTabset);
