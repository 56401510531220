import React from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import get from 'lodash/get'
import { Button, FormFieldWrapper, TableComp } from '../shared'


const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
    },
};

const onValidate = (values) => {
    const { rejectedReasons, rejectDescription } = values
    const error = {}
    if ((!rejectedReasons || !get(rejectedReasons, 'length')) && (!rejectDescription || !get(rejectDescription, 'length'))) {
        error.rejectedReasons = "Please select reason or add description"
    }
    return error
}
const formFieldIds = {
    rejectDescription: "",
    rejectedReasons: []

}
const getInitalValues = (artwork = {}) => {
    let initialState = {}
    Object.keys(formFieldIds).forEach(key => {
        initialState[key] = artwork[key]
    })
    return initialState
}

const RejectReasonsModal = (props) => {
    const {
        mopen,
        onCloseModal,
        submitHandler,
        title,
        artwork,
        reasons,
        loading
    } = props


    const formik = useFormik({
        initialValues: getInitalValues(artwork || {}),
        validate: (v) => onValidate(v),
        onSubmit: values => {
            submitHandler(values)
        },
    });

    const parseReasons = () => {
        const r = reasons.map((reason) => {
            const { _id } = reason
            return {
                ...reason,
                selected: rejectedReasons && Array.isArray(rejectedReasons) && rejectedReasons.length > 0 ? rejectedReasons.findIndex(o => o === _id) !== -1 ? true : false : false
            }
        })
        //console.log({ r, rejectedReasons })
        return r
    }
    const { rejectedReasons, rejectDescription } = formik.values
    const columns = [
        {
            name: "Reject Reason",
            selector: "title",
            width: "100%",
            cellRenderer: (row) => (<p>{row.title || 'N/A'}</p>)
        },

    ]

    const rowSelectorHandler = ({ row, flag, rows }) => {
        let clonnedData = rejectedReasons || []
        if (row) {
            const { _id } = row || {}
            if (flag) {
                clonnedData.push(_id)
            } else {
                clonnedData = rejectedReasons.filter(o => o !== _id)
            }
        } else if (rows) {
            if (flag) {
                clonnedData = rows.map(({ _id }) => _id)
            } else {
                clonnedData = []
            }
        }
        formik.setFieldValue('rejectedReasons', clonnedData)
    }

    return (
        <Modal
            isOpen={mopen}
            onClose={onCloseModal}
            onRequestClose={onCloseModal}
            style={customStyles}
            className="ModalContent"
            overlayClassName="OverlayContent"
        >
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    {title}
                </h5>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <TableComp
                        columns={columns}
                        data={parseReasons()}
                        enableSelection
                        onSelection={({ row, rows, flag }) => rowSelectorHandler({ row, rows, flag })}
                    />
                </div>
                <div className="form-group">
                    <FormFieldWrapper label="Other" error={formik.errors.rejectedReasons}>
                        <textarea
                            className="form-control textareaExpand"
                            as="textarea"
                            onChange={formik.handleChange}
                            rows={10}
                            cols={20}
                            id="rejectDescription"
                            value={rejectDescription}
                            name="rejectDescription"
                            maxLength={500}
                            placeholder="Type your reason here"
                        />
                    </FormFieldWrapper>
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="firstName">{formik?.errors?.selectedReasons}</label>
            </div>
            <div className="modal-footer">
                <Button loading={loading} type="submit" clicked={formik.handleSubmit} className="btn btn-primary">
                    Reject
                </Button>
                <Button
                    type="button"
                    className="btn btn-light"
                    clicked={() => onCloseModal()}
                >
                    Close
                </Button>
            </div>
        </Modal >
    );
};

export default RejectReasonsModal;