import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { translations } from "../../constants/translations";
import axios from "axios";

const initialState = {
  admins: [],
  roles: [],
  status: "idle",
  roleStatus: "idle",
  error: null,
  isSuccess: false,
  isError: false,
  currentPage: 1,
  errorMessage: "",
  successMessage: "",
};
/**
 * Fetch admin users list
 */
export const fetchAdminUsers = createAsyncThunk(
  "admins/list",
  async ({ page, pageSize }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}admins/list`,
        {
          params: { page: page, limit: pageSize },
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;

      if (data.success) {
        return { data: data?.data?.users || [], total: data?.data?.total, currentPage: page };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Get user by ID
 * @param {*} state
 * @param {*} userId
 * @returns
 */
export const getAdminUserById = createAsyncThunk(
  "admins/userId",
  async ({ userId }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}admins/:userId`,
        {
          params: { userId: userId },
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data.admins;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/**
 * Delete Admin User
 */

export const deleteAdminUser = createAsyncThunk(
  "admins/delete",
  async ({ userId }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "admins/delete",
        {
          method: "DELETE",
          headers: {
            Authorization: `${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
          }),
        }
      );
      let data = await response.json();
      if (data.success) {
        data.userId = userId;
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Get all admin roles
 */
export const fetchAdminRoles = createAsyncThunk(
  "admins/roles",
  async (thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}admins/roles`,
        {
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;

      if (data.success) {
        return data.data.roles;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/**
 * Add admin user
 */
export const addAdminUser = createAsyncThunk(
  "admins/add",
  async ({ firstName, lastName, email, role, language }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "admins/add",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({ firstName, lastName, email, role, language }),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/**
 * Update admin user
 */
export const updateAdminUser = createAsyncThunk(
  "admins/update  ",
  async ({ firstName, lastName, email, role, language, userId }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "admins/update",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            role,
            language,
            userId,
          }),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const UpdateAdminList = (listData, user) => {
  return listData.map((list) => {
    if (list.uuid === user.uuid) {
      return user;
    } else {
      return list;
    }
  });
};

export const adminSlice = createSlice({
  name: "admins",
  initialState,
  reducers: {
    clearAdminState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = ""
      return state;
    },
  },
  extraReducers: {
    [fetchAdminUsers.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchAdminUsers.fulfilled]: (state, action) => {
      state.status = "completed";
      state.admins = action.payload.data;
      state.total = action.payload.total;
      state.currentPage = action.payload.currentPage;
    },
    [fetchAdminUsers.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [fetchAdminRoles.pending]: (state, action) => {
      state.roleStatus = "loading";
    },
    [fetchAdminRoles.fulfilled]: (state, action) => {
      state.roleStatus = "completed";
      state.roles = action.payload;
    },
    [fetchAdminRoles.rejected]: (state, action) => {
      state.roleStatus = "failed";
      state.error = action.error.message;
    },
    [addAdminUser.fulfilled]: (state, { payload }) => {
      state.isError = false;
      state.isSuccess = true;
      state.errorMessage = "";
      state.total = (state.total || 0) + 1
      state.successMessage = payload.data.message;
      state.admins = [payload.data.user, ...state.admins];
      return state;
    },
    [addAdminUser.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = payload.messageCode === "userAlreadyExist"
        ? translations.User.AlreadyExist
        : payload.message;
    },
    [addAdminUser.pending]: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
    [deleteAdminUser.fulfilled]: (state, { payload }) => {
      state.isError = false;
      state.isSuccess = true;
      state.errorMessage = "";
      state.total = (state.total || 1) - 1
      state.successMessage = payload.data.message;
      state.admins = state.admins.filter(
        (admin) => admin.uuid !== payload.data.userId
      );
      return state;
    },
    [deleteAdminUser.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = payload.messageCode === payload.message;
    },
    [deleteAdminUser.pending]: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
    [updateAdminUser.fulfilled]: (state, { payload }) => {
      state.isError = false;
      state.isSuccess = true;
      state.errorMessage = "";
      state.successMessage = payload.data.message;
      state.admins = UpdateAdminList(state.admins, payload.data.user);
      return state;
    },
    [updateAdminUser.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = payload.messageCode === payload.message;
    },
    [updateAdminUser.pending]: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
});

export default adminSlice.reducer;

export const { clearAdminState } = adminSlice.actions;

export const adminSelector = (state) => state.admins;

/**
 * Get all admin users
 * @param {*} state
 * @returns
 */
export const getAllAdminUsers = (state) => state.admins.admins;

/**
 * Get all admin roles
 * @param {*} state
 * @returns
 */
export const getAdminRoles = (state) => {
  return state.admins.roles;
};