import React from "react";
import { useField } from "formik";

export const FormInput = ({ label, ...props }) => {
  let outputInput = "";
  const [field, meta] = useField(props);
  if (props.type === "textarea") {
    outputInput = <textarea className="form-control" {...field} {...props} />;
  } else {
    outputInput = <input className="form-control" {...field} {...props} />;
  }

  return (
    <>
      {outputInput}
      {meta.touched && meta.error ? (
        <div className="alert alert-danger">{meta.error}</div>
      ) : null}
    </>
  );
};
export const LabelFormInput = ({ label, ...props }) => {
  let outputInput = "";
  const [field, meta] = useField(props);
  if (props.type === "textarea") {
    outputInput = <textarea className="form-control" {...field} {...props} />;
  } else {
    outputInput = <input className="form-control" {...field} {...props} />;
  }

  return (
    <div className="form-group">
      <label htmlFor={props.id || props.name}>{label}</label>
      {outputInput}
      {meta.touched && meta.error ? (
        <div className="alert alert-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export const FormSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <select {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};
