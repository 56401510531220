import React from 'react'
import styles from '../helpdesk.module.css'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import { createImageUrl } from '../../../utils/imageHelper'
import InfiniteScroll from 'react-infinite-scroll-component'
import renderPremiumBatch from '../../../utils/renderPremiumBatch'
import { isEmptyObj } from '../../../utils/checkEmptyObject'

const UsersList = props => {
    const { conversations, openChat, loading, loadMoreUsers, totalConversations } = props
    const fetchMore = () => {
        !loading && loadMoreUsers()
    }

    const getAuthorName = (user) => {
        const { firstName, lastName, email } = user || {}
        if (firstName || lastName) {
            return `${firstName || ''} ${lastName || ''}`
        }
        return email?.value || 'N/A'
    }
    return <div className={styles.usersList} >
        <InfiniteScroll
            dataLength={conversations.length}
            scrollableTarget={'chat-users'}
            next={() => fetchMore()}
            hasMore={!totalConversations || totalConversations > conversations.length}
            loader={<></>}
            endMessage={<></>}
        >
            {conversations.map(user =>
                <div key={user._id} onClick={() => openChat(user)} className={styles.userList}>
                    <img className={styles.userImage} src={createImageUrl(user?.userInfo?.profilePicUrl)} onError={(e) => e.target.src = '/static/media/man.7b23c9a1.png'} alt="" />
                    <div className={styles.conversationInfo}>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <h1 className={styles.userName}>{getAuthorName(user.userInfo)} 
                        {!isEmptyObj(user.userInfo.userSubscriptionId)&& !isEmptyObj(user.userInfo.userSubscriptionId.subscription) &&renderPremiumBatch(user.userInfo.userSubscriptionId.subscription)} 
                        </h1>
                        {user.userInfo?.userRoleId?.roleName} - {user.userInfo.country?.value}

                        </div>
                        {user.isUnread && <div className={styles.readMsg}></div>}
                    </div>
                </div>)}
        </InfiniteScroll>
        {loading && <div className="m-10">{[...new Array(10)].map(i => <Skeleton height={60} />)}</div>}
        {!loading && !conversations.length ? <p style={{ textAlign: "center" }}>No conversation found</p> : null}
    </div>
}

UsersList.propTypes = {
    conversations: PropTypes.array.isRequired,
    openChat: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
}

UsersList.defaultProps = {
    conversations: [],
    openChat: () => null,
    loading: false,
}

export default UsersList