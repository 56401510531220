import React, { useEffect, useState } from "react";
// import Modal from "react-responsive-modal";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Editor } from "@tinymce/tinymce-react";
import Spinner from "../common/Spinner/Spinner";
import { translations } from "../../constants/translations";
import { fetchContentById } from "../../features/common/commonSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { replaceWordpressImage } from "../../utils/image";

import "./content-management.css";
import { createImageUrl, uploadImageToMediaLibrary } from "../../utils/imageHelper";

const EditorWrap = styled.div`
  padding: 0px 0;
  border: 2px solid #eee;
  margin: 0px 0 12px;
  .tox .tox-statusbar {
    display: none;
  }
  .tox-tinymce {
    border: 0;
  }
  .tox .tox-menubar + .tox-toolbar-overlord .tox-toolbar__primary {
    border-color: #eee;
    border-width: 2px;
    background-image: none;
    border-bottom: 2px solid #eee;
  }
  .tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
    border-right: 0;
  }
  .tox .tox-menubar {
    @media (max-width: 767px) {
      background-image: none;
      border-bottom: 2px solid #eee;
    }
  }
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
  },
};

Modal.setAppElement("#root");

const ModalEditContent = (props) => {
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const {
    heading,
    mopen,
    viewOnly,
    contentId,
    languages,
    onCloseModal,
    submitHandler,
    initialValue,
    currentLanguage,
  } = props;

  const [englishLangContent, setEnglishLangContent] = useState("");
  const [language, setLanguage] = useState(currentLanguage ?? "");
  const [contentText, setContentText] = useState(initialValue ?? "");
  const [showBlank, setShowBlank] = useState(0);
  const [showLoading, setShowLoading] = useState(true);
  const [plainText, setPlainText] = useState(false)	

  /**
   * Fetch content from server
   * @param {*} page
   * @param {*} size
   */
  const fetchContentData = async (contentId, languageId) => {
    setShowLoading(true);
    if (language !== "") {
      dispatch(fetchContentById({ contentId, language }))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          let conText = "";
          let engCont = "";
          if (originalPromiseResult !== null) {
            if (originalPromiseResult.content !== null) {
              setShowBlank(0);
              conText = originalPromiseResult.content.content;
            } else {
              setShowBlank(1);
            }
            engCont = originalPromiseResult.engLangContent;
          } else if (originalPromiseResult === null && viewOnly === 1) {
            setShowBlank(1);
          }
          setContentText(conText);
          setEnglishLangContent(engCont);
        })
        .catch((rejectedValueOrSerializedError) => {
          toast.error(rejectedValueOrSerializedError);
        });
    }
    setShowLoading(false);
  };

  useEffect(() => {
    if (contentId !== undefined && contentId !== "") {
      if (language === "") setLanguage(currentLanguage);
      fetchContentData(contentId);
    }
  }, [contentId, language, currentLanguage, dispatch]);

  const fetchUpdatedContent = (e) => {
    e.preventDefault();
    
      setShowLoading(true);
      setLanguage(e.target.value);
      fetchContentData(contentId, e.target.value);
      setShowLoading(false);
    
  };

  const saveContent = (e) => {
    e.preventDefault();
    if ( language === '605c5ea1f451c11494fd1923' ) { 
      if (contentText !== undefined) {
        setShowError(false);
        submitHandler(language, contentText);
      } else {
        setShowError(true);
        toast.error("Please enter content");
      }
    } else {
      setShowError(false);
      submitHandler(language, contentText);
    }
    
  };

  const manageCloseModal = (e) => {
    onCloseModal("VaryingMdo");
    setLanguage(currentLanguage)
  }
  const image_upload_handler = async (blobInfo, success, failure, progress) => {
    const size = blobInfo.blob().size
    if (size > (1024 * 1024)) {
      toast.error('Article image sizes cannot exceed 1MB')
      failure('Image upload failed due to a XHR Transport error.', { remove: true })
      return;
    } 
    const response = await uploadImageToMediaLibrary(blobInfo.blob(), blobInfo.filename())
    if (response && response.data && response.data.data && response.data.data.images && response.data.data.images[0] ) {
      success(createImageUrl(response.data.data.images[0].pictureUrl))
    } else {
      failure('Image upload failed due to a XHR Transport error.', { remove: true })
    }
  }
  return (
    <div className="btn-popup pull-right">
      <ToastContainer autoClose={2000} />
      {mopen && showLoading ? (
        <Spinner />
      ) : (
        <Modal
          focusTrapped={false}
          id="modalContent"
          isOpen={mopen}
          onRequestClose={onCloseModal}
          style={customStyles}
          className="ModalContent"
          overlayClassName="OverlayContent"
        >
          <div className="modal-header">
            <h5 className="modal-title f-w-600" id="exampleModalLabel2">
              {viewOnly ? "View" : "Edit"} Content
            </h5>
            <h5 className="modal-title f-w-600" id="exampleModalLabel2">
              {heading}
            </h5>
          </div>
          <div className="modal-body">
            <label className="font-b">English Content:</label>
            <div
              className="originalContent"
              dangerouslySetInnerHTML={{
                __html: replaceWordpressImage(englishLangContent),
              }}
            ></div>
            <div className="form-group">
              <label htmlFor="language">Language</label>
              <select
                name="language"
                className="form-control"
                value={language}
                onChange={(e) => fetchUpdatedContent(e)}
              >
                {languages.map((lang, index) => {
                  return (
                    <option key={index} value={lang._id}>
                      {lang.title}
                    </option>
                  );
                })}
              </select>
            </div>
            {!viewOnly ? (
              <div className="form-group">
                <label htmlFor="content">Content</label><br/>
                <button type="button" onClick={() => {setPlainText(!plainText)}}>Switch to {plainText ? 'rich text editor' : 'plain text editor'}</button>	
                <EditorWrap>
                {                    	
                  plainText ? 	
                  <textarea style={{width: "100%", minHeight: "250px"}} value={contentText} onChange={(e) => {	
                    setContentText(e.target.value)
                  }}></textarea>	
                  :
                  <Editor
                    apiKey={process.env.REACT_APP_TINY_MCE}
                    initialValue={initialValue}
                    value={replaceWordpressImage(contentText)}
                    init={{
                      height: 500,
                      menubar: false,
                      target: false,
                      media_live_embeds: true,
                      plugins:
                          'visualblocks insertdatetime table help wordcount advlist autolink quickbars lists link image charmap print preview anchor searchreplace code fullscreen media paste code placeholder mediaembed',
          
                      quickbars_insert_toolbar: 'quickimage',
                      quickbars_selection_toolbar: 'bold italic underline h2 h3',
                      toolbar1:
                      'bold italic underline h1 h2 h3 removeformat alignleft aligncenter alignright alignjustify numlist blockquote media image link',
                      toolbar2: " selectall strikethrough subscript visualaid	copy	cut fontselect fontsizeselect lineheight paste remove ",
                      quickbars_image_toolbar: 'alignleft aligncenter alignright | remove',
                      image_title: true,
                      automatic_uploads: true,
                      file_picker_types: 'image',
                      images_upload_handler: image_upload_handler,
                      file_picker_callback: function (cb, value, meta) {
                        var input = document.createElement('input')
                        input.setAttribute('type', 'file')
                        input.setAttribute('accept', 'image/*')
                        input.onchange = function () {
                          var file = this.files[0]
                          var reader = new FileReader()
                          reader.onload = function () {
                            var id = 'blobid' + new Date().getTime()
                            let blobCache = this.editor.editorUpload.blobCache // Use this.editor instead of tinymce.activeEditor
                            var base64 = reader.result.split(',')[1]
                            var blobInfo = blobCache.create(id, file, base64)
                            blobCache.add(blobInfo)
                            cb(blobInfo.blobUri(), { title: file.name })
                          }
                          reader.readAsDataURL(file)
                        }
                        input.click()
                      },
                    }}
                    onEditorChange={(newValue, editor) =>
                      setContentText(newValue)
                    }
                  ></Editor>
                }
                </EditorWrap>
              </div>
            ) : (
              <div className="view-content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: replaceWordpressImage(contentText.replace(
                      /(<? *script)/gi,
                      "illegalscript"
                    )),
                  }}
                ></div>

                {showBlank ? (
                  <div className="alert alert-danger">
                    <i>No content has been added for this langauge</i>
                  </div>
                ) : null}
              </div>
            )}
            {showError ? (
              <div className="alert alert-danger">Please enter content</div>
            ) : null}
          </div>
          <div className="modal-footer">
            {!viewOnly ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => saveContent(e)}
              >
                {translations.User.Save}
              </button>
            ) : null}
            <button
              type="button"
              className="btn btn-light"
              onClick={(e) => manageCloseModal(e)}
            >
              {translations.User.Close}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ModalEditContent;