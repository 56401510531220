import React, { useEffect, useState} from "react";
import Modal from "react-responsive-modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import styled from "styled-components";
import { translations } from "../../constants/translations";
import ArrowIcon from '../../../src/assets/images/down-arrow.png'
import { Button } from '../shared'
import { checkMassterProfile } from "../../services/masterProfile";
import { toast } from "react-toastify";

const AddAdminDialog = styled.div`
  select {
      appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
      background: url(${ArrowIcon}) no-repeat right;
      background-position-x: 96.5%;
  }
  & .errorDiv {
    color: red ; 
  }
`

const ModalAddNewCategory = (props) => {
  const {
    mopen,
    onOpenModal,
    onCloseModal,
    submitHandler,
    updating
  } = props;

  const [ showError, setShowError ] = useState( false  )
  const [ disable, setDisable] = useState( true  )
  const [formData, setFormData] = useState({
    title: ''
  });
  const {
    title,
  } = formData;
  useEffect(()=>{
    if(title)
      setDisable(false)
    else
      setDisable(true)
  },[title])
  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  return (
    <div className="btn-popup pull-left">
      <button
        type="button"
        className="btn btn-primary"
        onClick={onOpenModal}
        data-toggle="modal"
        data-original-title="test"
        data-target="#exampleModal"
      >
        Add New Category
      </button>
      <Modal open={mopen} onClose={onCloseModal} focusTrapped={false}>
        <div className="modal-header">
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Add New Category
          </h5>
        </div>
        <div className="modal-body">
        <Formik
            initialValues={{
              title: "",
            }}
            onSubmit={(values, { resetForm }) => {
              submitHandler({title});
            }}
          >
            <AddAdminDialog>
              <Form id="frmAddAdmin">
                <div className="form-group">
                  <label htmlFor="title">Title<span className="required">*</span></label>
                  <Field
                    className="form-control"
                    id="title"
                    name="title"
                    placeholder="title"
                    maxLength={40}
                    onChange={onChangeHandler}
                    value={title}
                  />
                  { showError && ( <div className="errorDiv">Title {translations.MasterProfile.errorMessage}</div> )}
                </div>
                <Button type="submit" loading={updating} loadingText="Saving" disabled={disable} className="btn btn-primary">
                  Submit
                </Button>
                <Button
                  className="btn btn-light"
                  clicked={() => onCloseModal("VaryingMdo")}
                >
                  Close
                </Button>
              </Form>
            </AddAdminDialog>
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAddNewCategory;
