import React, { useState } from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import { Button } from '../shared'
import { rejectReasonValidationSchema } from '../common/validations'
import { Editor } from "@tinymce/tinymce-react";
import styled from "styled-components";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
    },
};
const EditorWrap = styled.div`
  padding: 0px 0;
  border: 2px solid #eee;
  margin: 0px 0 12px;
  .tox .tox-statusbar {
    display: none;
  }
  .tox-tinymce {
    border: 0;
  }
  .tox .tox-menubar + .tox-toolbar-overlord .tox-toolbar__primary {
    border-color: #eee;
    border-width: 2px;
    background-image: none;
    border-bottom: 2px solid #eee;
  }
  .tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
    border-right: 0;
  }
  .tox .tox-menubar {
    @media (max-width: 767px) {
      background-image: none;
      border-bottom: 2px solid #eee;
    }
  }
`;

const ButtonSwitcher  = styled.button`
    &.editorSwitch {
        text-align: center;
        position: relative;
        left: 70%;
        // top: 40px;
        // z-index: 9;
    }` 
; 

const RejectReasonsModal = (props) => {
    const {
        mopen,
        onCloseModal,
        submitHandler,
        title,
        values,
        loading
    } = props

    const [plainText, setPlainText] = useState(false)	
    const [contentText, setContentText] = useState(values.description ?? "");
    const formik = useFormik({
        initialValues: { ...values },
        validationSchema: () => rejectReasonValidationSchema,
        onSubmit: v => {
            //submitHandler(v)
            const valToSubmit = {
                "_id": v._id,
                "title": v.title,
                "description": contentText,
                "isDeleted": 0,
                "dateCreated": v.dateCreated,
                "dateUpdated": v.dateUpdated
            }
            submitHandler(valToSubmit)
        },
    });

    const { title: reasonTitle, description } = formik.values

    return (
        <Modal
            isOpen={mopen}
            onClose={onCloseModal}
            onRequestClose={onCloseModal}
            style={customStyles}
            className="ModalContent"
            overlayClassName="OverlayContent"
        >
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    {title}
                </h5>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <label htmlFor="firstName">Title</label>
                    <input value={reasonTitle} className="form-control" id="title" maxLength={100} onChange={formik.handleChange} />
                </div>
                <div className="form-group">
                    {/* <label htmlFor="firstName">Description</label>
                    <textarea
                        className="form-control textareaExpand"
                        as="textarea"
                        onChange={formik.handleChange}
                        rows={10}
                        cols={20}
                        id="description"
                        value={description}
                        name="description"
                        //maxLength={500}
                        placeholder="Type description here"
                    /> */}
                    <ButtonSwitcher type="button" className="editorSwitch" onClick={() => {setPlainText(!plainText)}}>Switch to {plainText ? 'rich text editor' : 'plain text editor'}</ButtonSwitcher>
                        <EditorWrap>
                        {                    	
                        plainText ? 	
                        <textarea style={{width: "100%", minHeight: "250px"}} value={contentText} onChange={(e) => {	
                            setContentText(e.target.value)
                        }}></textarea>	
                        :
                        <Editor
                            apiKey={process.env.REACT_APP_TINY_MCE}
                            initialValue={values.description}
                            value={contentText}
                            init={{
                            height: 300,
                            menubar: false,
                            target: false,
                            media_live_embeds: true,
                            plugins:
                                'visualblocks help wordcount advlist autolink quickbars lists link preview anchor fullscreen paste',
                
                            //quickbars_insert_toolbar: 'quickimage',
                            quickbars_selection_toolbar: 'bold italic underline h2 h3',
                            toolbar1:
                            'bold italic underline h1 h2 h3 numlist blockquote link ',
                            toolbar2: "code",
                            }}
                            onEditorChange={(newValue, editor) =>{
                                //console.log( newValue ) ; 
                                setContentText(newValue) ; 
                            }
                            }
                        ></Editor>
                        }
                        </EditorWrap>
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="firstName">{formik?.errors?.selectedReasons}</label>
            </div>
            <div className="modal-footer">
                <Button loading={loading} type="submit" clicked={formik.handleSubmit} className="btn btn-primary">
                    Save
                        </Button>
                <Button
                    type="button"
                    className="btn btn-light"
                    clicked={() => onCloseModal()}
                >
                    Close
                        </Button>
            </div>
        </Modal >
    );
};

export default RejectReasonsModal;