import React, { useEffect, useReducer, useState } from 'react'
import Breadcrumb from "../common/breadcrumb";
import { TableComp, Button } from '../shared';
import get from 'lodash/get'
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../hooks/apiStateHandler'
import moment from "moment";
import { EditSuspensionReason } from '../modals';
import { toast } from 'react-toastify';
import { SOMETING_WENT_WRONG, TOTAL_RECORDS } from '../../constants/translations';
import { getSuspensionReasons, updateSuspensionReason, insertSuspensionReason } from '../../services/suspensionReasons';

const editValuesInitial = { showModal: false, values: {}, updating: false, error: null }
const EmailTemplatesList = (props) => {
    const [suspensionReasonsState, suspensionReasonsDispatch] = useReducer(ApiStateReducer, initialState)
    const [editValues, setEditValues] = useState(editValuesInitial)
    useEffect(() => {
        getSuspensionReasonsHandler({})
    }, [])

    const columns = [
        {
            name: "Title",
            selector: "title",
            sortable: true,
        },
        // {
        //     name: "Description",
        //     selector: "description",
        //     sortable: true,
        // },
        {
            name: "Date",
            sortable: true,
            selector: "dateUpdated",
            cellRenderer: (row) => (<div >{moment(row.dateUpdated).format("DD MMM, YYYY h:mm:ss a")}</div>)
        },
        {
            name: 'Actions',
            cellRenderer: (row) => (<>
                <Button title="Edit Reason" clicked={() => setEditValues({ showModal: true, values: row })} className="btn btn-primary m-10">Edit</Button>
            </>)
        }
    ]

    const getSuspensionReasonsHandler = async (params) => {
        try {
            suspensionReasonsDispatch({ type: ApiStateEvents.FETCHING })
            const res = await getSuspensionReasons(params)
            if (res.success) {
                suspensionReasonsDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.reasons', []), totalRecords: get(res, 'data.total', 0) })
            } else {
                suspensionReasonsDispatch({ type: ApiStateEvents.ERROR, data: [] })
            }
        } catch (error) {
            suspensionReasonsDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }


    const blankSuspensionReasonsHandler = async (params) => {
        try {
            suspensionReasonsDispatch({ type: ApiStateEvents.FETCHING })
            const res = await insertSuspensionReason(params)
            if (res.success) {
                suspensionReasonsDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.reasons', []), totalRecords: get(res, 'data.total', 0) })
            } else {
                suspensionReasonsDispatch({ type: ApiStateEvents.ERROR, data: [] })
            }
        } catch (error) {
            suspensionReasonsDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }

    const onSort = ({ fieldName, sortOrder }) => {
        suspensionReasonsDispatch({
            type: ApiStateEvents.ADD_SORTING, sorting: {
                sortField: fieldName,
                sortOrder: sortOrder,
            }
        })
        getSuspensionReasonsHandler({
            sortField: fieldName,
            sortOrder: sortOrder,
        })
    }

    const suspensionReasonUpdateHandler = async (values) => {
        try {
            setEditValues({ ...editValues, updating: true })
            const res = await updateSuspensionReason({ ...values })
            if (res.success) {
                const clonned = get(suspensionReasonsState, 'data', [])
                const index = clonned.findIndex(o => o._id === values._id)
                if (index !== -1 && res.data.reason) {
                    clonned[index] = res.data.reason
                    toast.success(get(res, 'data.message', ''))
                    //console.log({ clonned })
                    suspensionReasonsDispatch({ type: ApiStateEvents.SUCCESS, data: clonned })
                    setEditValues(editValuesInitial)
                } else {
                    toast.error(SOMETING_WENT_WRONG)
                }
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
        }
    }

    return <>
        <Breadcrumb title='Suspension Reasons' />
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h5 className="BackButtonHeading">
                        Suspension Reasons
                    </h5>
                </div>
                <div style={{ margin: "15px 30px 15px" }}>
                    {!get(suspensionReasonsState, 'loading') && TOTAL_RECORDS + get(suspensionReasonsState, 'totalRecords', 0)}
                </div>
                <div>
                    {!get(suspensionReasonsState, 'loading') && <div onClick={() => {blankSuspensionReasonsHandler(editValues)}}>NEW SUSPENSION REASON</div>}
                </div>
                <div className="card-body">
                    <TableComp
                        loading={get(suspensionReasonsState, 'loading')}
                        data={get(suspensionReasonsState, 'data', [])}
                        columns={columns}
                        onSort={onSort}
                        sortField={get(suspensionReasonsState, 'sorting.sortField', null)}
                        sortOrder={get(suspensionReasonsState, 'sorting.sortOrder', null)}
                    />
                </div>
            </div>
            {editValues.showModal && <EditSuspensionReason
                values={editValues.values}
                mopen={true}
                submitHandler={(values) => suspensionReasonUpdateHandler(values)}
                loading={editValues.updating}
                error={editValues.error}
                title={"Edit Suspension Reason"}
                onCloseModal={() => setEditValues({ showModal: false, values: {} })}
            />}
        </div>
    </>
}

export default EmailTemplatesList