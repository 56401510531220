import React, { useEffect, useMemo, useReducer, useState } from 'react'
import UsersList from './components/usersList'
import ChatHistory from './components/chatHistory'
import Breadcrumb from "../common/breadcrumb";
import styles from './helpdesk.module.css'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../hooks/apiStateHandler'
import { getUsersList, getChatHistory, sendMessage, markRead } from '../../services/helpdesk'
import HelpdeskSearch from './components/search';
import { SOMETING_WENT_WRONG } from '../../constants/translations';

const Helpdesk = props => {
    const { history, location } = props
    const [usersList, usersListDipatch] = useReducer(ApiStateReducer, initialState)
    const [chatHistory, chatHistoryDipatch] = useReducer(ApiStateReducer, initialState)
    const [appliedfilters, setAppliedFilters] = useState({})
    const [allfilters, setAllFilters] = useState({})
    const [adminUserInfo, setAdminUser] = useState({})

    const getConversationId = useMemo(() => {
        const search = location.search
        const params = new URLSearchParams(search)
        return params.get('conversationId');
    }, [location])

    const getUserInfo = (user = {}, adminUser = {}) => {
        const { userIdReceiver, userIdSender } = user
        if (adminUser._id !== userIdReceiver?._id) {
            return userIdReceiver
        }
        return userIdSender
    }
    const getUsersListHandler = async (params) => {
        try {
            usersListDipatch({ type: ApiStateEvents.FETCHING })
            const res = await getUsersList(params)
            //console.log({ res })
            if (res.success) {
                const oldConvo = params.offset !== 0 ? get(usersList, 'data', []) : []
                const adminUser = get(res, 'data.adminUser', {})
                const parsedUsers = []
                setAdminUser(adminUser)
                get(res, 'data.conversations', []).forEach(user => {
                    if (user && user?._id && user.userIdSender && user.userIdReceiver) {
                        parsedUsers.push({
                            id: user?._id,
                            userInfo: getUserInfo(user, get(res, 'data.adminUser', {})),
                            isUnread: checkUnreadMessage(user?.isRead, user?.readPendingFrom, adminUser?._id)
                        })
                    }
                })
                //console.log({ parsedUsers, oldConvo })
                usersListDipatch({ type: ApiStateEvents.SUCCESS, data: [...oldConvo, ...parsedUsers], offset: params.offset, totalRecords: get(res, 'data.total') })
            } else {
                toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
                usersListDipatch({ type: ApiStateEvents.ERROR, data: [], totalRecords: get(res, 'data.total') })
            }
        } catch (error) {
            //console.log({ error })
            usersListDipatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }

    const checkUnreadMessage = (isRead, readPendingFrom, adminUser) => {
        return !isRead && readPendingFrom === adminUser
    }

    const getChatHistoryHandler = async (params) => {
        try {
            chatHistoryDipatch({ type: ApiStateEvents.FETCHING })
            const res = await getChatHistory(params)
            let allMessages = []
            if (res.success) {
                if (params.offset !== 0) {
                    allMessages = [...chatHistory.data || [], ...res?.data?.chats || []]
                } else {
                    allMessages = res?.data?.chats
                }
                chatHistoryDipatch({ type: ApiStateEvents.SUCCESS, data: allMessages, offset: params.offset, totalRecords: get(res, 'data.total') })
            } else {
                toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
                chatHistoryDipatch({ type: ApiStateEvents.ERROR, data: [], totalRecords: get(res, 'data.total') })
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
            chatHistoryDipatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }

    useEffect(() => {
        getUsersListHandler({ limit: get(usersList, 'limit', 20), offset: get(usersList, 'offset', 0) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getSelectedUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersList.data])

    useEffect(() => {
        if (getConversationId) {
            getChatHistoryHandler({ conversationId: getConversationId, offset: 0, limit: 20 })
            chatHistoryDipatch({ type: ApiStateEvents.SET_TO_INITIAL, data: { loading: true } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getConversationId])

    const sendMessageHandler = async (data) => {
        const sentMessageRes = await sendMessage(data)
        if (sentMessageRes.success && sentMessageRes?.data?.message) {
            const newMessage = sentMessageRes?.data?.message
            chatHistoryDipatch({ type: ApiStateEvents.SUCCESS, data: [newMessage, ...chatHistory.data] })
        } else {
            toast.error(get(sentMessageRes, 'err.response.data.message') || 'Something went wrong')
        }
    }

    const getSelectedUser = () => {
        const selectedUser = getConversationId
        if (selectedUser) {
            return usersList?.data.find(c => c.id === selectedUser)
        }
        return {}
    }

    const loadMoreUsers = () => {
        getUsersListHandler({ limit: get(usersList, 'limit', 20), offset: get(usersList, 'offset', 0) + get(usersList, 'limit', 20), filters: appliedfilters })
    }

    const loadMoreChatHistory = () => {
        getChatHistoryHandler({ conversationId: getConversationId, limit: get(chatHistory, 'limit', 20), offset: get(chatHistory, 'offset', 0) + get(usersList, 'limit', 20) })
    }

    const applyFilters = (filt) => {
        history.push('/help-desk')
        setAppliedFilters(filt)
        setAllFilters(filt)
        usersListDipatch({ type: ApiStateEvents.SUCCESS, data: [], offset: 0, totalRecords: 0 })
        getUsersListHandler({ limit: get(usersList, 'limit', 20), offset: 0, filters: filt })
    }

    const markReadHandler = async (id) => {
        const clonned = usersList?.data || []
        const index = get(usersList, 'data', []).findIndex(o => o.id === id)
        if (clonned[index] && clonned[index].isUnread) {
            const { success } = await markRead({ conversationId: id })
            if (success) {

                if (index !== -1) {
                    clonned[index].readPendingFrom = ''
                    clonned[index].isUnread = false
                    usersListDipatch({ type: ApiStateEvents.SUCCESS, data: clonned })
                }
            }
        }
    }

    return <>
        <Breadcrumb title="Help Desk" parent="Users" />
        <div className="container-fluid">
            <div className="card">

                <div className="card-body">
                    <div>
                        <HelpdeskSearch addFilter={(f) => setAllFilters({ ...allfilters, ...f })} appliedFilters={appliedfilters} allfilters={allfilters} applyFilters={(f) => applyFilters(f)} />
                    </div>
                    <div className={styles.messenger}>
                        <div id="chat-users" className={`${styles.scrollable} ${styles.sidebar}`}>
                            <UsersList
                                totalConversations={get(usersList, 'totalRecords', null)}
                                loadMoreUsers={() => loadMoreUsers()}
                                loading={usersList?.loading}
                                openChat={(data) => {
                                    markReadHandler(data.id);
                                    history.push('/help-desk?conversationId=' + data.id)
                                }}
                                conversations={usersList?.data || []} />
                        </div>
                        <div className={`${styles.scrollable} ${styles.content}`}>
                            {getConversationId ? <ChatHistory totalMessages={get(chatHistory, 'totalRecords', null)} conversationId={getConversationId} loadMoreChat={() => loadMoreChatHistory()} loading={chatHistory.loading} user={getSelectedUser()} sendMessage={(data) => sendMessageHandler(data)} chat={get(chatHistory, 'data', [])} /> : <p style={{ textAlign: 'center', marginTop: '30%' }} >Click on user to view chat history</p>}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Helpdesk