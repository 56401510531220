export const countriesData = [
    { label: 'Afghanistan', value: 'Afghanistan', subregion: 'Southern Asia' },
    { label: 'Åland Islands', value: 'Åland Islands', subregion: 'Northern Europe' },
    { label: 'Albania', value: 'Albania', subregion: 'Southern Europe' },
    { label: 'Algeria', value: 'Algeria', subregion: 'Northern Africa' },
    { label: 'American Samoa', value: 'American Samoa', subregion: 'Polynesia' },
    { label: 'AndorrA', value: 'AndorrA', subregion: 'Southern Europe' },
    { label: 'Angola', value: 'Angola', subregion: 'Sub-Saharan Africa' },
    { label: 'Anguilla', value: 'Anguilla', subregion: 'Latin America and the Caribbean' },
    { label: 'Antarctica', value: 'Antarctica', subregion: 'Northern America' },
    { label: 'Antigua and Barbuda', value: 'Antigua and Barbuda', subregion: 'Latin America and the Caribbean' },
    { label: 'Argentina', value: 'Argentina', subregion: 'Latin America and the Caribbean' },
    { label: 'Armenia', value: 'Armenia', subregion: 'Western Asia' },
    { label: 'Aruba', value: 'Aruba', subregion: 'Latin America and the Caribbean' },
    { label: 'Australia', value: 'Australia', subregion: 'Australia and New Zealand' },
    { label: 'Austria', value: 'Austria', subregion: 'Western Europe' },
    { label: 'Azerbaijan', value: 'Azerbaijan', subregion: 'Western Asia' },
    { label: 'Bahamas', value: 'Bahamas', subregion: 'Latin America and the Caribbean' },
    { label: 'Bahrain', value: 'Bahrain', subregion: 'Western Asia' },
    { label: 'Bangladesh', value: 'Bangladesh', subregion: 'Southern Asia' },
    { label: 'Barbados', value: 'Barbados', subregion: 'Latin America and the Caribbean' },
    { label: 'Belarus', value: 'Belarus', subregion: 'Eastern Europe' },
    { label: 'Belgium', value: 'Belgium', subregion: 'Western Europe' },
    { label: 'Belize', value: 'Belize', subregion: 'Latin America and the Caribbean' },
    { label: 'Benin', value: 'Benin', subregion: 'Sub-Saharan Africa' },
    { label: 'Bermuda', value: 'Bermuda', subregion: 'Northern America' },
    { label: 'Bhutan', value: 'Bhutan', subregion: 'Southern Asia' },
    { label: 'Bolivia', value: 'Bolivia', subregion: 'Latin America and the Caribbean' },
    { label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina', subregion: 'Southern Europe' },
    { label: 'Botswana', value: 'Botswana', subregion: 'Sub-Saharan Africa' },
    { label: 'Bouvet Island', value: 'Bouvet Island', subregion: 'Latin America and the Caribbean' },
    { label: 'Brazil', value: 'Brazil', subregion: 'Latin America and the Caribbean' },
    { label: 'British Indian Ocean Territory', value: 'British Indian Ocean Territory', subregion: 'Sub-Saharan Africa' },
    { label: 'Brunei Darussalam', value: 'Brunei Darussalam', subregion: 'Sub-Saharan Africa' },
    { label: 'Bulgaria', value: 'Bulgaria', subregion: 'Eastern Europe' },
    { label: 'Burkina Faso', value: 'Burkina Faso', subregion: 'Sub-Saharan Africa' },
    { label: 'Burundi', value: 'Burundi', subregion: 'Sub-Saharan Africa' },
    { label: 'Cambodia', value: 'Cambodia', subregion: 'Sub-Saharan Africa' },
    { label: 'Cameroon', value: 'Cameroon', subregion: 'Sub-Saharan Africa' },
    { label: 'Canada', value: 'Canada', subregion: 'Northern America' },
    { label: 'Cape Verde', value: 'Cape Verde', subregion: 'Sub-Saharan Africa' },
    { label: 'Cayman Islands', value: 'Cayman Islands', subregion: 'Latin America and the Caribbean' },
    { label: 'Central African Republic', value: 'Central African Republic', subregion: 'Central African Republic' },
    { label: 'Chad', value: 'Chad', subregion: 'Sub-Saharan Africa' },
    { label: 'Chile', value: 'Chile', subregion: 'Latin America and the Caribbean' },
    { label: 'China', value: 'China', subregion: 'Eastern Asia' },
    { label: 'Christmas Island', value: 'Christmas Island', subregion: 'Australia and New Zealand' },
    { label: 'Cocos (Keeling) Islands', value: 'Cocos (Keeling) Islands', subregion: 'Australia and New Zealand' },
    { label: 'Colombia', value: 'Colombia', subregion: 'Latin America and the Caribbean' },
    { label: 'Comoros', value: 'Comoros', subregion: 'Sub-Saharan Africa' },
    { label: 'Congo', value: 'Congo', subregion: 'Sub-Saharan Africa' },
    { label: 'Cook Islands', value: 'Cook Islands', subregion: 'Polynesia' },
    { label: 'Costa Rica', value: 'Costa Rica', subregion: 'Latin America and the Caribbean' },
    { label: "Cote D'Ivoire", value: "Cote D'Ivoire", subregion: 'Sub-Saharan Africa' },
    { label: 'Croatia', value: 'Croatia', subregion: 'Southern Europe' },
    { label: 'Cuba', value: 'Cuba', subregion: 'Latin America and the Caribbean' },
    { label: 'Cyprus', value: 'Cyprus', subregion: 'Western Asia' },
    { label: 'Czech Republic', value: 'Czech Republic', subregion: 'Eastern Europe' },
    { label: 'Denmark', value: 'Denmark', subregion: 'Northern Europe' },
    { label: 'Djibouti', value: 'Djibouti', subregion: 'Sub-Saharan Africa' },
    { label: 'Dominica', value: 'Dominica', subregion: 'Latin America and the Caribbean' },
    { label: 'Dominican Republic', value: 'Dominican Republic', subregion: 'Latin America and the Caribbean' },
    { label: 'Ecuador', value: 'Ecuador', subregion: 'Latin America and the Caribbean' },
    { label: 'Egypt', value: 'Egypt', subregion: 'Northern Africa' },
    { label: 'El Salvador', value: 'El Salvador', subregion: 'Latin America and the Caribbean' },
    { label: 'Equatorial Guinea', value: 'Equatorial Guinea', subregion: 'Sub-Saharan Africa' },
    { label: 'Eritrea', value: 'Eritrea', subregion: 'Sub-Saharan Africa' },
    { label: 'Estonia', value: 'Estonia', subregion: 'Northern Europe' },
    { label: 'Ethiopia', value: 'Ethiopia', subregion: 'Sub-Saharan Africa' },
    { label: 'Falkland Islands (Malvinas)', value: 'Falkland Islands (Malvinas)', subregion: 'Latin America and the Caribbean' },
    { label: 'Faroe Islands', value: 'Faroe Islands', subregion: 'Northern Europe' },
    { label: 'Fiji', value: 'Fiji', subregion: 'Melanesia' },
    { label: 'Finland', value: 'Finland', subregion: 'Northern Europe' },
    { label: 'France', value: 'France', subregion: 'Western Europe' },
    { label: 'French Guiana', value: 'French Guiana', subregion: 'Latin America and the Caribbean' },
    { label: 'French Polynesia', value: 'French Polynesia', subregion: 'Polynesia' },
    { label: 'French Southern Territories', value: 'French Southern Territories', subregion: 'Sub-Saharan Africa' },
    { label: 'Gabon', value: 'Gabon', subregion: 'Sub-Saharan Africa' },
    { label: 'Gambia', value: 'Gambia', subregion: 'Sub-Saharan Africa' },
    { label: 'Georgia', value: 'Georgia', subregion: 'Western Asia' },
    { label: 'Germany', value: 'Germany', subregion: 'Western Europe' },
    { label: 'Ghana', value: 'Ghana', subregion: 'Sub-Saharan Africa' },
    { label: 'Gibraltar', value: 'Gibraltar', subregion: 'Southern Europe' },
    { label: 'Greece', value: 'Greece', subregion: 'Southern Europe' },
    { label: 'Greenland', value: 'Greenland', subregion: 'Northern America' },
    { label: 'Grenada', value: 'Grenada', subregion: 'Latin America and the Caribbean' },
    { label: 'Guadeloupe', value: 'Guadeloupe', subregion: 'Latin America and the Caribbean' },
    { label: 'Guam', value: 'Guam', subregion: 'Micronesia' },
    { label: 'Guatemala', value: 'Guatemala', subregion: 'Latin America and the Caribbean' },
    { label: 'Guernsey', value: 'Guernsey', subregion: 'Northern Europe' },
    { label: 'Guinea', value: 'Guinea', subregion: 'Sub-Saharan Africa' },
    { label: 'Guinea-Bissau', value: 'Guinea-Bissau', subregion: 'Sub-Saharan Africa' },
    { label: 'Guyana', value: 'Guyana', subregion: 'Latin America and the Caribbean' },
    { label: 'Haiti', value: 'Haiti', subregion: 'Latin America and the Caribbean' },
    { label: 'Heard Island and Mcdonald Islands', value: 'Heard Island and Mcdonald Islands', subregion: 'Australia and New Zealand' },
    { label: 'Holy See (Vatican City State)', value: 'Holy See (Vatican City State)', subregion: 'Southern Europe' },
    { label: 'Honduras', value: 'Honduras', subregion: 'Latin America and the Caribbean' },
    { label: 'Hong Kong', value: 'Hong Kong', subregion: 'Eastern Asia' },
    { label: 'Hungary', value: 'Hungary', subregion: 'Eastern Europe' },
    { label: 'Iceland', value: 'Iceland', subregion: 'Northern Europe' },
    { label: 'India', value: 'India', subregion: 'Southern Asia' },
    { label: 'Indonesia', value: 'Indonesia', subregion: 'South-eastern Asia' },
    { label: 'Iran', value: 'Iran', subregion: 'Southern Asia' },
    { label: 'Iraq', value: 'Iraq', subregion: 'Western Asia' },
    { label: 'Ireland', value: 'Ireland', subregion: 'Northern Europe' },
    { label: 'Isle of Man', value: 'Isle of Man', subregion: 'Northern Europe' },
    { label: 'Israel', value: 'Israel', subregion: 'Western Asia' },
    { label: 'Italy', value: 'Italy', subregion: 'Southern Europe' },
    { label: 'Jamaica', value: 'Jamaica', subregion: 'Latin America and the Caribbean' },
    { label: 'Japan', value: 'Japan', subregion: 'Eastern Asia' },
    { label: 'Jersey', value: 'Jersey', subregion: 'Northern Europe' },
    { label: 'Jordan', value: 'Jordan', subregion: 'Western Asia' },
    { label: 'Kazakhstan', value: 'Kazakhstan', subregion: 'Central Asia' },
    { label: 'Kenya', value: 'Kenya', subregion: 'Sub-Saharan Africa' },
    { label: 'Kiribati', value: 'Kiribati', subregion: 'Micronesia' },
    { label: 'Korea', value: 'Korea', subregion: 'Eastern Asia' },
    { label: 'Kuwait', value: 'Kuwait', subregion: 'Eastern Asia' },
    { label: 'Kyrgyzstan', value: 'Kyrgyzstan', subregion: 'Central Asia' },
    { label: "Lao People'S Democratic Republic", value: "Lao People'S Democratic Republic", subregion: 'South-eastern Asia' },
    { label: 'Latvia', value: 'Latvia', subregion: 'Northern Europe' },
    { label: 'Lebanon', value: 'Lebanon', subregion: 'Western Asia' },
    { label: 'Lesotho', value: 'Lesotho', subregion: 'Sub-Saharan Africa' },
    { label: 'Liberia', value: 'Liberia', subregion: 'Sub-Saharan Africa' },
    { label: 'Libyan Arab Jamahiriya', value: 'Libyan Arab Jamahiriya', subregion: 'Northern Africa' },
    { label: 'Liechtenstein', value: 'Liechtenstein', subregion: 'Western Europe' },
    { label: 'Lithuania', value: 'Lithuania', subregion: 'Northern Europe' },
    { label: 'Luxembourg', value: 'Luxembourg', subregion: 'Western Europe' },
    { label: 'Macao', value: 'Macao', subregion: 'Eastern Asia' },
    { label: 'Macedonia', value: 'Macedonia', subregion: 'Southern Europe' },
    { label: 'Madagascar', value: 'Madagascar', subregion: 'Sub-Saharan Africa' },
    { label: 'Malawi', value: 'Malawi', subregion: 'Sub-Saharan Africa' },
    { label: 'Malaysia', value: 'Malaysia', subregion: 'South-eastern Asia' },
    { label: 'Maldives', value: 'Maldives', subregion: 'Southern Asia' },
    { label: 'Mali', value: 'Mali', subregion: 'Sub-Saharan Africa' },
    { label: 'Malta', value: 'Malta', subregion: 'Southern Europe' },
    { label: 'Marshall Islands', value: 'Marshall Islands', subregion: 'Micronesia' },
    { label: 'Martinique', value: 'Martinique', subregion: 'Latin America and the Caribbean' },
    { label: 'Mauritania', value: 'Mauritania', subregion: 'Sub-Saharan Africa' },
    { label: 'Mauritius', value: 'Mauritius', subregion: 'Sub-Saharan Africa' },
    { label: 'Mayotte', value: 'Mayotte', subregion: 'Sub-Saharan Africa' },
    { label: 'Mexico', value: 'Mexico', subregion: 'Latin America and the Caribbean' },
    { label: 'Micronesia', value: 'Micronesia', subregion: 'Micronesia' },
    { label: 'Moldova', value: 'Moldova', subregion: 'Eastern Europe' },
    { label: 'Monaco', value: 'Monaco', subregion: 'Western Europe' },
    { label: 'Mongolia', value: 'Mongolia', subregion: 'Eastern Asia' },
    { label: 'Montserrat', value: 'Montserrat', subregion: 'Latin America and the Caribbean' },
    { label: 'Morocco', value: 'Morocco', subregion: 'Northern Africa' },
    { label: 'Mozambique', value: 'Mozambique', subregion: 'Sub-Saharan Africa' },
    { label: 'Myanmar', value: 'Myanmar', subregion: 'South-eastern Asia' },
    { label: 'Namibia', value: 'Namibia', subregion: 'Sub-Saharan Africa' },
    { label: 'Nauru', value: 'Nauru', subregion: 'Micronesia' },
    { label: 'Nepal', value: 'Nepal', subregion: 'Southern Asia' },
    { label: 'Netherlands', value: 'Netherlands', subregion: 'Western Europe' },
    { label: 'Netherlands Antilles', value: 'Netherlands Antilles', subregion: 'Western Europe' },
    { label: 'New Caledonia', value: 'New Caledonia', subregion: 'Melanesia' },
    { label: 'New Caledonia Zealand', value: 'New Caledonia Zealand', subregion: 'Australia and New Zealand' },
    { label: 'Nicaragua', value: 'Nicaragua', subregion: 'Latin America and the Caribbean' },
    { label: 'Niger', value: 'Niger', subregion: 'Sub-Saharan Africa' },
    { label: 'Nigeria', value: 'Nigeria', subregion: 'Sub-Saharan Africa' },
    { label: 'Niue', value: 'Niue', subregion: 'Polynesia' },
    { label: 'Norfolk Island', value: 'Norfolk Island', subregion: 'Australia and New Zealand' },
    { label: 'Northern Mariana Islands', value: 'Northern Mariana Islands', subregion: 'Micronesia' },
    { label: 'Norway', value: 'Norway', subregion: 'Northern Europe' },
    { label: 'Oman', value: 'Oman', subregion: 'Western Asia' },
    { label: 'Pakistan', value: 'Pakistan', subregion: 'Southern Asia' },
    { label: 'Palau', value: 'Palau', subregion: 'Micronesia' },
    { label: 'Palestinian Territory, Occupied', value: 'Palestinian Territory, Occupied', subregion: 'Western Asia' },
    { label: 'Panama', value: 'Panama', subregion: 'Latin America and the Caribbean' },
    { label: 'Papua New Guinea', value: 'Papua New Guinea', subregion: 'Melanesia' },
    { label: 'Paraguay', value: 'Paraguay', subregion: 'Latin America and the Caribbean' },
    { label: 'Peru', value: 'Peru', subregion: 'Latin America and the Caribbean' },
    { label: 'Philippines', value: 'Philippines', subregion: 'South-eastern Asia' },
    { label: 'Pitcairn', value: 'Pitcairn', subregion: 'Polynesia' },
    { label: 'Poland', value: 'Poland', subregion: 'Eastern Europe' },
    { label: 'Portugal', value: 'Portugal', subregion: 'Southern Europe' },
    { label: 'Puerto Rico', value: 'Puerto Rico', subregion: 'Latin America and the Caribbean' },
    { label: 'Qatar', value: 'Qatar', subregion: 'Western Asia' },
    { label: 'Reunion', value: 'Reunion', subregion: 'Sub-Saharan Africa' },
    { label: 'Romania', value: 'Romania', subregion: 'Eastern Europe' },
    { label: 'Russian Federation', value: 'Russian Federation', subregion: 'Eastern Europe' },
    { label: 'RWANDA', value: 'RWANDA', subregion: 'Sub-Saharan Africa' },
    { label: 'Saint Helena', value: 'Saint Helena', subregion: 'Sub-Saharan Africa' },
    { label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis', subregion: 'Latin America and the Caribbean' },
    { label: 'Saint Lucia', value: 'Saint Lucia', subregion: 'Latin America and the Caribbean' },
    { label: 'Saint Pierre and Miquelon', value: 'Saint Pierre and Miquelon', subregion: 'Northern America' },
    { label: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines', subregion: 'Latin America and the Caribbean' },
    { label: 'Samoa', value: 'Samoa', subregion: 'Polynesia' },
    { label: 'San Marino', value: 'San Marino', subregion: 'Southern Europe' },
    { label: 'Sao Tome and Principe', value: 'Sao Tome and Principe', subregion: 'Sub-Saharan Africa' },
    { label: 'Saudi Arabia', value: 'Saudi Arabia', subregion: 'Western Asia' },
    { label: 'Senegal', value: 'Senegal', subregion: 'Sub-Saharan Africa' },
    { label: 'Serbia and Montenegro', value: 'Serbia and Montenegro', subregion: 'Southern Europe' },
    { label: 'Seychelles', value: 'Seychelles', subregion: 'Sub-Saharan Africa' },
    { label: 'Sierra Leone', value: 'Sierra Leone', subregion: 'Sub-Saharan Africa' },
    { label: 'Singapore', value: 'Singapore', subregion: 'South-eastern Asia' },
    { label: 'Slovakia', value: 'Slovakia', subregion: 'Eastern Europe' },
    { label: 'Slovenia', value: 'Slovenia', subregion: 'Southern Europe' },
    { label: 'Solomon Islands', value: 'Solomon Islands', subregion: 'Melanesia' },
    { label: 'Somalia', value: 'Somalia', subregion: 'Sub-Saharan Africa' },
    { label: 'South Africa', value: 'South Africa', subregion: 'Sub-Saharan Africa' },
    { label: 'South Georgia and the South Sandwich Islands', value: 'South Georgia and the South Sandwich Islands', subregion: 'Latin America and the Caribbean' },
    { label: 'Spain', value: 'Spain', subregion: 'Southern Europe' },
    { label: 'Sri Lanka', value: 'Sri Lanka', subregion: 'Southern Asia' },
    { label: 'Sudan', value: 'Sudan', subregion: 'Northern Africa' },
    { label: 'Surilabel', value: 'Surilabel', subregion: 'Sub-Saharan Africa' },
    { label: 'Svalbard and Jan Mayen', value: 'Svalbard and Jan Mayen', subregion: 'Northern Europe' },
    { label: 'Swaziland', value: 'Swaziland', subregion: 'Sub-Saharan Africa' },
    { label: 'Sweden', value: 'Sweden', subregion: 'Northern Europe' },
    { label: 'Switzerland', value: 'Switzerland', subregion: 'Western Europe' },
    { label: 'Syrian Arab Republic', value: 'Syrian Arab Republic', subregion: 'Western Asia' },
    { label: 'Taiwan', value: 'Taiwan', subregion: 'Eastern Asia' },
    { label: 'Tajikistan', value: 'Tajikistan', subregion: 'Central Asia' },
    { label: 'Tanzania', value: 'Tanzania', subregion: 'Sub-Saharan Africa' },
    { label: 'Thailand', value: 'Thailand', subregion: 'South-eastern Asia' },
    { label: 'Timor-Leste', value: 'Timor-Leste', subregion: 'South-eastern Asia' },
    { label: 'Togo', value: 'Togo', subregion: 'Sub-Saharan Africa' },
    { label: 'Tokelau', value: 'Tokelau', subregion: 'Polynesia' },
    { label: 'Tonga', value: 'Tonga', subregion: 'Polynesia' },
    { label: 'Trinidad and Tobago', value: 'Trinidad and Tobago', subregion: 'Latin America and the Caribbean' },
    { label: 'Tunisia', value: 'Tunisia', subregion: 'Northern Africa' },
    { label: 'Turkey', value: 'Turkey', subregion: 'Western Asia' },
    { label: 'Turkmenistan', value: 'Turkmenistan', subregion: 'Central Asia' },
    { label: 'Turks and Caicos Islands', value: 'Turks and Caicos Islands', subregion: 'Latin America and the Caribbean' },
    { label: 'Tuvalu', value: 'Tuvalu', subregion: 'Polynesia' },
    { label: 'Uganda', value: 'Uganda', subregion: 'Sub-Saharan Africa' },
    { label: 'Ukraine', value: 'Ukraine', subregion: 'Eastern Europe' },
    { label: 'United Arab Emirates', value: 'United Arab Emirates', subregion: 'Western Asia' },
    { label: 'United Kingdom', value: 'United Kingdom', subregion: 'Northern Europe' },
    { label: 'United States', value: 'United States', subregion: 'Northern America' },
    { label: 'United States Minor Outlying Islands', value: 'United States Minor Outlying Islands', subregion: 'Micronesia' },
    { label: 'Uruguay', value: 'Uruguay', subregion: 'Latin America and the Caribbean' },
    { label: 'Uzbekistan', value: 'Uzbekistan', subregion: 'Central Asia' },
    { label: 'Vanuatu', value: 'Vanuatu', subregion: 'Melanesia' },
    { label: 'Venezuela', value: 'Venezuela', subregion: 'Latin America and the Caribbean' },
    { label: 'Viet Nam', value: 'Viet Nam', subregion: 'South-eastern Asia' },
    { label: 'Virgin Islands, British', value: 'Virgin Islands, British', subregion: 'Latin America and the Caribbean' },
    { label: 'Virgin Islands, U.S.', value: 'Virgin Islands, U.S.', subregion: 'Latin America and the Caribbean' },
    { label: 'Wallis and Futuna', value: 'Wallis and Futuna', subregion: 'Polynesia' },
    { label: 'Western Sahara', value: 'Western Sahara', subregion: 'Northern Africa' },
    { label: 'Yemen', value: 'Yemen', subregion: 'Western Asia' },
    { label: 'Zambia', value: 'Zambia', subregion: 'Sub-Saharan Africa' },
    { label: 'Zimbabwe', value: 'Zimbabwe', subregion: 'Sub-Saharan Africa' },
]
