import React from "react";
import Modal from "react-modal";
import { translations } from "../../constants/translations";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { tagValidationSchema } from "../common/validations";
import { Button } from "../shared";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
  },
};
Modal.setAppElement("#root");

const ModalTag = (props) => {
  const { mopen, loading, tagData, onCloseModal, submitHandler } =
    props;

  return (
    <div className="btn-popup pull-right">
      <ToastContainer autoClose={2000} />
      <Modal
        id="modalContent"
        isOpen={mopen}
        onRequestClose={onCloseModal}
        className="ModalContent"
        style={customStyles}
        overlayClassName="OverlayContent"
      >
        <div className="modal-header">
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
            {translations.Settings.EditTag}
          </h5>
        </div>
        <div className="modal-body">
          <Formik
            initialValues={{
              tagTitle: tagData.title ? tagData.title : "",
            }}
            validationSchema={tagValidationSchema}
            onSubmit={(values, { resetForm }) => {
              submitHandler(values);
              resetForm({});
            }}
          >
            <Form id="frmAddAdmin">
              <div className="form-group">
                <label htmlFor="tag">
                  {translations.Settings.TagTitle}
                </label>
                <Field
                  className="form-control"
                  id="tagTitle"
                  name="tagTitle"
                  placeholder="Example..."
                  maxLength={30}
                />
                <ErrorMessage
                  component="div"
                  className="alert alert-danger"
                  name="tagTitle"
                />
              </div>
              <Button loadingText="Updating" loading={loading} type="submit" className="btn btn-primary">
                {translations.Settings.SaveTag}
              </Button>
              <Button
                type="button"
                className="btn btn-light"
                clicked={() => onCloseModal("VaryingMdo")}
              >
                {translations.Settings.Close}
              </Button>
            </Form>
          </Formik>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </div>
  );
};

export default ModalTag;
