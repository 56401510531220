import React, { useEffect, useReducer, useState } from 'react'
import { useHistory } from 'react-router';
import moment from "moment";
import Breadcrumb from "../common/breadcrumb";
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../hooks/apiStateHandler'
import { getGroups, deleteGroups, changeOwner} from '../../services/groups'
import GroupSeacrh from './components/search'
import { Button, TableComp } from '../shared'
import { get } from 'lodash-es';
import { SOMETING_WENT_WRONG, TOTAL_RECORDS } from '../../constants/translations'
import { toast } from 'react-toastify';
import { openInNewTab } from '../../utils/anchor';

const GroupsList = (props) => {
    const [groupsState, groupsDispatch] = useReducer(ApiStateReducer, { ...initialState })
    const [appliedfilters, setAppliedFilters] = useState({ status: [0, 1] })
    const [selectedUser, setSelectedUser] = useState(null)
    const history = useHistory()

    useEffect( ()=> {
        const localFilters = JSON.parse( localStorage.getItem('filterObj') )
        const sellername = localStorage.getItem('sellerName')
        if ( localFilters ) {
            getGroupsHandler({ offset: 0, limit: get(groupsState, 'limit', 20), filters: localFilters }) 
            setAppliedFilters(localFilters)
            setSelectedUser({_id: localFilters.userId, fullname: sellername })
            localStorage.removeItem('filterObj')
            localStorage.removeItem('sellerName')
        } else {
            getGroupsHandler({ offset: 0, limit: get(groupsState, 'limit', 20), filters: appliedfilters })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getGroupsHandler = async (p = {}) => {
        try {
            const params = { ...p }
            groupsDispatch({ type: ApiStateEvents.FETCHING })
            if (params.filters && params.filters.status) {
                if (params.filters.status.includes('isDrafted')) {
                    params.filters.isDrafted = true
                    params.filters.status = params.filters.status.filter(o => o !== 'isDrafted')
                }
            }
            const res = await getGroups(params)
            if (res.success) {
                groupsDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.groups', []), offset: params.offset, totalRecords: get(res, 'data.total') })
            } else {
                toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
                groupsDispatch({ type: ApiStateEvents.ERROR, data: [], totalRecords: get(res, 'data.total') })
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
            groupsDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }

    const appliedFiltersHandler = (filtersParams, type='') => {
        localStorage.setItem('filterObj' , JSON.stringify(filtersParams) )
        if ( type !== 'reset' ) {
            const currentURL = history.location.pathname
            window.open(currentURL, "_blank")
        } else {
            getGroupsHandler({ offset: 0, limit: get(groupsState, 'limit', 20), filters: { ...filtersParams } })
            setAppliedFilters(filtersParams)
        }
        
        
    }

    const getAuthor = (artwork) => {
        if (get(artwork, 'nameOfArtist.firstName', '').length) {
            return get(artwork, 'nameOfArtist.firstName') + ' ' + get(artwork, 'nameOfArtist.lastName')
        }
        return get(artwork, 'userId.firstName', 'N/A') + ' ' + get(artwork, 'userId.lastName', '')
    }

    const columns = [
        {
            name: "Group Name",
            selector: "title",
            sortable: true,
        },
        // {
        //     name: "Description",
        //     selector: "description",
        //     sortable: true,
        //     cellRenderer: (row) => (<p>{row.description || 'N/A'}</p>)
        // },
        {
            name: "Group admin",
            cellRenderer: (row) => (<p onClick={() => { localStorage.setItem('sellerName', row.userId.firstName + ' ' + row.userId.lastName) ; appliedFiltersHandler({ ...appliedfilters, userId: row?.userId?._id }); setSelectedUser(row.userId) }}>{getAuthor(row)}</p>)
        },
        {
            name: "Date Created",
            selector: "dateCreated",
            cellRenderer: (row) => (<div >{moment(row.dateCreated).format("DD MMM, YYYY h:mm:ss a")}</div>)
        },
        {
            name: "Number",
            sortable: true,
            selector: "moderated",
            cellRenderer: (row) => (<div>{row.groupMembers.length}</div>)
        },
        {
            name: 'Actions',
            cellRenderer: (row) => (<>
                <Button title="Visit group" clicked={() => openInNewTab(`https://artmo.com/groups/${row._id}`)} className="IconButon"><i
                    className="fa fa-eye"
                    style={{
                        width: 'auto',
                        fontSize: 18,
                        padding: 0,
                        color: "rgb(0,191,255)",
                    }}
                ></i></Button>
                <Button title="Delete Group" loading={row.deleting} loadingText="Deleting" clicked={() => deleteArticleHandler(row._id)} className="IconButon"><i
                    className="fa fa-trash"
                    style={{
                        width: 'auto',
                        fontSize: 18,
                        padding: 0,
                        color: "#e4566e",
                    }}
                ></i></Button>
                    <Button title="Change owner to artmo" loadingText="Change owner to artmo." clicked={() => putArticleHandler(row._id)} className="IconButon"><i
                    className="fa fa-exchange"
                    style={{
                        width: 'auto',
                        fontSize: 18,
                        padding: 0,
                        color: "rgb(0,191,255)",
                    }}
                ></i></Button>
            </>)
        }
    ]

    const onPageChange = (page) => {
        getGroupsHandler({
            offset: get(groupsState, 'limit', 20) * (page - 1),
            limit: get(groupsState, 'limit', 20),
            filters: { ...appliedfilters },
            sortField: get(groupsState, 'sorting.sortField'),
            sortOrder: get(groupsState, 'sorting.sortOrder'),
        })
    }

    const onSort = ({ fieldName, sortOrder }) => {
        groupsDispatch({
            type: ApiStateEvents.ADD_SORTING, sorting: {
                sortField: fieldName,
                sortOrder: sortOrder,
            }
        })
        getGroupsHandler({
            offset: 0,
            limit: get(groupsState, 'limit', 20),
            filters: { ...appliedfilters },
            sortField: fieldName,
            sortOrder: sortOrder,
        })
    }

    const updateArtworkHandler = (articleId, field, value) => {
        const allGroups= get(groupsState, 'data', [])
        const articleIndex = allGroups.findIndex(o => o._id === articleId)
        if (articleIndex !== -1) {
            allGroups[articleIndex][field] = value
            groupsDispatch({ type: ApiStateEvents.SUCCESS, data: allGroups })
        }
    }

    const deleteArticleHandler = async (groupId) => {
        updateArtworkHandler(groupId, 'deleting', true)
        const res = await deleteGroups({ groupId })
        if (res.success) {
            toast.success(get(res, 'data.message'))
            const allArticles = get(groupsState, 'data', [])
            const newArticles = allArticles.filter(o => o._id !== groupId)
            if (newArticles) {
                groupsDispatch({ type: ApiStateEvents.SUCCESS, data: newArticles })
            }
        } else {
            toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
            updateArtworkHandler(groupId, 'deleting', false)
        }
    }

    const putArticleHandler = async (groupId) => {
        const res = await changeOwner({ groupId })
        if (res.success) {
            toast.success(get(res, 'data.message'))
            const allArticles = get(groupsState, 'data', [])
            const newArticles = allArticles.filter(o => o._id !== groupId)
            if (newArticles) {
                groupsDispatch({ type: ApiStateEvents.SUCCESS, data: newArticles })
            }
        } else {
            toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
            updateArtworkHandler(groupId, 'deleting', false)
        }
    }
    return <>
        <Breadcrumb title='Groups' />
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h5 className="BackButtonHeading">Groups</h5>
                </div>
                <div>
                    <GroupSeacrh selectedUser={selectedUser} addFilter={(filter) => setAppliedFilters({ ...appliedfilters, ...filter })} applyFilters={(filters, type) => appliedFiltersHandler(filters, type)} allfilters={appliedfilters} />
                </div>
                <div style={{ margin: "15px 30px 15px" }}>
                    {!get(groupsState, 'loading') && TOTAL_RECORDS + get(groupsState, 'totalRecords', 0)}
                </div>
                <div className="card-body">
                    <TableComp
                        page={(get(groupsState, 'offset', 1) / get(groupsState, 'limit', 1)) + 1}
                        limit={get(groupsState, 'limit', 20)}
                        totalRecords={get(groupsState, 'totalRecords', 0)}
                        onPageChange={(p) => onPageChange(p)}
                        loading={get(groupsState, 'loading')}
                        data={get(groupsState, 'data', [])}
                        columns={columns}
                        onSort={onSort}
                        sortField={get(groupsState, 'sorting.sortField', null)}
                        sortOrder={get(groupsState, 'sorting.sortOrder', null)}
                    />

                </div>
            </div>
        </div>
    </>
}

export default GroupsList