export const PERMISSIONS = {
  superadmin: [
    "dashboard",
    "admin",
    "users",
    "content-managment",
    "settings",
    "templates",
    "seo-settings",
    "activities",
    "help-desk",
    "artwork-desk",
    "accountsettings",
    "articles",
    "artworks",
    "social-activities",
    "help-desk",
    "email-template",
    "notification-types",
    "groups",
    "master"
  ],
  admin: [
    "dashboard",
    "admin",
    "users",
    "content-managment",
    "settings",
    "templates",
    "seo-settings",
    "activities",
    "help-desk",
    "artwork-desk",
    "accountsettings",
    "articles",
    "artworks",
    "social-activities",
    "help-desk",
    "email-template",
    "notification-types",
    "groups",
    "master"
  ],
  editor: ["dashboard", "artworks","artwork-desk", "content-managment", "settings", "accountsettings"],
  user: ["dashboard", "accountsettings"],
};
