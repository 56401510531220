import React, { useEffect, useReducer, useState } from 'react'
import Breadcrumb from "../common/breadcrumb";
import { TableComp, Button } from '../shared';
import get from 'lodash/get'
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../hooks/apiStateHandler'
import { toast } from 'react-toastify';
import { SOMETING_WENT_WRONG, TOTAL_RECORDS } from '../../constants/translations';
import { getmasterProfile, addMasterProfile, deleteMasterProfile } from '../../services/masterProfile';
import ModalAddNewMasterProfile from "./add_new_instructor";

const editValuesInitial = { showModal: false, values: {}, updating: false, error: null }
const MasterProfile = (props) => {
    const [masterProfileState, masterProfileDispatch] = useReducer(ApiStateReducer, initialState)
    const [editValues, setEditValues] = useState(editValuesInitial)
    const [mAddopen, setMAddopen] = useState(false);
    const [updating, setUpdating] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
  
    const onOpenAddModal = () => {
        setMAddopen(true);
      };
      const onCloseAddModal = () => {
        setMAddopen(false);
      };
      const submitAddHandler = async ({username}) => {
        setUpdating(true)
        const res = await addMasterProfile({username})
        if (res && res?.success) {
            const profile = get(masterProfileState, 'data', []);
            profile.unshift(res.data.profile);
            toast.success(res.data?.message)
        } else {
            toast.error('This username already exists')
        }
        setMAddopen(false)
        setUpdating(false)
    };
    const handlePageChange = (page) => {
        getMasterProfileHandler({
            page: page, perPage:20, delay: 1,
        })
        setCurrentPage(page);
      };
    useEffect(() => {
        getMasterProfileHandler({
            page: currentPage, perPage:20, delay: 1,
        })
    }, [])
    const deleteMasterProfileHandler = async ({username}) => {
        try {
            const res = await deleteMasterProfile({username})
            if (res && res?.success) {
                let profile = get(masterProfileState, 'data', []);
                profile.map(x=>console.log(x.username))
                let data = profile.filter(item => item.username !== username);
                masterProfileDispatch({ type: ApiStateEvents.SUCCESS, data: data })
                toast.success(res.data?.message)
            } else {
                toast.error('This username already exists')
            }

        } catch (error) {
            masterProfileDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }
    const columns = [
        {
            name: "FirstName",
            selector: "firstName",
            sortable: true,
        },
        {
            name: "LastName",
            selector: "lastName",
            sortable: true,
        },
        {
            name: 'Actions',
            cellRenderer: (row) => (<>
                                 <span
            style={{ cursor: "pointer" }}
            title="Edit Instructor"
            onClick={() => {
                //history.push(`/users/${UPDATE}/${row._id}`)
                const win = window.open(`/users/update/${row._id}`, "_blank")
                win.focus()
            }}
            // onClick={() => setEditValues({ showModal: true, values: row })}
          >
            <i
              className="fa fa-pencil"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "rgb(0, 123, 255)",
              }}
            ></i>
          </span>
                {/* <Button title="Edit Message" clicked={() => setEditValues({ showModal: true, values: row })} className="btn btn-primary m-10">Edit</Button> */}
            </>)
        },
        {
            name: 'Delete',
            cellRenderer: (row) => (<>
                                 <span
            style={{ cursor: "pointer" }}
            title="Delete inscructor"
            onClick={() => {
                deleteMasterProfileHandler({username:row.username});
            }}          >
            <i
              className="fa fa-trash"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "red",
              }}
            ></i>
          </span>
                {/* <Button title="Edit Message" clicked={() => setEditValues({ showModal: true, values: row })} className="btn btn-primary m-10">Edit</Button> */}
            </>)
        }
    ]
  /**
   * @param {*} page
   * @param {*} size
   */
    const getMasterProfileHandler = async (params) => {
        try {
            masterProfileDispatch({ type: ApiStateEvents.FETCHING })
            const res = await getmasterProfile(params)
            if (res.success) {
                masterProfileDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.profiles', []), totalRecords: get(res, 'data.total', 0) })
                setTotalRows(get(res, 'data.total', 0))
            } else {
                masterProfileDispatch({ type: ApiStateEvents.ERROR, data: [] })
            }
        } catch (error) {
            masterProfileDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }


    // const blankResponseMessageHandler = async (params) => {
    //     try {
    //         masterProfileDispatch({ type: ApiStateEvents.FETCHING })
    //         const res = await insertMessageResponseArt(params)
    //         if (res.success) {
    //             masterProfileDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.responses', []), totalRecords: get(res, 'data.total', 0) })
    //         } else {
    //             masterProfileDispatch({ type: ApiStateEvents.ERROR, data: [] })
    //         }
    //     } catch (error) {
    //         masterProfileDispatch({ type: ApiStateEvents.ERROR, data: [], error })
    //     }
    // }

    const onSort = ({ fieldName, sortOrder }) => {
        masterProfileDispatch({
            type: ApiStateEvents.ADD_SORTING, sorting: {
                sortField: fieldName,
                sortOrder: sortOrder,
            }
        })
        getMasterProfileHandler({
            page: currentPage, perPage, delay: 1,
            sortField: fieldName,
            sortOrder: sortOrder,
        })
    }

    return <>
        <Breadcrumb title='Instructor' />
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h5 className="BackButtonHeading">
                    Instructor
                    </h5>
                </div>
                <div style={{ margin: "15px 30px 15px" }}>
                    {!get(masterProfileState, 'loading') && TOTAL_RECORDS + get(masterProfileState, 'totalRecords', 0)}
                </div>

                <div className="card-body">
                    <ModalAddNewMasterProfile
                        mopen={mAddopen}
                        submitHandler={submitAddHandler}
                        onOpenModal={onOpenAddModal}
                        onCloseModal={onCloseAddModal}
                        updating={updating}
                    />
                    <TableComp
                        loading={get(masterProfileState, 'loading')}
                        data={get(masterProfileState, 'data', [])}
                        columns={columns}
                        totalRecords={totalRows}
                        page={currentPage}
                        onPageChange={handlePageChange}
                        onSort={onSort}
                        limit={perPage}
                        sortField={get(masterProfileState, 'sorting.sortField', null)}
                        sortOrder={get(masterProfileState, 'sorting.sortOrder', null)}
                    />
                </div>
            </div>
        </div>
    </>
}

export default MasterProfile