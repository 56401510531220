import { get, isObject, isString, startCase, sortBy } from 'lodash'
import { captalizeFirstChar } from './stringHelper'
export const selectOptionsGenerator = ({ options, labelField, labelValue }) => {
    try {
        return options.map(option => ({
            label: captalizeFirstChar(get(option, labelField, '')),
            value: get(option, labelValue, '')
        }))
    } catch (error) {
        return []
    }
}

export const createOptionsForSelect = ({ options, valueProperty, labelProperty, defaultValue, selected = [], sort }) => {
    let parsedOptions = []
    if (Array.isArray(options)) {
        options.forEach((option) => {
            let value = null
            let label = null
            if (isObject(option)) {
                value = get(option, valueProperty)
                label = startCase(get(option, labelProperty))
            } else if (isString(option)) {
                value = option
                label = startCase(option)
            }
            if (value && label) {
                parsedOptions.push({
                    value,
                    label,
                })
            }
        })
    } else if (isObject(options)) {
        for (const property in options) {
            const value = options[property]
            parsedOptions.push({ value: property, label: get(value, `${labelProperty}`) })
        }
    }
    if (defaultValue) {
        parsedOptions.unshift(defaultValue)
    }
    let filtered = []
    parsedOptions.forEach(({ value, label }) => {
        if (!selected.includes(value)) {
            filtered.push({ value, label })
        }
    })

    return sort ? sortBy(filtered, o => o.label) : filtered
}

export default createOptionsForSelect