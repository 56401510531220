import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { statusForAdminAcceptence } from '../../../constants/translations'
import MultiSelect from "react-multi-select-component";
import { get } from 'lodash';
import { Button } from '../../shared';
import styled from 'styled-components';
import DateRangePicker from "@wojtekmaj/react-daterange-picker";


const selectionRange = {
    autoFocus: false,
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
};

const SectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

const InputBox = styled.div`
    width: 24%;
`
const ButtonBox = styled.div`
    margin-top: 23px;
    & button {
        margin: 0px 10px;
    }
`
const DateRange = styled.div`
    input {
        height : 19px !important;
    }
    .react-daterange-picker {
        width: 100%;
    }
    .react-daterange-picker__wrapper {
        padding: 4.5px .50rem;
        border: 1px solid #ced4da;
        border-radius: .25rem;
    }
    .react-daterange-picker__button svg {
        stroke: #aaa;
    }
`

const ActivitySearch = (props) => {
    const { applyFilters, allfilters } = props
    const [filters, setFilters] = useState({})

    const handleSubmit = () => {
        let parsedfilters = {}
        const { registrationDate} = filters
        filters.user = filters.user?.trim()
        if (registrationDate && registrationDate.length) {
            let tempObj = {}
            registrationDate.forEach((dt, index) => {
                if (index) {
                    tempObj.end_date = dt
                } else {
                    tempObj.start_date = dt
                }
                parsedfilters.registrationDate = tempObj
            })
        }
        applyFilters({ ...filters, ...parsedfilters })
    }

    const addFilter = (filter) => {
        setFilters({ ...filters, ...filter })
    }

    const resetHandler = () => {
        setFilters({})
        applyFilters({})
    }


    return  <SectionWrapper>
                <InputBox>
                    <h6>Enter Username</h6>
                    <input
                    className="form-control"
                    placeholder="Search username"
                    maxLength={40}
                    value={get(filters, 'user', '')}
                    onChange={(e) => addFilter({ user: e.target.value })}
                    onKeyPress={(e) => { e.key === 'Enter' && applyFilters(filters) }}
                />
                </InputBox>
                <InputBox>
                    <h6>Activity Posted</h6>
                    <DateRange>
                        <DateRangePicker
                            name="registrationDate"
                            ranges={[selectionRange]}
                            value={get(filters, 'registrationDate', null)}
                            maxDate={new Date()}
                            onChange={(date) => addFilter({ registrationDate: date })}
                        />
                    </DateRange>
                </InputBox>
                <ButtonBox>
                    <h6></h6>
                    <Button clicked={() => handleSubmit(filters)} className="btn btn-primary" >Filter</Button>
                    <Button clicked={() => resetHandler()} className="btn" >Reset</Button>
                </ButtonBox>
            </SectionWrapper>
}

ActivitySearch.defaultProps = {
    applyFilters: () => null,
    //addFilter: () => null,
    allfilters: {}

}

ActivitySearch.propTypes = {
    allfilters: PropTypes.object.isRequired,
    //addFilter: PropTypes.func.isRequired,
    applyFilters: PropTypes.func.isRequired,
}

export default ActivitySearch