import styled from "styled-components";

export const SectionContentWrap = styled.div`
  position: relative;
  margin: 0;
  border-top: 1px solid #eee;
  padding: 10px 15px;
  max-height: 220px;
  overflow: auto;
  overflow-x: hidden;
  @media (max-width: 991px) and (orientation: landscape) {
    max-height: 200px;
  }
  @media (max-width: 479px) {
    padding: 10px;
  }
`

export const FollowingList = styled.div`
  position: relative;
  margin: 0 0 10px;
  border-bottom: 1px solid #f5f5f5;
  padding: 0 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FollowsWrap = styled.div`
  display: flex;
  font-family: 'ProximaNova-Regular';
  max-width: calc(100% - 35px);
  width: 100%;
`

export const FollowingThumb = styled.div`
  width: 50px;
  height: 50px;
  margin: 0 10px 0 0;
  overflow: hidden;
  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 767px) {
    width: 40px;
    height: 40px;
  }
`
export const FollowsNameWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  max-width: calc(100% - 60px);
  width: 100%;
`

export const FollowsName = styled.div`
  font-style: normal;
  font-size: 18px;
  line-height: normal;
  margin: 0 0 5px 0;
  color: #222;
  cursor: pointer;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`
export const LikesPopupWrap = styled.div`
width: 100%;
position: relative;
max-width: 374px;
min-width: 374px;
min-height: 270px;
height: auto;
background: #ffffff;
box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
margin: 15px auto;
@media (max-width: 767px) {
  min-width: 300px;
  max-width: 300px;
}
`