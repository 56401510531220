import APIHandler from './apiHandler'

const getEmailTemplates = (params) => APIHandler('email-templates/list', 'get', params)

const getUniqueIds = (params) => APIHandler('email-templates/uniqueIds', 'get', params)

const addEmailTemplate = (params) => APIHandler('email-templates/add', 'post', params)

const updateEmailTemplates = (params) => APIHandler('email-templates/update', 'put', params)

const getStaticMessage = ( params ) => APIHandler('static-messages/list', 'get', params)

const getUniqueIdsStaticMessage = (params) => APIHandler('static-messages/uniqueIds', 'get', params)

const updateStaticMessages = (params) => APIHandler('static-messages/update', 'put', params)

const addStaticMesssage = ( params ) => APIHandler('static-messages/add', 'post', params )

export { getEmailTemplates, updateEmailTemplates, getUniqueIds, getStaticMessage, getUniqueIdsStaticMessage, updateStaticMessages, addStaticMesssage, addEmailTemplate }