import React, { useEffect, useState, useReducer } from 'react'
import ApiReducer, { initialState, events as ApiEvents } from '../../hooks/apiStateHandler'
import { SOMETING_WENT_WRONG } from '../../constants/translations'
import DetailsViewer from './components/userDetails'
import EditDetails from './components/editUser'
import { blockUser, getEndUser, updateUser,deletePage, deleteUserData } from '../../services/endUserManagement'
import Breadcrumb from "../common/breadcrumb";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { get } from 'lodash-es';
import { UPDATE, DETAILS } from '../../constants/actionTypes'
import styles from "./endUser.module.css"
import { LoaderComp, Button } from '../shared'
import { useSelector } from "react-redux";

import {
    getAllSubscriptions
  } from "../../features/common/commonSlice";
const EndUserView = (props) => {
    const { match, history } = props
    const [userState, userDispatch] = useReducer(ApiReducer, initialState)
    const [actionPerforming, setActionPerforming] = useState(null)
    const subscriptions = useSelector(getAllSubscriptions);

    useEffect(() => {
        getUserHandler(match.params.userId)
    }, [match.params.userId])
    
    const deletePageHandler = async (id, blocked) => {
        setActionPerforming("deletingPage")
        const res = await deletePage({ userId: id })
        if (res && res?.success) {
            //redirect to userlist
            // window.open(`/users/list`)
            window.location.href = '/users/list';

        } else {
            toast.error(SOMETING_WENT_WRONG)
        }
        setActionPerforming(null)
    }
    const blockUserHandler = async (id, blocked, reason) => {
        setActionPerforming("blockingUser")
        const res = await blockUser({ userId: id, blocked, reason })
        if (res && res?.success) {
            toast.success(res?.data?.message)
            let updatedData = get(userState, 'data', {})
            if (updatedData && updatedData.user) {
                updatedData.user.isBlocked = blocked
            }
            userDispatch({ type: ApiEvents.SUCCESS, data: updatedData })
        } else {
            toast.error(SOMETING_WENT_WRONG)
        }
        setActionPerforming(null)
    }

    const deleteUserHandler = async (id) => {
        setActionPerforming("deleteUserData")
        const res = await deleteUserData({ userId: id })
        if (res && res?.success) {
            toast.success(res?.data?.message)
            let updatedData = get(userState, 'data', {})
            if (updatedData && updatedData.user) {
                delete updatedData.user;
            }
            userDispatch({ type: ApiEvents.SUCCESS, data: updatedData })
        } else {
            toast.error(SOMETING_WENT_WRONG)
        }
        setActionPerforming(null)
    }

    const updateUserHandler = async () => {
        setActionPerforming("updatingUser")
        const { firstName, lastName, _id, coreTeam, isVerifiedReal, status, sub } = get(userState, 'data.user', {})
        const res = await updateUser({ userId: _id, firstName, lastName, coreTeam, isVerifiedReal, status, sub })
        if (res && res?.success) {
            toast.success(res?.data?.message)
        } else {
            toast.error(SOMETING_WENT_WRONG)
        }
        setActionPerforming(null)
    }

    const getUserHandler = async (userId) => {
        userDispatch({ type: ApiEvents.FETCHING })
        const res = await getEndUser({ userId })
        if (res.data) {
            userDispatch({ type: ApiEvents.SUCCESS, data: res.data })
        } else {
            userDispatch({ type: ApiEvents.ERROR, data: [] })
        }
    }

    const updateInfo = (field, value) => {
        const user = get(userState, 'data.user')
        user[field] = value
        userDispatch({ type: ApiEvents.SUCCESS, data: { ...userState.data, user } })
    }

    const sendMessageHandler = () => {
        const { _id } = get(userState, 'data.user', {})
        history.push(`/help-desk/chat/${_id}`)
    }

    return <>
        <Breadcrumb title="View User" parent="Users" />
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className={`card-header ${styles.cardHeaderFlex}`}>
                            <Button clicked={() => history.push('/users/list')} className="BackButtonTop"><i title="Back" class="fa fa-angle-left pull-left"></i></Button>
                            <h5>{`${get(userState, 'data.user.firstName', '')} ${get(userState, 'data.user.lastName', '')}`}</h5>

                        </div>
                        
                        <div className="card-body">
                            {match.params.action === DETAILS && !get(userState, 'loading', false) && <DetailsViewer
                                blockUserHandler={blockUserHandler}
                                deleteUserHandler={deleteUserHandler}
                                blockingUser={actionPerforming === 'blockingUser'}
                                deleteUserData={actionPerforming === 'deleteUserData'}
                                parentUser={get(userState, 'data.parentUser', null)}
                                subscriptionData={get(userState, 'data.subscriptions', [])}
                                user={get(userState, 'data.user', {})}
                                loadingUser={get(userState, 'loading', false)}
                                sendMessageHandler={() => sendMessageHandler()}
                            />}
                            {match.params.action === UPDATE && !get(userState, 'loading', false) && <EditDetails
                                updateInfo={(f, v) => updateInfo(f, v)}
                                blockUserHandler={blockUserHandler}
                                deleteUserHandler={deleteUserHandler}
                                deletePageHandler={deletePageHandler}
                                deleteUserData={actionPerforming === 'deleteUserData'}
                                deletingPage={actionPerforming === 'deletingPage'}
                                subscriptions={subscriptions}
                                blockingUser={actionPerforming === 'blockingUser'}
                                parentUser={get(userState, 'data.parentUser', null)}
                                updatingUser={actionPerforming === 'updatingUser'}
                                updateUserHandler={() => updateUserHandler()}
                                user={get(userState, 'data.user', {})}
                                loadingUser={get(userState, 'loading', false)}
                                sendMessageHandler={() => sendMessageHandler()}
                            />}
                            <LoaderComp loading={get(userState, 'loading', false)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default EndUserView