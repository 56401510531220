import styled from 'styled-components'

export const ArtworkCommonWrapper = styled.div`
  position: relative;
  margin: 18px 0 0 0;
  padding: 0;
  width: 100%;
`
export const ArtworkCommonContainer = styled.div`
  width: 100%;
  position: relative;
  max-width: 1290px;
  padding: 0 15px;
  margin: 0 auto;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  @media (max-width: 1280px) {
    width: auto;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (max-width: 599px) {
    flex-direction: column;
  }
`
export const ArtworkLeftContainer = styled.div`
  width: 100%;
  position: relative;
  max-width: 350px;
  /* @media (min-width: 1025px) and (max-width: 1199px) {
    max-width: 350px;
    margin: 0 auto 20px;
  } */
  @media (min-width: 992px) and (max-width: 1024px) {
    max-width: 28%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 100%;
    margin: 0 auto 20px;
  }
  @media (max-width: 767px) {
    margin: 0 auto 20px;
    max-width: 100%;
  }
`
export const ArtworkRightContainer = styled.div`
  width: 100%;
  position: relative;
  max-width: 880px;
  margin-left: 15px;
  margin-bottom: 30px;
  /* @media (min-width: 1025px) and (max-width: 1199px) {
    max-width: 625px;
  } */
  @media (min-width: 992px) and (max-width: 1024px) {
    max-width: 625px;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
    max-width: 100%;
  }
`
export const ArtworkFiltersWrap = styled.div`
  width: 100%;
  display: flex;
  padding-top: 15px;
  flex-direction: column;
`

export const ArtworkFiltersBox = styled.div`
  padding: 8px 15px;
  background: #fff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  margin: 0 0 15px;
  .size {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .rc-slider-handle {
    border: solid 2px #000000;
    background-color: #000;
  }
  .rc-slider-handle-dragging {
    box-shadow: none;
    border-color: #000;
  }
  .rc-slider-handle:hover,
  :focus,
  :active {
    /* box-shadow: none; */
    border-color: none;
  }
  .rc-slider-track {
    background-color: grey;
  }
  @media (max-width: 767px) {
    padding: 8px 10px;
  }
  &.TopPadding {
    padding: 15px;
    @media (max-width: 767px) {
      padding: 10px;
    }
  }
  &.resetBox {
    background: transparent;
    box-shadow: none;
  }
  &.SizeSelect {
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .size {
      width: 100%;
      align-items: center;
      span {
        svg {
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
    select {
      border: 0;
      font-size: 16px;
      color: #222;
      padding: 0 10px 0 0;
      margin: 0;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
`


export const ProfileBtnWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  padding: 0;
  button {
    width: auto;
    height: auto;
    padding: 8px 15px;
    font-family: 'ProximaNova-Regular';
  }
`

export const SearchBar = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  margin: 0;
  background: #fff;
  .CustomBoxSelect {
    width: 100%;
    display: flex;
    align-items: center;
  }
  &.resetLink {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    span {
      cursor: pointer;
    }
  }
`
export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 50px;
`

export const RadioWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  > :first-child {
    margin-right: 5px;
  }
  img {
    margin-top: 2px;
    margin-left: 10px;
  }
  align-items: flex-start;
`

export const ArtworkSearchIcon = styled.div`
  width: 32px;
  height: 35px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 22px;
    color: #222;
    font-weight: bold;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
`
export const ArtworkHeadingRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px;
  select {
    width: 70px;
    font-size: 16px;
    color: #222;
    padding: 0 10px;
    margin: 0;
    text-transform: uppercase;
    background-size: 10px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
`
export const ArtworkPriceHeading = styled.div`
  font-size: 16px;
  color: #222;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`
export const ArtworkValuesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  span {
    color: white;
    font-size: 12px;
    background-color: #b0b0b0;
    padding: 2px 5px;
  }
`
export const SearchInput = styled.input`
  width: calc(100% - 32px);
  height: 35px;
  color: #222;
  background: transparent;
  border: 0;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  ::placeholder {
    color: #aaa;
  }
  :focus,
  :hover {
    outline: none;
    border: 0;
  }
  @media (max-width: 767px) {
    font-size: 14px;
  }
`
export const SearchIcon = styled.div`
  width: 32px;
  height: 35px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 22px;
    color: #222;
    font-weight: bold;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
`
export const ArtworkDetailWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #fff;
  justify-content: space-between;
`
export const ImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    width: 100%;
  }
`
export const ArtworkImageSectionWrap = styled.div`
  width: 398px;
  max-height: 544px !important;
  margin: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .magnifierDiv {
    z-index: 0;
    width: 100%;
    height: 100%;
  }
  img {
    max-width: inherit;
    max-height: inherit;
    width: 100%;
    @media (max-width: 1024px) {
      width: auto;
      max-height: inherit;
    }
  }
  @media (min-width: 1025px) and (max-width: 1399px) {
    width: 44%;
  }
  @media (max-width: 1024px) {
    width: auto;
  }
`
export const ArtworkImageLeftIcons = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  min-height: 33px;
  min-width: 100px;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  padding: 0 10px;
  svg {
    color: #000;
    font-size: 18px;
    cursor: pointer;
  }
`
export const ArtworkIconWrap = styled.span`
  display: flex;
`
export const MagnifierDiv = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  height: 35px;
  width: 35px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 1;
  padding: 0;
  border-radius: 50%;
  svg {
    color: #222;
    font-size: 18px;
    cursor: pointer;
  }
`
export const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-left: 15px;
  max-width: 402px;
  @media (max-width: 479px) {
    flex-direction: column;
  }
  &.RowflexStart {
    justify-content: flex-start;
  }
  &.InnerSecRow {
    justify-content: flex-start;
    @media (max-width: 1024px) {
      max-width: 100%;
    }
    @media (max-width: 479px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
`
export const ImgSmContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px 20px 0;
  width: 125px;
  border: 2px solid #eeeeee;
  height: 125px;
  cursor: pointer;
  @media (max-width: 479px) {
    width: 110px;
    height: 110px;
  }
  @media (max-width: 343px) {
    width: 90px;
    height: 90px;
  }
  img {
    max-height: 125px;
    width: 100%;
    height: 100%;
    @media (max-width: 343px) {
      max-height: 90px;
    }
  }
`
export const DescriptionSection = styled.div`
  width: 420px;
  margin: 15px;
  position: relative;
  .added-button {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-left: 5px;
    }
  }
  @media (min-width: 1025px) and (max-width: 1399px) {
    width: 47%;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`
export const TopDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`
export const ArtworkName = styled.h1`
  font-family: 'ProximaNova-Regular' !important;
  margin: 10px 0 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: normal;
  text-align: left;
  color: #000;
  padding: 0;
  max-width: calc(100% - 45px);
  @media (max-width: 767px) {
    font-size: 16px;
  }
`

export const Location = styled.h1`
  padding: 0;
  margin: 10px 0 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: normal;
  text-align: left;
  color: #000;
  font-family: 'ProximaNova-Regular' !important;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`
export const ArtworkDescription = styled.h1`
  padding: 0;
  margin: 10px 0 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: #000;
  font-family: 'ProximaNova-Regular' !important;
  max-width: 260px;
  &.fullWidth {
    max-width: 100%;
  }
  @media (max-width: 1024px) {
    max-width: 100%;
  }
  @media (max-width: 767px) {
    font-size: 13px;
  }
`
export const DescriptionToggleIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  svg {
    cursor: pointer;
    font-size: 22px;
    color: #222;
    font-weight: bold;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
`
export const BorderBottomSec = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  flex-direction: row;
  max-width: 260px;
  align-items: center;
  /* @media (max-width: 1024px) {
    max-width: 100%;
  } */
  @media (min-width: 768px) and (max-width: 991px) {
  }
  select {
    max-width: 70px;
    margin: 0;
    color: #222;
    text-transform: uppercase;
    padding: 0 10px;
  }
  &.FlexStartDivFirst {
    justify-content: flex-start;
    margin: 15px 0;
    max-width: 400px;
    @media (max-width: 479px) {
      flex-direction: row;
      align-items: center;
    }
  }
  &.FlexStartDiv {
    justify-content: flex-start;
    margin: 15px 0;
    max-width: 400px;
    @media (max-width: 479px) {
      flex-direction: column;
      align-items: flex-start;
    }
    button {
      margin: 0 5px 0 0;
      font-family: 'ProximaNova-Regular';
      font-size: 16px;
      width: auto;
      padding: 8px 15px;
      @media (max-width: 767px) {
        font-size: 14px;
        padding: 4px 10px;
      }
      @media (max-width: 479px) {
        width: 100%;
        margin: 0 0 10px;
      }
    }
  }
`
export const LikeDiv = styled.div`
  display: flex;
  flex-direction: row;
`
export const UsersButton = styled.button`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #222;
  background: transparent;
  width: auto;
  border: 0;
  padding: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 0px;
  font-family: 'ProximaNova-Regular';
  font-weight: 100;
  :hover,
  :focus {
    outline: 0;
    border: 0;
    background: transparent;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 14px;
  }
  svg {
    margin: 0 5px 0 0;
    font-size: 18px;
    color: #aaa;
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 14px;
    }
  }
`

export const ConnectionsUl = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const ConnectionsLi = styled.div`
  width: 25px;
  height: 25px;
  margin: 0 0 0 2px;
  :first-child {
    width: auto;
    height: auto;
    margin: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: #222;
    padding: 0 5px;
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 14px;
    }
  }
  a {
    cursor: pointer;
  }
  @media (max-width: 767px) {
    font-size: 14px;
  }
  img {
    width: 25px;
    height: 25px;
  }
`
export const ArtworkPrice = styled.h1`
  padding: 0;
  margin: ${(props) => (props.showMargin ? '0 0 0 0' : '0 0 0 15px')};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  color: #000;
  font-family: 'ProximaNova-Regular';
  svg {
    cursor: pointer;
    margin-left: 5px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
`

export const TagsWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 0 12px;
  @media (max-width: 767px) {
    align-items: flex-start;
  }
`
export const LabeLTags = styled.label`
  padding: 7px 0 0 0;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: normal;
  text-align: left;
  color: #000;
  font-family: 'ProximaNova-Regular';
  width: 120px;
  text-transform: uppercase;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`

export const TagsInputWrap = styled.div`
  width: calc(100% - 120px);
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`
export const TagsDiv = styled.div`
  display: flex;
  justify-content: center;
  padding: 7px 10px;
  cursor: pointer;
  margin: 0 5px 5px 0;
  align-items: center;
  background: #ffffff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  color: #666666;
  font-size: 16px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`
export const ArtworksTabsContainer = styled.div`
  width: 100%;
  position: relative;
  font-family: 'ProximaNova-Regular';
  margin: 33px 0 0;
  .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
    margin-bottom: 0;
    order: 2;
  }
  .TabsContentDiv {
    background: #fff;
    font-size: 16px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .MuiAppBar-colorPrimary {
    box-shadow: none !important;
    background: transparent;
    margin-top: 0;
    border-bottom: 1px solid #ccc;
    padding: 0 15px;
  }
  .MuiTabs-indicator {
    display: none !important;
    background-color: #222;
    height: 4px;
  }
  .Mui-selected {
    background: #222 !important;
    color: #fff !important;
    .MuiTab-wrapper {
      color: #fff !important;
    }
  }
  .MuiButtonBase-root.MuiTab-root {
    opacity: 1;
    min-height: 25px;
    font-size: 16px;
    color: #222;
    min-width: auto;
    padding: 4px 12px;
    margin-right: 5px;
    background: #eee;
    font-weight: 400;
    max-width: inherit;
    @media (min-width: 992px) and (max-width: 1024px) {
      font-size: 14px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      max-width: inherit;
    }
  }
  .MuiButtonBase-root .MuiTab-wrapper {
    display: flex;
    flex-direction: row;
    font-family: 'ProximaNova-Regular';
    color: #222;
  }
  .MuiTab-root {
    text-transform: capitalize;
  }
  .Mui-selected {
    .Count {
      color: #222;
    }
  }
  .MuiTabs-flexContainer {
    @media (min-width: 992px) and (max-width: 1024px) {
      overflow-x: scroll;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
`

export const ListingWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 15px;
  .DefaultMsg {
    font-style: italic;
    color: #666;
    font-size: 16px;
  }
`
export const ItemsWrapper = styled.div`
  position: relative;
  margin: 0 10px 10px 0;
  padding: 0 0 10px 0;
  background: #ffffff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 200px;
  cursor: pointer;
  @media (min-width: 1025px) and (max-width: 1399px) {
    max-width: 196px;
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    width: 31%;
    max-width: inherit;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 31%;
    max-width: inherit;
  }
  @media (max-width: 767px) {
    max-width: 45%;
    margin: 0 auto 10px;
  }
  @media (max-width: 479px) {
    max-width: 205px;
    margin: 0 auto 10px;
  }
`
export const CoverImg = styled.div`
  position: relative;
  margin: 0;
  height: 177px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    @media (max-width: 1280px) {
      width: 100%;
    }
  }
`

export const ListName = styled.h1`
  font-family: 'ProximaNova-Regular';
  padding: 0 5px;
  margin: 15px auto 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  color: #000;
  @media (max-width: 767px) {
    font-size: 16px;
  }
`
export const PriceList = styled.div`
  padding: 0 5px;
  margin: 0 auto 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  color: #222;
  font-family: 'ProximaNova-Regular';
  @media (max-width: 767px) {
    font-size: 14px;
  }
`

export const BtnUser = styled.button`
background: #222;
color: #fff;
padding: 5px 10px;
border: 1px solid #222;
cursor: pointer;
&:hover{
    background: #fff;
color: #222;
}

`

export const MintTag = styled.div`
background: #222;
color: #fff;
padding: 5px 10px;
border: 1px solid #222;
display: inline-block;
`