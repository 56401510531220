// src/components/sorting.table.js
import React from "react";

// import {
//   useTable,
//   useFilters,
//   useGlobalFilter,
//   useAsyncDebounce,
//   usePagination,
//   useSortBy,
// } from "react-table";
// import "bootstrap/dist/css/bootstrap.min.css";

import { translations } from "../../../constants/translations";
import { TableComp } from "../../shared";

// // Define a default UI for filtering
// function GlobalFilter({
//   preGlobalFilteredRows,
//   globalFilter,
//   setGlobalFilter,
// }) {
//   // const count = preGlobalFilteredRows.length;
//   const [value, setValue] = React.useState(globalFilter);
//   const onChange = useAsyncDebounce((value) => {
//     setGlobalFilter(value || undefined);
//   }, 200);

//   return (
//     <span>
//       <input
//         className="form-control"
//         value={value || ""}
//         onChange={(e) => {
//           setValue(e.target.value);
//           onChange(e.target.value);
//         }}
//         placeholder={translations.User.SearchUser}
//         maxLength={15}
//       />
//     </span>
//   );
// }

// function DefaultColumnFilter({
//   column: { filterValue, preFilteredRows, setFilter },
// }) {
//   const count = preFilteredRows.length;

//   return (
//     <input
//       className="form-control"
//       value={filterValue || ""}
//       onChange={(e) => {
//         setFilter(e.target.value || undefined);
//       }}
//       placeholder={`${translations.User.Search} ${count} ${translations.User.Records}...`}
//     />
//   );
// }

// const Table = ({ columns, data, userDetails, onEditClick, onDeleteClick }) => {
//   const defaultColumn = React.useMemo(
//     () => ({
//       // Default Filter UI
//       Filter: DefaultColumnFilter,
//     }),
//     []
//   );

//   let hrefLink = "#";

//   // Use the state and functions returned from useTable to build your UI
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     rows,
//     prepareRow,
//     page,
//     canPreviousPage,
//     canNextPage,
//     pageOptions,
//     pageCount,
//     gotoPage,
//     nextPage,
//     previousPage,
//     setPageSize,
//     preGlobalFilteredRows,
//     setGlobalFilter,
//     state: { pageIndex, pageSize, globalFilter },
//   } = useTable(
//     {
//       columns,
//       data,
//       defaultColumn,
//       initialState: { pageIndex: 0, pageSize: 20 },
//     },
//     useFilters,
//     useGlobalFilter,
//     useSortBy,
//     usePagination
//   );

//   // Render the UI for your table
//   return (
//     <div className="admin-users">
//       <GlobalFilter
//         preGlobalFilteredRows={preGlobalFilteredRows}
//         globalFilter={globalFilter}
//         setGlobalFilter={setGlobalFilter}
//       />
//       <table
//         className="table table-bordernone table-striped table-hover"
//         {...getTableProps()}
//       >
//         <thead>
//           {headerGroups.map((headerGroup) => (
//             <tr {...headerGroup.getHeaderGroupProps()}>
//               {headerGroup.headers.map((column) => (
//                 // Add the sorting props to control sorting. For this example
//                 // we can add them into the header props
//                 <th {...column.getHeaderProps(column.getSortByToggleProps())}>
//                   {column.render("Header")}
//                   {/* Add a sort direction indicator */}
//                   <span>
//                     {column.isSorted
//                       ? column.isSortedDesc
//                         ? <i class="fa fa-arrow-down"></i>
//                         : <i class="fa fa-arrow-up"></i>
//                       : ""}
//                   </span>
//                 </th>
//               ))}
//               <th>Actions</th>
//             </tr>
//           ))}
//         </thead>
//         <tbody {...getTableBodyProps()}>
//           {page.map((row, i) => {
//             prepareRow(row);
//             return (
//               <tr {...row.getRowProps()}>
//                 {row.cells.map((cell) => {
//                   return (
//                     <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
//                   );
//                 })}
//                 <td className="action-btn">
//                   <div>
//                     {userDetails.roleName === "superadmin" ? (
//                       <span
//                         style={{ cursor: "pointer" }}
//                         onClick={() => {
//                           onDeleteClick(i, row.original.uuid);
//                         }}
//                       >
//                         <i
//                           className="fa fa-trash"
//                           style={{
//                             width: 35,
//                             fontSize: 20,
//                             padding: 11,
//                             color: "#e4566e",
//                           }}
//                         ></i>
//                       </span>
//                     ) : null}

//                     {userDetails.roleName === "superadmin" ? (
//                       <span
//                         style={{ cursor: "pointer" }}
//                         onClick={() => {
//                           onEditClick(row.original.uuid, row.original);
//                         }}
//                       >
//                         <i
//                           className="fa fa-pencil"
//                           style={{
//                             width: 35,
//                             fontSize: 20,
//                             padding: 11,
//                             color: "rgb(40, 167, 69)",
//                           }}
//                         ></i>
//                       </span>
//                     ) : null}

//                     {
//                       userDetails.roleName === "admin" &&
//                         row.original.roleId.roleName !== "superadmin" ? (
//                         <span
//                           style={{ cursor: "pointer" }}
//                           onClick={() => {
//                             onEditClick(row.original.uuid, row.original);
//                           }}
//                         >
//                           <i
//                             className="fa fa-pencil"
//                             style={{
//                               width: 35,
//                               fontSize: 20,
//                               padding: 11,
//                               color: "rgb(40, 167, 69)",
//                             }}
//                           ></i>
//                         </span>
//                       ) : null}
//                   </div>
//                 </td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>
//       {rows.length > pageSize ? (
//         <>
//           <ul className="pagination">
//             <li
//               className="page-item"
//               onClick={() => gotoPage(0)}
//               disabled={!canPreviousPage}
//             >
//               <a href={hrefLink} className="page-link">
//                 {"<<"}
//               </a>
//             </li>
//             <li
//               className="page-item"
//               onClick={() => previousPage()}
//               disabled={!canPreviousPage}
//             >
//               <a href={hrefLink} className="page-link">
//                 {"<"}
//               </a>
//             </li>
//             <li
//               className="page-item"
//               onClick={() => nextPage()}
//               disabled={!canNextPage}
//             >
//               <a href={hrefLink} className="page-link">
//                 {">"}
//               </a>
//             </li>
//             <li
//               className="page-item"
//               onClick={() => gotoPage(pageCount - 1)}
//               disabled={!canNextPage}
//             >
//               <a href={hrefLink} className="page-link">
//                 {">>"}
//               </a>
//             </li>
//             <li>
//               <a href={hrefLink} className="page-link">
//                 {translations.User.Page} &nbsp;
//                 <strong>
//                   {pageIndex + 1} of {pageOptions.length}
//                 </strong>
//               </a>
//             </li>
//             {/* <select
//               className="form-control"
//               value={pageSize}
//               onChange={(e) => {
//                 setPageSize(Number(e.target.value));
//               }}
//               style={{ width: "120px", height: "38px" }}
//             >
//               {[5, 10, 20, 30, 40, 50].map((pageSize) => (
//                 <option key={pageSize} value={pageSize}>
//                   {translations.User.Show} {pageSize}
//                 </option>
//               ))}
//             </select> */}
//           </ul>
//           <br />
//           {/* <div>{`${translations.User.ShowingFirst} ${rows.length} ${translations.User.ShowingResults} ${rows.length} ${translations.User.Rows}`}</div> */}
//         </>
//       ) : null}
//     </div>
//   );
// };

const SortingPaginationTableComponent = (props) => {
  const { data, userDetails, handleEditClick, handleDeleteClick, loadMore, total, currentPage } = props;
  const columns = React.useMemo(
    () => [
      {
        name: translations.User.FirstName,
        selector: "firstName",
        width: '15%'
      },
      {
        name: translations.User.LastName,
        selector: "lastName",
        width: "15%"
      },
      {
        name: translations.User.Email,
        selector: "email",
        width: "20%"
      },
      {
        name: translations.User.Role,
        selector: "roleName",
        width: "10%"
      },
      {
        name: translations.User.Language,
        selector: "language",
        width: "10%"
      },
      {
        name: 'Action',
        selector: "language",
        width: "10%",
        cellRenderer: (row) => {
          //console.log({ row })
          return <div>
            {userDetails && userDetails.roleName === "superadmin" ? (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDeleteClick(row.uuid);
                }}
              >
                <i
                  className="fa fa-trash"
                  style={{
                    width: 35,
                    fontSize: 20,
                    padding: 11,
                    color: "#e4566e",
                  }}
                ></i>
              </span>
            ) : null}

            {userDetails && userDetails.roleName === "superadmin" ? (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleEditClick(row.uuid, row);
                }}
              >
                <i
                  className="fa fa-pencil"
                  style={{
                    width: 35,
                    fontSize: 20,
                    padding: 11,
                    color: "rgb(40, 167, 69)",
                  }}
                ></i>
              </span>
            ) : null}

            {
              userDetails && userDetails.roleName === "admin" &&
                row.roleId.roleName !== "superadmin" ? (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleEditClick(row.uuid, row);
                  }}
                >
                  <i
                    className="fa fa-pencil"
                    style={{
                      width: 35,
                      fontSize: 20,
                      padding: 11,
                      color: "rgb(40, 167, 69)",
                    }}
                  ></i>
                </span>
              ) : null}
          </div>
        }
      },
    ],
    []
  );



  return (
    <TableComp
      columns={columns}
      data={data}
      onPageChange={(p) => loadMore({ limit: 20, page: p })}
      page={currentPage}
      limit={20}
      totalRecords={total}
      loading={false}

    />
    // <Table
    //   columns={columns}
    //   data={data}
    //   userDetails={userDetails}
    //   onEditClick={handleEditClick}
    //   onDeleteClick={handleDeleteClick}
    // />
  );
};

export default SortingPaginationTableComponent;
