import React, { useEffect, useRef, useState } from 'react'
import styles from '../helpdesk.module.css'
import Message from './message'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Button } from '../../shared'
import { get } from 'lodash'
import { createImageUrl } from '../../../utils/imageHelper'
import Chat from './chat'
import { redirectToClientAppUser } from '../../../utils/redirects'
import { useDispatch, useSelector } from 'react-redux'
import { ResponseMessagesModal } from '../../modals'
import { fetchMessageResponseArt } from '../../../features/artworks/artworkSlice'
const ChatHistory = props => {
    const dispatch=useDispatch()
    const { chat, sendMessage, user, loading, totalMessages, loadMoreChat, conversationId } = props
    const [textMessage, setTextMessage] = useState('')
    const [ deletedMessageId, setDeletedMessageId ] = useState()
    const [showModal, setShowModal] = useState(false)
    const MessageResponseArt = useSelector(state => get(state, 'artwork.allMessageResponseArt', []))
    const loadingMessageResponseArt = useSelector(state => get(state, 'artwork.loadingCounter', false))

    const messageRef = useRef()

    const renderChatHistory = (messages) => {
        let i = 0;
        let messageCount = messages.length;
        let tempMessages = [];

        while (i < messageCount) {
            let previous = messages[i + 1];
            let current = messages[i];
            let next = messages[i + 1];
            let isMine = current?.userIdSender?.username==='marketplace';
            let currentMoment = moment(current.dateCreated);
            let prevBySameAuthor = false;
            let nextBySameAuthor = false;
            let startsSequence = true;
            let endsSequence = true;
            let showTimestamp = true;

            if (previous) {
                let previousMoment = moment(previous.dateCreated);
                let previousDuration = moment.duration(currentMoment.diff(previousMoment));
                prevBySameAuthor = previous?.userIdSender?._id === current?.userIdSender?._id;

                if (prevBySameAuthor && previousDuration.as('hours') < 1) {
                    startsSequence = false;
                }

                if (previousDuration.as('hours') < 1) {
                    showTimestamp = false;
                }
            }

            if (next) {
                let nextMoment = moment(next.dateCreated);
                let nextDuration = moment.duration(nextMoment.diff(currentMoment));
                nextBySameAuthor = next.author === current.author;

                if (nextBySameAuthor && nextDuration.as('hours') < 1) {
                    endsSequence = false;
                }
            }

            tempMessages.push(
                <Message
                    key={i}
                    isMine={isMine}
                    startsSequence={startsSequence}
                    endsSequence={endsSequence}
                    showTimestamp={showTimestamp}
                    data={current}
                    profilePic={current.profilePicUrl}
                    setDeletedMessageId={setDeletedMessageId}
                />
            );
            i += 1;
        }

        return tempMessages
    }

    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }
    }, [conversationId])

    const sendMessageHandler = () => {
        sendMessage({ receiver: user?.userInfo?._id, message: textMessage })
        setTextMessage('')
    }

    const fetchMore = () => {
        !loading && loadMoreChat()
    }


    const getAuthorName = (u) => {
        const { firstName, lastName, email } = u || {}
        if (firstName || lastName) {
            return `${firstName || ''} ${lastName || ''}`
        }
        return email?.value || 'N/A'
    }
useEffect(() => {
    dispatch(fetchMessageResponseArt())
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
useEffect(()=>{
    console.log('gggggggg'+JSON.stringify(MessageResponseArt))
},[MessageResponseArt])
    return <>
        {showModal && <ResponseMessagesModal
            mopen={true}
            onCloseModal={() => setShowModal(false)}
            submitHandler={(v) => setTextMessage(v)}
            message={''}
            title="Response Messages"
            responses={MessageResponseArt}
        />}
    <div >
        <div onClick={() => redirectToClientAppUser(get(user, 'userInfo.username', null))} className={styles.chatWindowTop}>
            <img src={user?.profilePicUrl ? createImageUrl(user?.userInfo?.profilePicUrl) : '/static/media/man.7b23c9a1.png'} alt="Not found" onError={(e) => e.target.src = '/static/media/man.7b23c9a1.png'} className={`${styles.messageUserImg}`}></img>
            <h1 className={styles.chatWindowUser}>{getAuthorName(user?.userInfo)}</h1>
        </div>
        <div id="userHistory" style={{ display: "flex", flexDirection: "column-reverse" }} ref={messageRef} className={styles.chatHistory} >
            <div>
                <Chat
                    chat={chat}
                    fetchMore={() => fetchMore()}
                    totalMessages={totalMessages}
                    renderChatHistory={(c) => renderChatHistory(c)}
                    loading={loading}
                    deletedMessageId={deletedMessageId}
                />
            </div>
        </div>
        <div className={styles.replyBox}>
            <form onSubmit={() => null}>
                <div className={styles.InputWrapper}>
                    <textarea value={textMessage} onChange={(e) => setTextMessage(e.target.value)} placeholder="Type your message here.." className={styles.messageTextarea} />
                </div>
                <div style={{display:'flex',justifyContent: 'space-between',width:'28%'}}>
                <Button loading={loading} type="button" clicked={() => sendMessageHandler()} className={styles.sendButton} >Send</Button>
                <Button loading={loading || loadingMessageResponseArt} type="button" clicked={() => setShowModal(true)} className={styles.sendButton} >Response</Button>
                </div>
            </form>

        </div>
    </div>
    </>
}

ChatHistory.propTypes = {
    chat: PropTypes.array.isRequired,
    sendMessage: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
}

ChatHistory.defaultProps = {
    chat: [],
    sendMessage: () => null,
    user: {},
    loading: false
}

export default ChatHistory