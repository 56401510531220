/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer, useState } from 'react'
import Breadcrumb from "../../common/breadcrumb";
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../../hooks/apiStateHandler'
import { getArticles, acceptArticle, fetaureArticle, updateArticle, deleteArticle,AdminAssignArticle } from '../../../services/articles'
import { Button, ShowMoreViewer, TableComp, ToolTip } from '../../shared'
import { get } from 'lodash-es';
import { toast } from 'react-toastify';
import SearchArticle from '../components/search'
import { SOMETING_WENT_WRONG, statusForAdminAcceptence, TOTAL_RECORDS } from '../../../constants/translations'
import styles from '../articles.module.css'
import { EditArticleModal } from '../../modals'

const ExhibitionsList = (props) => {
    const { history } = props
    const [exhibitionArticleState, exhibitionDispatch] = useReducer(ApiStateReducer, initialState)
    const [appliedfilters, setAppliedFilters] = useState({ status: [0, 1] })
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        getArticlesHandler({ articleType: 'exhibition', offset: 0, limit: get(exhibitionArticleState, 'limit', 20), filters: appliedfilters })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getArticlesHandler = async (params) => {
        try {
            exhibitionDispatch({ type: ApiStateEvents.FETCHING })
            const res = await getArticles(params)
            if (res.success) {
                exhibitionDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.articles', []), offset: params.offset, totalRecords: get(res, 'data.total') })
            } else {
                toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
                exhibitionDispatch({ type: ApiStateEvents.ERROR, data: [], totalRecords: get(res, 'data.total') })
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
            exhibitionDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }

    const appliedFiltersHandler = (filtersParams) => {
        getArticlesHandler({ articleType: 'exhibition', offset: 0, limit: get(exhibitionArticleState, 'limit', 20), filters: { ...filtersParams } })
        setAppliedFilters(filtersParams)
    }

    const deleteArticleHandler = async (articleId) => {
        updateArticleHandler(articleId, 'deleting', true)
        const res = await deleteArticle({ articleType: 'exhibition', articleId })
        if (res.success) {
            toast.success(get(res, 'data.message'))
            const allArticles = get(exhibitionArticleState, 'data', [])
            const newArticles = allArticles.filter(o => o._id !== articleId)
            if (newArticles) {
                exhibitionDispatch({ type: ApiStateEvents.SUCCESS, data: newArticles })
            }
        } else {
            toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
            updateArticleHandler(articleId, 'deleting', false)
        }
    }

    const getAuthor = (artwork) => {
        if (get(artwork, 'nameOfArtist.firstName', '').length) {
            return get(artwork, 'nameOfArtist.firstName') + ' ' + get(artwork, 'nameOfArtist.lastName')
        }
        return get(artwork, 'userId.firstName', 'N/A') + ' ' + get(artwork, 'userId.lastName', '')
    }

    const columns = [
        {
            name: "Title",
            selector: "title",
            width: "15%",
            sortable: true,
        },
        {
            name: "Description",
            sortable: true,
            width: "40%",
            selector: "description",
            cellRenderer: (row) => <ShowMoreViewer
                type="html" text={row.description || ''}
                className={styles.descriptionViwer}
                showMoreLength={10000}
                defaultLength={5000}
            />
        },
        {
            name: "Author",
            sortable: true,
            width: "15%",
            selector: "author",
            cellRenderer: (row) => (<p>{getAuthor(row)}</p>)
        },
        {
            name: "Status",
            sortable: true,
            width: "15%",
            selector: "moderated",
            cellRenderer: (row) => (<div>{statusForAdminAcceptence[row.moderated || 0]}</div>)
        },
        {
            name: 'Actions',
            width: "15%",
            cellRenderer: (row) => (<>
                <><Button title="Edit Article" loading={row.editing} loadingText={'Updating'} clicked={() => setShowModal(row)} className="IconButon">
                    <i class="fa fa-check-square"
                        className="fa fa-pencil"
                        style={{
                            width: 35,
                            fontSize: 20,
                            padding: 11,
                            color: "rgb(40, 167, 69)",
                        }} aria-hidden="true"></i>
                </Button></>
                {(row.moderated === 0) && <Button title="Accept Article" loading={row.loading} loadingText={'Loading'} clicked={() => acceptArticleHandler(row._id, 1, "Accepted")} className="IconButon">
                    <i class="fa fa-check-square" style={{
                        width: 'auto',
                        fontSize: 18,
                        padding: 0,
                        color: "rgb(0,191,255)",
                    }} aria-hidden="true"></i>
                </Button>}
                {(row.moderated === 0 || row.moderated === 1) && <Button title="Reject Article" loading={row.loading} loadingText={'Loading'} clicked={() => acceptArticleHandler(row._id, -1, "Rejected")} className="IconButon">
                    <i class="fa fa-minus-square" style={{
                        width: 'auto',
                        fontSize: 18,
                        padding: 0,
                        color: "#ff0000",
                    }} aria-hidden="true"></i>
                </Button>}
                {(row.moderated === 1) && <Button title={row.isFeatured ? "Unfeature Article" : "Feature Article"} clicked={() => featureArticleHandler(row._id, !row.isFeatured)} className="IconButon">{!row.isFeatured ? <i class="fa fa-bookmark-o"></i> : <i class="fa fa-bookmark"></i>}</Button>}
                <Button title="View Article" clicked={() => history.push(`/articles/details/exhibition/${row._id}`)} className="IconButon">
                    <i
                        className="fa fa-eye"
                        style={{
                            width: 'auto',
                            fontSize: 18,
                            padding: 0,
                            color: "rgb(0,191,255)",
                        }}
                    ></i>
                </Button>
                <Button title="Delete Article" loading={row.deleting} loadingText={'Deleting'} clicked={() => deleteArticleHandler(row._id)} className="IconButon">
                    <i class="fa fa-check-square"
                        className="fa fa-trash"
                        style={{
                            width: 35,
                            fontSize: 20,
                            padding: 11,
                            color: "#e4566e",
                        }} aria-hidden="true"></i>
                </Button>
                <><Button title="Assign to admin" loading={row.assigning} loadingText={'Assigning'} clicked={() => AssignToAdminArticleHandler(row._id)} className="IconButon">
                    <i data-tip data-for="Assigning" class="fa fa-share"
                        className="fa fa-share"
                        style={{
                            width: 35,
                            fontSize: 20,
                            padding: 11,
                            color: "black",
                        }} aria-hidden="true"></i>
                </Button></>
            </>)
        }
    ]
    const updateArticleHandler = (articleId, field, value) => {
        const allArticles = get(exhibitionArticleState, 'data', [])
        const articleIndex = allArticles.findIndex(o => o._id === articleId)
        if (articleIndex !== -1) {
            allArticles[articleIndex][field] = value
            exhibitionDispatch({ type: ApiStateEvents.SUCCESS, data: allArticles })
        }
    }

    const acceptArticleHandler = async (articleId, moderated, action) => {
        updateArticleHandler(articleId, 'loading', true)
        const res = await acceptArticle({ articleType: 'exhibition', articleId, moderated })
        if (res.success) {
            toast.success(get(res, 'data.message'))
            updateArticleHandler(articleId, 'moderated', moderated)
            updateArticleHandler(articleId, 'action', action)
        } else {
            toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
        }
        updateArticleHandler(articleId, 'loading', false)
    }

    const featureArticleHandler = async (articleId, featureStatus) => {
        updateArticleHandler(articleId, 'loadingFeaturing', true)
        const res = await fetaureArticle({ articleId, featureStatus, articleType: 'exhibition' })
        if (res.success) {
            toast.success(get(res, 'data.message'))
            updateArticleHandler(articleId, 'isFeatured', featureStatus)
        } else {
            toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
        }
        updateArticleHandler(articleId, 'loadingFeaturing', false)
    }

    const updateArticleHandlerApi = async (values = {}) => {
        updateArticleHandler(values._id, 'editing', true)
        setShowModal(false)
        const res = await updateArticle({ ...values, articleId: values._id, articleType: 'exhibition' })
        if (res.success) {
            toast.success(get(res, 'data.message'))
            const allArticles = get(exhibitionArticleState, 'data', [])
            const articleIndex = allArticles.findIndex(o => o._id === values._id)
            if (articleIndex !== -1) {
                allArticles[articleIndex] = get(res, 'data.article', {})
                exhibitionDispatch({ type: ApiStateEvents.SUCCESS, data: allArticles })
            }
        } else {
            toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
        }
        updateArticleHandler(values._id, 'editing', false)
    }

    const AssignToAdminArticleHandler = async (articleId) => {
        const res = await AdminAssignArticle({ articleType: 'exhibition', articleId })
        if (res.success) {
        updateArticleHandler(articleId, 'userId.firstName', 'ARTMO')
        updateArticleHandler(articleId, 'userId.lastName', 'Team')
            toast.success(get(res, 'data.message'))
        } else {
            toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
        }
    }

    const onPageChange = (page) => {
        getArticlesHandler({
            articleType: 'exhibition',
            offset: get(exhibitionArticleState, 'limit', 20) * (page - 1),
            limit: get(exhibitionArticleState, 'limit', 20),
            filters: appliedfilters,
            sortField: get(exhibitionArticleState, 'sorting.sortField'),
            sortOrder: get(exhibitionArticleState, 'sorting.sortOrder'),
        })
    }

    const onSort = ({ fieldName, sortOrder }) => {
        exhibitionDispatch({
            type: ApiStateEvents.ADD_SORTING, sorting: {
                sortField: fieldName,
                sortOrder: sortOrder,
            }
        })
        getArticlesHandler({
            articleType: 'exhibition',
            offset: 0,
            limit: get(exhibitionArticleState, 'limit', 20),
            filters: appliedfilters,
            sortField: fieldName,
            sortOrder: sortOrder,
        })
    }

    return <>
        <Breadcrumb title='Exhibitions' parent="Articles" />
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h5 className="BackButtonHeading">Exhibitions</h5>
                </div>
                <div>
                    <SearchArticle addFilter={(filter) => setAppliedFilters({ ...appliedfilters, ...filter })} applyFilters={(filters) => appliedFiltersHandler(filters)} allfilters={appliedfilters} />
                </div>
                <div style={{ margin: "0 30px 15px" }}>
                    {!get(exhibitionArticleState, 'loading') && TOTAL_RECORDS + get(exhibitionArticleState, 'totalRecords', 0)}
                </div>
                <div className="card-body">
                    <TableComp
                        onPageChange={(p) => onPageChange(p)}
                        page={(get(exhibitionArticleState, 'offset', 0) / get(exhibitionArticleState, 'limit', 20) + 1)}
                        limit={get(exhibitionArticleState, 'limit', 20)}
                        totalRecords={get(exhibitionArticleState, 'totalRecords', 0)}
                        loading={get(exhibitionArticleState, 'loading', false)}
                        data={get(exhibitionArticleState, 'data', [])}
                        columns={columns}
                        onSort={onSort}
                        sortField={get(exhibitionArticleState, 'sorting.sortField', null)}
                        sortOrder={get(exhibitionArticleState, 'sorting.sortOrder', null)}
                    />
                </div>
                {showModal && showModal._id ? <EditArticleModal
                    loading={false}
                    onCloseModal={() => setShowModal(false)}
                    submitHandler={(v) => updateArticleHandlerApi({ ...v, moderated: 0 })}
                    mopen={true}
                    articleType="exhibition"
                    values={showModal}
                    title="Edit Exhibition"
                /> : null}
            </div>
        </div>
    </>
}

export default ExhibitionsList