import React, { useState } from "react";
import Modal from "react-modal";
import { unwrapResult } from "@reduxjs/toolkit";
import styles from './membermessage.module.css'
import styled from 'styled-components'

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
    },
};

const CommonTd = styled.td`
    display: flex ;
    p{
        color: #313131;
        &.pPad{
            padding-left: 10px ;
        }
    }
    a {
        cursor: pointer ;
        font-weight: bolder;
    }
    strong{
        font-weight: bolder;
    }
    
`

const CommentModal = ( props ) => {
    const {
        onCloseModal,
        mopen,
        values,
        title,
    } = props

    return ( 
        <Modal
            isOpen="true"
            onClose={onCloseModal}
            onRequestClose={onCloseModal}
            className="ModalContent"
            overlayClassName="OverlayContent"
            style={customStyles}
        >
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    {title}
                </h5>
                <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => onCloseModal()}
                        >
                            Close
                        </button>
            </div>
            <div className="modal-body">
                <table className="table table-bordered tableFixHead"> 
                    <thead> 
                        <tr>  
                            <th>Comment Content</th>  
                        </tr>  
                    </thead>
                    <tbody>
                    {values.map((value, index) => (  
                        <tr data-index={index}>  
                            <CommonTd><p className="pPad"> <strong>{ value } </strong></p></CommonTd>
                        </tr>  
                    ))} 
                    </tbody> 
                </table> 
            </div>
        </Modal>
    )
}
export default CommentModal;