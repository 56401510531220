import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import { TOTAL_RECORDS, translations } from "../../constants/translations";
// import { languages } from "../../constants/languages";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import {
  getAllAdminUsers,
  fetchAdminUsers,
  deleteAdminUser,
  getAdminRoles,
  addAdminUser,
  updateAdminUser,
  clearAdminState,
  adminSelector,
} from "../../features/user/adminSlice";
import { getAllLanguages } from "../../features/common/commonSlice";
import ModalAddAdminUser from "./add-admin-modal";
import SortingPaginationTableComponent from "../common/table/table-pagination";
import { LoaderComp, Button } from "../shared";

const ListUsers = (props) => {
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState("");
  const [currentUserData, setCurrentUserData] = useState([]);
  const [mopen, setMopen] = useState(false);
  const fetchStatus = useSelector((state) => state.admins.status);
  const total = useSelector((state) => state.admins.total || 0);
  const currentPage = useSelector((state) => state.admins.currentPage || 1);
  const error = useSelector((state) => state.admins.error);
  const admins = useSelector(getAllAdminUsers);
  const adminRoles = useSelector(getAdminRoles);
  const languages = useSelector(getAllLanguages);
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const [updating, setUpdating] = useState(false)
  const { isSuccess, isError, successMessage, errorMessage } =
    useSelector(adminSelector);

  useEffect(() => {
    if (fetchStatus === "idle") {
      const data = {
        page: 1,
        pageSize: 20,
      };
      dispatch(fetchAdminUsers(data));
    }
  }, [fetchStatus, dispatch]);

  const loadMore = (params) => dispatch(fetchAdminUsers(params))

  const onOpenModal = () => {
    setCurrentUser("");
    setCurrentUserData([]);

    setMopen(true);
  };

  const onCloseModal = () => {
    setMopen(false);
    dispatch(clearAdminState());
  };

  const submitHandler = (data) => {
    setUpdating(true)
    if (currentUser !== "" && currentUser !== undefined) {
      data.userId = currentUser;
      dispatch(updateAdminUser(data)).finally(() => setUpdating(false));
    } else {
      dispatch(addAdminUser(data)).finally(() => setUpdating(false));
    }
  };

  const handleDeleteClick = (userId) => {
    if (window.confirm(translations.User.DeleteConfirm)) {
      dispatch(deleteAdminUser({ userId }));
      dispatch(clearAdminState());
    }
  };

  /**
   * Handle Edit admin user
   * @param {*} userId
   * @param {*} userData
   */
  const handleEditClick = (userId, userData) => {
    setCurrentUser(userId);
    setCurrentUserData(userData);

    setMopen(true);
  };

  useEffect(() => {
    if (isError) {
      // toast.error(errorMessage);
      // dispatch(clearAdminState());
    }

    if (isSuccess) {
      toast.success(successMessage);
      setMopen(false);
      dispatch(clearAdminState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess, dispatch]);

  let userContent = null;
  if (fetchStatus === "loading") {
    userContent = <LoaderComp loading />;
  } else if (fetchStatus === "completed") {
    if (admins && admins.length) {
      // userContent = <TableAdmin users={admins} onDeleteUser={deleteThisUser} />;
      userContent = (
        <SortingPaginationTableComponent
          data={admins}
          userDetails={userDetails}
          handleEditClick={handleEditClick}
          total={total}
          currentPage={currentPage}
          loadMore={(p) => loadMore(p)}
          handleDeleteClick={handleDeleteClick}
        />
      );
    }
  } else if (fetchStatus === "failed") {
    userContent = <div className="error">{error}</div>;
  }

  return (
    <Fragment>
      <Breadcrumb title={translations.User.AdminUsersList} parent="Admin" />
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <h5 className="BackButtonHeading"><Button className="BackButtonTop"><i title="Back" class="fa fa-angle-left pull-left"></i></Button>
              {translations.User.AdminUsersList}
            </h5>
          </div>
          <div style={{ margin: "15px 30px 15px" }}>
            {TOTAL_RECORDS + total || 0}
          </div>
          <div className="card-body">
            <ModalAddAdminUser
              mopen={mopen}
              errorMessage={errorMessage}
              userDetails={userDetails}
              userId={currentUser}
              userData={currentUserData}
              submitHandler={submitHandler}
              languages={languages}
              adminRoles={adminRoles}
              onOpenModal={onOpenModal}
              onCloseModal={onCloseModal}
              updating={updating}
            />
            {userContent}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ListUsers;
