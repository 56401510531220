import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash';
import { Button, MultiSelectComp } from '../../shared';
import styles from '../emailTemplates.module.css'
import styled from "styled-components";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

const DateRange = styled.div`
    input {
        height : 19px !important;
    }
    .react-daterange-picker {
        width: 100%;
    }
    .react-daterange-picker__wrapper {
        padding: 4.5px .50rem;
        border: 1px solid #ced4da;
        border-radius: .25rem;
    }
    .react-daterange-picker__button svg {
        stroke: #aaa;
    }
`

const EmailTemplateSearch = (props) => {
    const { applyFilters, uniqueIds, loading } = props
    const [filters, setFilters] = useState({})
    const getUniqueIdOptions = useMemo(() => uniqueIds.map(({ uniqueId }) => ({ value: uniqueId, label: uniqueId })), [uniqueIds])

    const handleSubmit = () => {
        let parsedfilters = {}
        const { dateUpdated } = filters
        if (dateUpdated && dateUpdated.length) {
            let tempObj = {}
            dateUpdated.forEach((dt, index) => {
                if (index) {
                    tempObj.end_date = dt
                } else {
                    tempObj.start_date = dt
                }
                parsedfilters.dateUpdated = tempObj
            })
        }
        applyFilters({ ...filters, ...parsedfilters })
    }

    return <div className={styles.filterContainer}>
        <div className={styles.userFilter}>
            <MultiSelectComp
                options={getUniqueIdOptions}
                displayNumberOfItems={3}
                disableSearch
                loading={loading}
                values={get(filters, 'uniqueId', [])}
                onSubmit={(v) => setFilters({ ...filters, uniqueId: v.parsedValues })}
                placeholder="Select uniqueId"
            />
        </div>
        <div className={styles.userFilter}>
            <input
                name="title"
                className={`form-control ${styles.inputTitle}`}
                maxLength={40}
                placeholder="Search title"
                value={get(filters, 'title', '')}
                onChange={(e) => setFilters({ ...filters, title: e.target.value })}
                onKeyPress={(e) => { e.key === 'Enter' && handleSubmit(filters) }}
            />
        </div>
        <div className={styles.userFilter}>
            <DateRange>
                <DateRangePicker
                    name="dateUpdated"
                    ranges={[]}
                    value={get(filters, 'dateUpdated', null)}
                    maxDate={new Date()}
                    onChange={(date) => setFilters({ ...filters, dateUpdated: date })}
                />
            </DateRange>
        </div>
        <Button clicked={() => handleSubmit(filters)} className={`btn btn-primary ${styles.filterBtn}`}>Filter</Button>
        <Button clicked={() => { applyFilters({}); setFilters({}) }} className="btn btn-light" >Reset</Button></div>
}

EmailTemplateSearch.defaultProps = {
    applyFilters: () => null,
    allfilters: {},
    uniqueIds: [],
    loading: false,

}

EmailTemplateSearch.propTypes = {
    allfilters: PropTypes.object.isRequired,
    applyFilters: PropTypes.func.isRequired,
    uniqueIds: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default EmailTemplateSearch