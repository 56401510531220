import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash';
import { Button } from '../../shared';
import styles from '../helpdesk.module.css'

const MassMemberSeach = (props) => {
    const { applyFilters, allfilters, addFilter } = props

    return <div className={styles.filterContainer}>
        <div className={styles.userFilter}>
            <input
                name="user"
                className={`form-control ${styles.inputTitle}`}
                maxLength={40}
                placeholder="Search user by username"
                value={get(allfilters, 'user', '')}
                onChange={(e) => addFilter({ user: e.target.value })}
                onKeyPress={(e) => { e.key === 'Enter' && applyFilters(allfilters) }}
            />
        </div>
        <Button clicked={() => applyFilters(allfilters)} className={`btn btn-primary ${styles.filterBtn}`}>Filter</Button>
        <Button clicked={() => applyFilters({})} className="btn btn-light" >Reset</Button></div>
}

MassMemberSeach.defaultProps = {
    applyFilters: () => null,
    addFilter: () => null,
    allfilters: {}

}

MassMemberSeach.propTypes = {
    allfilters: PropTypes.object.isRequired,
    addFilter: PropTypes.func.isRequired,
    applyFilters: PropTypes.func.isRequired,
}

export default MassMemberSeach