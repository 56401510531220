import React, { useState } from 'react'
import { DescriptionSection, TopDiv, ArtworkName, Location, ArtworkDescription, DescriptionToggleIcon, BorderBottomSec, LikeDiv, UsersButton, ConnectionsLi, ConnectionsUl, ArtworkPrice, TagsWrap, LabeLTags, TagsDiv, TagsInputWrap, BtnUser, MintTag } from '../../../styled'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import { BsFillQuestionCircleFill } from 'react-icons/bs'
import { IoThumbsUpSharp } from 'react-icons/io5'
import { createResizeImageUrl } from '../../../../utils/imageHelper'
import artmoDefault from '../../../../assets/images/artmo-default.png'
import { Select } from '../../../shared'
import { LikesModal } from "../../../modals";

const ArtworkDetailSection = props => {
    const { artwork } = props
    const { title, isAdmin, nameOfArtist, userId, country, type, artworkType, isMinted, contractTokenID, mintedAt, transactionHash, editionDetail, artDimensions = {}, sizeType, partOfSeries, seriesDetails, material, year, description, likes = [], priceOnRequest, price, artMediumId, genreId, subjects, nftArtworkData } = artwork
    const [showFullDesc, setShowFullDesc] = useState(false)
    const [likesModal, setLikesModal] = useState(false)

    const name = !isAdmin
        ? nameOfArtist &&
        nameOfArtist.firstName &&
        `${nameOfArtist.firstName} ${nameOfArtist.lastName}`
        : userId && userId.firstName && userId.lastName
            ? `${userId.firstName} ${userId.lastName}`
            : userId && userId.username
                ? userId.username
                : ''

    return <DescriptionSection>
        {likesModal && <LikesModal likesData={likes} isOpen onCloseModal={() => setLikesModal(false)} title="Likes" />}
        <TopDiv>
            <ArtworkName>{title}</ArtworkName>
            <div className="mb15">
                <BtnUser onClick={() => {
                    //history.push(`/users/${UPDATE}/${row._id}`)
                    const win = window.open(`https://artmo.com/user/${artwork && artwork.userId && artwork.userId.username}`, "_blank")
                    win.focus()
                }}>View User</BtnUser>
            </div>
        </TopDiv>
        <Location>
            {name}
            {' • '}
            {country}
        </Location>
        <ArtworkDescription>
            {year}
            {' • '}
            {material}
            <br />
            {type === 'edition' &&
                editionDetail &&
                editionDetail.version &&
                editionDetail.count !== 0 && (
                    <>
                        Edition{' '}
                        {editionDetail &&
                            editionDetail.version &&
                            editionDetail.version}
                        /
                        {editionDetail &&
                            editionDetail.count !== 0 &&
                            editionDetail.count}
                    </>
                )}{' '}
            {type === 'edition' &&
                editionDetail &&
                editionDetail.version &&
                editionDetail.count !== 0 &&
                artDimensions &&
                ' • '}
            {artDimensions && sizeType === '2D'
                ? `${artDimensions.length} cm x ${artDimensions.height} cm`
                : `${artDimensions.length} cm x ${artDimensions.height} cm x ${artDimensions.width} cm`}
            {partOfSeries && ' • '}
            {seriesDetails}
        </ArtworkDescription>
        <ArtworkDescription className="fullWidth">
            {description && description.length < 201
                ? description
                : description && description.length > 200 && !showFullDesc
                    ? description.slice(0, 201)
                    : description && description.length > 200 && showFullDesc
                        ? description
                        : null}
        </ArtworkDescription>
        <DescriptionToggleIcon>
            {description && description.length > 200 && !showFullDesc && (
                <RiArrowDownSLine onClick={() => setShowFullDesc(true)} />
            )}
            {description && description.length > 200 && showFullDesc && (
                <RiArrowUpSLine onClick={() => setShowFullDesc(false)} />
            )}
        </DescriptionToggleIcon>
        <BorderBottomSec>
            <LikeDiv>
                <UsersButton>
                    <IoThumbsUpSharp /> Like{' '}
                </UsersButton>
            </LikeDiv>
            {likes.length > 0 && (
                <ConnectionsUl>
                    <ConnectionsLi>
                        {likes.length > 5 && (
                            <a onClick={() => setLikesModal(true)}>+{likes.length - 5}</a>
                        )}
                    </ConnectionsLi>
                    {likes.slice(0, 5).map((user) => (
                        <ConnectionsLi onClick={() => setLikesModal(true)} key={user._id}>
                            <a>
                                <img
                                    src={
                                        user.profilePicUrl
                                            ? createResizeImageUrl(user.profilePicUrl, 50, 50, 'profileCover')
                                            : artmoDefault
                                    }
                                    // onError={(e) => {
                                    //     imageErrorHandler(e, createImageUrl(user.profilePicUrl), artmoDefault, 'profileCover', '')
                                    // }}
                                    alt=""
                                />
                            </a>
                        </ConnectionsLi>
                    ))}
                </ConnectionsUl>
            )}
        </BorderBottomSec>
        <BorderBottomSec className="FlexStartDivFirst">
            {!priceOnRequest && (
                <Select value='EUR'>
                    <option value="EUR">EUR</option>
                </Select>
            )}
            <ArtworkPrice showMargin={priceOnRequest}>
                {priceOnRequest ? (
                    <>
                        Price on Request <BsFillQuestionCircleFill />
                    </>
                ) :
                    price}
            </ArtworkPrice>
        </BorderBottomSec>
        {artMediumId && artMediumId.length > 0 && (
            <TagsWrap>
                <LabeLTags>Medium</LabeLTags>
                <TagsInputWrap>
                    {artMediumId.map((medium) => (
                        <TagsDiv
                            key={medium._id}
                        >
                            {medium.title}
                        </TagsDiv>
                    ))}
                </TagsInputWrap>
            </TagsWrap>
        )}
        {genreId && genreId.length > 0 && (
            <TagsWrap>
                <LabeLTags>Genre</LabeLTags>
                <TagsInputWrap>
                    {genreId.map((genre) => (
                        <TagsDiv
                            key={genre._id}
                        >
                            {genre.title}
                        </TagsDiv>
                    ))}
                </TagsInputWrap>
            </TagsWrap>
        )}
        {subjects && subjects.length > 0 && (
            <TagsWrap>
                <LabeLTags>Subject</LabeLTags>

                <TagsInputWrap>
                    {subjects.map((subject, ind) => (
                        <TagsDiv
                            key={ind}
                        >
                            {subject}
                        </TagsDiv>
                    ))}
                </TagsInputWrap>
            </TagsWrap>
        )}
        {artworkType === 'nft' ?
            <div>
                <ArtworkDescription>
                    {isMinted ? <>
                        {contractTokenID ? `Token ID: ${contractTokenID}` : 'ID N/A'}
                        <br></br>
                        {mintedAt ? `Minted at: ${mintedAt}` : 'Date N/A'}
                        <br></br>
                        See transaction <a href={`https://etherscan.io/tx/${transactionHash}`} target='_blank' style={{color: 'blue'}}>here</a>
                    </> : null}
                </ArtworkDescription>
                <MintTag>NFT {' • '} {isMinted ? 'MINTED' : 'NOT MINTED'}</MintTag>
            </div>
            : null}
    </DescriptionSection >
}

export default ArtworkDetailSection