import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { unwrapResult } from "@reduxjs/toolkit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { translations } from "../../constants/translations";
import ReactHtmlParser from 'react-html-parser'
import {
  commonSelector,
  clearCommonState,
  getAllSubscriptions,
  getAllSubscriptionCategories,
  fetchAllSubscriptionCategories,
  enableDisableSubscriptionCategory,
} from "../../features/common/commonSlice";
import Switch from 'react-switch'
import { LoaderComp } from "../shared";

const UpdatePlanSettings = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRole, setselectedRole] = useState("member");
  const [selectedSubscription, setSelectedSubscription] = useState([]);
  const [selectedSubsCategories, setSelectedSubsCategories] = useState([]);
  const subscriptions = useSelector(getAllSubscriptions);
  // const subscriptionsCategories = useSelector(getAllSubscriptionCategories);
  // const fetchSubCatStatus = useSelector((state) => state.common.subCatStatus);
  const { isSuccess, isError, successMessage, errorMessage } =
    useSelector(commonSelector);

  /**
   * Fetch subscriptions categories
   * @param {*} params
   */
  const fetchSubscriptionCategories = () => {
    dispatch(fetchAllSubscriptionCategories())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setData(originalPromiseResult.subscriptionCategories);
        setLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        toast.error(rejectedValueOrSerializedError);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchSubscriptionCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearCommonState());
    }

    if (isSuccess) {
      toast.success(successMessage);
      dispatch(clearCommonState());
    }
  }, [isError, isSuccess, errorMessage, successMessage, dispatch]);

  const handleEnableDisable = (
    categoryId,
    subscriptionId,
    currentStatus
  ) => {
    let clonnedCategories = [...selectedSubsCategories]
    const index = clonnedCategories.findIndex(o => o._id === categoryId)
    if (index !== -1) {
      let subsIds = [...clonnedCategories[index].subscriptionIds] || []
      if (!currentStatus) {
        subsIds.push(subscriptionId)
      } else {
        subsIds = subsIds.filter(o => o !== subscriptionId)
      }
      clonnedCategories[index] = {
        ...clonnedCategories[index],
        subscriptionIds: subsIds
      }

    }
    setSelectedSubsCategories([...clonnedCategories])
    dispatch(
      enableDisableSubscriptionCategory({
        categoryId,
        subscriptionId,
        currentStatus,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        fetchSubscriptionCategories();
        setLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        toast.error(rejectedValueOrSerializedError.messageCode);
        if (index !== -1) {
          let subsIds = [...clonnedCategories[index].subscriptionIds] || []
          if (!currentStatus) {
            subsIds = subsIds.filter(o => o !== subscriptionId)
          } else {
            subsIds.push(subscriptionId)
          }
          clonnedCategories[index] = {
            ...clonnedCategories[index],
            subscriptionIds: subsIds
          }

        }
        setSelectedSubsCategories([...clonnedCategories])
        setLoading(false);
      });
  };

  const handleRoleChange = (value) => {
    setselectedRole(value);
    if (value === "member") {
      setSelectedSubscription(
        subscriptions.filter((plan) => plan.role === "member")
      );
      setSelectedSubsCategories(data.filter((cats) => cats.role === "member"));
    } else if (value === "artist") {
      setSelectedSubscription(
        subscriptions.filter((plan) => plan.role === "artist")
      );
      setSelectedSubsCategories(data.filter((cats) => cats.role === "artist"));
    }
  };

  return (
    <Fragment>
      <ToastContainer autoClose={2000} />
      <Breadcrumb
        title={translations.Settings.UpdatePlanSettings}
        parent={translations.Settings.Settings}
        loading={loading}
      />
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <h5 className="BackButtonHeading">
              {translations.Settings.UpdatePlanSettings}
            </h5>
          </div>
          <div className="card-body">
            {loading && <LoaderComp loading />}
            {!loading && <div className="select-subscription-role">
              <select
                name="selectRole"
                className="form-control"
                onChange={(e) => handleRoleChange(e.target.value)}
              >
                <option value="">{translations.Settings.SelectRole}</option>
                <option value="artist">Artist</option>
                <option value="member">Member</option>
              </select>
            </div>}
            {!loading && selectedSubscription.length ? (
              <table
                id="subscription-plan-categories-list"
                className="table table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <td align="center" width="25%">
                      {translations.Settings.SubscriptionCategories}
                    </td>
                    {selectedSubscription.map((item, index) => {
                      return (
                        <td key={"plan-" + index} align="center">
                          <div className="subscription-data">
                            <div className="subscription-title">
                              {item.title}
                            </div>
                            <div className="subscription-price">
                              {typeof item.price === "object" ? (
                                <span>
                                  € {item.price[Object.keys(item.price)[0]]} /
                                  {Object.keys(item.price)[0]}
                                </span>
                              ) : (
                                "Free"
                              )}
                            </div>
                            <div className="subscription-publish">
                              <small>
                                {translations.Settings.PublishAndSell}
                              </small>{" "}
                              <br />
                              {`${item.publishArtworks
                                ? item.publishArtworks
                                : translations.Settings.Unlimited
                                } ${translations.Settings.Artworks}`}
                            </div>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {selectedSubsCategories &&
                    selectedSubsCategories.map((category, ii) => {
                      return (
                        <tr key={"plan-category-" + ii}>
                          <td width="25%">{ReactHtmlParser(category.title)}</td>
                          {selectedSubscription.map((item, iii) => {
                            return (
                              <td
                                key={"plan-category-sub-" + iii}
                                align="center"
                              >

                                <Switch
                                  checked={category.subscriptionIds.includes(
                                    item._id
                                  )}
                                  onChange={(e) => handleEnableDisable(
                                    category._id,
                                    item._id,
                                    category.subscriptionIds.includes(
                                      item._id
                                    )
                                  )}
                                  onColor="#86d3ff"
                                  onHandleColor="#2693e6"
                                  handleDiameter={30}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={20}
                                  width={48}
                                  className="react-switch"
                                  id="material-switch"
                                />
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            ) : null}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdatePlanSettings;
