import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { SOMETING_WENT_WRONG, statusForAdminAcceptence } from '../../../constants/translations'
import { searchEndUser } from '../../../services/endUserManagement'
import { get } from 'lodash';
import { Button, MultiSelectComp, ReactSelect } from '../../shared';
import styles from '../artwork.module.css'
import { useDebouncedValue } from "../../../hooks/debounce";
import { getAllGenres } from '../../../services/artworks';
import { toast } from 'react-toastify';
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../../hooks/apiStateHandler'

const ArtworkSeacrh = (props) => {
    const { applyFilters, allfilters, addFilter, selectedUser } = props
    const [keyword, setKeyword] = useState('')
    const debouncedValue = useDebouncedValue(keyword, 500)
    const [loadingOptions, setLoadingOptions] = useState(false)
    const [options, setOptions] = useState([])

    const getAuthorValueOptions = useMemo(() => {
        const parsedOptions = options
        if (selectedUser) {
            const { firstName, lastName, _id, fullname } = selectedUser
            const index = options.findIndex((o) => o.value === selectedUser._id)
            if (index === -1) {
                if (fullname ){
                    parsedOptions.push({ label: fullname, value: _id })
                } else {
                    parsedOptions.push({ label: firstName + ' ' + lastName, value: _id })
                }
                
            }
        }
        return parsedOptions
    }, [options, selectedUser])

    const [genres, setGenres] = useState([]);
    useEffect(() => {
        const getGenres = async () => {
            try {
                const res = await getAllGenres({ offset: 0, limit: 20 });
                if (res.success) {
                    setGenres(res.data.genres);
                } else {
                    toast.error(SOMETING_WENT_WRONG);
                }
            } catch (error) {
                toast.error(SOMETING_WENT_WRONG);
            }
        };
        getGenres();
    }, []);

    const getGenreValueOptions = useMemo(() => {
        return genres.map(({ title, _id }) => ({ label: title, value: _id }));
    }, [genres]);


    useEffect(() => {
        const getOptions = async () => {
            setLoadingOptions(true)
            const res = await searchEndUser({ searchKeyword: debouncedValue })
            let parsedOptions = []
            if (res.success) {
                parsedOptions = res.data.users.map(({ firstName, _id, lastName }) => ({ label: firstName + ' ' + lastName, value: _id }))
            }
            setOptions(parsedOptions)
            setLoadingOptions(false)
        }
        debouncedValue && getOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue])
    const getStatusOptions = useMemo(() => Object.keys(statusForAdminAcceptence).map(id => ({ value: parseInt(id), label: statusForAdminAcceptence[id] })), [])
    return <div className={styles.filterContainer}>
        <div className={styles.userFilter}>
            <MultiSelectComp
                options={[...getStatusOptions, { label: "Draft", value: 'isDrafted' }]}
                disableSearch
                displayNumberOfItems={3}
                values={get(allfilters, 'status', [])}
                onSubmit={(v) => addFilter({ status: v.parsedValues })}
                placeholder="Select Status"
            />
        </div>
        <div className={styles.userFilter}>
            <ReactSelect
                options={[{label: 'Basic', value: false}, {label: 'Premium', value: true}]}
                onChange={(v) => { localStorage.setItem('isPremium', v.label) ; addFilter({ isPremium: v.value })  }}
                values={[get(allfilters, 'isPremium', null)]}
                placeholder="Select Sub Type"
                name="Select Sub Type"
            />
        </div>
        <div className={styles.userFilter}>
            <input
                name="title"
                maxLength={40}
                className={`form-control ${styles.inputTitle}`}
                placeholder="Search title"
                value={get(allfilters, 'title', '')}
                onChange={(e) => addFilter({ title: e.target.value })}
                onKeyPress={(e) => { e.key === 'Enter' && applyFilters(allfilters) }}
            />
        </div>
        <div className={styles.userFilter}>
            <ReactSelect
                options={getGenreValueOptions} // You need to define this function to get the genre options
                onChange={(v) => { localStorage.setItem('genreId', v.label) ; addFilter({ genreId: v.value })  }}
                values={[get(allfilters, 'genreId', null)]}
                placeholder="Select Genre"
                name="Select Genre"
            />
        </div>
        
        <div className={styles.userFilter}>
            <input
                name="username"
                maxLength={40}
                className={`form-control ${styles.inputTitle}`}
                placeholder="Search by Username"
                value={get(allfilters, 'username', '')}
                onChange={(e) => addFilter({ username: e.target.value })}
                onKeyPress={(e) => { e.key === 'Enter' && applyFilters(allfilters) }}
            />
        </div>
        <div className={styles.userFilter}>
            <ReactSelect
                options={getAuthorValueOptions}
                onChange={(v) => { localStorage.setItem('sellerName', v.label) ; addFilter({ userId: v.value })  }}
                values={[get(allfilters, 'userId', null)]}
                onInputChange={(v) => setKeyword(v)}
                loading={loadingOptions}
                name="Search Seller"
            />
        </div>
        <div className={styles.userFilter}>
                <ReactSelect
                    options={[{label: 'Digital', value: 'digital'}, {label: 'Physical', value: 'physical'}, {label: 'NFT', value: 'nft'}]}
                    onChange={(v) => { localStorage.setItem('artworkType', v.label) ; addFilter({ artworkType: v.value })  }}
                    values={[get(allfilters, 'artworkType', null)]}
                    placeholder="Select Artwork Type"
                    name="Select Artwork Type"
                />
            </div>
        <Button clicked={() => applyFilters(allfilters)} className={`btn btn-primary ${styles.filterBtn}`}>Filter</Button>
        <Button clicked={() => applyFilters({}, 'reset')} className="btn btn-light" >Reset</Button></div>
}

ArtworkSeacrh.defaultProps = {
    applyFilters: () => null,
    addFilter: () => null,
    allfilters: {}

}

ArtworkSeacrh.propTypes = {
    allfilters: PropTypes.object.isRequired,
    addFilter: PropTypes.func.isRequired,
    applyFilters: PropTypes.func.isRequired,
}

export default ArtworkSeacrh
