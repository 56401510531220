import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import styles from './styles.module.css'

const RadioButtonText = styled.div`
  margin: 0 16px 0 0;
  .ValueName {
    color: #848484;
    font-size: 16px;
  }
`

const Radio = (props) => (
    <RadioButtonText className={props.classText ? props.classText : 'InnerText'}>
        <label className={styles.radioContainer}>
            <span className="ValueName">{props.labelName}</span>
            <input type="radio" name={props.name} value={props.value} checked={props.checked} onChange={props.onChange} />
            <span className={styles.radioCheckmark}></span>
        </label>
    </RadioButtonText>
)

Radio.propTypes = {
    labelName: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    classText: PropTypes.string,
}

export default Radio
