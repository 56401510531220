import React, { Fragment, useState, useEffect, useMemo, useReducer } from "react";
import Breadcrumb from "../common/breadcrumb";
import { unwrapResult } from "@reduxjs/toolkit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import ModalEditContent from "./edit-content-modal.js";
import { updateStaticContent } from "../../features/common/commonSlice";
import { addNewContent } from "../../services/content"
import { TableComp } from "../shared";
import { SOMETING_WENT_WRONG, TOTAL_RECORDS } from "../../constants/translations";
import Search from './components/search'
import ModalAddNewContent from "./add_new_content";
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../hooks/apiStateHandler'

const ContentManagment = () => {
  const dispatch = useDispatch();
  const [mopen, setMopen] = useState(false);
  const [row,setRow] = useState()
  const [contentId, setContentId] = useState("");
  const [viewOnly, setViewOnly] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [sorting, setSorting] = useState({})
  const languages = useSelector((state) => state.common.languages);
  const [appliedfilters, setAppliedFilters] = useState({})

  const columns = useMemo(() => [
    {
      name: "Label",
      selector: "uniqueContentId",
      sortable: true,
    },
    {
      name: "Actions",
      // eslint-disable-next-line react/button-has-type
      cellRenderer: (row) => (
        <>
          <span
            title="Edit Content"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleViewEdit(row._id, 0,row);
            }}
          >
            <i
              className="fa fa-pencil"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "rgb(40, 167, 69)",
              }}
            ></i>
          </span>

          <span
            title="View Content"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleViewEdit(row._id, 1);
            }}
          >
            <i
              className="fa fa-eye"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "rgb(0,191,255)",
              }}
            ></i>
          </span>
        </>
      ),
    },
  ]);

  /**
   * Fetch content from server
   * @param {*} page
   * @param {*} size
   */
  const fetchContents = async (page, size = perPage, sortingParams = sorting, filters = appliedfilters) => {
    setLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}common/contents`, { params: { page: page, perPage: size, delay: 1, ...sortingParams, filters } }
    );
    const respData = response.data;
    setData(respData.data.contents);
    setTotalRows(respData.data.total);
    setCurrentLanguage(respData.data.engLangId);
    setLoading(false);
  };

  useEffect(() => {
    fetchContents(1);
  }, []);

  const handlePageChange = (page) => {
    fetchContents(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    fetchContents(page, newPerPage);
  };
  /**
   * Handle edit/view buttons
   * @param {*} contentId
   * @param {*} isViewOnly
   */
  const handleViewEdit = (contentId, isViewOnly,row) => {
    setRow(row)
    setViewOnly(isViewOnly);
    setContentId(contentId);
    setMopen(true);
  };

  const onCloseModal = () => {
    setMopen(false);
  };
  /**
   * Submit editor handler
   * @param {*} languageId
   * @param {*} content
   */
  const submitHandler = (languageId, content) => {
    setLoading(true);
    const params = {
      languageId: languageId,
      contentText: content,
    };
    if (contentId !== "" && contentId !== undefined) {
      params.contentId = contentId;
    }

    dispatch(updateStaticContent(params))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        toast.success(originalPromiseResult?.data?.message);
        setLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        toast.error(rejectedValueOrSerializedError);
        setLoading(false);
      });
    setMopen(false);
  };

  const onSort = ({ fieldName, sortOrder }) => {
    setSorting({
      sortField: fieldName,
      sortOrder: sortOrder,
    })
    setCurrentPage(1);
    fetchContents(1, perPage, {
      sortField: fieldName,
      sortOrder: sortOrder,
    })
  }

  const appliedFiltersHandler = (f) => {
    fetchContents(1, perPage, sorting, f);
    setAppliedFilters(f)
  }
  const [ addNewTemplateState, addNewTemplatesDispatch] = useReducer(ApiStateReducer, initialState)
  const [mAddopen, setMAddopen] = useState(false);
  const [updating, setUpdating] = useState(false)
  const onOpenAddModal = () => {
    setMAddopen(true);
  };
  const onCloseAddModal = () => {
    setMAddopen(false);
  };
  const submitAddHandler = async (data) => {
    setUpdating(true)
    const res = await addNewContent({uniqueCode: data})
    if (res && res?.success) {
        toast.success(res?.data?.message)
        addNewTemplatesDispatch({ type: ApiStateEvents.SUCCESS})
        fetchContents(1)
    } else {
        toast.error(SOMETING_WENT_WRONG)
        fetchContents(1)
    }
    setMAddopen(false)
    setUpdating(false)
};

  return (
    <Fragment>
      <ToastContainer autoClose={2000} />
      <Breadcrumb title="Content Managment" parent="ContentManagment" />
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <h5 className="BackButtonHeading">
              Content Management
            </h5>
          </div>
          <Search addFilter={(filter) => setAppliedFilters({ ...appliedfilters, ...filter })} applyFilters={(filters) => appliedFiltersHandler(filters)} allfilters={appliedfilters} />
          <div style={{ margin: "0 30px 15px" }}>
            {!loading && (TOTAL_RECORDS + totalRows || 0)}
          </div>
          <div className="card-body">
            <ModalAddNewContent
                    mopen={mAddopen}
                    submitHandler={submitAddHandler}
                    onOpenModal={onOpenAddModal}
                    onCloseModal={onCloseAddModal}
                    updating={updating}
                />
            {mopen && <ModalEditContent
              heading={row && row.uniqueContentId}
              mopen={true}
              viewOnly={viewOnly}
              languages={languages}
              contentId={contentId}
              currentLanguage={currentLanguage}
              submitHandler={submitHandler}
              onCloseModal={onCloseModal}
            />}
            
            <TableComp
              onSort={onSort}
              loading={loading}
              columns={columns}
              data={data}
              totalRecords={totalRows}
              page={currentPage}
              onPageChange={handlePageChange}
              limit={perPage}
              sortField={sorting.sortField}
              sortOrder={sorting.sortOrder}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ContentManagment;
