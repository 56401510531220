import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'

const ListingWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 15px;
`
const DescriptionHeading = styled.h1`
  font-family: 'ProximaNova-Regular';
  padding: 15px 0 15px;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: normal;
  text-align: left;
  color: #222;
  @media (max-width: 767px) {
    font-size: 18px;
  }
`

const ShippingPolicySection = ({ artmoShippingPolicy, getPolicy }) => {
    useEffect(() => {
        getPolicy()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <ListingWrap>
                {/* {shippingPolicy && shippingPolicy.length > 0 && (
                    <>
                        <DescriptionHeading>My Return Policy:</DescriptionHeading>
                        <AboutDescription>{shippingPolicy}</AboutDescription>
                    </>
                )} */}
                <DescriptionHeading>ARTMO Shipping Policy:</DescriptionHeading>
                {ReactHtmlParser(artmoShippingPolicy)}
            </ListingWrap>
        </>
    )
}
ShippingPolicySection.propTypes = {
    shippingPolicy: PropTypes.string,
}
export default ShippingPolicySection
