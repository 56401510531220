import React, { Component } from "react";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import { translations } from "../../constants/translations";

export class Breadcrumb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breakpoint : 767,
      width: typeof window !== 'undefined' ? window.innerWidth : undefined,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    return (
      // <div className="container-fluid">
      //   <div className="page-header">
      //     <div className="row">
      //       <div className="col-lg-6">
      //         <div className="page-header-left">
      <div>
              {this.state.breakpoint<this.state.width?
                <h3 style={{position:'absolute',zIndex:999,top:'-50px',left:'80px'}}>
                {this.props.title}
                {/* <small>{translations.Common.TitleAdminPanel}</small> */}
              </h3>:<h5 style={{position:'absolute',zIndex:999,top:'-50px',left:'180px'}}>
              {this.props.title}
              {/* <small>{translations.Common.TitleAdminPanel}</small> */}
            </h5>
              } 
              </div> 
      //         </div>
      //       </div>
      //       <div className="col-lg-6">
      //         <ol className="breadcrumb pull-right">
      //           <li className="breadcrumb-item">
      //             <Link to="/dashboard">
      //               <Home />
      //             </Link>
      //           </li>
      //           {this.props.parent && <li className="breadcrumb-item">{this.props.parent}</li>}
      //           <li className="breadcrumb-item active">{this.props.title}</li>
      //         </ol>
      //       </div>
      //       <div className="col-lg-12">
      //         <button type="button" class="BackButtonTop"><i class="fa fa-arrow-left"></i> Back</button>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default Breadcrumb;
