import * as Yup from "yup";
import { translations } from "../../constants/translations";

/**
 * article Validations Schema
 */
export const addMasterClassValidationSchema = Yup.object({
  title: Yup.string()
    .required(translations.Validations.Require),
  description: Yup.string()
    .required(translations.Validations.Require),
    shortDescription: Yup.string()
    .max(200, translations.Validations.ShortDescriptionMaxLength)
    .required(translations.Validations.Require),
    price: Yup.string()
    .required(translations.Validations.Require),
    userId: Yup.string()
    .required(translations.Validations.Require),
    // category: Yup.string()
    // .required(translations.Validations.Require),
    // startDate: Yup.string()
    // .required(translations.Validations.Require),
    // endDate: Yup.string()
    // .required(translations.Validations.Require),
});

/**
 * Login Validations Schema
 */
export const loginValidationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email(translations.Validations.InvalidEmailAddress)
    .required(translations.Validations.Require),
  password: Yup.string().required(translations.Validations.Require),
});
/**
 * Forgot Password Validations Schema
 */
export const forgotValidationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email(translations.Validations.InvalidEmailAddress)
    .required(translations.Validations.Require),
});
/**
 * Change Password Validations Schema
 */
export const changepassValidationSchema = Yup.object({
  currentPassword: Yup.string()
    .trim()
    .required(translations.Validations.Require),
  newPassword: Yup.string()
    .trim()
    .required(translations.Validations.Require)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      translations.Validations.PasswordStrength
    ),
  confirmPassword: Yup.string()
    .required(translations.Validations.Require)
    .oneOf([Yup.ref("newPassword")], translations.Validations.PasswordNotMatch),
});
/**
 * Reset Password Validations Schema
 */
export const resetValidationSchema = Yup.object({
  password: Yup.string()
    .trim()
    .required(translations.Validations.Require)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      translations.Validations.PasswordStrength
    ),
  confirmPassword: Yup.string()
    .required(translations.Validations.Require)
    .oneOf([Yup.ref("password")], translations.Validations.PasswordNotMatch),
});
/**
 * Add Admin User Validations Schema
 */
export const adminAddValidationSchema = Yup.object({
  firstName: Yup.string().trim().required(translations.Validations.Require),
  lastName: Yup.string().trim().required(translations.Validations.Require),
  email: Yup.string()
    .trim()
    .email(translations.Validations.InvalidEmailAddress)
    .required(translations.Validations.Require),
  role: Yup.string().required(translations.Validations.Require),
  language: Yup.string().required(translations.Validations.Require),
});
/**
 * Video Category Validations Schema
 */
export const videoCategoryValidationSchema = Yup.object({
  categoryTitle: Yup.string().trim().required(translations.Validations.Require),
});
/**
 * Group Category Validations Schema
 */
export const groupCategoryValidationSchema = Yup.object({
  categoryTitle: Yup.string().trim().required(translations.Validations.Require),
});
/**
 * Tag Validations Schema
 */
export const tagValidationSchema = Yup.object({
  tagTitle: Yup.string().trim().required(translations.Validations.Require),
});
/**
 * Subscription Validations Schema
 */
export const subscriptionValidationSchema = Yup.object({
  title: Yup.string().trim().required(translations.Validations.Require),
});

/**
 * Message Validations Schema
 */
export const messageValidationSchema = Yup.object({
  message: Yup.string()
    .required(translations.Validations.Require),
  noEmail: Yup.boolean().oneOf([true, false], 'Invalid selection') // Validation for noEmail checkbox
});

/**
 * Email template Validations Schema
 */
export const emailTemplateValidationSchema = Yup.object({
  title: Yup.string()
    .required(translations.Validations.Require),
  subject: Yup.string()
    .required(translations.Validations.Require),
  content: Yup.string()
    .required(translations.Validations.Require),
});

/**
 * Static Message Validation Schema
 */
export const staticMessageValidationSchema = Yup.object({
  content: Yup.string()
    .required(translations.Validations.Require),
});

/**
 * article Validations Schema
 */
export const editArticleValidationSchema = Yup.object({
  title: Yup.string()
    .required(translations.Validations.Require),
  description: Yup.string()
    .required(translations.Validations.Require),
  articleSlug: Yup.string()
    .required(translations.Validations.Require)
});

/**
 * Reject Reason Validations Schema
 */
export const rejectReasonValidationSchema = Yup.object({
  title: Yup.string()
    .required(translations.Validations.Require),
  description: Yup.string()
    .required(translations.Validations.Require),
});
/**
 * Suspension Reason Validations Schema
 */
export const suspensionReasonValidationSchema = Yup.object({
  title: Yup.string()
    .required(translations.Validations.Require),
  description: Yup.string()
    .required(translations.Validations.Require),
});
/**
 * Response Message Validations Schema
 */
export const responseMessageValidationSchema = Yup.object({
  title: Yup.string()
    .required(translations.Validations.Require),
  message: Yup.string()
    .required(translations.Validations.Require),
});
/**
 * Artwork validations
 */
export const artworkValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('titleRequired')
    .test('', 'titleMaxLength', function (value) {
      if (value && value.length <= 250) return true
    }),
  year: Yup.string().test('', 'invalidYear', function (value) {
    if (value === undefined) return true
    if ((value.length === 4 || value.length === 0) && parseInt(value) > 1000) return true
  }),
  artPhotos: Yup.array()
    .test('', 'artworkPhotosRequired', function (value) {
      if (value && value.length > 0) {
        const filteredArr = value.filter((o) => o.pictureUrl !== 'default')
        if (filteredArr.length > 0) {
          return true
        } else {
          return false
        }
      }
    })
    .of(Yup.object())
    .nullable()
    .required('artworkPhotosRequired'),
  priceOnRequest: Yup.boolean(),
  price: Yup.string().when(['priceOnRequest'], {
    is: (priceOnRequest) => {
      if (!priceOnRequest) return true
      return false
    },
    then: Yup.string()
      .required('priceRequired')
      .test('', 'minimumPrice', function (value) {
        if (parseInt(value && value.replace(',', '.')) >= 10) return true
      }),
  }),
  type: Yup.string().required('artworkTypeRequired'),
  isAdmin: Yup.boolean(),
  // nameOfArtist: Yup.object().when(['isAdmin'], {
  //   is: (isAdmin) => {
  //     if (!isAdmin) return true
  //     return false
  //   },
  //   then: Yup.object().shape({
  //     firstName: Yup.string().required('artistFirstNameRequired'),
  //     lastName: Yup.string().required('artistLastNameRequired'),
  //   }),
  // }),
  partOfSeries: Yup.boolean(),
  seriesDetails: Yup.string().when(['partOfSeries'], {
    is: (partOfSeries) => {
      if (partOfSeries) return true
      return false
    },
    then: Yup.string().required('seriesNameRequired'),
  }),
  freeShipping: Yup.boolean(),
  editionDetail: Yup.object().when(['type'], {
    is: (type) => {
      if (type === 'edition') return true
      return false
    },
    then: Yup.object().shape({
      version: Yup.string().required('editionNoRequired'),
      count: Yup.string().required('editionTotalRequired'),
    }),
  }),
  sizeType: Yup.string(),
  isFramed: Yup.boolean(),
  frameDimensions: Yup.object().when(['sizeType', 'isFramed'], {
    is: (sizeType, isFramed) => {
      if (sizeType === '2D' && isFramed) return true
      return false
    },
    then: Yup.object().shape({
      length: Yup.string().required('frameLengthRequired'),
      width: Yup.string().required('frameWidthRequired'),
    }),
  }),
  frameMaterial: Yup.string().when(['sizeType', 'isFramed'], {
    is: (sizeType, isFramed) => {
      if (sizeType === '2D' && isFramed) return true
      return false
    },
    then: Yup.string().required('frameMaterialRequired'),
  }),
  timePeriod: Yup.string().required('timePeriodRequired'),
})
