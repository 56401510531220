import React from "react";
import Modal from "react-responsive-modal";
import { messageValidationSchema } from '../common/validations'
import { Formik, Field, Form, ErrorMessage } from "formik";

const SendNoticeModal = (props) => {
    const {
        mopen,
        onCloseModal,
        submitHandler,
    } = props

    return (
        <Modal open={mopen} onClose={onCloseModal} focusTrapped={false}>
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    Send Notice
          </h5>
            </div>
            <div className="modal-body">
                <Formik
                    initialValues={{
                        message: "",
                    }}
                    validationSchema={messageValidationSchema}
                    onSubmit={(values) => {
                        submitHandler(values)
                    }}
                >
                    <Form id="messageForm">
                        <div className="form-group">
                            <label htmlFor="firstName">Notice</label>
                            <Field
                                className="form-control textareaExpand"
                                as="textarea"
                                rows={10}
                                cols={20}
                                id="message"
                                name="message"
                                placeholder="Type your message here"
                            />
                            <ErrorMessage
                                component="div"
                                className="alert alert-danger"
                                name="message"
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">
                            Send
                        </button>
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => onCloseModal()}
                        >
                            Close
                        </button>
                    </Form>
                </Formik>
            </div>
            <div className="modal-footer"></div>
        </Modal>
    );
};

export default SendNoticeModal;
