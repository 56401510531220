import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { addMasterClassValidationSchema } from '../common/validations'
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Button, RadioButton } from '../shared'
import { Editor } from "@tinymce/tinymce-react";
import styled from 'styled-components'
import SectionContent from './SectionContent'
import UploadPhotoModal from './UploadPhotoModal'
import { uploadImageToMediaLibrary } from "../../utils/imageHelper";
import { getmasterProfile } from "../../services/masterProfile";
import { replaceWordpressImage } from "../../utils/image";
import { get } from "lodash";
import { fetchActiveMasterclassCategories } from "../../features/common/commonSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import ReactSelect from "react-select";

const ArticleSectionWrap = styled.div`
  background: #ffffff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
  width: auto;
  position: relative;
  margin: 0 0 16px;
  display: flex;
  flex-direction: column;
  padding: 0px 35px 0;
  justify-content: space-between;
  @media (max-width: 991px) {
    padding: 15px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    padding: 15px;
  }
`

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
    },
};

const AddNewClassModal = (props) => {
    const {
        mopen,
        onCloseModal,
        submitHandler,
        loading,
        values
    } = props

    const dispatch = useDispatch()
    const [plainText, setPlainText] = useState(false)
    const [openUploadModal, setOpenUploadModal] = useState(false)
    const [articleImage, setArticleImage] = useState([])
    const [masterProfile, setMasterProfile] = useState([])
    const [currentTimeZone, setCurrentTimeZone] = useState('');
    const [allCategories, setCategories] = useState('');
    const [isTBD, setIsTBD] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    useEffect(()=>{
        dispatch(fetchActiveMasterclassCategories())
        .then(unwrapResult)
        .then((originalPromiseResult) => {
            setCategories(originalPromiseResult.categories);
        })
    },[])
    useEffect(()=>{
        if(values)
        setIsEdit(true)
    },[])
    useEffect(() => {
        const fetchCurrentTimeZone = () => {
          const now = new Date();
          const offsetInMinutes = now.getTimezoneOffset();
          const offsetSign = offsetInMinutes > 0 ? '-' : '+';
          const offsetHours = Math.abs(Math.floor(offsetInMinutes / 60));
          const offsetMinutes = Math.abs(offsetInMinutes % 60);
          const formattedOffset = `${offsetSign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
          setCurrentTimeZone(formattedOffset);
        };
        fetchCurrentTimeZone();
      }, []);

    const getMasterProfileHandler = async () => {
        try {
            const res = await getmasterProfile()
            if (res.success)
                setMasterProfile(res.data.profiles)
        } catch (error) {

        }
    }
    useEffect(() => {
        getMasterProfileHandler()
    }, [])

    const deleteImg = (imgId) => {
        const imagesArr = [...articleImage]
        const filteredArr = imagesArr.filter((imgObj, index) => index !== imgId)
        setArticleImage(filteredArr)
    }
    const handleOnImageChange = (data) => {
        setArticleImage(data)
      }
      const applyHandler = async (values) => {
        if (articleImage) {
          let image = articleImage.result
          fetch(image)
            .then((res) => res.blob())
            .then(async (blob) => {
              const response = await uploadImageToMediaLibrary(blob, articleImage.filename)
              if (response && response.data && response.data.data) {
                const imgId = response.data.data.images[0]._id;
                setArticleImage(response.data.data.images)
                values.picUrl = imgId
              }
              setOpenUploadModal(false)
            })
        }
      }
      const getDateTimeWithTimeZone = (startDate) => {
        try {
          const dateObj = new Date(startDate);
          if (isNaN(dateObj.getTime())) {
            throw new Error("Invalid date");
          }
      
          // Get the default time zone of the user's environment
          const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      
          // Use the date object's toLocaleString method to get the time in the user's time zone
          const localeDateString = dateObj.toLocaleString('en-US', { timeZone });
      
          // Create a new date object from the locale date string
          const adjustedDateObj = new Date(localeDateString);
      
          return adjustedDateObj.toISOString().slice(0, 16);
        } catch (error) {
          console.error("Error:", error.message);
          return null;
        }
      };
      const formatCategories = (categories) => {
        if (!categories) return [];
        return Array.isArray(categories) 
            ? categories.map(cat => typeof cat === 'object' ? { value: cat._id, label: cat.title } : { value: cat, label: cat })
            : [categories].map(cat => typeof cat === 'object' ? { value: cat._id, label: cat.title } : { value: cat, label: cat });
    };
    useEffect(() => {
        if (values && values.picUrl) {
            setArticleImage([ values.picUrl ]);
        }
    }, [values]);
    return (
        <Modal
            isOpen={mopen}
            onClose={onCloseModal}
            onRequestClose={onCloseModal}
            style={customStyles}
            className="ModalContent"
            overlayClassName="OverlayContent"
        >
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    {/* {title} */}
                </h5>
            </div>
            <div className="modal-body">
                <Formik
                    initialValues={{
                        title: get(values, 'title', ''),
                        shortDescription: get(values, 'shortDescription', ''),
                        description: get(values, 'description', ''),
                        picUrl: get(values, 'picUrl', ''),
                        trailerLink: get(values, 'trailerLink', ''),
                        channelId: get(values, 'channelId', ''),
                        channelPlaybackUrl: get(values, 'channelPlaybackUrl', ''),
                        channelIngestServer: get(values, 'channelIngestServer', ''),
                        channelStreamKey: get(values, 'channelStreamKey', ''),
                        S3PlaybackURL: get(values, 'S3PlaybackURL', ''),
                        price: get(values, 'price', ''),
                        discountedprice: get(values, 'discountedprice', ''),
                        startPlayback: get(values, 'startPlayback', ''),
                        userId: get(values, 'userId._id', ''),
                        categories: formatCategories(get(values, 'categories', [])),
                        startDate: values?.startDate ? getDateTimeWithTimeZone(values.startDate) : '',
                        endDate: values?.endDate ? getDateTimeWithTimeZone(values.endDate) : '',
                    }}
                    validationSchema={addMasterClassValidationSchema}
                    onSubmit={(Mastervalues, { resetForm }) => {
                        if(values){
                        submitHandler({...Mastervalues,action:'edit',masterClassId:values._id});
                        }
                        else{
                            submitHandler({...Mastervalues,action:'add'});
                        }
                        onCloseModal()
                    }}
                >
                    {({ values: formikValues, setFieldValue }) => (
                        <Form id="emailTemplate">
                            <div className="form-group">
                                <>
                                    {openUploadModal && (
                                    <UploadPhotoModal
                                    open={openUploadModal}
                                    closeModal={() => setOpenUploadModal(false)}
                                    type="article"
                                    action="Upload"
                                    value={articleImage}
                                    onChange={handleOnImageChange}
                                    isCover={true}
                                    applyHandler={() => applyHandler(formikValues)}
                                    />
                                    )}

                                    <ArticleSectionWrap>
                                        <SectionContent
                                            deleteImg={deleteImg}
                                            articleImage={articleImage}
                                            openModal={() => setOpenUploadModal(true)}
                                        />
                                    </ArticleSectionWrap>
                                </>
                            
                                <div className="form-group">
                                <label htmlFor="trailerLink">Youtube Trailer URL</label>
                                <Field
                                    className="form-control"
                                    id="trailerLink"
                                    name="trailerLink"
                                    placeholder="Youtube Trailer URL"
                                />
                                
                            </div>
                            <div className="form-group">
                            <label htmlFor="channelId">Channel ID (for IVS)</label>
                                <Field
                                    className="form-control"
                                    id="channelId"
                                    name="channelId"
                                    placeholder="Channel ID"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="channelPlaybackUrl">Channel Playback URL (for IVS)</label>
                                <Field
                                    className="form-control"
                                    id="channelPlaybackUrl"
                                    name="channelPlaybackUrl"
                                    placeholder="Channel Playback URL"
                                />
                             </div>
                            <div className="form-group">
                            <label htmlFor="channelIngestServer">Channel Ingest URL</label>
                                <Field
                                    className="form-control"
                                    id="channelIngestServer"
                                    name="channelIngestServer"
                                    placeholder="Channel Ingest URL"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="channelStreamKey">Channel Stream Key</label>
                                <Field
                                    className="form-control"
                                    id="channelStreamKey"
                                    name="channelStreamKey"
                                    placeholder="Channel Stream Key"
                                />
                             </div>
                             <div className="form-group">
                                <label htmlFor="S3PlaybackURL">S3 MP4 Playback URL</label>
                                <Field
                                    className="form-control"
                                    id="S3PlaybackURL"
                                    name="S3PlaybackURL"
                                    placeholder="S3 MP4 Playback URL"
                                />
                             </div>
                                <label htmlFor="title">Title of the Masterclass*</label>
                                <Field
                                    className="form-control"
                                    id="title"
                                    name="title"
                                    placeholder="Type your title here"
                                />
                                <ErrorMessage
                                    component="div"
                                    className="alert alert-danger"
                                    name="title"
                                />
                            </div>
                                 <div className="form-group">
                                <label htmlFor="shortDescription">Short Description* (Characters left: {200 - formikValues.shortDescription.length})</label>
                                <Field
                                    className="form-control"
                                    id="shortDescription"
                                    name="shortDescription"
                                    type="text" // Changed type from "shortDescription" to "text"
                                    placeholder="Short Description"
                                />
                                <ErrorMessage
                                    component="div"
                                    className="alert alert-danger"
                                    name="shortDescription"
                                />
                            </div>
                            <div className="form-group" >
                                <label>Long Description*</label><br />
                                <button type="button" onClick={() => { setPlainText(!plainText) }}>Switch to {plainText ? 'rich text editor' : 'plain text editor'}</button>
                                {                    	
                            plainText ? 	
                            <textarea style={{width: "100%", minHeight: "250px"}} value={formikValues.description} onChange={(e) => {	
                                setFieldValue('description', e.target.value);	
                                //console.log(e.target.value);	
                            }}></textarea>	
                            : <Editor	
                            apiKey={process.env.REACT_APP_TINY_MCE}	
                            // initialValue={formikValues.description}	
                            value={replaceWordpressImage(formikValues.description)}	
                            init={{	
                                height: 500,	
                                target: false,	
                                menubar: false,	
                                media_live_embeds: true,	
                                plugins:	
                                    'advlist autolink quickbars lists link image charmap print preview anchor searchreplace code fullscreen media paste code placeholder mediaembed imagetools',	
                                toolbar:	
                                        'bold italic underline h2 h3 removeformat alignleft aligncenter alignright alignjustify numlist blockquote media quickimage link',	
                                quickbars_image_toolbar: 'alignleft aligncenter alignright | remove',	
                                quickbars_insert_toolbar: false,	
                                quickbars_selection_toolbar: 'bold italic underline h2 h3',	
                            }}	
                            onEditorChange={(newValue, editor) =>	
                                setFieldValue("description", newValue)	
                            }	
                        ></Editor>	
                            }	
                            </div>
                            <div className="form-group">
                                <label htmlFor="price">Masterclass Original Price*</label>
                                <Field
                                    className="form-control"
                                    id="price"
                                    name="price"
                                    type="number"
                                    placeholder="Masterclass Original Price"
                                />
                                <ErrorMessage
                                    component="div"
                                    className="alert alert-danger"
                                    name="price"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="discountedprice">Masterclass Discounted Price*</label>
                                <Field
                                    className="form-control"
                                    id="discountedprice"
                                    name="discountedprice"
                                    type="number"
                                    placeholder="Masterclass Discounted Price"
                                />
                                <ErrorMessage
                                    component="div"
                                    className="alert alert-danger"
                                    name="discountedprice"
                                />
                            </div>
                            <div className="form-group">
                                <label>
                                    <Field
                                        type="checkbox"
                                        name="isTBD"
                                        checked={isTBD}
                                        onChange={(e) => {
                                            setIsTBD(e.target.checked);
                                            if (e.target.checked) {
                                                setFieldValue('startDate', null);
                                                setFieldValue('endDate', null);
                                            }
                                        }}
                                    />
                                    TBD
                                </label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="startDate">Event Start Date & Time* (Time Zone: {currentTimeZone})</label>
                                <Field
                                    className="form-control"
                                    id="startDate"
                                    name="startDate"
                                    type="datetime-local"
                                    placeholder="Start Date & Time"
                                    defaultValue={getDateTimeWithTimeZone(formikValues.startDate)}
                                    disabled={isTBD}
                                    />
                                <ErrorMessage
                                    component="div"
                                    className="alert alert-danger"
                                    name="startDate"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="endDate">Event End Date & Time*</label>
                                <Field
                                    className="form-control"
                                    id="endDate"
                                    name="endDate"
                                    type="datetime-local"
                                    placeholder="End Date & Time"
                                    defaultValue={getDateTimeWithTimeZone(formikValues.endDate)}
                                    disabled={isTBD}
                                />
                                <ErrorMessage
                                    component="div"
                                    className="alert alert-danger"
                                    name="endDate"
                                />
                            </div>
                            {isEdit && new Date(values.endDate) < new Date() &&<div className="form-group">
                                <label htmlFor="startPlayback">Start Playback Recording*</label>
                                <Field
                                    className="form-control"
                                    id="startPlayback"
                                    name="startPlayback"
                                    type="number"
                                    placeholder="Enter the number of seconds"
                                />
                                <ErrorMessage
                                    component="div"
                                    className="alert alert-danger"
                                    name="startPlayback"
                                />
                            </div>}
                            <div className="form-group">
                                <label htmlFor="userId">Instructor*</label>
                                <Field as="select" name="userId" className="form-control">
                                    <option>
                                        Select Instructor
                                    </option>
                                    {masterProfile?.map((profile, index) => (
                                        <option key={index} value={profile._id}>
                                            {profile.firstName} {profile.lastName}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                            <div className="form-group">
                <label htmlFor="categories">Categories*</label>
                <ReactSelect
                    name="categories"
                    options={allCategories.length > 0 ? allCategories.map((o) => ({ value: o._id, label: o.title })) : []}
                    value={
                        allCategories && allCategories.length > 0 && formikValues.categories && formikValues.categories.length > 0
                        ? allCategories
                            .filter((el) => formikValues.categories.some((f) => f.value === el._id))
                            .map((o) => ({ value: o._id, label: o.title }))
                        : []
                    }
                    onChange={(selectedOptions) => {
                        console.log('Selected options: ' + JSON.stringify(selectedOptions));
                        setFieldValue('categories', selectedOptions);
                    }}
                    isMulti
                />
                <ErrorMessage name="categories" component="div" className="error" />
            </div>
                            <Button type="submit" className="btn btn-primary">
                                {values?'Save Masterclass':'Add Masterclass'}
                            </Button>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={onCloseModal}
                            >
                                Close
                            </button>
                        </Form>)}
                </Formik>
            </div>
            <div className="modal-footer"></div>
        </Modal >
    );
};

export default AddNewClassModal;