import React, { useEffect, useReducer, useState } from 'react'
import Breadcrumb from "../common/breadcrumb";
import { TableComp, Button } from '../shared';
import get from 'lodash/get'
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../hooks/apiStateHandler'
import moment from "moment";
import { EditRejectReason } from '../modals';
import { toast } from 'react-toastify';
import { SOMETING_WENT_WRONG, TOTAL_RECORDS } from '../../constants/translations';
import { getRejectReasons, updateRejectReason, insertRejectReason } from '../../services/rejectReasons';

const editValuesInitial = { showModal: false, values: {}, updating: false, error: null }
const EmailTemplatesList = (props) => {
    const [rejectReasonsState, rejectReasonsDispatch] = useReducer(ApiStateReducer, initialState)
    const [editValues, setEditValues] = useState(editValuesInitial)
    useEffect(() => {
        getRejectReasonsHandler({})
    }, [])

    const columns = [
        {
            name: "Title",
            selector: "title",
            sortable: true,
        },
        // {
        //     name: "Description",
        //     selector: "description",
        //     cellRenderer: (row) => (<p className='cusColor' dangerouslySetInnerHTML={{ __html: row.description }} ></p>),
        //     sortable: true,
        // },
        // {
        //     name: "Description",
        //     selector: "description",
        //     sortable: true,
        // },
        {
            name: "Date",
            sortable: true,
            selector: "dateUpdated",
            cellRenderer: (row) => (<div >{moment(row.dateUpdated).format("DD MMM, YYYY h:mm:ss a")}</div>)
        },
        {
            name: 'Actions',
            cellRenderer: (row) => (<>
                      <span
            style={{ cursor: "pointer" }}
            title="Edit Reason"
            onClick={() => setEditValues({ showModal: true, values: row })} 
          >
            <i
              className="fa fa-pencil"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "rgb(0, 123, 255)",
              }}
            ></i>
          </span>
                {/* <Button title="Edit Reason" clicked={() => setEditValues({ showModal: true, values: row })} className="btn btn-primary m-10">Edit</Button> */}
            </>)
        }
    ]

    const getRejectReasonsHandler = async (params) => {
        try {
            rejectReasonsDispatch({ type: ApiStateEvents.FETCHING })
            const res = await getRejectReasons(params)
            if (res.success) {
                rejectReasonsDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.reasons', []), totalRecords: get(res, 'data.total', 0) })
            } else {
                rejectReasonsDispatch({ type: ApiStateEvents.ERROR, data: [] })
            }
        } catch (error) {
            rejectReasonsDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }


    const blankRejectReasonsHandler = async (params) => {
        try {
            rejectReasonsDispatch({ type: ApiStateEvents.FETCHING })
            const res = await insertRejectReason(params)
            if (res.success) {
                rejectReasonsDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.reasons', []), totalRecords: get(res, 'data.total', 0) })
            } else {
                rejectReasonsDispatch({ type: ApiStateEvents.ERROR, data: [] })
            }
        } catch (error) {
            rejectReasonsDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }

    const onSort = ({ fieldName, sortOrder }) => {
        rejectReasonsDispatch({
            type: ApiStateEvents.ADD_SORTING, sorting: {
                sortField: fieldName,
                sortOrder: sortOrder,
            }
        })
        getRejectReasonsHandler({
            sortField: fieldName,
            sortOrder: sortOrder,
        })
    }

    const rejectReasonUpdateHandler = async (values) => {
        try {
            setEditValues({ ...editValues, updating: true })
            const res = await updateRejectReason({ ...values })
            if (res.success) {
                const clonned = get(rejectReasonsState, 'data', [])
                const index = clonned.findIndex(o => o._id === values._id)
                if (index !== -1 && res.data.reason) {
                    clonned[index] = res.data.reason
                    toast.success(get(res, 'data.message', ''))
                    //console.log({ clonned })
                    rejectReasonsDispatch({ type: ApiStateEvents.SUCCESS, data: clonned })
                    setEditValues(editValuesInitial)
                } else {
                    toast.error(SOMETING_WENT_WRONG)
                }
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
        }
    }

    return <>
        <Breadcrumb title='Reject Reasons' />
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h5 className="BackButtonHeading">
                        Reject Reasons
                    </h5>
                </div>
                <div style={{ margin: "15px 30px 15px" }}>
                    {!get(rejectReasonsState, 'loading') && TOTAL_RECORDS + get(rejectReasonsState, 'totalRecords', 0)}
                </div>
                <div>
                    {!get(rejectReasonsState, 'loading') &&
                              <span
                              margin
                              style={{ cursor: "pointer",marginLeft:'20px'}}
                              title="NEW REJECT REASON"
                              onClick={() => {blankRejectReasonsHandler(editValues)}}
                            >
                              <i
                                className="fa fa-plus"
                                style={{
                                  width: 35,
                                  fontSize: 20,
                                  padding: 11,
                                  color: "rgb(0, 123, 255)",
                                }}
                              ></i>NEW REJECT REASON
                            </span>
                    
                    // <div >NEW REJECT REASON</div>
                    }
                </div>
                <div className="card-body">
                    <TableComp
                        loading={get(rejectReasonsState, 'loading')}
                        data={get(rejectReasonsState, 'data', [])}
                        columns={columns}
                        onSort={onSort}
                        sortField={get(rejectReasonsState, 'sorting.sortField', null)}
                        sortOrder={get(rejectReasonsState, 'sorting.sortOrder', null)}
                    />
                </div>
            </div>
            {editValues.showModal && <EditRejectReason
                values={editValues.values}
                mopen={true}
                submitHandler={(values) => rejectReasonUpdateHandler(values)}
                loading={editValues.updating}
                error={editValues.error}
                title={"Edit Reject Reason"}
                onCloseModal={() => setEditValues({ showModal: false, values: {} })}
            />}
        </div>
    </>
}

export default EmailTemplatesList