import React from 'react'
import Loader from "react-loader-spinner";
import PropTypes from 'prop-types'

const LoaderComp = (props) => {
    const { loading } = props
    return loading && <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}><Loader
        type="ThreeDots"
        color="#222222"
        height={120}
        width={120}
    /></div>
}

LoaderComp.defaultProps = {
    loading: false
}

LoaderComp.propTypes = {
    loading: PropTypes.bool.isRequired
}

export default LoaderComp