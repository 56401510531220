import React, { Component, Fragment } from "react";

import designer from "../../assets/images/dashboard/designer.jpg";
import TabsetProfile from "./tabset-profile";
import Breadcrumb from "../common/breadcrumb";
import { translations } from "../../constants/translations";

export class Profile extends Component {
  render() {
    const user = JSON.parse(localStorage.getItem("user"));
    return (
      <Fragment>
        <Breadcrumb title={translations.Profile.Profile} parent="Settings" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4">
              <div className="card">
                <div className="card-body">
                  <div className="profile-details text-center">
                    <img
                      src={designer}
                      alt=""
                      className="img-fluid img-90 rounded-circle blur-up lazyloaded"
                    />
                    <h5 className="f-w-600 f-16 mb-0">
                      {user.firstName} {user.lastName}
                    </h5>
                    <span>{user.email}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="card profile-card">
                <div className="card-body">
                  <TabsetProfile user={user} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Profile;
