import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getGenres, getMediums } from '../../services/artworks'
import { getRejectReasons } from '../../services/rejectReasons'
import { getSuspensionReasons } from '../../services/suspensionReasons'
import { getMessageResponseArt } from "../../services/messageResponseArt";

const initialState = {
    allGenres: [],
    loadingCounter: 0,
    allMediums: [],
    allRejectReasons: [],
    allMessageResponseArt: [],
};
/**
 * Fetch genres list
 */
export const fetchGenres = createAsyncThunk(
    "genres/list",
    async (params, thunkAPI) => {
        try {
            const response = await getGenres(params)
            const { data, success, err } = response;
            if (success) {
                return data.genres;
            } else {
                return thunkAPI.rejectWithValue(err);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

/**
 * Fetch Message Response Art list
 */
export const fetchMessageResponseArt = createAsyncThunk(
    "messageResponseArt/list",
    async (params, thunkAPI) => {
        try {
            const response = await getMessageResponseArt(params)
            const { data, success, err } = response;

            if (success) {
                return data.responses;
            } else {
                return thunkAPI.rejectWithValue(err);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

/**
 * Fetch reject reasons list
 */
export const fetchRejectReasons = createAsyncThunk(
    "rejectReasons/list",
    async (params, thunkAPI) => {
        try {
            const response = await getRejectReasons(params)
            const { data, success, err } = response;

            if (success) {
                return data.reasons;
            } else {
                return thunkAPI.rejectWithValue(err);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
/**
 * Fetch reject reasons list
 */
export const fetchSuspensionReasons = createAsyncThunk(
    "suspensionReasons/list",
    async (params, thunkAPI) => {
        try {
            const response = await getSuspensionReasons(params)
            const { data, success, err } = response;

            if (success) {
                return data.reasons;
            } else {
                return thunkAPI.rejectWithValue(err);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

/**
 * Fetch mediums list
 */
export const fetchMediums = createAsyncThunk(
    "mediums/list",
    async (params, thunkAPI) => {
        try {
            const response = await getMediums(params)
            const { data, success, err } = response;

            if (success) {
                return data.mediums;
            } else {
                return thunkAPI.rejectWithValue(err);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);


export const artworkSlice = createSlice({
    name: "artworks",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchGenres.pending]: (state, action) => {
            state.loadingCounter = state.loadingCounter + 1;
        },
        [fetchGenres.fulfilled]: (state, action) => {
            //console.log({ action })
            state.loadingCounter = state.loadingCounter - 1
            state.allGenres = action.payload || [];
        },
        [fetchGenres.rejected]: (state, action) => {
            state.loadingCounter = state.loadingCounter - 1
            state.error = action.error.message;
        },
        [fetchMediums.pending]: (state, action) => {
            state.loadingCounter = state.loadingCounter + 1
        },
        [fetchMediums.fulfilled]: (state, action) => {
            state.loadingCounter = state.loadingCounter - 1
            state.allMediums = action.payload || [];
        },
        [fetchMediums.rejected]: (state, action) => {
            state.loadingCounter = state.loadingCounter - 1
            state.error = action.error.message;
        },
        [fetchRejectReasons.pending]: (state, action) => {
            state.loadingCounter = state.loadingCounter + 1
        },
        [fetchRejectReasons.fulfilled]: (state, action) => {
            state.loadingCounter = state.loadingCounter - 1
            state.allRejectReasons = action.payload || [];
        },
        [fetchRejectReasons.rejected]: (state, action) => {
            state.loadingCounter = state.loadingCounter - 1
            state.error = action.error.message;
        },
        [fetchMessageResponseArt.pending]: (state, action) => {
            state.loadingCounter = state.loadingCounter + 1
        },
        [fetchMessageResponseArt.fulfilled]: (state, action) => {
            state.loadingCounter = state.loadingCounter - 1
            state.allMessageResponseArt = action.payload || [];
        },
        [fetchMessageResponseArt.rejected]: (state, action) => {
            state.loadingCounter = state.loadingCounter - 1
            state.error = action.error.message;
        },
        
    },
});

export default artworkSlice.reducer;

