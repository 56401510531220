import React, { useEffect, useState } from 'react'
import { ArtworksTabsContainer, ListingWrap, ItemsWrapper, CoverImg, ListName, PriceList, ProfileBtnWrap } from '../../../styled'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PropTypes from 'prop-types'
import { getArtworks } from '../../../../services/artworks'
import { get } from 'lodash'
import { createResizeImageUrl, imageErrorHandler, createImageUrl } from "../../../../utils/imageHelper";
import { openInNewTab } from '../../../../utils/anchor'
import { parseUserName } from '../../../../utils/stringHelper'
import AboutTabsSection from './aboutMe'
import { getContent } from '../../../../services/content'
import ShippingPolicySection from './shippingPolicy'
import { LoaderComp, Button } from '../../../shared'
import { redirectToClientAppArtwork } from '../../../../utils/redirects'
import CoverPhoto from '../../../../assets/images/artworkdemo.png'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'transparent',
    },
    Count: {
        backgroundColor: 'transparent',
        fontSize: '16px',
        color: '#222',
        fontFamily: 'ProximaNova-Regular',
        width: '24px',
        height: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        margin: '0 0 0 10px',
    },
    HeaderTabs: {
        flexGrow: 1,
        backgroundColor: 'transparent',
        boxShadow: 'none',
        fontSize: '15px',
        fontFamily: 'ProximaNova-Bold',
        color: '#222',
        minHeight: 'inherit',
    },
}))
const ArtworkTabsComp = props => {
    const { user, artworkId } = props
    const classes = useStyles()
    const [value, setValue] = useState(0)
    const [tabsData, setTabsData] = useState({})
    const [loading, setLoading] = useState(0)

    useEffect(() => {
        const getArtworksAsync = async (params) => {
            setLoading(true)
            const { data, success } = await getArtworks(params)
            if (success) {
                const filtered = get(data, 'artworks', []).filter(o => o._id !== artworkId)
                setTabsData({ ...tabsData, 0: filtered || [] })
            }
            setLoading(false)
        }
        user?._id && getArtworksAsync({ offset: 0, limit: 20, filters: { userId: user?._id, status: [1] } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?._id])

    const getContentHandler = async () => {
        setLoading(true)
        const { data, success } = await getContent({ uniqueCode: 'ARTWORK_DETAILS_artmo_policy' })
        if (success) {
            setTabsData({ ...tabsData, 2: data?.contentData?.content || '' })
        }
        setLoading(false)
    }

    return <ArtworksTabsContainer>
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs className={classes.HeaderTabs} value={value} onChange={(_, n) => setValue(n)} aria-label="">
                    <Tab
                        label={`More From ${parseUserName(user || {})}`}
                        {...a11yProps(0)}
                    />
                    <Tab
                        label={`About ${parseUserName(user || {})}`}
                        {...a11yProps(1)}
                    />
                    <Tab label="Shipping Policy" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <ListingWrap>
                    {loading && <LoaderComp loading={true} />}
                    {get(tabsData, '[0]', []).length ? get(tabsData, '[0]', []).map(artwork => {
                        return <><ItemsWrapper onClick={() => redirectToClientAppArtwork(artwork?.productSlug)} >
                            <CoverImg>
                                <img
                                    src={
                                        artwork.artPhotos[0] && createResizeImageUrl(artwork.artPhotos[0].pictureUrl, 200, 200, 'mediaLibrary')
                                    }
                                    onError={(e) => {
                                        imageErrorHandler(e, createImageUrl(get(artwork, 'artPhotos[0].pictureUrl', '')), CoverPhoto, 'mediaLibrary', '')
                                    }}
                                    alt="Cover"
                                />
                            </CoverImg>
                            <ListName>{artwork.title}</ListName>
                            <PriceList>
                                {artwork && artwork.priceOnRequest
                                    ? 'Price on request'
                                    : artwork.price}
                            </PriceList>
                        </ItemsWrapper>
                        </>
                    }) : null}
                    {!loading && !get(tabsData, '[0]', []).length ? <span className="DefaultMsg">No more artworks found</span> : null}
                    {get(tabsData, '[0]', []).length ? <ProfileBtnWrap>
                        <Button clicked={() => openInNewTab(`${process.env.REACT_APP_CLIENT_APP_URL}/artworks?sort="mostLiked"&artist=${JSON.stringify(user?._id)}`)} >
                            {`Show all artworks from ${parseUserName(user || {})}`}
                        </Button>
                    </ProfileBtnWrap> : null}

                </ListingWrap>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AboutTabsSection user={user || {}} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                {loading && <LoaderComp loading={true} />}
                <ShippingPolicySection artmoShippingPolicy={get(tabsData, '[2]', '')} getPolicy={() => getContentHandler()} />
            </TabPanel>
        </div>
    </ArtworksTabsContainer>
}

export default ArtworkTabsComp