import React, { useMemo, useState, useReducer, useEffect } from 'react'
import Breadcrumb from "../common/breadcrumb";
import { captalizeFirstChar } from '../../utils/stringHelper'
import { getEndUsers, sendBulkMessage, sendMasterclassPromotion } from '../../services/endUserManagement'
import { get } from 'lodash'
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../hooks/apiStateHandler'
import Filters from './components/filter'
import { DETAILS, UPDATE } from '../../constants/actionTypes';
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from 'react-toastify'
import { updateUsersInBulk } from "../../features/user/userSlice";
import { addNotices } from '../../services/notices'
import { MessageModal as SendMessageModal, NoticeModal } from '../modals'
import { TableComp, CheckboxComp, ToolTip } from '../shared';
import { SOMETING_WENT_WRONG } from '../../constants/translations';
import MasterclassPromotionModal from '../modals/masterclassPromotion';

const ListUsers = (props) => {
    const { history } = props
    useEffect(() => {
        fetchUsers({ page: 1, perPage: get(endUsersApiState, 'limit', 20), delay: 1 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const dispatch = useDispatch()
    const [endUsersApiState, endUsersApiDispatch] = useReducer(ApiStateReducer, initialState)
    const [selectAll, setSelectAll] = useState(false)
    const [appliedFilters, setAppliedFilters] = useState({})
    const [showModal, setShowModal] = useState(null)
    const [  customSelectedUsers, setCustomSelectedUsers] = useState([]);

    const fetchUsers = async (params) => {
        endUsersApiDispatch({ type: ApiStateEvents.FETCHING })
        const endUsers = await getEndUsers({ ...params })
        if (endUsers.success) {
            endUsersApiDispatch({ type: ApiStateEvents.SUCCESS, totalRecords: get(endUsers, 'data.total', 0), currentPage: params.page || 1, data: get(endUsers, 'data.users', []) })
        } else {
            toast.error(endUsers?.err?.response?.data?.message || SOMETING_WENT_WRONG)
            endUsersApiDispatch({ type: ApiStateEvents.ERROR })
        }
    };

    const columns = useMemo(
        () => [
            {
                name: "First Name",
                selector: "firstName",
                cellRenderer: (row) => <div>{captalizeFirstChar(get(row, 'firstName', 'N/A'))}</div>,
                sortable: true,
            },
            {
                name: "Last Name",
                selector: "lastName",
                cellRenderer: (row) => <div>{captalizeFirstChar(get(row, 'lastName', 'N/A'))}</div>,
                sortable: true,
            },
            {
                name: "Email",
                selector: "email.value",
                cellRenderer: (row) => <div>{get(row, 'email.value', 'N/A')}</div>,
                sortable: true,
            },
            {
                name: "Role",
                selector: "userRoleId.roleName",
                cellRenderer: (row) => <div>{get(row, 'userRoleId.roleName', '').length ? captalizeFirstChar(get(row, 'userRoleId.roleName', 'N/A')) : 'N/A'}</div>,
                sortable: true,
            },
            {
                name: "City",
                selector: "city.value",
                cellRenderer: (row) => <div>{get(row, 'city.value', '').length ? captalizeFirstChar(get(row, 'city.value', 'N/A')) : 'N/A'}</div>,
                sortable: true,
            },
            {
                name: "Country",
                selector: "country.value",
                cellRenderer: (row) => <div>{get(row, 'country.value', '').length ? captalizeFirstChar(get(row, 'country.value', 'N/A')) : 'N/A'}</div>,
                sortable: true,
            },
            appliedFilters && appliedFilters?.referralUser && (appliedFilters?.referralUser.length===2||appliedFilters?.referralUser.length===3)?{
                name: "Referral",
                selector: "referredBy.username",
                cellRenderer: (row) => <div>{get(row, 'referredBy.username', '').length ? captalizeFirstChar(get(row, 'referredBy.username', 'N/A')) : 'N/A'}</div>,
                sortable: true,
            }:
            appliedFilters && appliedFilters?.referralUser && (appliedFilters?.referralUser[0]=='referral'||appliedFilters?.referralUser[0]=='InviteFriends')?
            {
                name: "Referral",
                selector: "referredByUser.username",
                cellRenderer: (row) => <div>{get(row, 'referredByUser.username', '').length ? captalizeFirstChar(get(row, 'referredByUser.username', 'N/A')) : 'N/A'}</div>,
                sortable: true,
            }:
            {
                name: "",
                selector: "",
                cellRenderer: (row) => <div></div>,
                sortable: false,
            },
            {
                name: "Actions",
                // eslint-disable-next-line react/button-has-type
                cellRenderer: (row) => (
                    <>
                        <ToolTip tip="Edit User" id="editUser" />
                        <span
                            data-tip
                            data-for="editUser"
                            style={{ cursor: "pointer" }}
                            title="Edit"
                            onClick={() => {
                                //history.push(`/users/${UPDATE}/${row._id}`)
                                const win = window.open(`/users/${UPDATE}/${row._id}`, "_blank")
                                win.focus()
                            }}
                        >
                            <i
                                className="fa fa-pencil"
                                style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "rgb(40, 167, 69)",
                                }}
                            ></i>
                        </span>
                        <ToolTip tip="View User" id="viewUser" />
                        <span
                            data-tip
                            data-for="viewUser"
                            style={{ cursor: "pointer" }}
                            title="View"
                            onClick={() => {
                                //history.push(`/users/${DETAILS}/${row._id}`)
                                const win = window.open(`/users/${DETAILS}/${row._id}`, "_blank")
                                win.focus()
                            }}
                        >
                            <i
                                className="fa fa-eye"
                                style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "rgb(0,191,255)",
                                }}
                            ></i>
                        </span>
                        <ToolTip tip="View User on frontend" id="viewUserFrontend" />
                        <span
                            data-tip
                            data-for="viewUserFrontend"
                            style={{ cursor: "pointer" }}
                            title="View"
                            onClick={() => {
                                //history.push(`/users/${DETAILS}/${row._id}`)
                                const win = window.open(`https://artmo.com/user/${row.username}`, "_blank")
                                win.focus()
                            }}
                        >
                            <i
                                className="fa fa-user"
                                style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: "rgb(0,191,255)",
                                }}
                            ></i>
                        </span>
                    </>
                ),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [appliedFilters]
    );

    const parseUsersResults = (usersData) => {
        return usersData.map((user) => user._id);
    };

    const clearTableSelection = () => {
        setCustomSelectedUsers([]) ; 
        rowSelectorHandler({ rows: [], flag: false })
        setSelectAll(false)
    }

    const bulkActionHandler = async (action) => {
        const userRows = parseUsersResults(getSelectedUsers);
        if (
            window.confirm(
                "Are you sure to perform this action?"
            )
        ) {
            if (action === 'enable' || action === 'disable') {
                dispatch(updateUsersInBulk({ users: userRows, bulkAction: action, filters: appliedFilters }))
                    .then(unwrapResult)
                    .then((originalPromiseResult) => {
                        toast.success(originalPromiseResult?.data?.message || 'Users updated successfully');
                    })
                    .catch((rejectedValueOrSerializedError) => {
                        toast.error(rejectedValueOrSerializedError.messageCode);
                    });
                clearTableSelection()
            } else if (action === 'sendMessage' || action === 'sendNotice'|| action === 'masterclass') {
                setShowModal(action)
            }
        }
    }

    const onPageChange = (page) => {
        fetchUsers({ page, perPage: get(endUsersApiState, 'limit', 20), delay: 1, filters: appliedFilters, ...get(endUsersApiState, 'sorting', {}) });
    }

    const onSort = ({ fieldName, sortOrder }) => {
        endUsersApiDispatch({
            type: ApiStateEvents.ADD_SORTING, sorting: {
                sortField: fieldName,
                sortOrder: sortOrder,
            }
        })
        fetchUsers({
            page: 1,
            perPage: get(endUsersApiState, 'limit', 20),
            delay: 1,
            filters: appliedFilters,
            sortField: fieldName,
            sortOrder: sortOrder,
        });

    }

    const applyFilter = (filters) => {
        fetchUsers({ page: 1, perPage: get(endUsersApiState, 'limit', 20), delay: 1, filters });
        setAppliedFilters({ ...filters })
        endUsersApiDispatch({
            type: ApiStateEvents.ADD_SORTING, sorting: {}
        })
        if (!Object.keys(filters).length) {
            clearTableSelection()
        }
    }

    const handleBulkMessage = async (ids, values) => {
        clearTableSelection()
        setShowModal(null)
        const res = await sendBulkMessage({ userIds: ids, message: values?.message, noEmail: values?.noEmail, filters: appliedFilters })
        if (res && res.success) {
            toast.success(get(res, 'data.message', "Message scheduled successfully"))
        } else {
            toast.error(res?.err?.response?.data?.message || SOMETING_WENT_WRONG)
        }
    }

    const handleMasterclassPromotion = async (ids, values) => {
        clearTableSelection()
        setShowModal(null)
        const res = await sendMasterclassPromotion({ userIds: ids, message: values?.message, noEmail: values?.noEmail, filters: appliedFilters })
        if (res && res.success) {
            toast.success(get(res, 'data.message', "Message scheduled successfully"))
        } else {
            toast.error(res?.err?.response?.data?.message || SOMETING_WENT_WRONG)
        }
    }
    const handleBulkNotice = async (ids, notice) => {
        clearTableSelection()
        setShowModal(null)
        const res = await addNotices({ userIds: ids, notice, filters: appliedFilters })
        if (res && res.success) {
            toast.success("Notice added successfully")
        } else {
            toast.error(res?.err?.response?.data?.message || SOMETING_WENT_WRONG)
        }
    }

    const rowSelectorHandler = ({ row, flag, rows }) => {
        if ( !flag && customSelectedUsers.length > 0  && row ) {
            const objToRemove = customSelectedUsers.filter(obj => obj._id !== row._id);
             setCustomSelectedUsers( objToRemove ) ; 
         }
        const clonnedData = get(endUsersApiState, 'data', [])
        if (row) {
            const { _id } = row
            const index = get(endUsersApiState, 'data', []).findIndex(o => o._id === _id)
            if (index !== -1) {
                clonnedData[index] = { ...clonnedData[index], selected: flag }
            }
        } else if (rows) {
            clonnedData.forEach(({ _id }, index) => {
                clonnedData[index] = { ...clonnedData[index], selected: flag }
            });
        }
        endUsersApiDispatch({ type: ApiStateEvents.SUCCESS, data: clonnedData })
    }

    const getSelectedUsers = useMemo(() => {
        return get(endUsersApiState, 'data', []).filter(o => o.selected)
    }, [endUsersApiState])

    useEffect(() => {
        setCustomSelectedUsers(prevSelectedUsers => {
            // Create a Set from the IDs of the previous selected users
            const userSet = new Set(prevSelectedUsers.map(user => user._id));
            // Filter the new selected users to exclude any that are already in the Set
            const newSelectedUsers = getSelectedUsers.filter(user => 
                !userSet.has(user._id)
            );
            // Return a new array combining previous selected users and the new unique users
            return [...prevSelectedUsers, ...newSelectedUsers];
        });
    }, [getSelectedUsers]);

    return <>
        <Breadcrumb title="User List" parent="Users" />
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h5 className="BackButtonHeading">User List</h5>
                </div>
                <div className="users-list-table">
                    <Filters allUsersSelected={selectAll} selectedUsers={customSelectedUsers} bulkActionHandler={(a) => bulkActionHandler(a)} applyFilters={(f) => applyFilter(f)} />
                </div>
                <div style={{ margin: "0 30px 15px" }}>
                    <CheckboxComp checked={selectAll} onChange={(e) => setSelectAll(e.target.checked)} label={`Select all ${get(endUsersApiState, 'totalRecords', 0)} records`} />
                </div>
                <div className="card-body">
                    {customSelectedUsers && customSelectedUsers.length ? <strong>{customSelectedUsers.length} {customSelectedUsers.length > 1 ? 'users' : 'user'} selected</strong> : null}
                    <TableComp
                        onPageChange={(p) => onPageChange(p)}
                        page={get(endUsersApiState, 'currentPage', 1)}
                        limit={get(endUsersApiState, 'limit', 20)}
                        totalRecords={get(endUsersApiState, 'totalRecords', 0)}
                        loading={get(endUsersApiState, 'loading', false)}
                        data={get(endUsersApiState, 'data', [])}
                        columns={columns}
                        onSort={onSort}
                        enableSelection
                        sortField={get(endUsersApiState, 'sorting.sortField', null)}
                        sortOrder={get(endUsersApiState, 'sorting.sortOrder', null)}
                        onSelection={({ row, rows, flag }) => rowSelectorHandler({ rows, flag, row })}
                        selectedUserData={customSelectedUsers}
                    />
                </div>
                
            </div>
            <SendMessageModal mopen={showModal === 'sendMessage'} submitHandler={(v) => handleBulkMessage(parseUsersResults(customSelectedUsers), v)} onCloseModal={() => setShowModal(null)} />
            <MasterclassPromotionModal mopen={showModal === 'masterclass'} submitHandler={(v) => handleMasterclassPromotion(parseUsersResults(customSelectedUsers), v)} onCloseModal={() => setShowModal(null)} />
            <NoticeModal mopen={showModal === 'sendNotice'} submitHandler={(v) => handleBulkNotice(parseUsersResults(customSelectedUsers), v.message)} onCloseModal={() => setShowModal(null)} />
        </div>
    </>
}

export default ListUsers