import React, { useReducer, useState } from 'react';
import moment from 'moment';
import styles from '../helpdesk.module.css';
import ReactHtmlParser from 'react-html-parser'
import logo from '../../../assets/images/dashboard/atmo-logo.png';
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../../hooks/apiStateHandler'
import { toast } from 'react-toastify';
import { SOMETING_WENT_WRONG, TOTAL_RECORDS } from '../../../constants/translations';
import { deleteSingle, getChatHistory } from '../../../services/helpdesk'
import {msgSend5minsAgo} from '../../../utils/time'

const Message = (props) => {
    const {
        data,
        isMine,
        showTimestamp,
        profilePic,
        setDeletedMessageId
    } = props;
    const timestamp = moment(data.dateCreated).format('LLLL');
    const hours = moment(data.dateCreated).format("HH:mm:ss")

    const [actionPerforming, setActionPerforming] = useState(null)
    const [chatHistory, chatHistoryDipatch] = useReducer(ApiStateReducer, initialState)

    const deleteClickHandler = async () => {
        setActionPerforming("deletingMessage")
        setDeletedMessageId(data._id)
        const res = await deleteSingle({ messageId: data._id })
        if (res && res?.success) {
            toast.success(res?.data?.message)
            //getReportedMessageHandler({ offset: offset , limit: get(reportedMessageState, 'limit', 20), })
        } else {
            toast.error(SOMETING_WENT_WRONG)
        }
        setActionPerforming(null)
    }

    return (
        <div>
            {
                showTimestamp &&
                <div className={styles.messageTimestamp}>
                    {timestamp}
                </div>
            }
            {isMine ? <div class={styles.sentMessage}>
                <div class={styles.sentMessageInner}>
                    <div className={styles.chatBox}>
                        <div className={styles.chatContent}>
                            <p>{ReactHtmlParser(data.content)}</p>
                            <span class={styles.messageUserTimeRight}>{hours}</span>
                        </div>
                        <img src={logo} alt="" class={`${styles.messageUserImg} ${styles.messageUserImgRight}`} />
                    </div>
                </div>
                {
                    msgSend5minsAgo( data.dateCreated ) && (<div className={styles.deleteIcon} onClick={()=>{ deleteClickHandler()}}>
                    <i
                    className="fa fa-trash"
                    style={{
                        width: 35,
                        fontSize: 18,
                        padding: 10,
                        color: "rgb(255, 0, 0)",
                    }}
                ></i>
                </div>)
                }
            </div> : <div class={`${styles.messageContainer}`}>
                <img src={profilePic || "/static/media/man.7b23c9a1.png"} alt="" class={`${styles.messageUserImg}`} />
                <div className={styles.chatContent}>
                    <p>{ReactHtmlParser(data.content)}</p>
                    <span class={styles.messageUserTimeLeft}>{hours}</span>
                </div>
            </div>}
        </div >
    );
}

export default React.memo(Message)