import React, { useState} from "react";
import Modal from "react-responsive-modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import styled from "styled-components";
import { translations } from "../../../constants/translations";
import ArrowIcon from '../../../../src/assets/images/down-arrow.png'
import { Button } from '../../shared'

const AddAdminDialog = styled.div`
  select {
      appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
      background: url(${ArrowIcon}) no-repeat right;
      background-position-x: 96.5%;
  }
  & .errorDiv {
    color: red ; 
  }
`

const ModalAddNewGenre = (props) => {
  const {
    mopen,
    onOpenModal,
    onCloseModal,
    submitHandler,
    updating
  } = props;

  const [ genreTitle, setGenreTitle ] = useState('')
  const [ genreDiscription, setGenreDescription] = useState()
  const [ showError, setShowError ] = useState( false  )
  const [ btnDisabled, setButtonDisabled ] = useState( true  )

  const onChangeHandler = (e) => {
    const inputValue = e.target.value
    if ( inputValue !== '' ) {
        setGenreTitle( inputValue )
        setShowError( false )
        setButtonDisabled( false )
    } else {
        //setShowError( true )
      setButtonDisabled(true)
      setGenreTitle('')
    }
  }

  const onTextChangeHandler = (e) => {
    const inputValue = e.target.value
    if ( inputValue !== '' ) {
        setGenreDescription( inputValue )
        setShowError( false )
        setButtonDisabled( false )
    } else {
        //setShowError( true )
      setButtonDisabled(true)
      setGenreDescription('')
    }
  }

  return (
    <div className="btn-popup pull-right">
      <button
        type="button"
        className="btn btn-primary"
        onClick={onOpenModal}
        data-toggle="modal"
        data-original-title="test"
        data-target="#exampleModal"
      >
        {translations.AddGenre.btnTitle}
      </button>
      <Modal open={mopen} onClose={onCloseModal} focusTrapped={false}>
        <div className="modal-header">
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          {translations.AddGenre.modalHeading}
          </h5>
        </div>
        <div className="modal-body">
          <Formik
            initialValues={{
                genreTitle: "",
                genredescription: ""
            }}
            onSubmit={(values, { resetForm }) => {
              submitHandler(
                {
                    genreTitle: genreTitle,
                    genredescription: genreDiscription
                }
              );
              {
                /* resetForm({}); */
              }
            }}
          >
            <AddAdminDialog>
              <Form id="frmAddAdmin">
                <div className="form-group">
                  <label htmlFor="title">{translations.AddGenre.inputTitle}<span className="required">*</span></label>
                  <Field
                    className="form-control"
                    id="genreTitle"
                    name="genreTitle"
                    placeholder="Genre Title"
                    maxLength={40}
                    onChange={onChangeHandler}
                    value={genreTitle}
                  />
                  { showError && ( <div className="errorDiv">{translations.AddGenre.errorMessage}</div> )}
                </div>
                <div className="form-group">
                  <label htmlFor="genredescription">{translations.AddGenre.inputDescription}<span className="required">*</span></label>
                  <Field
                    as="textarea"
                    className="form-control"
                    id="genredescription"
                    name="genredescription"
                    placeholder="Genre description"
                    maxLength={40}
                    onChange={onTextChangeHandler}
                    value={genreDiscription}
                  />
                  { showError && ( <div className="errorDiv">{translations.AddGenre.errorMessage}</div> )}
                </div>
                <Button type="submit" loading={updating} loadingText="Saving" disabled={btnDisabled} className="btn btn-primary">
                  Submit
                </Button>
                <Button
                  type="button"
                  className="btn btn-light"
                  clicked={() => onCloseModal("VaryingMdo")}
                >
                  Close
                </Button>
              </Form>
            </AddAdminDialog>
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAddNewGenre;
