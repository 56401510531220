import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { useLocation, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Formik, Form } from "formik";
import { FormInput } from "../common/Form/input";
import { resetValidationSchema } from "../common/validations";
import { translations } from "../../constants/translations";

import {
  resetPassAdmin,
  userSelector,
  clearState,
} from "../../features/auth/authSlice";
import { Button } from "../shared";

const ResetPassTabset = (props) => {
  const dispatch = useDispatch();
  let query = new URLSearchParams(useLocation().search);

  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(userSelector);

  const submitHandler = (values) => {
    if (query.get("token")) {
      const data = {
        password: values.password,
        token: query.get("token"),
      };
      dispatch(resetPassAdmin(data));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage)
      dispatch(clearState());
    }

    if (isSuccess) {
      toast.success(translations.Login.passwordResetSuccess);

      dispatch(clearState());
      setTimeout(() => {
        props.history.push("/login");
      }, 1000);
    }
  }, [isError, isSuccess, dispatch]);

  return (
    <Fragment>
      <ToastContainer autoClose={2000} />
      <Tabs>
        <TabList className="nav nav-tabs tab-coupon">
          <Tab className="nav-link">
            <User />
            {translations.Login.ResetPassword}
          </Tab>
        </TabList>

        <TabPanel>
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            validationSchema={resetValidationSchema}
            onSubmit={(values, { resetForm }) => submitHandler(values)}
          >
            {({ values, isSubmitting }) => (
              <Form id="frmLogin" className="form-horizontal auth-form">
                <div className="form-group">
                  <FormInput
                    type="password"
                    name="password"
                    className="form-control"
                    value={values.password}
                    placeholder={translations.Login.Password}
                    maxLength="20"
                  />
                </div>

                <div className="form-group">
                  <FormInput
                    className="form-control"
                    type="password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    placeholder={translations.Login.ConfirmPassword}
                    maxLength="20"
                  />
                </div>

                <div className="form-button">
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    loading={isFetching}
                  >
                    {translations.Login.ResetPasswordBtn}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};

export default withRouter(ResetPassTabset);
