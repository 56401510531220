import React, { useEffect, useReducer, useState } from 'react'
import Breadcrumb from "../common/breadcrumb";
import { TableComp, Button } from '../shared';
import get from 'lodash/get'
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../hooks/apiStateHandler'
import { toast } from 'react-toastify';
import { SOMETING_WENT_WRONG, TOTAL_RECORDS } from '../../constants/translations';
import { getmasterClass,addMasterClass,deleteMasterClass, fullyDeleteMasterClass } from '../../services/masterProfile';
import AddNewClassModal from '../modals/add_new_masterclassclass';

const MasterClass = (props) => {
    const [masterProfileState, masterProfileDispatch] = useReducer(ApiStateReducer, initialState)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setEditValues] = useState(false)

      const submitAddHandler = async ({title, description,shortDescription,picUrl, trailerLink, channelId, channelPlaybackUrl,S3PlaybackURL, price,discountedprice ,startPlayback, userId, categories, startDate,endDate,action,masterClassId}) => {
        const res = await addMasterClass({title, description,shortDescription,picUrl ,trailerLink , channelId, channelPlaybackUrl,S3PlaybackURL, price,discountedprice ,startPlayback, userId, categories,startDate,endDate,action,masterClassId})
        if (res && res?.success) {
            let updatedProfile = [...get(masterProfileState, 'data', [])];
            // Add the new masterClass to the beginning of the array
            if(action==='add'){
                updatedProfile.unshift(res.data.masterClass);
            }else{
                updatedProfile = updatedProfile.map(x => x._id === masterClassId ? res.data.masterClass : x);
            }
            // Dispatch the update to the state

            masterProfileDispatch({ type: ApiStateEvents.SUCCESS, data: updatedProfile });
            toast.success(res.data?.message)
            } else {
            toast.error(SOMETING_WENT_WRONG)
        }
    };

    const classMasterFullyDeleteHandler = async (id) => {
        try {
            const res = await fullyDeleteMasterClass({ id })
            if (res.success) {
                const clonned = await get(masterProfileState, 'data', [])
                let data = clonned.filter(x => x._id !== id) 
                masterProfileDispatch({ type: ApiStateEvents.SUCCESS, data: data })
                toast.success(get(res, 'data.message', ''))
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
        }
    }

    const classMasterDeleteHandler = async (id) => {
        try {
            const res = await deleteMasterClass({ id })
            if (res.success) {
                const clonned = await get(masterProfileState, 'data', [])
                let data = clonned.map(x => x._id === id ? { ...x, isDeleted: x.isDeleted === 0 ? 1 : 0 } : x);
                masterProfileDispatch({ type: ApiStateEvents.SUCCESS, data: data })
                toast.success(get(res, 'data.message', ''))
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
        }
    }
    const handlePageChange = (page) => {
        getMasterClassHandler({
            page: page, perPage:20, delay: 1,
        })
        setCurrentPage(page);
      };
    useEffect(() => {
        getMasterClassHandler({
            page: currentPage, perPage:20, delay: 1,
        })
    }, [])

    const columns = [
        {
            name: "Title",
            selector: "title",
            sortable: true,
        },
        {
            name: "Instructor",
            selector: "userId.username",
        },
        {
                name: "Is Active",
                selector: "isDeleted",
                cellRenderer: (row) => row.isDeleted === 1 ? false.toString() : true.toString()
         },
        {
            name: 'Actions',
            cellRenderer: (row) => (<>
             <span
            style={{ cursor: "pointer" }}
            title="Edit Reason"
            onClick={() => setEditValues({ showModal: true, values: row })}
          >
            <i
              className="fa fa-pencil"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "rgb(0, 123, 255)",
              }}
            ></i>
          </span>
          <span
            style={{ cursor: "pointer" }}
            title="Edit Reason"
            onClick={() => classMasterDeleteHandler(row._id)}
          >
            <i
              className="fa fa-ban"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "red",
              }}
            ></i>
          </span>
          <span
            style={{ cursor: "pointer" }}
            title="Edit Reason"
            onClick={() => classMasterFullyDeleteHandler(row._id)}
          >
            <i
              className="fa fa-trash"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "red",
              }}
            ></i>
          </span>
          
            </>)
        },

    ]
  /**
   * @param {*} page
   * @param {*} size
   */
    const getMasterClassHandler = async (params) => {
        try {
            masterProfileDispatch({ type: ApiStateEvents.FETCHING })
            const res = await getmasterClass(params)
            if (res.success) {
                masterProfileDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.classes', []), totalRecords: get(res, 'data.total', 0) })
                setTotalRows(get(res, 'data.total', 0))
            } else {
                masterProfileDispatch({ type: ApiStateEvents.ERROR, data: [] })
            }
        } catch (error) {
            masterProfileDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }


    const onSort = ({ fieldName, sortOrder }) => {
        masterProfileDispatch({
            type: ApiStateEvents.ADD_SORTING, sorting: {
                sortField: fieldName,
                sortOrder: sortOrder,
            }
        })
        getMasterClassHandler({
            page: currentPage, perPage, delay: 1,
            sortField: fieldName,
            sortOrder: sortOrder,
        })
    }

    return <>
        <Breadcrumb title='Masterclass' />
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h5 className="BackButtonHeading">
                    Masterclass
                    </h5>
                </div>
                <div style={{ margin: "15px 30px 15px" }}>
                    {!get(masterProfileState, 'loading') && TOTAL_RECORDS + get(masterProfileState, 'totalRecords', 0)}
                </div>
    <div className="btn-popup pull-left" style={{ margin: "15px 30px 15px" }}>

<button
        type="button"
        className="btn btn-primary"
        onClick={() => {setEditValues(true)}}
        data-toggle="modal"
        data-original-title="test"
        data-target="#exampleModal"
      >
        NEW MASTERCLASS
      </button>
      </div>
                <div className="card-body">
                {showModal? <AddNewClassModal
                    loading={false}
                    onCloseModal={() => setEditValues(false)}
                    submitHandler={submitAddHandler}
                    mopen={true}
                    values={showModal.values}
                    articleType="masterclass"
                    title="Add MasterClass"
                    action=''
                /> : null}
    
                    <TableComp
                        loading={get(masterProfileState, 'loading')}
                        data={get(masterProfileState, 'data', [])}
                        columns={columns}
                        totalRecords={totalRows}
                        page={currentPage}
                        onPageChange={handlePageChange}
                        onSort={onSort}
                        limit={perPage}
                        sortField={get(masterProfileState, 'sorting.sortField', null)}
                        sortOrder={get(masterProfileState, 'sorting.sortOrder', null)}
                    />
                </div>
            </div>
        </div>
    </>
}

export default MasterClass