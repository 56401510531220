import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { translations } from "../../constants/translations";

export const loginAdmin = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "auth/login",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            password,
          }),
        }
      );
      let data = await response.json();
      if (data.success) {
        localStorage.setItem("token", data.token);

        localStorage.setItem("authenticated", true);
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("user", JSON.stringify(data.data.user));

        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const forgotPassAdmin = createAsyncThunk(
  "auth/forgot-password",
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "auth/forgot-password",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
          }),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const changePassAdmin = createAsyncThunk(
  "auth/change-password",
  async ({ currentPassword, newPassword, confirmPassword }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "auth/change-password",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user.email,
            currentPassword,
            newPassword,
          }),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const resetPassAdmin = createAsyncThunk(
  "auth/reset-password",
  async ({ password, token }, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "auth/reset-password",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            password,
            token,
          }),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    email: "",
    user: "",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    successMessage: "",
    errorMessageForgot: "",
    successMessageForgot: "",
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [loginAdmin.fulfilled]: (state, { payload }) => {
      state.email = payload.email;
      state.isFetching = false;
      state.isSuccess = true;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    [loginAdmin.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.successMessage = "";
      state.errorMessage =
        payload.messageCode === "credentialsMisMatch"
          ? translations.Login.credentialsMisMatch
          : payload.message;
    },
    [loginAdmin.pending]: (state) => {
      state.isFetching = true;
      state.errorMessage = "";
      state.successMessage = "";
    },
    [forgotPassAdmin.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.errorMessageForgot = "";
      state.successMessageForgot = payload.data.message;
      return state;
    },
    [forgotPassAdmin.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.successMessageForgot = "";
      state.errorMessageForgot =
        payload.messageCode === "emailNotFound"
          ? translations.Login.InvalidEmail
          : payload.message;
    },
    [forgotPassAdmin.pending]: (state) => {
      state.isFetching = true;
      state.errorMessageForgot = "";
      state.successMessageForgot = "";
    },
    [changePassAdmin.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.errorMessage = "";
      state.successMessage = payload.data.message;
      return state;
    },
    [changePassAdmin.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.successMessage = "";
      state.errorMessage =
        payload.messageCode === "currentPasswordMisMatch"
          ? translations.Login.currentPasswordMisMatch
          : payload.message;
    },
    [changePassAdmin.pending]: (state) => {
      state.isFetching = true;
      state.errorMessage = "";
      state.successMessage = "";
    },
    [resetPassAdmin.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.errorMessage = "";
      state.successMessage = payload.data.message;
      return state;
    },
    [resetPassAdmin.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.successMessage = "";
      state.errorMessage =
        payload.messageCode === "emailNotFound"
          ? translations.Login.InvalidEmail
          : payload.message;
    },
    [resetPassAdmin.pending]: (state) => {
      state.isFetching = true;
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
});

export const { clearState } = authSlice.actions;

export const userSelector = (state) => state.auth;
