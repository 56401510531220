import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Formik, Form } from "formik";
import { FormInput } from "../common/Form/input";
import { loginValidationSchema } from "../common/validations";
import { translations } from "../../constants/translations";

import {
  loginAdmin,
  userSelector,
  clearState,
} from "../../features/auth/authSlice";
import { Button } from "../shared";

const LoginTabset = (props) => {
  const dispatch = useDispatch();

  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(userSelector);

  const submitHandler = (data) => {
    dispatch(loginAdmin(data));
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      props.history.push("/dashboard");
    }

    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      toast.success(translations.Login.LoginSuccess);

      dispatch(clearState());
      setTimeout(() => {
        props.history.push("/dashboard");
      }, 1000);
    }
  }, [isError, isSuccess, dispatch]);

  return (
    <Fragment>
      <ToastContainer autoClose={4000} />
      <Tabs>
        <TabList className="nav nav-tabs tab-coupon">
          <Tab className="nav-link">
            <User />
            {translations.Login.Login}
          </Tab>
        </TabList>

        <TabPanel>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={loginValidationSchema}
            onSubmit={(values, { resetForm }) => submitHandler(values)}
          >
            {({ values, isSubmitting }) => (
              <Form id="frmLogin" className="form-horizontal auth-form">
                <div className="form-group">
                  <FormInput
                    id="email"
                    type="email"
                    className="form-control"
                    name="email"
                    value={values.email}
                    placeholder="jane.doe@example.com"
                    maxLength="50"
                  />
                </div>

                <div className="form-group">
                  <FormInput
                    id="password"
                    type="password"
                    className="form-control"
                    name="password"
                    placeholder="********"
                    maxLength="30"
                  />
                </div>

                <div className="form-button">
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    loading={isFetching}
                  >
                    {translations.Login.LoginBtn}
                  </Button>
                  <Link to="forgot-pass" className="ml-1 pull-right">
                    {translations.Login.ForgotPassword}?
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};

export default withRouter(LoginTabset);
