import React, { useEffect } from 'react'
import { get } from 'lodash';
import { CheckboxComp } from '../index';
import PaginationComp from '../Pagination';
import LoaderComp from '../Loader';
import PropTypes from 'prop-types'
import { replaceWordpressImage } from '../../../utils/image';

const TableComp = (props) => {
    const { columns, data, enableSelection, onSelection, sortField, sortOrder, onSort, loading, page, limit, totalRecords, onPageChange, selectedUserData } = props
    const checkAllSelected = () => {
        if (!data?.length) {
            return false
        }
        return !data.some(d => !d.selected)
    }

    const onHeaderClickHandler = (id) => {
        if (sortField === id && sortOrder === 'desc') {
            onSort({})
        } else if (sortField === id && sortOrder === 'asc') {
            onSort({ fieldName: id, sortOrder: 'desc' })
        } else if (sortField !== id) {
            onSort({ fieldName: id, sortOrder: 'asc' })
        }
    }
    const checkCustomSelectedUser = ( rowData ) => {
        if ( selectedUserData ) {
            const markAsChecked = selectedUserData.some(obj => obj._id === rowData );
            return markAsChecked ;
        }
    }

    return (
        <>
            {!loading ? <table className="table table-responsive">
                <thead>
                    <tr>
                        {enableSelection && <td><CheckboxComp checked={checkAllSelected()} onChange={(e) => onSelection({ rows: data, flag: e.target.checked })} /></td>}
                        {columns.map(column => {
                            return <td style={{ width: column.width }} onClick={() => column.sortable && onHeaderClickHandler(column.selector)} >{column.name} {sortField === column.selector && <>{sortOrder === "asc" ? <i class="fa fa-arrow-up"></i> : <i class="fa fa-arrow-down"></i>

                            }</>}</td>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data && data.length ? data.map((rowData, index) => {
                            if(rowData && rowData.description)
                                rowData.description=replaceWordpressImage(rowData.description)
                        return (<tr >
                            { selectedUserData  ? (
                                enableSelection && <td><CheckboxComp onChange={(e) => onSelection({ row: rowData, flag: e.target.checked })} checked={checkCustomSelectedUser( rowData._id )} /></td>) : (enableSelection && <td><CheckboxComp onChange={(e) => onSelection({ row: rowData, flag: e.target.checked })} checked={rowData.selected} /></td>)
                            }
                            {columns.map(column => {
                                return (<td style={{ width: column.width }}>
                                    {column.cellRenderer ? column.cellRenderer(rowData) : <>{get(rowData, `${column.selector}`, 'N/A')}</>}
                                </td>)
                            })}
                        </tr>)
                    }) : null}
                </tbody>
            </table> : <LoaderComp loading={loading} />}
            {!loading && !data?.length ? <p style={{ textAlign: "center" }}>No records found</p> : null}
            {!loading && limit && totalRecords > 0 && page ? <PaginationComp currentPage={page} maxPages={Math.ceil(totalRecords / limit) || 0} setCurrentPage={(p) => onPageChange(p)} /> : null}
        </>
    )
};

TableComp.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    enableSelection: PropTypes.bool,
    onSelection: PropTypes.func,
    sortField: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
    onSort: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    totalRecords: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
}

TableComp.defaultProps = {
    columns: [],
    data: [],
    enableSelection: false,
    sortField: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
    onSort: () => console.error("onSort function not provided for TableComp"),
    loading: false,
    page: 1,
    limit: 0,
    totalRecords: 0,
    onSelection: () => console.error("onSelection function not provided for TableComp"),
    onPageChange: () => console.error("onPageChange function not provided for TableComp"),
}

export default TableComp