import React from 'react'
import PropTypes from 'prop-types'
import Modal from "react-modal";
import { SectionContentWrap, FollowingList, FollowsNameWrap, FollowingThumb, FollowsName, FollowsWrap } from "../styled";
import { createImageUrl, createResizeImageUrl, imageErrorHandler } from "../../utils/imageHelper";
import defaultThumbnail from '../../assets/images/artmo-default.png'
import { parseUserName } from '../../utils/stringHelper';
import { redirectToClientAppUser } from '../../utils/redirects';
import FieldWrapper from '../shared/FormFieldWrapper';
import { useFormik } from "formik";
import Switch from "react-switch";
import { TableComp, Button, LoaderComp } from '../shared';

const columns = [
    {
        name: "Reject Reason",
        selector: "title",
        width: "100%",
        cellRenderer: (row) => (<p>{row.title || 'N/A'}</p>)
    },

]

const customStyles = {
    content: {
        top: "50%",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        maxWidth: "500px",
        width: "100%",
        overflowX: "hidden",
        inset: "50% 0 auto auto",
        Right: "0px",
        marginRight: "-190px",
        '@media (max-width: 991px)': {
            inset: "50% auto auto 50%",
        },
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0)"
    },
};

const formFieldIds = {
    title: "",
    price: 0,
    priceOnRequest: false,
    moderated: 1,
    inStock: true,
    rejectDescription: "",
    rejectedReasons: []

}

const getInitalValues = (artwork = {}) => {
    //console.log({ artwork, a: artwork.inStock })
    const clonnedData = artwork
    if (clonnedData.moderated !== -1) {
        clonnedData.moderated = 1
    }
    let initialState = {}
    Object.keys(formFieldIds).forEach(key => {
        //console.log({ a: clonnedData[key], key })
        initialState[key] = clonnedData[key]
    })
    //console.log(initialState)
    return initialState
}

const validateForm = (values) => {
    const { title, price, priceOnRequest, rejectDescription, rejectedReasons, moderated } = values
    let errors = {}
    if (!title || !title?.length) {
        errors.title = 'Title is required'
    }
    if (!price && !priceOnRequest) {
        errors.price = 'Price is required'
    }
    if (moderated === -1 && !rejectDescription && !rejectedReasons.length) {
        errors.rejectedReasons = 'Reject reason is required'
    }
    return errors
}

const LikesModal = ({ artwork, modalTitle, isOpen, onCloseModal, reasons = [], saving, submitHandler }) => {

    const formik = useFormik({
        initialValues: getInitalValues(artwork),
        validate: (v) => validateForm(v),
        onSubmit: values => {
            submitHandler(values)
        },
    });
    const { title, price, priceOnRequest, inStock, moderated, rejectDescription, rejectedReasons } = formik.values

    const parseReasons = () => {
        const r = reasons.map((reason) => {
            const { _id } = reason
            return { ...reason, selected: rejectedReasons.findIndex(o => o === _id) !== -1 ? true : false }
        })
        return r
    }

    const rowSelectorHandler = ({ row, flag, rows }) => {
        let clonnedData = rejectedReasons
        if (row) {
            const { _id } = row || {}
            if (flag) {
                clonnedData.push(_id)
            } else {
                clonnedData = rejectedReasons.filter(o => o !== _id)
            }
        } else if (rows) {
            if (flag) {
                clonnedData = rows.map(({ _id }) => _id)
            } else {
                clonnedData = []
            }
        }
        formik.setFieldValue('rejectedReasons', clonnedData)
    }

    const statusHandler = (flag) => {
        if (flag) {
            formik.setFieldValue('rejectedReasons', [])
            formik.setFieldValue('rejectDescription', '')
        }
        formik.setFieldValue('moderated', flag ? -1 : 1)
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onCloseModal}
            onRequestClose={onCloseModal}
            bodyOpenClassName=""
            shouldCloseOnOverlayClick={true}
            style={customStyles}
        >
            <div className="EditArtworkModal">
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    {modalTitle}
                </h5>
            </div>
            <div className="FormField">
                <FieldWrapper error={formik.errors.title} label="Title">
                    
                        <input onChange={formik.handleChange} name="title" value={title} type="text" className="form-control" />
                        
                    
                </FieldWrapper>
            </div>
            <div className="FormField">
                <FieldWrapper error={formik.errors.price} label="Price">
                    <div className="FormFieldOne">
                        <input className="form-control" onChange={formik.handleChange} name="price" disabled={priceOnRequest} value={!priceOnRequest ? price : "Price On Request"} type="text" />
                        <div className="FormFieldTwo">
                                <input type="checkbox" name="priceOnRequest" /><span>Price on Request</span>
                        </div>
                    </div>
                </FieldWrapper>
            </div>
            {/* {console.log({ inStock })} */}
            <div className="FormField">
                <FieldWrapper >
                    <span>Available</span>
                    <Switch className="FormFieldSwitch" onChange={(e) => formik.setFieldValue('inStock', e ? false : true)} offColor="#888" onColor="#888" uncheckedIcon={false} checkedIcon={false} checked={!inStock} />
                    <span>Sold</span>
                </FieldWrapper>
            </div>
            <div className="FormField">
                <FieldWrapper label="Status" >
                    <span>Accept</span>
                    <Switch className="FormFieldSwitch" onChange={(e) => statusHandler(e)} offColor="#888" onColor="#888" uncheckedIcon={false} checkedIcon={false} checked={moderated === -1} />
                    <span>Reject</span>
                </FieldWrapper>
            </div>
            {moderated === -1 && <div style={{ width: 500 }} className="form-group"><TableComp
                columns={columns}
                data={parseReasons()}
                enableSelection
                onSelection={({ row, rows, flag }) => rowSelectorHandler({ row, rows, flag })}
            />
            </div>}
            <div className="FormField">
            {moderated === -1 && <FieldWrapper error={formik.errors.rejectedReasons} label="Other">
                <textarea
                    className="form-control textareaExpand"
                    as="textarea"
                    onChange={formik.handleChange}
                    rows={3}
                    cols={20}
                    id="rejectDescription"
                    value={rejectDescription}
                    name="rejectDescription"
                    maxLength={500}
                    placeholder="Type your reason here"
                />
            </FieldWrapper>}
            </div>
            <Button loading={saving} loadingText='Updating' type="submit" clicked={formik.handleSubmit} className="btn btn-primary">
                {moderated === -1 ? 'Reject' : 'Accept'}
            </Button>
            <Button
                type="button"
                className="btn btn-light"
                clicked={() => onCloseModal()}
            >
                Close
            </Button>
            </div>
        </Modal>
    )
}

LikesModal.propTypes = {
    likesData: PropTypes.array,
}

export default LikesModal
