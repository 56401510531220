import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  countries: [],
  languages: [],
  contents: [],
  subscriptions: [],
  videoCategories: [],
  groupCategories: [],
  masterclassCategories: [],
  subscriptionCategories: [],
  tags: [],
  isError: false,
  isSuccess: false,
  status: "idle",
  statusContent: "idle",
  categoryStatus: "idle",
  gcategoryStatus: "idle",
  mcategoryStatus: "idle",
  tagStatus: "idle",
  subsStatus: "idle",
  subCatStatus: "idle",
  totalMasterclassCategories:0,
  error: null,
};
/**
 * Fetch countries list
 */
export const fetchCountries = createAsyncThunk(
  "common/countries",
  async (thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}common/countries`,
        {
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data.countries;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Get all languages
 */
export const fetchAllLanguages = createAsyncThunk(
  "common/languages",
  async (thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}common/languages`,
        {
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data.languages;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Get all subscriptions
 */
export const fetchAllsubscriptions = createAsyncThunk(
  "common/subscriptions",
  async (thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}common/subscriptions`,
        {
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data.subscriptions;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Fetch all static content
 */
export const fetchAllContent = createAsyncThunk(
  "common/contents",
  async (thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}common/contents`,
        {
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data.contents;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Fetch content by id
 */
export const fetchContentById = createAsyncThunk(
  "common/fetchContentById",
  async ({ contentId, language }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}common/fetchContentById`,
        {
          params: { contentId: contentId, languageId: language },
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Update content
 */
export const updateStaticContent = createAsyncThunk(
  "common/updateContent",
  async (params, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "common/updateContent",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/**
 * Fetch all video categories
 */
export const fetchAllVideoCategories = createAsyncThunk(
  "common/videoCategories",
  async ({ page, pageSize }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}common/videoCategories`,
        {
          params: { page: page, limit: pageSize },
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Fetch video category by id
 */
export const fetchVideoCategoryById = createAsyncThunk(
  "common/fetchVideoCategoryById",
  async ({ categoryId }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}common/fetchVideoCategoryById`,
        {
          params: { categoryId: categoryId },
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Update video category
 */
export const updateVideoCategory = createAsyncThunk(
  "common/updateVideoCategory",
  async (params, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "common/updateVideoCategory",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Enable/Disable video category
 */
export const enableDisableVideoCategory = createAsyncThunk(
  "common/enableDisableVideoCategory",
  async ({ categoryId }, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
        "common/enableDisableVideoCategory",
        {
          method: "DELETE",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryId,
          }),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/**
 * Fetch all group categories
 */
export const fetchAllGroupCategories = createAsyncThunk(
  "common/groupCategories",
  async ({ page, pageSize }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}common/groupCategories`,
        {
          params: { page: page, limit: pageSize },
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Fetch Group category by id
 */
export const fetchGroupCategoryById = createAsyncThunk(
  "common/fetchGroupCategoryById",
  async ({ categoryId }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}common/fetchGroupCategoryById`,
        {
          params: { categoryId: categoryId },
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Update Group category
 */
export const updateGroupCategory = createAsyncThunk(
  "common/updateGroupCategory",
  async (params, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "common/updateGroupCategory",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Enable/Disable Group category
 */
export const enableDisableGroupCategory = createAsyncThunk(
  "common/enableDisableGroupCategory",
  async ({ categoryId }, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
        "common/enableDisableGroupCategory",
        {
          method: "DELETE",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryId,
          }),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/**
 * Fetch all group categories
 */
export const fetchAllMasterclassCategories = createAsyncThunk(
  "common/fetchAllMasterclassCategories",
  async ({ page, pageSize }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}common/masterclassCategories`,
        {
          params: { page: page, limit: pageSize },
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/**
 * Fetch all group categories
 */
export const fetchActiveMasterclassCategories = createAsyncThunk(
  "common/fetchActiveMasterclassCategories",
  async (thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}common/activeMasterclassCategories`,
        {
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * add categories
 */
export const addMasterclassCategories = createAsyncThunk(
  "common/addMasterclassCategories",
  async ({ title }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}common/masterclassCategories`,
          { title },
          {
          headers: {
          Authorization: token,
          },
          }
          );
      const { data } = response;
      if (data.success) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/**
 * Enable/Disable Group category
 */
export const enableDisableMasterclassCategory = createAsyncThunk(
  "common/enableDisableMasterclassCategory",
  async ({ categoryId }, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
        "common/masterclassCategories",
        {
          method: "DELETE",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryId,
          }),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/**
 * Update Group category
 */
export const updateMasterclassCategory = createAsyncThunk(
  "common/updateMasterclassCategory",
  async (params, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "common/updateMasterclassCategory",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Fetch all tags
 */
export const fetchAllTags = createAsyncThunk(
  "common/tags",
  async ({ page, pageSize }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}common/tags`,
        {
          params: { page: page, limit: pageSize },
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Fetch Tags by id
 */
export const fetchTagById = createAsyncThunk(
  "common/fetchTagById",
  async ({ tagId }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}common/fetchTagById`,
        {
          params: { tagId: tagId },
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Update Tags
 */
export const updateTag = createAsyncThunk(
  "common/updateTag",
  async (params, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "common/updateTag",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Enable/Disable Tags
 */
export const enableDisableTag = createAsyncThunk(
  "common/enableDisableTag",
  async ({ tagId }, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "common/enableDisableTag",
        {
          method: "DELETE",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tagId,
          }),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/**
 * Get all vendor subscriptions plans
 */
export const fetchAllVendoSubscriptions = createAsyncThunk(
  "common/vendorSubscriptions",
  async ({ page, pageSize }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}common/vendorSubscriptions`,
        {
          params: { page: page, limit: pageSize },
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
/**
 * Update vendor subscription plan
 */
export const updateSubscriptionPlan = createAsyncThunk(
  "common/updateVendorSubscription",
  async (params, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "common/updateVendorSubscription",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/**
 * Enable/Disable vendor subscription plan
 */
export const enableDisableSubscription = createAsyncThunk(
  "common/enableDisableSubscription",
  async ({ subscriptionId }, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "common/enableDisableSubscription",
        {
          method: "DELETE",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subscriptionId,
          }),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/**
 * Get all vendor subscriptions categories
 */
export const fetchAllSubscriptionCategories = createAsyncThunk(
  "common/subscriptionCategories",
  async (thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}common/subscriptionCategories`,
        {
          headers: { Authorization: `${token}` },
        }
      );
      const { data } = response;
      if (data.success) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/**
 * Enable/Disable vendor subscription plan
 */
export const enableDisableSubscriptionCategory = createAsyncThunk(
  "common/enableDisableSubscriptionCategory",
  async ({ categoryId, subscriptionId, currentStatus }, thunkAPI) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
        "common/enableDisableSubscriptionCategory",
        {
          method: "PUT",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryId,
            subscriptionId,
            currentStatus,
          }),
        }
      );
      let data = await response.json();
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    clearCommonState: (state) => {
      state.isError = false;
      state.isSuccess = false;

      return state;
    },
  },
  extraReducers: {
    [fetchCountries.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchCountries.fulfilled]: (state, action) => {
      state.status = "completed";
      state.countries = action.payload;
    },
    [fetchCountries.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [fetchAllLanguages.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchAllLanguages.fulfilled]: (state, action) => {
      state.status = "completed";
      state.languages = action.payload;
    },
    [fetchAllLanguages.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [fetchAllsubscriptions.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchAllsubscriptions.fulfilled]: (state, action) => {
      state.status = "completed";
      state.subscriptions = action.payload;
    },
    [fetchAllsubscriptions.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [fetchAllContent.pending]: (state, action) => {
      state.statusContent = "loading";
    },
    [fetchAllContent.fulfilled]: (state, action) => {
      state.statusContent = "completed";
      state.contents = action.payload;
    },
    [fetchAllContent.rejected]: (state, action) => {
      state.statusContent = "failed";
      state.error = action.error.message;
    },
    [fetchAllVideoCategories.pending]: (state, action) => {
      state.categoryStatus = "loading";
    },
    [fetchAllVideoCategories.fulfilled]: (state, action) => {
      state.categoryStatus = "completed";
      state.totalVideoCategories = action.payload.total
      state.videoCategories = action.payload.categories;
    },
    [fetchAllVideoCategories.rejected]: (state, action) => {
      state.categoryStatus = "failed";
      state.error = action.error.message;
    },
    [updateVideoCategory.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.successMessage = payload.data.message;
      return state;
    },
    [updateVideoCategory.rejected]: (state, { payload }) => {
      state.isError = true;
      state.errorMessage = payload.data.message;
    },
    [updateVideoCategory.pending]: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
    [enableDisableVideoCategory.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.successMessage = payload.data.message;
      return state;
    },
    [enableDisableVideoCategory.rejected]: (state, { payload }) => {
      state.isError = true;
      state.errorMessage = payload.data.message;
    },
    [enableDisableVideoCategory.pending]: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
    [fetchAllGroupCategories.pending]: (state, action) => {
      state.gcategoryStatus = "loading";
    },
    [fetchAllGroupCategories.fulfilled]: (state, action) => {
      state.gcategoryStatus = "completed";
      state.totalGroupCategories = action.payload.total || 0
      state.groupCategories = action.payload.categories;
    },
    [fetchAllGroupCategories.rejected]: (state, action) => {
      state.gcategoryStatus = "failed";
      state.error = action.error.message;
    },
    [updateGroupCategory.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.successMessage = payload.data.message;
      return state;
    },
    [updateGroupCategory.rejected]: (state, { payload }) => {
      state.isError = true;
      state.errorMessage = payload.data.message;
    },
    [updateGroupCategory.pending]: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
    [updateMasterclassCategory.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.successMessage = payload.data.message;
      return state;
    },
    [updateMasterclassCategory.rejected]: (state, { payload }) => {
      state.isError = true;
      state.errorMessage = payload.data.message;
    },
    [updateMasterclassCategory.pending]: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
    [enableDisableGroupCategory.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.successMessage = payload.data.message;
      return state;
    },
    [enableDisableGroupCategory.rejected]: (state, { payload }) => {
      state.isError = true;
      state.errorMessage = payload.data.message;
    },
    [enableDisableGroupCategory.pending]: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
    [fetchAllMasterclassCategories.pending]: (state, action) => {
      state.mcategoryStatus = "loading";
    },
    [fetchAllMasterclassCategories.fulfilled]: (state, action) => {
      state.mcategoryStatus = "completed";
      state.totalMasterclassCategories = action.payload.total || 0
      state.masterclassCategories = action.payload.categories;
    },
    [fetchAllMasterclassCategories.rejected]: (state, action) => {
      state.gcategoryStatus = "failed";
      state.error = action.error.message;
    },
    [fetchActiveMasterclassCategories.pending]: (state, action) => {
      state.mcategoryStatus = "loading";
    },
    [fetchActiveMasterclassCategories.fulfilled]: (state, action) => {
      state.mcategoryStatus = "completed";
      state.totalMasterclassCategories = action.payload.total || 0
      state.masterclassCategories = action.payload.categories;
    },
    [fetchActiveMasterclassCategories.rejected]: (state, action) => {
      state.gcategoryStatus = "failed";
      state.error = action.error.message;
    },
    [addMasterclassCategories.pending]: (state, action) => {
      state.mcategoryStatus = "loading";
    },
    [addMasterclassCategories.fulfilled]: (state, action) => {
      state.mcategoryStatus = "completed";
      state.totalMasterclassCategories =state.totalMasterclassCategories+1
      state.masterclassCategories = [...state.masterclassCategories, action.payload.category];
    },
    [addMasterclassCategories.rejected]: (state, action) => {
      
      state.gcategoryStatus = "failed";
      state.error = action.error.message;
    },
    [enableDisableMasterclassCategory.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.successMessage = payload.data.message;
      return state;
    },
    [enableDisableMasterclassCategory.rejected]: (state, { payload }) => {
      state.isError = true;
      state.errorMessage = payload.data.message;
    },
    [enableDisableMasterclassCategory.pending]: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
    [fetchAllTags.pending]: (state, action) => {
      state.tagStatus = "loading";
    },
    [fetchAllTags.fulfilled]: (state, action) => {
      state.tagStatus = "completed";
      state.totaltags = action.payload.total || 0
      state.tags = action.payload.tags;
    },
    [fetchAllTags.rejected]: (state, action) => {
      state.tagStatus = "failed";
      state.error = action.error.message;
    },
    [updateTag.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.successMessage = payload.data.message;
      return state;
    },
    [updateTag.rejected]: (state, { payload }) => {
      state.isError = true;
      state.errorMessage = payload.data.message;
    },
    [updateTag.pending]: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
    [enableDisableTag.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.successMessage = payload.data.message;
      return state;
    },
    [enableDisableTag.rejected]: (state, { payload }) => {
      state.isError = true;
      state.errorMessage = payload.data.message;
    },
    [enableDisableTag.pending]: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
    [updateSubscriptionPlan.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.successMessage = payload.data.message;
      return state;
    },
    [updateSubscriptionPlan.rejected]: (state, { payload }) => {
      state.isError = true;
      state.errorMessage = payload.data.message;
    },
    [updateSubscriptionPlan.pending]: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
    [enableDisableSubscription.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.successMessage = payload.data.message;
      return state;
    },
    [enableDisableSubscription.rejected]: (state, { payload }) => {
      state.isError = true;
      state.errorMessage = payload.data.message;
    },
    [enableDisableSubscription.pending]: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
    [fetchAllSubscriptionCategories.pending]: (state, action) => {
      state.subCatStatus = "loading";
    },
    [fetchAllSubscriptionCategories.fulfilled]: (state, action) => {
      state.subCatStatus = "completed";
      state.subscriptionCategories = action.payload.subscriptionCategories;
    },
    [fetchAllSubscriptionCategories.rejected]: (state, action) => {
      state.subCatStatus = "failed";
      state.error = action.error.message;
    },
    [enableDisableSubscriptionCategory.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.successMessage = payload.data.message;
      return state;
    },
    [enableDisableSubscriptionCategory.rejected]: (state, { payload }) => {
      state.isError = true;
      state.errorMessage = payload.data.message;
    },
    [enableDisableSubscriptionCategory.pending]: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
});

export default commonSlice.reducer;

export const { clearCommonState } = commonSlice.actions;

export const commonSelector = (state) => state.common;

/**
 * Get all countries
 * @param {*} state
 * @returns
 */
export const getAllCountries = (state) => state.common.countries;

/**
 * Get all languages
 * @param {*} state
 * @returns
 */
export const getAllLanguages = (state) => state.common.languages;

/**
 * Get all subscriptions
 * @param {*} state
 * @returns
 */
export const getAllSubscriptions = (state) => state.common.subscriptions;

/**
 * Get all contents
 * @param {*} state
 * @returns
 */
export const getAllContents = (state) => state.common.contents;

/**
 * Get all video categories
 * @param {*} state
 * @returns
 */
export const getAllVideoCategories = (state) => state.common.videoCategories;

/**
 * Get all Group categories
 * @param {*} state
 * @returns
 */
export const getAllGroupCategories = (state) => state.common.groupCategories;


/**
 * Get all masterclass categories
 * @param {*} state
 * @returns
 */
export const getAllMasterclassCategories = (state) => state.common.masterclassCategories;

/**
 * Get all tags
 * @param {*} state
 * @returns
 */
export const getAllTags = (state) => state.common.tags;

/**
 * Get all subscription categories
 * @param {*} state
 * @returns
 */
export const getAllSubscriptionCategories = (state) =>
  state.common.subscriptionCategories;
