import React, { Component } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { translations } from "../../constants/translations";




export class TabsetProfile extends Component {
  render() {
    const { user } = this.props;
    return (
      <div>
        <Tabs>
          <TabList className="nav nav-tabs tab-coupon">
            <Tab className="nav-link">
              <User className="mr-2" />
              {translations.Profile.Profile}
            </Tab>
          </TabList>

          <TabPanel>
            <div className="tab-pane fade show active">
              <h5 className="f-w-600 f-16">{translations.Profile.Profile}</h5>
              <div className="table-responsive profile-table">
                <table className="table table-responsive">
                  <tbody>
                    <tr>
                      <td width="120"><strong>{translations.Profile.FirstName}:</strong></td>
                      <td>{user.firstName}</td>
                    </tr>
                    <tr>
                      <td width="120"><strong>{translations.Profile.LastName}:</strong></td>
                      <td>{user.lastName}</td>
                    </tr>
                    <tr>
                      <td width="120"><strong>{translations.Profile.Email}:</strong></td>
                      <td>{user.email}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

export default TabsetProfile;
