import moment from "moment"
export const  msgSend5minsAgo = ( dateCreated ) => {
    const now = moment(new Date()) //todays date
    const end = moment(dateCreated)// another date
    const duration = moment.duration(now.diff(end))
    const days = duration.asDays()
    if ( days < 1 ) {
        const hours = duration.asHours()
        if ( hours < 2 ) {
            const minutes = duration.asMinutes()
            if ( minutes > 5 ) {
                return false 
            }
            return true
        }
    }
    return false 
}