import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash';
import { Button, MultiSelectComp } from '../../shared';
import styles from '../helpdesk.module.css'

const sortOptions = [{ label: "Read", value: true }, { label: "Unread", value: false },{ label: "Received but Unanswered", value: "custom" }]

const HelpdeskSearch = (props) => {
    const { applyFilters, allfilters, addFilter } = props
    const [ cusFileter, setCusFilter ] = useState( false ) ; 
    const customAddFilter = (v) => {
        addFilter({ status: v.parsedValues })
    }
    const sendFilterData = () => {
        if ( allfilters && allfilters.status && allfilters.status.includes("custom") ) {
            setCusFilter( true ) 
            applyFilters({status1: ["custom"]})
        } else {
            setCusFilter( false ) 
            applyFilters(allfilters)
        }
    }
    const resetFilterData = () => {
        setCusFilter( false ) 
        applyFilters({})
    }
    return <div className={styles.filterContainer}>
        <div className={styles.userFilter}>
            <MultiSelectComp
                options={sortOptions}
                displayNumberOfItems={3}
                disableSearch
                values={cusFileter ? get(allfilters, 'status1', []) : get(allfilters, 'status', [])}
                onSubmit={(v) => customAddFilter(v)}
                placeholder="Select Status"
            />
        </div>
        <div className={styles.userFilter}>
            <input
                name="user"
                className={`form-control ${styles.inputTitle}`}
                maxLength={40}
                placeholder="Search user"
                value={get(allfilters, 'user', '')}
                onChange={(e) => addFilter({ user: e.target.value })}
                onKeyPress={(e) => { e.key === 'Enter' && applyFilters(allfilters) }}
            />
        </div>
        <Button clicked={() => sendFilterData()} className={`btn btn-primary ${styles.filterBtn}`}>Filter</Button>
        <Button clicked={() => resetFilterData()} className="btn btn-light" >Reset</Button></div>
}

HelpdeskSearch.defaultProps = {
    applyFilters: () => null,
    addFilter: () => null,
    allfilters: {}

}

HelpdeskSearch.propTypes = {
    allfilters: PropTypes.object.isRequired,
    addFilter: PropTypes.func.isRequired,
    applyFilters: PropTypes.func.isRequired,
}

export default HelpdeskSearch