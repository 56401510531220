import React, { useEffect, useState} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Skeleton from 'react-loading-skeleton'

const Chat = (props) => {
    const { chat, fetchMore, totalMessages, renderChatHistory, loading, deletedMessageId } = props
    const [ singleChat, setSingleChat ] = useState( chat ) 
    useEffect(()=>{
        setSingleChat(chat)
    }, [chat])
    useEffect(() => {
        const messageIdToRemove = deletedMessageId
        const indexOfObject = singleChat.findIndex(object => {
        return object._id === messageIdToRemove
        })
        let finalChat = [...singleChat.slice(0, indexOfObject), ...singleChat.slice(indexOfObject + 1)]
        setSingleChat( finalChat )
   }, [deletedMessageId])
    return <>
        { loading && <div className="m-10">{[...new Array(2)].map(i => <Skeleton height={60} />)}</div>}
        <InfiniteScroll
            inverse={true}
            style={{ display: "flex", flexDirection: "column-reverse" }}
            dataLength={chat.length}
            scrollableTarget={'userHistory'}
            next={() => fetchMore()}
            hasMore={totalMessages > chat.length}
            loader={loading && <div className="m-10">{[...new Array(2)].map(i => <Skeleton height={60} />)}</div>}
            endMessage={<p style={{ textAlign: "center" }} >{!loading && 'No more messages found'}</p>}
        >
            {renderChatHistory(singleChat)}
        </InfiniteScroll>
    </>
}

export default Chat