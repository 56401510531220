import React from 'react'
import styles from './pagination.module.css'
import PropTypes from 'prop-types'

const PaginationComp = (props) => {
    const { currentPage = 0, maxPages = 0, setCurrentPage } = props;
    let items = [];
    let leftSide = currentPage - 1;
    if (leftSide <= 0) leftSide = 1;
    let rightSide = currentPage + 1;
    if (rightSide > maxPages) rightSide = maxPages;
    for (let number = leftSide; number <= rightSide; number++) {
        items.push(
            <li
                key={number}
                className={
                    number === currentPage ? styles.active : styles.pageNumber
                }
                onClick={() => {
                    setCurrentPage(number);
                }}
            >
                {number}
            </li>
        );
    }
    const nextPage = () => {
        if (currentPage < maxPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const lastPage = () => {
        setCurrentPage(maxPages);
    }
    const firstPage = () => {
        setCurrentPage(1)
    }
    return <div className={styles.paginationWrapper}>
        <ul className={styles.pagination}>
            {
                currentPage > 2 && (<li onClick={() => firstPage()} className={styles.next} >First</li>)
            }
            <li onClick={() => prevPage()} className={styles.next} >Prev</li>
            {items}
            <li onClick={() => nextPage()} className={styles.next} >Next</li>
            {
                currentPage !== maxPages && ( <li onClick={() => lastPage()} className={styles.next} >Last</li> )
            }
            
        </ul>
    </div>
}

PaginationComp.propTypes = {
    maxPages: PropTypes.number.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired
}

PaginationComp.defaultProps = {
    maxPages: 0,
    currentPage: 0,
    setCurrentPage: () => console.error("setCurrentPage missing for PaginationComp")
}

export default PaginationComp