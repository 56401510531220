import React, { useEffect, useReducer, useState } from 'react'
import { useHistory } from 'react-router';
import moment from "moment";
import Breadcrumb from "../common/breadcrumb";
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../hooks/apiStateHandler'
import { getArtworks, deleteArtwork, markAppropriate, markFeatured } from '../../services/artworks'
import SearchArtwork from './components/search'
import { Button, TableComp, ToolTip } from '../shared'
import { get } from 'lodash-es';
import { SOMETING_WENT_WRONG, statusForAdminAcceptence, TOTAL_RECORDS } from '../../constants/translations'
import { openInNewTab } from '../../utils/anchor';
import { toast } from 'react-toastify';

const ArtworksList = (props) => {
    const [artworksState, artworksDispatch] = useReducer(ApiStateReducer, { ...initialState })
    const [appliedfilters, setAppliedFilters] = useState({ status: [0, 1] })
    const [selectedUser, setSelectedUser] = useState(null)
    const history = useHistory()

    useEffect( ()=> {
        const localFilters = JSON.parse( localStorage.getItem('filterObj') )
        const sellername = localStorage.getItem('sellerName')
        if ( localFilters ) {
            getArtworksHandler({ offset: 0, limit: get(artworksState, 'limit', 20), filters: localFilters }) 
            setAppliedFilters(localFilters)
            setSelectedUser({_id: localFilters.userId, fullname: sellername })
            localStorage.removeItem('filterObj')
            localStorage.removeItem('sellerName')
        } else {
            getArtworksHandler({ offset: 0, limit: get(artworksState, 'limit', 20), filters: appliedfilters })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getArtworksHandler = async (p = {}) => {
        try {
            const params = { ...p }
            artworksDispatch({ type: ApiStateEvents.FETCHING })
            if (params.filters && params.filters.status) {
                if (params.filters.status.includes('isDrafted')) {
                    params.filters.isDrafted = true
                    params.filters.status = params.filters.status.filter(o => o !== 'isDrafted')
                }
            }
            const res = await getArtworks(params)
            if (res.success) {
                artworksDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.artworks', []), offset: params.offset, totalRecords: get(res, 'data.total') })
            } else {
                toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
                artworksDispatch({ type: ApiStateEvents.ERROR, data: [], totalRecords: get(res, 'data.total') })
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
            artworksDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }

    const appliedFiltersHandler = (filtersParams, type='') => {
        localStorage.setItem('filterObj' , JSON.stringify(filtersParams) )
        if ( type !== 'reset' ) {
            const currentURL = history.location.pathname
            window.open(currentURL, "_blank")
        } else {
            getArtworksHandler({ offset: 0, limit: get(artworksState, 'limit', 20), filters: { ...filtersParams } })
            setAppliedFilters(filtersParams)
        }
        
        
    }

    const getAuthor = (artwork) => {
        if (get(artwork, 'nameOfArtist.firstName', '').length) {
            return get(artwork, 'nameOfArtist.firstName') + ' ' + get(artwork, 'nameOfArtist.lastName')
        }
        return get(artwork, 'userId.firstName', 'N/A') + ' ' + get(artwork, 'userId.lastName', '')
    }

    const columns = [
        {
            name: "Title",
            selector: "title",
            sortable: true,
        },
        {
            name: "Country",
            selector: "country",
            sortable: true,
            cellRenderer: (row) => (<p>{row.country || 'N/A'}</p>)
        },
        {
            name: "Author",
            cellRenderer: (row) => (<p onClick={() => { localStorage.setItem('sellerName', row.userId.firstName + ' ' + row.userId.lastName) ; appliedFiltersHandler({ ...appliedfilters, userId: row?.userId?._id }); setSelectedUser(row.userId) }}>{getAuthor(row)}</p>)
        },
        {
            name: "Price",
            selector: "price",
            sortable: true,
            cellRenderer: (row) => <>{!get(row, 'priceOnRequest') ? get(row, 'price').toString().replace('.', ',') : 'Price on request'}</>
        },
        {
            name: "Date Created",
            selector: "dateCreated",
            cellRenderer: (row) => (<div >{moment(row.dateCreated).format("DD MMM, YYYY h:mm:ss a")}</div>)
        },
        {
            name: "Status",
            sortable: true,
            selector: "moderated",
            cellRenderer: (row) => (!row.isDrafted ? <div>{statusForAdminAcceptence[row.moderated || 0]}</div> : <div>Drafted</div>)
        },
        {
            name: 'Actions',
            cellRenderer: (row) => (<>
                <Button title="View Artwork" clicked={() => openInNewTab(`/artworks/details/${row._id}`)} className="IconButon"><i
                    className="fa fa-eye"
                    style={{
                        width: 'auto',
                        fontSize: 18,
                        padding: 0,
                        color: "rgb(0,191,255)",
                    }}
                ></i></Button>
                <Button title="Edit Artwork" clicked={() => openInNewTab(`/artworks/edit/${row._id}`)} className="IconButon"><i
                    className="fa fa-pencil"
                    style={{
                        width: 'auto',
                        fontSize: 18,
                        padding: 0,
                        color: "rgb(40, 167, 69)",
                    }}
                ></i></Button>
                <ToolTip tip="View User on Frontend" id="viewOnFrontend" />
                    <span
                        data-tip
                        data-for="viewOnFrontend"
                        style={{ cursor: "pointer" }}
                        title="View User on Frontend"
                        onClick={() => {
                            //history.push(`/users/${UPDATE}/${row._id}`)
                            const win = window.open(`https://artmo.com/user/${row.userId.username}`, "_blank")
                            win.focus()
                        }}
                    >
                        <i
                            className="fa fa-user"
                            style={{
                                width: 35,
                                fontSize: 18,
                                padding: 10,
                                color: "rgb(0, 191, 255)",
                            }}
                        ></i>
                    </span>
                <Button
                    title={row.isFeatured ? "Unfeature Artwork" : "Feature Artwork"}
                    loadingText={!row.isFeatured ? 'Featuring' : 'Unfeaturing'}
                    clicked={() => featureArticleHandler(row._id, !row.isFeatured)}
                    className="IconButon">{!row.isFeatured ? <i class="fa fa-bookmark-o"></i> : <i class="fa fa-bookmark"></i>}
                </Button>
                <Button title="Delete Artwork" loading={row.deleting} loadingText="Deleting" clicked={() => deleteArticleHandler(row._id)} className="IconButon"><i
                    className="fa fa-trash"
                    style={{
                        width: 'auto',
                        fontSize: 18,
                        padding: 0,
                        color: "#e4566e",
                    }}
                ></i></Button>
            </>)
        }
    ]

    const onPageChange = (page) => {
        getArtworksHandler({
            offset: get(artworksState, 'limit', 20) * (page - 1),
            limit: get(artworksState, 'limit', 20),
            filters: { ...appliedfilters },
            sortField: get(artworksState, 'sorting.sortField'),
            sortOrder: get(artworksState, 'sorting.sortOrder'),
        })
    }

    const onSort = ({ fieldName, sortOrder }) => {
        artworksDispatch({
            type: ApiStateEvents.ADD_SORTING, sorting: {
                sortField: fieldName,
                sortOrder: sortOrder,
            }
        })
        getArtworksHandler({
            offset: 0,
            limit: get(artworksState, 'limit', 20),
            filters: { ...appliedfilters },
            sortField: fieldName,
            sortOrder: sortOrder,
        })
    }

    const updateArtworkHandler = (articleId, field, value) => {
        const allArtworks = get(artworksState, 'data', [])
        const articleIndex = allArtworks.findIndex(o => o._id === articleId)
        if (articleIndex !== -1) {
            allArtworks[articleIndex][field] = value
            artworksDispatch({ type: ApiStateEvents.SUCCESS, data: allArtworks })
        }
    }

    const deleteArticleHandler = async (artworkId) => {
        updateArtworkHandler(artworkId, 'deleting', true)
        const res = await deleteArtwork({ artworkId })
        if (res.success) {
            toast.success(get(res, 'data.message'))
            const allArticles = get(artworksState, 'data', [])
            const newArticles = allArticles.filter(o => o._id !== artworkId)
            if (newArticles) {
                artworksDispatch({ type: ApiStateEvents.SUCCESS, data: newArticles })
            }
        } else {
            toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
            updateArtworkHandler(artworkId, 'deleting', false)
        }
    }

    const featureArticleHandler = async (artworkId, featureStatus) => {
        updateArtworkHandler(artworkId, 'loadingFeaturing', true)
        const res = await markFeatured({ artworkId, status: featureStatus })
        if (res.success) {
            // toast.success(get(res, 'data.message'))
            updateArtworkHandler(artworkId, 'markFeatured', featureStatus)
        } else {
            // toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
        }
        updateArtworkHandler(artworkId, 'loadingFeaturing', false)
    }


    return <>
        <Breadcrumb title='Artworks' />
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h5 className="BackButtonHeading">Artworks</h5>
                </div>
                <div>
                    <SearchArtwork selectedUser={selectedUser} addFilter={(filter) => setAppliedFilters({ ...appliedfilters, ...filter })} applyFilters={(filters, type) => appliedFiltersHandler(filters, type)} allfilters={appliedfilters} />
                </div>
                <div style={{ margin: "15px 30px 15px" }}>
                    {!get(artworksState, 'loading') && TOTAL_RECORDS + get(artworksState, 'totalRecords', 0)}
                </div>
                <div className="card-body">
                    <TableComp
                        page={(get(artworksState, 'offset', 1) / get(artworksState, 'limit', 1)) + 1}
                        limit={get(artworksState, 'limit', 20)}
                        totalRecords={get(artworksState, 'totalRecords', 0)}
                        onPageChange={(p) => onPageChange(p)}
                        loading={get(artworksState, 'loading')}
                        data={get(artworksState, 'data', [])}
                        columns={columns}
                        onSort={onSort}
                        sortField={get(artworksState, 'sorting.sortField', null)}
                        sortOrder={get(artworksState, 'sorting.sortOrder', null)}
                    />

                </div>
            </div>
        </div>
    </>
}

export default ArtworksList