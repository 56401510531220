import APIHandler from './apiHandler'

const getArtworks = (params) => APIHandler('artworks/list', 'get', params)

const acceptArtwork = (params) => APIHandler('artworks/accept', 'put', params)

const getArtwork = (params) => APIHandler('artworks/detail', 'get', params)

const markAppropriate = (params) => APIHandler('artworks/markAppropriate', 'put', params)

const markFeatured = (params) => APIHandler('artworks/markFeatured', 'put', params)

const getGenres = () => APIHandler('common/getGenres', 'get')

const getCheckSlug = (params) => APIHandler('article/checkslug', 'get', params)

const getMediums = () => APIHandler('common/getMediums', 'get')

const updateArtwork = (params) => APIHandler('artworks/update', 'put', params)

const deleteArtwork = (params) => APIHandler('artworks/delete', 'delete', params)

const getAllGenres = (params) => APIHandler('artworks/genres', 'get', params )

const addNewGenre = (params) => APIHandler('artworks/genres/add', 'post', params )

const deleteGenre = (params) => APIHandler('artworks/genres/delete', 'delete', params )


export { getArtworks, acceptArtwork, getArtwork, markAppropriate, markFeatured, getGenres, deleteGenre, getMediums, updateArtwork, getCheckSlug, deleteArtwork, getAllGenres, addNewGenre }