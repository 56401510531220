import React, { useEffect, useReducer, useState } from 'react'
import Breadcrumb from "../common/breadcrumb";
import { TableComp } from '../shared';
import get from 'lodash/get'
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../hooks/apiStateHandler'
import { getNotificationTypes, addNotificationTYpe } from '../../services/notificationTypes'
import moment from "moment";
import { toast } from 'react-toastify';
import { SOMETING_WENT_WRONG, TOTAL_RECORDS } from '../../constants/translations';
import { useDispatch } from 'react-redux';
import ModalAddNotificationsType from "./create_notification_type";
import { translations  } from '../../constants/translations';


const NotificationTypesList = (props) => {
    const dispatch = useDispatch()
    const [ notificationTypeTemplateState, notificationTypeTemplatesDispatch] = useReducer(ApiStateReducer, initialState)
    useEffect(() => {
        getNotificationTypesHandler({ offset: 0, limit: get(notificationTypeTemplateState, 'limit', 20), })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const [mopen, setMopen] = useState(false);
    const [updating, setUpdating] = useState(false)
    const onOpenModal = () => {
        setMopen(true);
      };
    const onCloseModal = () => {
        setMopen(false);
    };
    const submitHandler = async (data) => {
        setUpdating(true)
        const res = await addNotificationTYpe({title: data})
        if (res && res?.success) {
            toast.success(res?.data?.message)
            notificationTypeTemplatesDispatch({ type: ApiStateEvents.SUCCESS})
            getNotificationTypesHandler({ offset: 0, limit: get(notificationTypeTemplateState, 'limit', 20), })
        } else {
            toast.error(SOMETING_WENT_WRONG)
            notificationTypeTemplatesDispatch({ type: ApiStateEvents.ERROR, data: []})
        }
        setMopen(false)
        setUpdating(false)
    };

    const columns = [
        {
            name: "Title",
            selector: "title",
            sortable: true,
        },
        {
            name: "Date",
            sortable: true,
            selector: "dateUpdated",
            cellRenderer: (row) => (<div >{moment(row.dateUpdated).format("DD MMM, YYYY h:mm:ss a")}</div>)
        },
    ]
    const getNotificationTypesHandler = async (params) => {
        try {
            notificationTypeTemplatesDispatch({ type: ApiStateEvents.FETCHING })
            const res = await getNotificationTypes(params)
            if (res.success) {
                notificationTypeTemplatesDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.notificationTypes', []), offset: params.offset, totalRecords: get(res, 'data.total') })
            } else {
                toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
                notificationTypeTemplatesDispatch({ type: ApiStateEvents.ERROR, data: [], totalRecords: get(res, 'data.total') })
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
            notificationTypeTemplatesDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }

    return <>
            <Breadcrumb title='Notification Types' />
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header">
                        <h5 className="BackButtonHeading">
                        {translations.NotificationTypes.pageHeading}
                        </h5>
                    </div>
                    <div style={{ margin: "0 30px 15px" }}>
                        {!get(notificationTypeTemplateState, 'loading') && TOTAL_RECORDS + get(notificationTypeTemplateState, 'totalRecords', 0)}
                    </div>
                    <div className="card-body">
                    <ModalAddNotificationsType
                        mopen={mopen}
                        submitHandler={submitHandler}
                        onOpenModal={onOpenModal}
                        onCloseModal={onCloseModal}
                        updating={updating}
                    />
              <div className="clearfix"></div>
                        <TableComp
                            page={(get(notificationTypeTemplateState, 'offset', 0) / get(notificationTypeTemplateState, 'limit', 100) + 1)}
                            limit={get(notificationTypeTemplateState, 'limit', 100)}
                            totalRecords={get(notificationTypeTemplateState, 'totalRecords', 0)}
                            loading={get(notificationTypeTemplateState, 'loading')}
                            data={get(notificationTypeTemplateState, 'data', [])}
                            columns={columns}
                        />
                    </div>
                </div>
                
            </div>
        </>
}

export default NotificationTypesList