import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import DataTable from "react-data-table-component";
import { unwrapResult } from "@reduxjs/toolkit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { TOTAL_RECORDS, translations } from "../../constants/translations";
import ModalTag from "./tag-modal.js";
import {
  commonSelector,
  clearCommonState,
  fetchAllTags,
  updateTag,
  getAllTags,
  enableDisableTag,
} from "../../features/common/commonSlice";
import Switch from 'react-switch'
import { LoaderComp } from "../shared";

const GroupTag = () => {
  const dispatch = useDispatch();
  const [mopen, setMopen] = useState(false);
  const [tagId, setTagId] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [tagData, setTagData] = useState([]);
  const tags = useSelector(getAllTags);
  const totalRows = useSelector(state => state.common.totaltags)
  const fetchTagStatus = useSelector(
    (state) => state.common.tagStatus
  );
  const { isSuccess, isError, successMessage, errorMessage } =
    useSelector(commonSelector);

  const columns = [
    {
      name: "Title",
      selector: "title",
      sortable: true,
    },
    {
      // eslint-disable-next-line react/button-has-type
      cell: (row) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            title="Edit Tag"
            onClick={() => {
              handleEdit(row);
            }}
          >
            <i
              className="fa fa-pencil"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "rgb(0, 123, 255)",
              }}
            ></i>
          </span>

          <Switch
            checked={!row.isDeleted}
            onChange={() => handleEnableDisable(row._id)}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="material-switch"
          />
        </>
      ),
    },
  ];

  /**
   * Fetch tags data
   * @param {*} params
   */
  const fetchTagsData = (params) => {
    dispatch(fetchAllTags(params))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setData(originalPromiseResult.tags);
        setLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        toast.error(rejectedValueOrSerializedError);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    const params = {
      page: currentPage,
      pageSize: perPage,
    };
    fetchTagsData(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (page) => {
    fetchTagsData({ page: page });
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchTagsData({ page: page, pageSize: newPerPage });
    setPerPage(newPerPage);
  };
  /**
   * Handle edit/view buttons
   * @param {*} tagId
   */
  const handleEdit = (t) => {
    setTagId(t._id);
    setTagData(t);
    setMopen(true);
  };

  const handleEnableDisable = (id) => {
    dispatch(enableDisableTag({ tagId: id }));
    let clonned = [...data]
    const updatedTagIndex = data.findIndex(o => o?._id === id)
    if (updatedTagIndex !== -1) {
      clonned[updatedTagIndex] = { ...data[updatedTagIndex], isDeleted: !clonned[updatedTagIndex].isDeleted }
      setData(clonned)
    }
    // fetch update page
    // fetchTagsData({ page: currentPage, pageSize: perPage });
  };

  const onCloseModal = () => {
    setMopen(false);
  };
  /**
   * Submit editor handler
   * @param {*} languageId
   * @param {*} content
   */
  const submitHandler = (params) => {
    setLoading(true);
    if (tagId !== "" && tagId !== undefined) {
      params.selectedTagId = tagId;
    }
    dispatch(updateTag(params))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // fetch update page
        fetchTagsData({ page: currentPage, pageSize: perPage });
        setLoading(false);
        setMopen(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        toast.error(rejectedValueOrSerializedError.messageCode);
        setLoading(false);
        setMopen(false);
      });
  };

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearCommonState());
    }

    if (isSuccess) {
      toast.success(successMessage);
      dispatch(clearCommonState());
    }
  }, [isError, isSuccess, errorMessage, successMessage, dispatch]);

  return (
    <Fragment>
      <ToastContainer autoClose={2000} />
      <Breadcrumb
        title={translations.Settings.Tags}
        parent={translations.Settings.Settings}
      />
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <h5 className="BackButtonHeading">
              {translations.Settings.Tags}
            </h5>
          </div>
          <div style={{ margin: "15px 30px 15px" }}>
            {!loading && (TOTAL_RECORDS + totalRows || 0)}
          </div>
          <div className="card-body">
            <ModalTag
              mopen={mopen}
              tagId={tagId}
              videoCatList={data}
              loading={loading}
              tagData={tagData}
              submitHandler={submitHandler}
              onCloseModal={onCloseModal}
            />

            <DataTable
              defaultSortField="uniqueContentId"
              className="-striped -highlight"
              columns={columns}
              data={data}
              progressPending={loading}
              progressComponent={<LoaderComp loading />}
              pagination
              paginationServer
              paginationPerPage={perPage}
              numberOfPages={Math.floor(totalRows / perPage)}
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              paginationComponentOptions={{ noRowsPerPage: true }}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GroupTag;
