import React from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import get from 'lodash/get'
import { Button, FormFieldWrapper, TableComp } from '../shared'


const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
    },
};

const onValidate = (values) => {
    const { responseMessages, messageDetails } = values

    const error = {}
    if ((!responseMessages || !get(responseMessages, 'length')) && (!messageDetails || !get(messageDetails, 'length'))) {
        error.responseMessages = "Please select reason or add description"
    }
    return error
}
const formFieldIds = {
    messageDetails: "",
    responseMessages: []

}
const getInitalValues = (message = {}) => {
    let initialState = {}
    Object.keys(formFieldIds).forEach(key => {
        initialState[key] = message[key]
    })
    return initialState
}

const ResponseMessagesModal = (props) => {
    const {
        mopen,
        onCloseModal,
        submitHandler,
        title,
        message,
        responses,
        loading,
        type
    } = props


    const formik = useFormik({
        initialValues: getInitalValues(message || {}),
        validate: (v) => onValidate(v),
        onSubmit: values => {
            const mywords = values.responseMessages.join(', ');
            submitHandler(mywords)
            onCloseModal()
        },
    });

    const parseResponse = () => {
        const r = responses.map((response) => {
            const { _id,message,description } = response
            if(type==='rejectedReasons')
            {
                return {
                    ...response,
                    selected: responseMessages && Array.isArray(responseMessages) && responseMessages.length > 0 ? responseMessages.findIndex(o => o === description) !== -1 ? true : false : false
                }
            }else{
            return {
                ...response,
                selected: responseMessages && Array.isArray(responseMessages) && responseMessages.length > 0 ? responseMessages.findIndex(o => o === message) !== -1 ? true : false : false
            }
        }
        })
        //console.log({ r, rejectedReasons })
        return r
    }
    const { responseMessages, messageDetails } = formik.values
    const columns = [
        {
            name: "Response Messages",
            selector: "title",
            width: "100%",
            cellRenderer: (row) => (<p>{row.title || 'N/A'}</p>)
        },

    ]

    const rowSelectorHandler = ({ row, flag, rows }) => {
        let clonnedData = responseMessages || []
        if(type==='rejectedReasons'){
            if (row) {
                const { description } = row || {}
                if (flag) {
                    clonnedData.push(description)
                } else {
                    clonnedData = responseMessages.filter(o => o !== description)
                }
            } else if (rows) {
                if (flag) {
                    clonnedData = rows.map(({ description }) => description)
                } else {
                    clonnedData = []
                }
            }  
        }else{

        if (row) {
            const { _id , message} = row || {}
            if (flag) {
                clonnedData.push(message)
            } else {
                clonnedData = responseMessages.filter(o => o !== message)
            }
        } else if (rows) {
            if (flag) {
                clonnedData = rows.map(({ message }) => message)
            } else {
                clonnedData = []
            }
        }
    }
        formik.setFieldValue('responseMessages', clonnedData)
    }

    return (
        <Modal
            isOpen={mopen}
            onClose={onCloseModal}
            onRequestClose={onCloseModal}
            style={customStyles}
            className="ModalContent"
            overlayClassName="OverlayContent"
        >
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    {title}
                </h5>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <TableComp
                        columns={columns}
                        data={parseResponse()}
                        enableSelection
                        onSelection={({ row, rows, flag }) => rowSelectorHandler({ row, rows, flag })}
                    />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="firstName">{formik?.errors?.responseMessages}</label>
            </div>
            <div className="modal-footer">
                <Button loading={loading} type="submit" clicked={formik.handleSubmit} className="btn btn-primary">
                    Response
                </Button>
                <Button
                    type="button"
                    className="btn btn-light"
                    clicked={() => onCloseModal()}
                >
                    Close
                </Button>
            </div>
        </Modal >
    );
};

export default ResponseMessagesModal;