import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { editArticleValidationSchema } from '../common/validations'
import { Formik, Field, Form, ErrorMessage } from "formik";
import { get } from "lodash";
import {checkSlug} from "../../features/articles/articlesSlice"
import { Button } from '../shared'
import { Editor } from "@tinymce/tinymce-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { replaceWordpressImage } from "../../utils/image";
import styled from 'styled-components'
import SectionContent from './SectionContent'
import ModalComponent from './ImageModal'
import UploadPhotoModal from './UploadPhotoModal'
import { uploadImageToMediaLibrary } from "../../utils/imageHelper";


const ArticleSectionWrap = styled.div`
  background: #ffffff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
  width: auto;
  position: relative;
  margin: 0 0 16px;
  display: flex;
  flex-direction: column;
  padding: 0px 35px 0;
  justify-content: space-between;
  @media (max-width: 991px) {
    padding: 15px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    padding: 15px;
  }
`

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
    },
};

const EditArticleModal = (props) => {
    const {
        mopen,
        onCloseModal,
        submitHandler,
        values,
        title,
        loading,
        articleType
    } = props

const dispatch = useDispatch()
const [slugExists,setSlugExists]=useState(false)
const [plainText, setPlainText] = useState(false)	
const [openUploadModal, setOpenUploadModal] = useState(false)
const [articleImage, setArticleImage] = useState([])


useEffect(()=>{
    //   setArticleImage((prevImages) => [...prevImages, newImageObject]);
   setArticleImage([values.picUrl])
},[values])

const handleSlugChange=(e,handleChange)=>{
    slugExists && setSlugExists(false)
    handleChange(e)
}

const handleCategoryChange = ( e, handleChange ) => {
    handleChange(e)
}
const handleSlugBlur=async(e,handleBlur,currentSlug,newSlug)=>{
    if(e)   handleBlur(e)
    //console.log(currentSlug,newSlug)
    if(currentSlug===newSlug)   return true
    const resultAction=await dispatch(checkSlug({articleSlug:newSlug,articleType:articleType}))
    const response=await unwrapResult(resultAction)
    if(response&&!response.exists){
        setSlugExists(false)
        return  true
    }
    setSlugExists(true)
    return false
}
const deleteImg = (imgId) => {
    const imagesArr = [...articleImage]
    const filteredArr = imagesArr.filter((imgObj, index) => index !== imgId)
    setArticleImage(filteredArr)
    values.picUrl = ''
  }
const onSubmit=async(v,currentSlug)=>{
    //console.log(currentSlug,v.articleSlug)
    const validate=await handleSlugBlur(null,null,currentSlug,v.articleSlug)
    v.picUrl=values.picUrl
    console.log('RRRRRR'+JSON.stringify(v))
    if(validate)
    submitHandler(v)
}
const handleOnImageChange = (data) => {
    setArticleImage(data)
  }
  const applyHandler = async () => {
    if (articleImage) {
      let image = articleImage.result
      fetch(image)
        .then((res) => res.blob())
        .then(async (blob) => {
          const response = await uploadImageToMediaLibrary(blob, articleImage.filename)
          if (response && response.data && response.data.data) {
            const imgId = response.data.data.images[0]._id;
            setArticleImage(response.data.data.images)
            console.log('imgId'+JSON.stringify(imgId))
            values.picUrl = imgId
          }
          setOpenUploadModal(false)
        })
    }
  }
    return (
        <Modal
            isOpen={mopen}
            onClose={onCloseModal}
            onRequestClose={onCloseModal}
            style={customStyles}
            className="ModalContent"
            overlayClassName="OverlayContent"
        >
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    {title}
                </h5>
            </div>
            <div className="modal-body">
                <Formik
                    initialValues={
                        {
                            // picUrl: articleImage && articleImage.length > 0 && articleImage[0]._id ? articleImage[0]._id : '',
                            picUrl: get(values, 'picUrl', ''),
                            articleSlug: get(values, 'articleSlug', ''),
                            metadataTitle: get(values, 'metadataTitle', ''),
                            metadataDescription: get(values, 'metadataDescription', ''),
                            imageAlt: get(values, 'imageAlt', ''),
                            title: get(values, 'title', ''),
                            description: get(values, 'description', ''),
                            _id: get(values, '_id', null)
                        }
                    }
                    validationSchema={editArticleValidationSchema}
                    onSubmit={(v)=>onSubmit(v,get(values, 'articleSlug', ''))}
                >
                    {({ values: formikValues, setFieldValue,handleBlur,handleChange }) => (<Form id="emailTemplate">
                        <div className="form-group">

                        <>
                  {openUploadModal && (
                    <UploadPhotoModal
                    open={openUploadModal}
                    closeModal={() => setOpenUploadModal(false)}
                    type="article"
                    action="Upload"
                    value={articleImage}
                    onChange={handleOnImageChange}
                    isCover={true}
                    applyHandler={applyHandler}
                  />
                  )}
      
                  <ArticleSectionWrap>
                    <SectionContent
                      deleteImg={deleteImg}
                      articleImage={articleImage}
                      openModal={() => setOpenUploadModal(true)}
                    />
                  </ArticleSectionWrap>
                </>


                            <label htmlFor="title">Title</label>
                            <Field
                                className="form-control"
                                id="title"
                                name="title"
                                placeholder="Type your title here"
                            />
                            <ErrorMessage
                                component="div"
                                className="alert alert-danger"
                                name="title"
                            />
                        </div>
                        <div className="form-group" >
                        <label>Description</label><br/>
                        <button type="button" onClick={() => {setPlainText(!plainText)}}>Switch to {plainText ? 'rich text editor' : 'plain text editor'}</button>	
                            {                    	
                            plainText ? 	
                            <textarea style={{width: "100%", minHeight: "250px"}} value={formikValues.description} onChange={(e) => {	
                                setFieldValue('description', e.target.value);	
                                //console.log(e.target.value);	
                            }}></textarea>	
                            : <Editor	
                            apiKey={process.env.REACT_APP_TINY_MCE}	
                            initialValue={values.description}	
                            value={replaceWordpressImage(formikValues.description)}	
                            init={{	
                                height: 500,	
                                target: false,	
                                menubar: false,	
                                media_live_embeds: true,	
                                plugins:	
                                    'advlist autolink quickbars lists link image charmap print preview anchor searchreplace code fullscreen media paste code placeholder mediaembed imagetools',	
                                toolbar:	
                                        'bold italic underline h2 h3 removeformat alignleft aligncenter alignright alignjustify numlist blockquote media quickimage link',	
                                quickbars_image_toolbar: 'alignleft aligncenter alignright | remove',	
                                quickbars_insert_toolbar: false,	
                                quickbars_selection_toolbar: 'bold italic underline h2 h3',	
                            }}	
                            onEditorChange={(newValue, editor) =>	
                                setFieldValue("description", newValue)	
                            }	
                        ></Editor>	
                            }	
                        </div>
                        <div className="form-group">
                            <label htmlFor="title">Meta-Data Title</label>
                            <Field
                                className="form-control"
                                id="metadataTitle"
                                name="metadataTitle"
                                placeholder="Type your title here"
                            />
                            <ErrorMessage
                                component="div"
                                className="alert alert-danger"
                                name="metadataTitle"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="title">Meta-Data Description</label>
                            <Field
                                className="form-control"
                                id="metadataDescription"
                                name="metadataDescription"
                                placeholder="Type your description here"
                            />
                            <ErrorMessage
                                component="div"
                                className="alert alert-danger"
                                name="metadataDescription"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="title">Image Alt</label>
                            <Field
                                className="form-control"
                                id="imageAlt"
                                name="imageAlt"
                                placeholder="Type your image alt here"
                            />
                            <ErrorMessage
                                component="div"
                                className="alert alert-danger"
                                name="imageAlt"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="title">Article slug</label>
                            <Field
                                onBlur={(e)=>handleSlugBlur(e,handleBlur,values.articleSlug,formikValues.articleSlug)}
                                onChange={(e)=>handleSlugChange(e,handleChange)}
                                className="form-control"
                                id="articleSlug"
                                name="articleSlug"
                                placeholder="Type your slug here"
                            />
                            <ErrorMessage
                                component="div"
                                className="alert alert-danger"
                                name="articleSlug"
                            />
                            {slugExists&&<div className="alert alert-danger">Product slug already exists</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="title">Change Category</label>
                            <select
                                name="categories"
                                //value={values.color}
                                onChange={(e)=>handleCategoryChange(e,handleChange)}
                                // onBlur={handleBlur}
                                style={{ display: "block" }}
                            >
                                <option value="" label="Select a Category">
                                Select a Category{" "}
                                </option>
                                {
                                    articleType !=='buzz' && ( <option value="buzz" label="Buzz">
                                    {" "}
                                    BUzz
                                    </option> )
                                }
                                {
                                    articleType !=='wikiart' && ( <option value="wikiart" label="WikiART">
                                    {" "}
                                    WikiART
                                    </option> )
                                }
                                {
                                    articleType !=='digitalnft' && ( <option value="digitalnft" label="NFT & DIGITAL ART ">
                                    NFT & DIGITAL ART 
                                    </option> )
                                }
                                {
                                    articleType !=='howtodo' && ( <option value="howtodo" label="HOW TO DO">
                                    HOW TO DO
                                    </option> )
                                }
                                {
                                    articleType !=='potd' && ( <option value="potd" label="POTD">
                                    POTD
                                    </option> )
                                }
                                {
                                    articleType !=='othersart' && ( <option value="othersart" label="My article">
                                    My article
                                    </option> )
                                }
                                
                                
                            </select>
                        </div>
                        <Button loading={loading} type="submit" className="btn btn-primary">
                            Update
                        </Button>
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => onCloseModal()}
                        >
                            Close
                        </button>
                    </Form>)}
                </Formik>
            </div>
            <div className="modal-footer"></div>
        </Modal >
    );
};

export default EditArticleModal;
