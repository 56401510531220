export const captalizeFirstChar = (string) => {
    try {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } catch (error) {
        return string
    }
}

export const parseUserName = (user = {}) => {
    const { firstName, lastName, username } = user
    if (firstName && lastName && firstName.length) {
        return firstName + ' ' + lastName
    }
    return username || ''
}
// Adds a suffix to str (string) if it is present in inputArr (Array)
export const markFreePlan =(str,inputArr,suffix)=> {
    if(!str) return
    for(let i=0;i<inputArr.length;i++){
        if(str.includes(inputArr[i]))
        return `${str} ${suffix}`
    }

    return str    
}