import APIHandler from './apiHandler'

const getMessageResponseArt = () => APIHandler('messageResponseArt/list', 'get')

const updateMessageResponseArt = (params) => APIHandler('messageResponseArt/update', 'put', params)

const insertMessageResponseArt = (params) => APIHandler('messageResponseArt/blank', 'put', params)

const deleteMessageResponseArt = (params) => APIHandler('messageResponseArt/delete', 'delete', params)

export { getMessageResponseArt, updateMessageResponseArt, insertMessageResponseArt,deleteMessageResponseArt }