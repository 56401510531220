import {
  Home,
  User,
  UserPlus,
  Settings,
  HelpCircle,
  FileText,
  Bell
} from "react-feather";

import { translations } from "../constants/translations";

export const MENUITEMS = [
  {
    path: "/dashboard",
    title: translations.Menu.Dashboard,
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
    permission: "dashboard",
  },
  {
    path: "/help-desk",
    title: "Help Desk",
    icon: HelpCircle,
    type: "link",
    active: false,
    permission: "help-desk",
    children: [
      {
        path: "/help-desk/reported-messages", title: "Reported Messages", type: "link"
      },
      {
        path: "/help-desk/mass-messaging", title: "User Mass Messages", type: "link"
      }
      ,
      {
        path: "/help-desk/user-comments", title: "User Comments", type: "link"
      }
    ]
  },
  {
    path: "/artwork-desk",
    title: "Artwork Desk",
    icon: HelpCircle,
    type: "link",
    active: false,
    permission: "artwork-desk",
  },
  {
    title: "Users",
    icon: UserPlus,
    type: "sub",
    active: false,
    permission: "admin",
    children: [
      { path: "/admin/list", title: translations.Menu.AdminList, type: "link" },
      { path: "/users/list", title: translations.Menu.UserList, type: "link" },
      // { path: "/admin/create", title: "Create Admin", type: "link" },
    ],
  },
  // {
  //   title: "Front End Users",
  //   icon: User,
  //   type: "sub",
  //   active: false,
  //   permission: "users",
  //   children: [
  //     { path: "/users/list", title: translations.Menu.UserList, type: "link" },
  //     // { path: "/users/create", title: "Create User", type: "link" },
  //   ],
  // },
  {
    title: "Articles",
    icon: User,
    type: "sub",
    active: false,
    permission: "articles",
    children: [
      { path: "/articles/buzz/list", title: "Buzz", type: "link" },
      { path: "/articles/exhibition/list", title: "Exhibitions", type: "link" },
      { path: "/articles/potd/list", title: "POTD", type: "link" },
      { path: "/articles/wikiart/list", title: "WikiART", type: "link" },
      { path: "/articles/digitalnft/list", title: "NFT & DIGITAL ART", type: "link" },
      { path: "/articles/howtodo/list", title: "HOW TO DO", type: "link" },
      { path: "/articles/otherart/list", title: "Other", type: "link" },
    ],
  },
  {
    title: translations.Menu.MasterClass,
    icon: Settings,
    type: "sub",
    permission: "master",
    children: [
      {
        path: "/master/master-profile",
        title: translations.Menu.MasterProfile,
        type: "link",
      },
      {
        path: "/master/master-class",
        title: translations.Menu.MasterClassList,
        type: "link",
      },
      // {
      //   path: "/master/streams",
      //   title: translations.Menu.StreamChannels,
      //   type: "link",
      // }
    ],
  },
  {
    path: "/groups/list",
    title: "Groups",
    icon: FileText,
    type: "link",
    badgeType: "primary",
    active: false,
    permission: "groups",
  },
  {
    path: "/email-template",
    title: translations.Menu.EmailTemplates,
    icon: FileText,
    type: "link",
    badgeType: "primary",
    active: false,
    permission: "email-template",
    children: [
      {
        path: "/email-template/static-messages", title: "Static Messages", type: "link"
      }
    ]
  },
  {
    path: "/content-managment",
    title: translations.Menu.ContentManagment,
    icon: FileText,
    type: "link",
    badgeType: "primary",
    active: false,
    permission: "content-managment",
  },
  {
    path: "/notification-types",
    title: "Notification Types",
    icon: Bell,
    type: "link",
    active: false,
    permission: "notification-types",
  },
  {
    path: "/artworks/list",
    title: "Artworks",
    icon: FileText,
    type: "link",
    badgeType: "primary",
    active: false,
    permission: "artworks",
    children: [
      { path: "/artworks/genres/list", title: "Genres", type: "link" },
    ],
  },
  {
    title: translations.Menu.SiteSettings,
    icon: Settings,
    type: "sub",
    permission: "settings",
    children: [
      {
        path: "/settings/video-categories",
        title: translations.Menu.VideoCategories,
        type: "link",
      },
      {
        path: "/settings/group-categories",
        title: translations.Menu.GroupCategories,
        type: "link",
      },
      {
        path: "/settings/masterclass-categories",
        title: translations.Menu.MasterclassCategories,
        type: "link",
      },
      {
        path: "/settings/tags",
        title: translations.Menu.GroupTags,
        type: "link",
      },
      {
        path: "/settings/rejectReasons",
        title: translations.Menu.RejectReasons,
        type: "link",
      },
      {
        path: "/settings/messageResponse",
        title: translations.Menu.ResponseMessage,
        type: "link",
      },
      {
        path: "/settings/messageResponseArt",
        title: translations.Menu.ResponseMessageArt,
        type: "link",
      },
      {
        path: "/settings/suspensionReasons",
        title: translations.Menu.SuspensionReasons,
        type: "link",
      },
      // {
      //   path: "/settings/vendor-subscriptions",
      //   title: translations.Menu.ListVendorSubscriptionPlan,
      //   type: "link",
      // },
      {
        path: "/settings/update-subscription",
        title: translations.Menu.UpdatePlanSettings,
        type: "link",
      },
    ],
  },
  {
    title: "Social Activites",
    icon: User,
    type: "sub",
    active: false,
    permission: "social-activities",
    children: [
      { path: "/social-activities/posts", title: "Posts", type: "link" },
      { path: "/social-activities/videos", title: "Videos", type: "link" },
      { path: "/social-activities/albums", title: "Albums", type: "link" },
    ],
  },
  


  /*{
    path: "/help-desk",
    title: translations.Menu.HelpDesk,
    icon: HelpCircle,
    type: "link",
    active: false,
    permission: "help-desk",
  },
  {
    title: translations.Menu.SocialActivities,
    icon: Activity,
    type: "sub",
    active: false,
    permission: "activities",
    children: [{ path: "/activities/posts", title: translations.Menu.AllPosts, type: "link" }],
  },
  {
    path: "/seo-settings",
    title: translations.Menu.SEOsettings,
    icon: Box,
    type: "link",
    active: false,
    permission: "seo-settings",
  },

  {
    title: translations.Menu.AutomatedEmails,
    icon: Mail,
    type: "sub",
    active: false,
    permission: "templates",
    children: [
      { path: "/templates/list", title: translations.Menu.AutomatedEmails, type: "link" },
    ],
  },*/
  {
    title: translations.Menu.AccountSettings,
    icon: Settings,
    type: "sub",
    permission: "accountsettings",
    children: [
      {
        path: "/accountsettings/profile",
        title: translations.Menu.Profile,
        type: "link",
      },
      {
        path: "/accountsettings/change-password",
        title: translations.Menu.ChangePassword,
        type: "link",
      },
    ],
  },
];

