import APIHandler from './apiHandler'

const getmasterProfile = (params) => APIHandler('masterProfile/list', 'get',params)

const addMasterProfile = (body) => APIHandler('masterProfile/add', 'post',body)

const deleteMasterProfile = (body) => APIHandler('masterProfile/delete', 'post',body)

const checkMassterProfile = (params) => APIHandler('masterProfile/details', 'get',params)

const getmasterClass = (params) => APIHandler('masterClass/list', 'get',params)

const addMasterClass = (body) => APIHandler('masterClass/add', 'post',body)

const deleteMasterClass = (body) => APIHandler('masterClass/delete', 'put',body)

const fullyDeleteMasterClass = (body) => APIHandler('masterClass/fully-delete', 'delete',body)



export { getmasterProfile,addMasterProfile,deleteMasterProfile,checkMassterProfile,getmasterClass,addMasterClass,deleteMasterClass, fullyDeleteMasterClass }