import React from 'react'
import styled from 'styled-components'

const ErrorWrapper = styled.span`
  color: #d62d1e;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  padding-bottom: 2px;
`

const FieldWrapper = props => {
    const { label, error, children } = props

    return <>{label && <div>{label}</div>}{children}<ErrorWrapper>{error}</ErrorWrapper></>
}

export default FieldWrapper