import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash';
import { Button, } from '../../shared';
import '../content-management.css'

const ContentManagementSeacrh = (props) => {
    const { applyFilters, allfilters, addFilter } = props
    return <div className="filterContainer">
        <div className="userFilter">
            <input
                name="title"
                maxLength={40}
                className={`form-control inputTitle`}
                placeholder="Search title"
                value={get(allfilters, 'title', '')}
                onChange={(e) => addFilter({ title: e.target.value })}
                onKeyPress={(e) => { e.key === 'Enter' && applyFilters(allfilters) }}
            />
        </div>
        <Button clicked={() => applyFilters(allfilters)} className={`btn btn-primary filterBtn`}>Filter</Button>
        <Button clicked={() => applyFilters({})} className="btn btn-light" >Reset</Button></div>
}

ContentManagementSeacrh.defaultProps = {
    applyFilters: () => null,
    addFilter: () => null,
    allfilters: {}

}

ContentManagementSeacrh.propTypes = {
    allfilters: PropTypes.object.isRequired,
    addFilter: PropTypes.func.isRequired,
    applyFilters: PropTypes.func.isRequired,
}

export default ContentManagementSeacrh