import axios from 'axios'

export const createImageUrl = (path) => {
    try {
        return `${process.env.REACT_APP_IMAGE_S3_URL}/${path}`
    } catch (error) {
        return path
    }
}

export const createResizeImageUrl = (path, width, height, type) => {
    const delimiter = '/'
    let start = 0
    if (type === 'profileCover') {
        start = 1
    } else if (type === 'mediaLibrary') {
        start = 2
    }
    const prefix = path.split(delimiter).slice(0, start).join(delimiter)
    const suffix = path.split(delimiter).slice(start).join(delimiter)

    const resizePath = `${prefix}/${width}x${height}/${suffix}`

    return `${process.env.REACT_APP_RESIZE_IMAGE_S3_URL}/${resizePath}`
}

export const showDefaultImg = (event, defaultImage) => {
    if (event && event.target) {
        event.target.src = defaultImage  
    }
}

export const checkIfImageExists = (url, callback) => {
    const img = new Image()
    img.src = url

    if (img.complete) {
        callback(true)
    } else {
        img.onload = () => {
            callback(true)
        }
        img.onerror = () => {
            callback(false)
        }
    }
}

export const imageErrorHandler = async (e, imgUrl, placeholderImg, type, timeSuffix) => {
    let fallbackImg = placeholderImg
    showDefaultImg(e, fallbackImg)

    await checkIfImageExists(imgUrl, (exists) => {
        if (exists) {
            if (type === 'profileCover') {
                fallbackImg = imgUrl + timeSuffix
            } else if (type === 'mediaLibrary') {
                fallbackImg = imgUrl
            }
            showDefaultImg(e, fallbackImg)
        } else {
            fallbackImg = placeholderImg
            showDefaultImg(e, fallbackImg)
        }
        return
    })
}
export const uploadImageToMediaLibrary = async (blob, filename) => {
    const formData = new FormData()
    formData.append('images', blob, filename)
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT + 'article/media-library/upload', formData, {
        headers: {
          Authorization: token,
          'Content-Type': 'multipart/form-data',
        },
      })
      return response
    } catch (err) {
      //Return an error
      return err
    }
  }