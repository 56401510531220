// import { createStore, applyMiddleware } from "redux";
// import ReduxThunk from "redux-thunk";
// import reducers from "../reducers";

// const store = createStore(reducers, applyMiddleware(ReduxThunk));

// export default store;

import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "../features/auth/authSlice";
import { usersSlice } from "../features/user/userSlice";
import { adminSlice } from "../features/user/adminSlice";
import { commonSlice } from "../features/common/commonSlice";
import { artworkSlice } from "../features/artworks/artworkSlice"
import { messageSlice } from "../features/messages/messageSlice"

import { articlesSlice } from "../features/articles/articlesSlice"
import { emailTemplateSlice } from "../features/email-templates/emailTemplatesSlice"

export default configureStore({
  reducer: {
    auth: authSlice.reducer,
    users: usersSlice.reducer,
    admins: adminSlice.reducer,
    common: commonSlice.reducer,
    artwork: artworkSlice.reducer,
    message: messageSlice.reducer,
    emailTemplate: emailTemplateSlice.reducer,
    articles:articlesSlice.reducer
  },
});
