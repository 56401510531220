import React from 'react'
import badge from '../assets/images/Mo_Icon.png'
import { isEmptyObj } from './checkEmptyObject';

const renderPremiumBatch = (subscription) => {
  if (!subscription || isEmptyObj(subscription)) return
  if (
    (subscription.title === 'basic' && subscription.role === 'artist') ||
    (subscription.title === 'plus' && subscription.role === 'member')
  )
    return

  return (
    <>
      {' '}
      <img width="17px" height="16px" src={badge} alt="premiumBadge" />
    </>
  )
}
export default renderPremiumBatch