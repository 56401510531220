import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { SOMETING_WENT_WRONG, statusForAdminAcceptence } from '../../../constants/translations'
import { searchEndUser } from '../../../services/endUserManagement'
import { get } from 'lodash';
import { Button, MultiSelectComp, ReactSelect } from '../../shared';
import styles from '../artwork.module.css'
import { useDebouncedValue } from "../../../hooks/debounce";
import { getAllGenres } from '../../../services/artworks';
import { toast } from 'react-toastify';

const GroupSeacrh = (props) => {
    const { applyFilters, allfilters, addFilter } = props
    const [keyword, setKeyword] = useState('')
    const debouncedValue = useDebouncedValue(keyword, 500)
    const [loadingOptions, setLoadingOptions] = useState(false)
    const [options, setOptions] = useState([])

   
    useEffect(() => {
        const getOptions = async () => {
            setLoadingOptions(true)
            const res = await searchEndUser({ searchKeyword: debouncedValue })
            let parsedOptions = []
            if (res.success) {
                parsedOptions = res.data.users.map(({ firstName, _id, lastName }) => ({ label: firstName + ' ' + lastName, value: _id }))
            }
            setOptions(parsedOptions)
            setLoadingOptions(false)
        }
        debouncedValue && getOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue])
    return <div className={styles.filterContainer}>

        <div className={styles.userFilter}>
            <input
                name="title"
                maxLength={40}
                className={`form-control ${styles.inputTitle}`}
                placeholder="Search title"
                value={get(allfilters, 'title', '')}
                onChange={(e) => addFilter({ title: e.target.value })}
                onKeyPress={(e) => { e.key === 'Enter' && applyFilters(allfilters) }}
            />
        </div>

        <div className={styles.userFilter}>
            <input
                name="username"
                maxLength={40}
                className={`form-control ${styles.inputTitle}`}
                placeholder="Search by Username"
                value={get(allfilters, 'username', '')}
                onChange={(e) => addFilter({ username: e.target.value })}
                onKeyPress={(e) => { e.key === 'Enter' && applyFilters(allfilters) }}
            />
        </div>

        <div className={styles.userFilter}>
                <ReactSelect
                    options={[{label: 'Public', value: 'public'}, {label: 'Private', value: 'private'}, {label: 'Hidden', value: 'hidden'},{label: 'Abandoned', value: 'abandoned'}]}
                    onChange={(v) => { localStorage.setItem('groupsType', v.label) ; addFilter({ groupsType: v.value })  }}
                    values={[get(allfilters, 'groupsType', null)]}
                    placeholder="Select Group Type"
                    name="Select Group Type"
                />
            </div>
        <Button clicked={() => applyFilters(allfilters)} className={`btn btn-primary ${styles.filterBtn}`}>Filter</Button>
        <Button clicked={() => applyFilters({}, 'reset')} className="btn btn-light" >Reset</Button></div>
}

GroupSeacrh.defaultProps = {
    applyFilters: () => null,
    addFilter: () => null,
    allfilters: {}

}

GroupSeacrh.propTypes = {
    allfilters: PropTypes.object.isRequired,
    addFilter: PropTypes.func.isRequired,
    applyFilters: PropTypes.func.isRequired,
}

export default GroupSeacrh
