import Select from 'react-select';
import React from 'react'
import PropTypes from "prop-types";

const ReactSelect = (props) => {
    const { values, options, name, onChange, isMulti, onInputChange, loading } = props

    const getSlectedValues = () => {
        const selected = []
        values.forEach(v => {
            const option = options.find(o => o.value === v)
            option && selected.push(option)

        })
        return selected
    }
     
    return <>
        <Select
            className="basic-single"
            classNamePrefix="select"
            isLoading={loading}
            placeholder={name}
            options={options}
            isMulti={isMulti}
            onInputChange={(e) => onInputChange && onInputChange(e)}
            onChange={(v) => onChange(v)}
            value={getSlectedValues()}
            name={name}
        />
    </>
}

ReactSelect.defaultProps = {
    values: [],
    name: '',
    loading: false,
    options: [],
    onChange: () => null,
    isMulti: false,
}

ReactSelect.propTypes = {
    values: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    isMulti: PropTypes.bool.isRequired,
}

export default ReactSelect