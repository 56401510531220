import React, { useEffect, useMemo, useReducer, useState } from 'react'
import ApiReducer, { initialState, events as ApiEvents } from '../../hooks/apiStateHandler'
import { acceptArtwork, getArtwork, updateArtwork } from '../../services/artworks'
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import get from 'lodash-es/get';
import "react-image-gallery/styles/css/image-gallery.css";
import { Button, LoaderComp } from '../shared';
import { fetchGenres, fetchMediums, fetchRejectReasons } from '../../features/artworks/artworkSlice'
import { createOptionsForSelect } from '../../utils/multiselectFunction'
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import EditArtworkComp from './components/edit'
import { toast } from 'react-toastify';
import { SOMETING_WENT_WRONG } from '../../constants/translations';
import { countriesData } from '../../constants/countries'

const ArtworkAdd = styled.div`
    .form-control {
        border-radius: 4px !important;
    }
    .btn-light {
        margin-left: 0px;
        margin-top: 10px;
    }
    .two-col {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .column {
            width: 49%;
        }
    }
    .three-col {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .two-item {
            width: 49%;
        }
        .one-third-item {
            width: 32.3%;
        }
    }
    .mb15 {
        margin-bottom: 15px;
    }
    
    input[type="radio"], input[type="checkbox"] {
            margin-right: 15px;
    }
    .dimension {
        display: flex;
        align-items: center;
        span:first-child {
            margin-right: 10px;
        }
        span:last-child {
            margin-left: 10px;
        }
    }
`

const EditArtwork = (props) => {
    const { match, history } = props
    const dispatch = useDispatch()
    const [artworkState, artworkDispatch] = useReducer(ApiReducer, initialState)
    const loadingGenres = useSelector(state => state.artwork.loadingCounter)
    const allMediums = useSelector(state => state.artwork.allMediums)
    const allGenres = useSelector(state => state.artwork.allGenres)
    const [updating, setUpdating] = useState(false)

    useEffect(() => {
        getArtworkHandler(match.params.artworkId)
        dispatch(fetchRejectReasons())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.artworkId])

    useEffect(() => {
        dispatch(fetchMediums())
        dispatch(fetchGenres())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getArtworkHandler = async (id) => {
        artworkDispatch({ type: ApiEvents.FETCHING })
        const res = await getArtwork({ artworkId: id })
        if (res.data) {
            const artwork = get(res, 'data.artwork', {})
            artworkDispatch({ type: ApiEvents.SUCCESS, data: artwork })

        } else {
            toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
            artworkDispatch({ type: ApiEvents.ERROR, data: [] })
        }
    }

    const updateArtworkHandler = async (v) => {
        const values = v
        delete v.artPhotos
        setUpdating(true)
        const { data, success } = await updateArtwork(values)
        if (success) {
            toast.success(get(data, 'message', ''))
            artworkDispatch({ type: ApiEvents.SUCCESS, data: data?.artwork || {} })
        } else {
            toast.error(SOMETING_WENT_WRONG)
            artworkDispatch({ type: ApiEvents.ERROR })
        }
        setUpdating(false)
    }


    const mediumOptions = useMemo(() => {
        return createOptionsForSelect({ options: allMediums, labelProperty: 'title', valueProperty: '_id', sort: true })
    }, [allMediums,])

    const genreOptions = useMemo(() => {
        return createOptionsForSelect({ options: allGenres, labelProperty: 'title', valueProperty: '_id', sort: true })
    }, [allGenres,])

    const rejectArtwork = async (params) => {
        setUpdating(true)
        const res = await acceptArtwork(params)
        if (res.success) {
            toast.success(get(res, 'data.message'))
            artworkDispatch({ type: ApiEvents.SUCCESS, data: res?.data?.artwork })
        } else {
            toast.error(get(res, 'err.response.data.message') || 'Something went wrong')
        }
        setUpdating(false)
    }

    return <>
        <Breadcrumb title="Artwork" parent="Artworks" />
        <ArtworkAdd>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="BackButtonHeading"><Button clicked={() => history.push('/artworks/list')} className="BackButtonTop"><i title="Back" class="fa fa-angle-left pull-left"></i></Button>{`${get(artworkState, 'data.title', '')}`}</h5>
                            </div>
                            {artworkState.loading || loadingGenres ? <LoaderComp loading /> : <EditArtworkComp
                                rejectArtwork={(p) => rejectArtwork(p)}
                                countriesOptions={countriesData}
                                updating={updating}
                                updateArtwork={(v) => updateArtworkHandler(v)}
                                mediumOptions={mediumOptions}
                                genreOptions={genreOptions}
                                artwork={get(artworkState, 'data', {})}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </ArtworkAdd>
    </>
}

export default EditArtwork