import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import DummyImg from '../../../../assets/images/artmo-default.png'
import { createImageUrl, createResizeImageUrl, imageErrorHandler } from '../../../../utils/imageHelper'
import { Button } from '../../../shared'
import { get } from 'lodash'
import { redirectToClientAppUser } from '../../../../utils/redirects'

const ListingWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 15px;
`
const AboutImg = styled.div`
  width: 150px;
  height: 150px;
  margin: 0 23px 0 0;
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 479px) {
    margin: 0 auto 20px;
  }
`
const AboutDescription = styled.p`
  max-width: calc(100% - 173px);
  font-size: 16px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #222222;
  margin: 0;
  padding: 0;
  .DefaultMsg {
    font-style: italic;
    color: #666;
  }
  @media (max-width: 479px) {
    font-size: 14px;
    max-width: 100%;
    line-height: 20px;
  }
`
const ProfileBtnWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  padding: 0;
  button {
    width: auto;
    height: auto;
    padding: 8px 15px;
    font-family: 'ProximaNova-Regular';
  }
`

const AboutTabsSection = ({ user = {} }) => {
  return (
    <>
      <ListingWrap>
        <AboutImg>
          <img
            src={
              user.profilePicUrl
                ? createResizeImageUrl(user.profilePicUrl, 150, 150, 'profileCover')
                : DummyImg
            }
            onError={(e) => {
              imageErrorHandler(e, createImageUrl(user.profilePicUrl), DummyImg, 'profileCover', '')
            }}
            alt=""
          />
        </AboutImg>
        <AboutDescription>
          {get(user, 'aboutMe.description', null) ? (
            user.aboutMe.description
          ) : (
            <span className="DefaultMsg">No information found</span>
          )}
        </AboutDescription>
        <ProfileBtnWrap>
          <Button
            clicked={() => redirectToClientAppUser(user?.username)}
          >
            View Profile
                    </Button>
        </ProfileBtnWrap>
      </ListingWrap>
    </>
  )
}
AboutTabsSection.propTypes = {
  aboutMe: PropTypes.string,
}
export default AboutTabsSection
