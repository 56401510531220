export const translations = {
  Common: {
    WelcomeTitle: "Welcome to Artmo",
    TitleAdminPanel: "Artmo Admin panel",
    WelcomeText:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
  },
  Validations: {
    Require: "This field is required.",
    InvalidEmailAddress: "Invalid email address",
    PasswordStrength:
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
    EnterConfirmPassword: "Please confirm your password",
    PasswordNotMatch: "New password and confirm password do not match",
  },
  Login: {
    Login: "Login",
    LoginBtn: "Login",
    Email: "Email",
    Password: "Password",
    ConfirmPassword: "Confirm Password",
    InvalidEmail: "Invalid email",
    credentialsMisMatch: "Invalid email or password",
    InvalidEmailPass: "Invalid email or password",
    LoginSuccess: "You have been logged in successfully.",
    ForgotPassword: "Forgot Password",
    SendResetEmail: "Send Reset Email",
    ResetPassword: "Reset Password",
    ResetPasswordBtn: "Reset Password",
    passwordResetSuccess: "Password reset successfully",
    ResetPassSuccess: "Reset password email has been sent successfully.",
    currentPasswordMisMatch: "Current password is not correct!",
    ChangePassSuccess: "Password has been changed successfully.",
  },
  Profile: {
    Profile: "Profile",
    Email: "Email",
    FirstName: "First Name",
    LastName: "Last Name",
    InstitutionName: "Institution Name",
    ChangePassword: "Change Password",
    EditProfile: "Edit Profile",
    Logout: "Logout",
    City: "City",
    Role: "Role",
    DOB: "DOB",
    Gender: "Gender",
    MaritalStatus: "Marital Status",
    ParentUserEmail: 'Personal Account Email',
    CurrentPassword: "Current Password",
    NewPassword: "New Password",
    ConfirmPassword: "Confirm Password",
  },
  User: {
    FirstName: "First Name",
    LastName: "Last Name",
    Email: "Email",
    Role: "Role",
    Language: "Language",
    Save: "Save",
    Close: "Close",
    SearchUser: "Search user by name or email or role",
    Search: "Search",
    Records: "Records",
    Show: "Show",
    Page: "Page",
    First: "First",
    Last: "Last",
    Select: "Select",
    ShowingResults: "results of",
    ShowingFirst: "Showing the first",
    AddAdminUser: "Add Admin User",
    EditAdminUser: "Edit Admin User",
    AdminUsersList: "Admin Users List",
    AlreadyExist: "User already exist",
    DeleteConfirm: "Are you sure to delete this user? Action can not be undo.",
  },
  Settings: {
    Settings: "Settings",
    Close: "Close",
    CategoryTitle: "Category Title",
    ParentCategory: "Parent Category",
    VideoCategories: "Video Categories",
    GroupCategories: "Group Categories",
    MasterClassCategories: "MasterClass Categories",
    Tags: "Tags",
    EnableCategory: "Enable Category",
    DisableCategory: "Disable Category",
    EditCategory: "Edit Category",
    SaveCategory: "Save Category",
    DeleteCategory: "Delete Category",
    TagTitle: "Tag Title",
    EnableTag: "Enable Tag",
    DisableTag: "Disable Tag",
    EditTag: "Edit Tag",
    SaveTag: "Save Tag",
    DeleteTag: "Delete Tag",
    VendorSubscriptions: "Vendor Subscription Plan",
    EditSubscription: "Edit Subscription",
    Title: "Title",
    Save: "Save",
    Price: "Price",
    Commission: "Commission",
    PublishArtworks: "Publish Artworks",
    Description: "Description",
    EnableSubscription: "Enable Subscription",
    DisableSubscription: "Disable Subscription",
    UpdatePlanSettings: "Update Plan Settings",
    PublishAndSell: "Publish and Sell",
    PublishUpto: "up to ",
    Artworks: "artworks",
    Get: "GET",
    SelectRole: "Select Role",
    SubscriptionCategories: "Subscription Categories",
    EnableSubscriptionCategory: "Enable Subscription Category",
    DisableSubscriptionCategory: "Disable Subscription Category",
    Enable: "Enable",
    Disable: "Disable",
    For: "for",
    Unlimited: "Unlimited",
    Subscription: "Subscription",
  },
  Menu: {
    Dashboard: "Dashboard",
    AdminList: "Admin List",
    UserList: "User List",
    ContentManagment: "Content Managment",
    SiteSettings: "Site Settings",
    VideoCategories: "Video Categories",
    GroupCategories: "Group Categories",
    MasterclassCategories: "Masterclass Categories",
    GroupTags: "Group Tags",
    RejectReasons: "Reject Reasons",
    SuspensionReasons: "Suspension Reasons",
    ResponseMessage: "Response [help]",
    ResponseMessageArt: "Response [art]",
    ListVendorSubscriptionPlan: "Vendor Subscription Plan",
    UpdatePlanSettings: "Update Subscription Plan Settings",
    AccountSettings: "Account Settings",
    Profile: "Profile",
    ChangePassword: "Change Password",
    HelpDesk: "Help Desk",
    ArtworkDesk: "Artwork Desk",
    SocialActivities: "Social Activities",
    AllPosts: "All Posts",
    SEOsettings: "SEO settings",
    AutomatedEmails: "Automated emails",
    EmailTemplates: "Email Templates",
    MasterClass: "Masterclass",
    MasterProfile: "Instructor",
    MasterClassList: "Masterclass List",
    StreamChannels: "Streaming Channels"

  },
  Dashboard: {
    Dashboard: "Dashboard",
    Title: "Dashboard",
    Welcome: "Welcome to dashboard",
  },
  NotificationTypes: {
    pageHeading: "Notification Types",
    btnTitle: "Add Notification Type",
    modalHeading: "Add title for new Notification Type",
    inputTitle: "Notification Type Title",
    errorMessage: "No spaces allowed!!"
  },
  AddNewContent: {
    pageHeading: "Content Management",
    btnTitle: "Add New Content",
    modalHeading: "Add unique code for new content",
    inputTitle: "Add Unique Code",
    errorMessage: "No spaces allowed!!"
  },
  AddGenre: {
    pageHeading: "Add Genre",
    btnTitle: "Add Genre",
    modalHeading: "Add title for new genre",
    inputTitle: "Genre Title",
    inputDescription: "Genre Description",
    errorMessage: "No spaces allowed!!"
  },
  StaticMessage: {
    pageHeading: "Static Messages",
    btnTitle: "Add Static Message",
    modalHeading: "Add title for new Static Message",
    inputTitle: "Static Message Title",
    errorMessage: "No spaces allowed!!"
  },
  Subscription: {
    amount: "Amount",
    period: "Period",
    title: "Title",
    subscriptionDate: "Subscription Date"
  },
  MasterProfile: {
    modalHeading:'New Instructor',
    AddNewProfile:'Submit',
    firstName: "Firstname",
    lastName: "Lastname",
    username: "Username",
    description: "About Master",
    btnTitle:'Add New Instructor',
    errorMessage:'is required'
  },
  Artworks: {
    "label": {
      "unique": "This artwork is unique",
      "edition": "This artwork is part of an edition",
      "creatorOfArtwork": "I am the creator and owner of this artwork",
      "partOfSeries": "This artwork is part of a series",
      "selectTimePeriod": "Select Time period",
      "ancient": "Ancient",
      "medieval": "Medieval",
      "renaissance": "Renaissance",
      "romanticism": "Romanticism",
      "modern": "Modern",
      "contemporary": "Contemporary",
      "or": "or",
      "priceOnRequest": "Price on Request",
      "2D": "2D",
      "3D": "3D",
      "addFrame": "Add Frame",
      "medium": "MEDIUM",
      "genre": "GENRE",
      "subject": "SUBJECT",
      "freeShipping": "Free Shipping",
    },
    "placeholders": {
      "title": "Title",
      "country": "Country (leave blank if your own)",
      "year": "Year",
      "artMaterial": "Art Material",
      "description": "Description",
      "selfNote": "Self Note",
      "editionNo": "Edition(no.)",
      "editionTotal": "Edition(total)",
      "firstNameArtist": "First name of Artist",
      "lastNameArtist": "Last name of Artist",
      "seriesName": "Series name",
      "price": "Price (EUR)",
      "length": "Length (cm)",
      "width": "Width (cm)",
      "height": "Height (cm)",
      "frameLength": "Frame length",
      "frameWidth": "Frame width",
      "medium": "Select mediums",
      "genre": "Select genres",
      "subject": "Enter subjects. Separate by a comma or hit enter after each subject",
      "internationalPrice": "International Shipping Price (EUR)",
      "domesticPrice": "Domestic Shipping Price (EUR)",
      "frameMaterial": "Frame Material"
    },
    "validationErrors": {
      artMaterialRequired: "Artwork material is required",
      artistFirstNameRequired: "Artist first name is required",
      artistLastNameRequired: "Artist last name is required",
      artworkPhotosRequired: "Artwork photos are required",
      artworkTypeRequired: "Artwork type is required",
      domesticPriceRequired: "Domestic Shipping Price is required",
      editionNoRequired: "Edition no. is required",
      editionTotalRequired: "Edition total is required",
      frameLengthRequired: "Frame length is required",
      frameMaterialRequired: "Frame material is required",
      frameWidthRequired: "Frame width is required",
      heightRequired: "Height is required",
      internationalPriceRequired: "International Shipping Price is required",
      invalidYear: "Invalid year",
      lengthRequired: "Length is required",
      minimumPrice: "Enter price greater than €50",
      priceRequired: "Price is required",
      seriesNameRequired: "Series name is required",
      timePeriodRequired: "Time Period is required",
      titleMaxLength: "Title can contain maximum of 250 characters",
      titleRequired: "Title is required",
      widthRequired: "Width is required",
    },
  }
};

export const statusForAdminAcceptence = {
  0: 'Pending for review',
  1: 'Accepted',
  '-1': 'Rejected'
}

export const SOMETING_WENT_WRONG = "Something went wrong"
export const TOTAL_RECORDS = 'Total records: '
export const ALREADY_PRESENT = "Genre Already Present"