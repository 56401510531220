import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCheckSlug } from '../../services/artworks'

const initialState = {};
/**
 * Fetch genres list
 */
export const checkSlug = createAsyncThunk(
    "aticles/checkSlug",
    async (params, thunkAPI) => {
        try {
            const response = await getCheckSlug(params)
            const { data, success, err } = response;
            if (success) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(err);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const articlesSlice = createSlice({
    name: "articles",
    initialState,
    reducers: {},
    extraReducers: {},
});

export default articlesSlice.reducer;

