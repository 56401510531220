import React from "react";
import { Route, Redirect } from "react-router-dom";

function PrivateRoute({ component: Component, roles, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          localStorage.getItem("user") &&
          localStorage.getItem("user") !== null &&
          localStorage.getItem("user") !== undefined
        ) {
          // logged in so return component
          return <Component {...props} />;
        }

        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: "/auth/login", state: { from: props.location } }}
          />
        );
      }}
    />
  );
}

export { PrivateRoute };
