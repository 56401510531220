import React, { useMemo, useState } from 'react'
import {
    ImgWrapper,
    ArtworkImageSectionWrap,
    ArtworkImageLeftIcons,
    ArtworkIconWrap,
    MagnifierDiv,
    SectionRow,
    ImgSmContainer
} from '../../../styled'
import get from 'lodash/get'
import { IoPricetagSharp } from 'react-icons/io5'
import { BiSearch } from 'react-icons/bi'
import { RiEditBoxFill, RiDeleteBin6Line } from 'react-icons/ri'
import { SRLWrapper, useLightbox } from 'simple-react-lightbox'
import { SideBySideMagnifier, MOUSE_ACTIVATION, TOUCH_ACTIVATION } from 'react-image-magnifiers'
import { createImageUrl, createResizeImageUrl, imageErrorHandler } from '../../../../utils/imageHelper'
import CoverPhoto from '../../../../assets/images/artworkdemo.png'
import { EditArtworkModal } from '../../../modals'
import { openInNewTab } from '../../../../utils/anchor'

const lightboxOptions = {
    settings: {
        disableWheelControls: true,
    },
    buttons: {
        showAutoplayButton: false,
        showDownloadButton: false,
        showThumbnailsButton: false,
    },
}

const ArtworkImageViewer = (props) => {
    const { artPhotos, artwork, rejectReasons, rejectArtwork, updateArtworkHandler, updating, deleteArtwork } = props
    const { openLightbox } = useLightbox()
    const [enlargedImage, setEnlargedImage] = useState(0)
    const [showModalBox, setShowModalBox] = useState(false)

    const imagesArr = useMemo(() => artPhotos.map((i) => ({ src: createImageUrl(i.pictureUrl) })), [artPhotos])

    const onSaveHandler = (v) => {
        const { moderated, rejectDescription, rejectedReasons } = v
        if (moderated === -1) {
            rejectArtwork({ artworkId: artwork._id, accept: -1, isDrafted: false, rejectDescription, rejectedReasons })
        } else {
            updateArtworkHandler({ ...v, moderated: 1, _id: artwork._id, isDrafted: false, rejectDescription: "", rejectedReasons: [] })
        }
    }

    const deleteHandler = () => {
        if (
            window.confirm(
                "Are you sure to perform this action?"
            )) {
            deleteArtwork(artwork._id)
        }
    }

    return <ImgWrapper>
        {showModalBox && <EditArtworkModal
            isOpen
            saving={updating}
            reasons={rejectReasons || []}
            artwork={artwork}
            modalTitle="Edit Artwork"
            onCloseModal={() => setShowModalBox(false)}
            submitHandler={(v) => onSaveHandler(v)}
        />}
        <ArtworkImageSectionWrap>
            <ArtworkImageLeftIcons>
                <ArtworkIconWrap onClick={() => setShowModalBox(true)} title="Quick Edit">
                    <IoPricetagSharp />
                </ArtworkIconWrap>
                <ArtworkIconWrap title="Edit">
                    <RiEditBoxFill onClick={() => openInNewTab(`/artworks/edit/${artwork._id}`)} />
                </ArtworkIconWrap>
                <ArtworkIconWrap title="Delete">
                    <RiDeleteBin6Line onClick={() => deleteHandler()} />
                </ArtworkIconWrap>
            </ArtworkImageLeftIcons>
            <MagnifierDiv>
                <BiSearch onClick={() => openLightbox(0)} />
            </MagnifierDiv>
            <SideBySideMagnifier
                className="magnifierDiv"
                imageSrc={createImageUrl(get(artPhotos, `[${enlargedImage}].pictureUrl`, ''))}
                imageAlt="artworkEnlargedImage"
                alwaysInPlace={true}
                cursorStyle="zoom-in"
                mouseActivation={MOUSE_ACTIVATION.HOVER}
                touchActivation={TOUCH_ACTIVATION.TAP}
                largeImageSrc={createResizeImageUrl(
                    get(artPhotos, `[${enlargedImage}].pictureUrl`, ''),
                    400,
                    'auto',
                    'mediaLibrary'
                )}
            />
        </ArtworkImageSectionWrap>
        <SectionRow className="InnerSecRow">
            {artPhotos &&
                artPhotos.map((image, index) => {
                    if (image && image.pictureUrl) {
                        return (
                            <ImgSmContainer key={index} onClick={() => setEnlargedImage(index)}>
                                <img
                                    src={createResizeImageUrl(image.pictureUrl, 200, 200, 'mediaLibrary')}
                                    onError={(e) => {
                                        imageErrorHandler(e, createImageUrl(image.pictureUrl), CoverPhoto, 'mediaLibrary', '')
                                    }}
                                    alt="artworkImage"
                                />
                            </ImgSmContainer>
                        )
                    } else {
                        return ''
                    }
                })}
        </SectionRow>
        <SRLWrapper elements={imagesArr} options={lightboxOptions} />
    </ImgWrapper>
}

export default ArtworkImageViewer
