import React from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import { Button } from '../shared'
import { suspensionReasonValidationSchema } from '../common/validations'
import { Editor } from "@tinymce/tinymce-react";
import { replaceWordpressImage } from "../../utils/image";
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
    },
};


const SuspensionReasonsModal = (props) => {
    const {
        mopen,
        onCloseModal,
        submitHandler,
        title,
        values,
        loading
    } = props


    const formik = useFormik({
        initialValues: { ...values },
        validationSchema: () => suspensionReasonValidationSchema,
        onSubmit: v => {
            submitHandler(v)
        },
    });

    const { title: reasonTitle, description } = formik.values

    return (
        <Modal
            isOpen={mopen}
            onClose={onCloseModal}
            onRequestClose={onCloseModal}
            style={customStyles}
            className="ModalContent"
            overlayClassName="OverlayContent"
        >
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    {title}
                </h5>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <label htmlFor="firstName">Title</label>
                    <input value={reasonTitle} className="form-control" id="title" maxLength={100} onChange={formik.handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="firstName">Description</label>
                    {/* <textarea
                        className="form-control textareaExpand"
                        as="textarea"
                        onChange={formik.handleChange}
                        rows={10}
                        cols={20}
                        id="description"
                        value={description}
                        name="description"
                        maxLength={500}
                        placeholder="Type description here"
                    /> */}
                       <Editor
                    apiKey={process.env.REACT_APP_TINY_MCE}
                    // initialValue={initialValue}
                    value={replaceWordpressImage(description)}
                    init={{
                      height: 500,
                      menubar: false,
                      target: false,
                      media_live_embeds: true,
                      plugins:
                          'visualblocks insertdatetime table help wordcount advlist autolink quickbars lists link image charmap print preview anchor searchreplace code fullscreen media paste code placeholder mediaembed',
          
                      quickbars_insert_toolbar: 'quickimage',
                      quickbars_selection_toolbar: 'bold italic underline h2 h3',
                      toolbar1:
                      'bold italic underline h1 h2 h3 removeformat alignleft aligncenter alignright alignjustify numlist blockquote media image link',
                      toolbar2: " selectall strikethrough subscript visualaid	copy	cut fontselect fontsizeselect lineheight paste remove ",
                    }}
                    onEditorChange={(newValue, editor) => {
                        formik.setFieldValue('description', newValue);
                    }}
                  ></Editor>
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="firstName">{formik?.errors?.selectedReasons}</label>
            </div>
            <div className="modal-footer">
                <Button loading={loading} type="submit" clicked={formik.handleSubmit} className="btn btn-primary">
                    Save
                        </Button>
                <Button
                    type="button"
                    className="btn btn-light"
                    clicked={() => onCloseModal()}
                >
                    Close
                        </Button>
            </div>
        </Modal >
    );
};

export default SuspensionReasonsModal;