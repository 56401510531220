import React, { Component, Fragment } from "react";
import Breadcrumb from "./common/breadcrumb";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { translations } from "../constants/translations";
export class Dashboard extends Component {
  render() {
    return (
      <Fragment>
        <ToastContainer autoClose={2000} />
        <Breadcrumb
          title={translations.Dashboard.Dashboard}
          parent="Dashboard"
        />
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h5>{translations.Dashboard.Title}</h5>
            </div>
            <div className="card-body">
              <h6>{translations.Dashboard.Welcome}</h6>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Dashboard;
