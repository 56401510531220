import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import DataTable from "react-data-table-component";
import { unwrapResult } from "@reduxjs/toolkit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import Switch from 'react-switch'
import { TOTAL_RECORDS, translations } from "../../constants/translations";
import ModalVideoCategory from "./video-category-modal.js";
import {
  commonSelector,
  clearCommonState,
  fetchAllVideoCategories,
  updateVideoCategory,
  getAllVideoCategories,
  enableDisableVideoCategory,
} from "../../features/common/commonSlice";
import { LoaderComp } from "../shared";

const ContentManagment = () => {
  const dispatch = useDispatch();
  const [mopen, setMopen] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryData, setCategoryData] = useState([]);
  const videoCategories = useSelector(getAllVideoCategories);
  const totalRows = useSelector(state => state.common.totalVideoCategories)
  const fetchCategoryStatus = useSelector(
    (state) => state.common.categoryStatus
  );
  const { isSuccess, isError, successMessage, errorMessage } =
    useSelector(commonSelector);

  const columns = [
    {
      name: "Title",
      selector: "title",
      sortable: true,
    },
    {
      name: "Parent Category",
      selector: "parentName",
      sortable: true,
    },
    {
      // eslint-disable-next-line react/button-has-type
      cell: (row) => (
        <>
          <span
            title="Edit Category"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleEdit(row);
            }}
          >
            <i
              className="fa fa-pencil"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "rgb(0, 123, 255)",
              }}
            ></i>
          </span>
          <Switch
            checked={!row.isDeleted}
            onChange={() => handleEnableDisable(row._id)}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="material-switch"
          />
        </>
      ),
    },
  ];

  /**
   * Fetch categories data
   * @param {*} params
   */
  const fetchCategoriesData = (params) => {
    dispatch(fetchAllVideoCategories(params))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setData(originalPromiseResult.categories);
        setLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        toast.error(rejectedValueOrSerializedError);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    const params = {
      page: currentPage,
      pageSize: perPage,
    };
    fetchCategoriesData(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (page) => {
    fetchCategoriesData({ page: page });
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchCategoriesData({ page: page, pageSize: newPerPage });
    setPerPage(newPerPage);
  };
  /**
   * Handle edit/view buttons
   * @param {*} categoryId
   */
  const handleEdit = (c) => {
    setCategoryId(c._id);
    setCategoryData(c);
    setMopen(true);
  };

  const handleEnableDisable = (id) => {
    dispatch(enableDisableVideoCategory({ categoryId: id }));
    let clonned = [...data]
    const updatedCategoryIndex = data.findIndex(o => o?._id === id)
    if (updatedCategoryIndex !== -1) {
      clonned[updatedCategoryIndex] = { ...data[updatedCategoryIndex], isDeleted: !clonned[updatedCategoryIndex].isDeleted }
      setData(clonned)
    }
    // fetch update page
    // fetchCategoriesData({ page: currentPage, pageSize: perPage });
  };

  const onCloseModal = () => {
    setMopen(false);
  };
  /**
   * Submit editor handler
   * @param {*} languageId
   * @param {*} content
   */
  const submitHandler = (params) => {
    setLoading(true);
    if (categoryId !== "" && categoryId !== undefined) {
      params.selectedCategoryId = categoryId;
    }
    dispatch(updateVideoCategory(params))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // fetch update page
        fetchCategoriesData({ page: currentPage, pageSize: perPage });
        setLoading(false);
        setMopen(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        toast.error(rejectedValueOrSerializedError.messageCode);
        setLoading(false);
        setMopen(false);
      });
  };

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearCommonState());
    }

    if (isSuccess) {
      toast.success(successMessage);
      dispatch(clearCommonState());
    }
  }, [isError, isSuccess, errorMessage, successMessage, dispatch]);

  return (
    <Fragment>
      <ToastContainer autoClose={2000} />
      <Breadcrumb
        title={translations.Settings.VideoCategories}
        parent={translations.Settings.Settings}
      />
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <h5 className="BackButtonHeading">
              {translations.Settings.VideoCategories}
            </h5>
          </div>
          <div style={{ margin: "15px 30px 15px" }}>
            {!loading && (TOTAL_RECORDS + totalRows || 0)}
          </div>
          <div className="card-body">
            <ModalVideoCategory
              mopen={mopen}
              loading={loading}
              categoryId={categoryId}
              videoCatList={data}
              categoryData={categoryData}
              submitHandler={submitHandler}
              onCloseModal={onCloseModal}
            />

            <DataTable
              defaultSortField="uniqueContentId"
              className="-striped -highlight"
              columns={columns}
              data={data}
              progressComponent={<LoaderComp loading />}
              progressPending={loading}
              pagination
              paginationServer
              paginationPerPage={perPage}
              numberOfPages={Math.floor(totalRows / perPage)}
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              paginationComponentOptions={{ noRowsPerPage: true }}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ContentManagment;
