import React from "react";
import Modal from "react-responsive-modal";
import { capitalizeFirstLetter } from "../common/random-functions";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { adminAddValidationSchema } from "../common/validations";
import styled from "styled-components";
import { translations } from "../../constants/translations";
import ArrowIcon from '../../../src/assets/images/down-arrow.png'
import { Button } from '../shared'

const AddAdminDialog = styled.div`
  select {
      appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
      background: url(${ArrowIcon}) no-repeat right;
      background-position-x: 96.5%;
  }
`

const ModalAddAdminUser = (props) => {
  const {
    mopen,
    errorMessage,
    userId,
    userData,
    userDetails,
    languages,
    adminRoles,
    onOpenModal,
    onCloseModal,
    submitHandler,
    updating
  } = props;

  let adminRolesList = adminRoles.filter(item => item.roleName !== 'user');
  if (userDetails.roleName !== "superadmin")
    adminRolesList = adminRolesList.filter(
      (item) => item.roleName !== "superadmin"
    );

  return (
    <div className="btn-popup pull-right">
      <button
        type="button"
        className="btn btn-primary"
        onClick={onOpenModal}
        data-toggle="modal"
        data-original-title="test"
        data-target="#exampleModal"
      >
        {translations.User.AddAdminUser}
      </button>
      <Modal open={mopen} onClose={onCloseModal} focusTrapped={false}>
        <div className="modal-header">
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
            {userId ? translations.User.EditAdminUser : translations.User.AddAdminUser}
          </h5>
        </div>
        <div className="modal-body">
          <Formik
            initialValues={{
              firstName: userData.length !== 0 ? userData.firstName : "",
              lastName: userData.length !== 0 ? userData.lastName : "",
              email: userData.length !== 0 ? userData.email : "",
              role: userData.length !== 0 ? userData.roleId._id : "",
              language: userData.length !== 0 ? userData.language : "",
            }}
            validationSchema={adminAddValidationSchema}
            onSubmit={(values, { resetForm }) => {
              submitHandler(values);
              {
                /* resetForm({}); */
              }
            }}
          >
            <AddAdminDialog>
              <Form id="frmAddAdmin">
                <div className="form-group">
                  <label htmlFor="firstName">{translations.User.FirstName} <span className="required">*</span></label>
                  <Field
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    placeholder="Jane"
                    maxLength={40}
                  />
                  <ErrorMessage
                    component="div"
                    className="alert alert-danger"
                    name="firstName"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="lastName">{translations.User.LastName} <span className="required">*</span></label>
                  <Field
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    placeholder="Doe"
                    maxLength={40}
                  />
                  <ErrorMessage
                    component="div"
                    className="alert alert-danger"
                    name="lastName"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">{translations.User.Email} <span className="required">*</span></label>
                  <Field
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="jane.doe@example.com"
                    type="email"
                    maxLength={50}
                    disabled={userId}
                  />
                  <ErrorMessage
                    component="div"
                    className="alert alert-danger"
                    name="email"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="language">{translations.User.Language} <span className="required">*</span></label>
                  <Field name="language" as="select" className="form-control">
                    <option value="">{`${translations.User.Select} ${translations.User.Language}`}</option>
                    {languages.map((lang, index) => {
                      return (
                        <option key={index} value={lang.title}>
                          {lang.title}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    component="div"
                    className="alert alert-danger"
                    name="language"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="role">{translations.User.Role} <span className="required">*</span></label>
                  <Field name="role" as="select" className="form-control">
                    <option value="">{`${translations.User.Select} ${translations.User.Role}`}</option>
                    {adminRolesList.map((role, index) => {
                      return (
                        <option key={index} value={role._id}>
                          {capitalizeFirstLetter(role.roleName)}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    component="div"
                    className="alert alert-danger"
                    name="role"
                  />
                </div>

                {errorMessage && (
                  <div className="form-group">
                    <div className="alert alert-danger">{errorMessage}</div>
                  </div>
                )}

                <Button type="submit" loading={updating} loadingText="Saving" className="btn btn-primary">
                  {translations.User.Save}
                </Button>
                <Button
                  type="button"
                  className="btn btn-light"
                  clicked={() => onCloseModal("VaryingMdo")}
                >
                  {translations.User.Close}
                </Button>
              </Form>
            </AddAdminDialog>
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAddAdminUser;
