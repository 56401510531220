import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { User, LogOut, Lock } from "react-feather";

import { translations } from "../../../constants/translations";

//images import
import man from "../../../assets/images/dashboard/man.png";
export class User_menu extends Component {
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("authenticated");
  }

  render() {
    return (
      <Fragment>
        <li className="onhover-dropdown">
          <div className="media align-items-center">
            <img
              className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
              src={man}
              alt="header-user"
            />
          </div>
          <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
            <li>
              <Link to={`/accountsettings/profile`}>
                <User />
                {translations.Profile.Profile}
              </Link>
            </li>
            <li>
              <Link to={`/accountsettings/change-password`}>
                <Lock />
                {translations.Profile.ChangePassword}
              </Link>
            </li>
            <li>
              <Link to={`/`} onClick={this.logout}>
                <LogOut />
                {translations.Profile.Logout}
              </Link>
            </li>
          </ul>
        </li>
      </Fragment>
    );
  }
}

export default User_menu;
