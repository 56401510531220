import React from "react";
import Modal from "react-modal";
import { translations } from "../../constants/translations";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { videoCategoryValidationSchema } from "../common/validations";
import { Button } from '../shared'

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
  },
};
Modal.setAppElement("#root");

const ModalVideoCategory = (props) => {

  const {
    mopen,
    categoryData,
    videoCatList,
    onCloseModal,
    submitHandler,
    loading
  } = props;

  return (
    <div className="btn-popup pull-right">
      <ToastContainer autoClose={2000} />
      <Modal
        focusTrapped={false}
        id="modalContent"
        isOpen={mopen}
        onRequestClose={onCloseModal}
        className="ModalContent"
        style={customStyles}
        overlayClassName="OverlayContent"
      >
        <div className="modal-header">
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
            {translations.Settings.EditCategory}
          </h5>
        </div>
        <div className="modal-body">
          <Formik
            initialValues={{
              categoryTitle: categoryData.title ? categoryData.title : "",
              parentCategory: categoryData.categoryId
                ? categoryData.categoryId !== null
                  ? categoryData.categoryId._id
                  : categoryData.categoryId
                : "",
            }}
            validationSchema={videoCategoryValidationSchema}
            onSubmit={(values, { resetForm }) => {
              submitHandler(values);
              resetForm({});
            }}
          >
            <Form id="frmAddAdmin">
              <div className="form-group">
                <label htmlFor="firstName">
                  {translations.Settings.CategoryTitle}
                </label>
                <Field
                  className="form-control"
                  id="categoryTitle"
                  name="categoryTitle"
                  placeholder="Example..."
                  maxLength={30}
                />
                <ErrorMessage
                  component="div"
                  className="alert alert-danger"
                  name="categoryTitle"
                />
              </div>

              <div className="form-group">
                <label htmlFor="parentCategory">
                  {translations.Settings.ParentCategory}
                </label>
                <Field
                  name="parentCategory"
                  as="select"
                  className="form-control"
                >
                  <option value="">Select Category</option>
                  {videoCatList.map((vidCat, index) => {
                    return (
                      <option key={index} value={vidCat._id}>
                        {vidCat.title}
                      </option>
                    );
                  })}
                </Field>
                <ErrorMessage
                  component="div"
                  className="alert alert-danger"
                  name="lastName"
                />
              </div>
              <Button loading={loading} loadingText="Updating" type="submit" className="btn btn-primary" > {translations.Settings.SaveCategory}</Button>
              <Button type="button"
                className="btn btn-light"
                clicked={() => onCloseModal("VaryingMdo")}> {translations.Settings.Close}</Button>
            </Form>
          </Formik>
        </div>
        <div className="modal-footer"></div>
      </Modal>
    </div>
  );
};

export default ModalVideoCategory;
