import React, { useState} from "react";
import Modal from "react-responsive-modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import styled from "styled-components";
import { translations } from "../../constants/translations";
import ArrowIcon from '../../../src/assets/images/down-arrow.png'
import { Button } from '../shared'

const AddAdminDialog = styled.div`
  select {
      appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
      background: url(${ArrowIcon}) no-repeat right;
      background-position-x: 96.5%;
  }
  & .errorDiv {
    color: red ; 
  }
`

const ModalAddNotificationsType = (props) => {
  const {
    mopen,
    onOpenModal,
    onCloseModal,
    submitHandler,
    updating
  } = props;

  const [ title, setTitle ] = useState('') ;
  const [ showError, setShowError ] = useState( false  )
  const [ btnDisabled, setButtonDisabled ] = useState( true  )

  const onChangeHandler = (e) => {
    const inputValue = e.target.value
    if ( inputValue !== '' ) {
      if ( inputValue.indexOf(' ') >= 0){
        setShowError( true )
        setButtonDisabled( true)
        setTitle( inputValue )
      } else {
        setTitle( inputValue )
        setShowError( false )
        setButtonDisabled( false )
      }
    } else {
      setButtonDisabled(true)
      setTitle('')
    }
      
    
    
    
  }

  return (
    <div className="btn-popup pull-right">
      <button
        type="button"
        className="btn btn-primary"
        onClick={onOpenModal}
        data-toggle="modal"
        data-original-title="test"
        data-target="#exampleModal"
      >
        {translations.NotificationTypes.btnTitle}
      </button>
      <Modal open={mopen} onClose={onCloseModal} focusTrapped={false}>
        <div className="modal-header">
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          {translations.NotificationTypes.modalHeading}
          </h5>
        </div>
        <div className="modal-body">
          <Formik
            initialValues={{
              title: "",
            }}
            onSubmit={(values, { resetForm }) => {
              submitHandler(title);
              {
                /* resetForm({}); */
              }
            }}
          >
            <AddAdminDialog>
              <Form id="frmAddAdmin">
                <div className="form-group">
                  <label htmlFor="title">{translations.NotificationTypes.inputTitle}<span className="required">*</span></label>
                  <Field
                    className="form-control"
                    id="title"
                    name="title"
                    placeholder="Notification Type"
                    maxLength={40}
                    onChange={onChangeHandler}
                    value={title}
                  />
                  { showError && ( <div className="errorDiv">{translations.NotificationTypes.errorMessage}</div> )}
                </div>
                <Button type="submit" loading={updating} loadingText="Saving" disabled={btnDisabled} className="btn btn-primary">
                  Submit
                </Button>
                <Button
                  type="button"
                  className="btn btn-light"
                  clicked={() => onCloseModal("VaryingMdo")}
                >
                  Close
                </Button>
              </Form>
            </AddAdminDialog>
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAddNotificationsType;
