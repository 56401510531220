import React, { useEffect, useReducer, useState } from 'react'
import Breadcrumb from "../common/breadcrumb";
import { TableComp, Button } from '../shared';
import get from 'lodash/get'
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../hooks/apiStateHandler'
import moment from "moment";
import { EditResponseMessage } from '../modals';
import { toast } from 'react-toastify';
import { SOMETING_WENT_WRONG, TOTAL_RECORDS } from '../../constants/translations';
import { getMessageResponseArt, updateMessageResponseArt, insertMessageResponseArt,deleteMessageResponseArt } from '../../services/messageResponseArt';

const editValuesInitial = { showModal: false, values: {}, updating: false, error: null }
const EmailTemplatesList = (props) => {
    const [responseMessageState, responseMessageDispatch] = useReducer(ApiStateReducer, initialState)
    const [editValues, setEditValues] = useState(editValuesInitial)
    useEffect(() => {
        getResponseMessageHandler({})
    }, [])

    const columns = [
        {
            name: "Title",
            selector: "title",
            sortable: true,
        },
        {
            name: "Date",
            sortable: true,
            selector: "dateUpdated",
            cellRenderer: (row) => (<div >{moment(row.dateUpdated).format("DD MMM, YYYY h:mm:ss a")}</div>)
        },
        {
            name: 'Actions',
            cellRenderer: (row) => (<>
                                 <span
            style={{ cursor: "pointer" }}
            title="Edit Reason"
            onClick={() => setEditValues({ showModal: true, values: row })}
          >
            <i
              className="fa fa-pencil"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "rgb(0, 123, 255)",
              }}
            ></i>
          </span>
                {/* <Button title="Edit Message" clicked={() => setEditValues({ showModal: true, values: row })} className="btn btn-primary m-10">Edit</Button> */}
            </>)
        },
        {
            name: 'Delete',
            cellRenderer: (row) => (<>
                                 <span
            style={{ cursor: "pointer" }}
            title="Edit Reason"
            onClick={() => responseMessageDeleteHandler(row._id)}
          >
            <i
              className="fa fa-trash"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "red",
              }}
            ></i>
          </span>
                {/* <Button title="Edit Message" clicked={() => setEditValues({ showModal: true, values: row })} className="btn btn-primary m-10">Edit</Button> */}
            </>)
        }
    ]

    const getResponseMessageHandler = async (params) => {
        try {
            responseMessageDispatch({ type: ApiStateEvents.FETCHING })
            const res = await getMessageResponseArt(params)
            if (res.success) {
                responseMessageDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.responses', []), totalRecords: get(res, 'data.total', 0) })
            } else {
                responseMessageDispatch({ type: ApiStateEvents.ERROR, data: [] })
            }
        } catch (error) {
            responseMessageDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }


    const blankResponseMessageHandler = async (params) => {
        try {
            responseMessageDispatch({ type: ApiStateEvents.FETCHING })
            const res = await insertMessageResponseArt(params)
            if (res.success) {
                responseMessageDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.responses', []), totalRecords: get(res, 'data.total', 0) })
            } else {
                responseMessageDispatch({ type: ApiStateEvents.ERROR, data: [] })
            }
        } catch (error) {
            responseMessageDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }

    const onSort = ({ fieldName, sortOrder }) => {
        responseMessageDispatch({
            type: ApiStateEvents.ADD_SORTING, sorting: {
                sortField: fieldName,
                sortOrder: sortOrder,
            }
        })
        getResponseMessageHandler({
            sortField: fieldName,
            sortOrder: sortOrder,
        })
    }

    const responseMessageUpdateHandler = async (values) => {
        try {
            setEditValues({ ...editValues, updating: true })
            const res = await updateMessageResponseArt({ ...values })
            if (res.success) {
                const clonned = get(responseMessageState, 'data', [])
                const index = clonned.findIndex(o => o._id === values._id)
                if (index !== -1 && res.data.responseMsg) {
                    clonned[index] = res.data.responseMsg
                    toast.success(get(res, 'data.message', ''))
                    //console.log({ clonned })
                    responseMessageDispatch({ type: ApiStateEvents.SUCCESS, data: clonned })
                    setEditValues(editValuesInitial)
                } else {
                    toast.error(SOMETING_WENT_WRONG)
                }
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
        }
    }
    const responseMessageDeleteHandler = async (_id) => {
        try {
            const res = await deleteMessageResponseArt({ _id })
            if (res.success) {
                const clonned = await get(responseMessageState, 'data', [])
                let data =await clonned.filter(x => x._id !== _id);
                responseMessageDispatch({ type: ApiStateEvents.SUCCESS, data: data })
                toast.success(get(res, 'data.message', ''))
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
        }
    }
    return <>
        <Breadcrumb title='Response Messages' />
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h5 className="BackButtonHeading">
                    Response Messages
                    </h5>
                </div>
                <div style={{ margin: "15px 30px 15px" }}>
                    {!get(responseMessageState, 'loading') && TOTAL_RECORDS + get(responseMessageState, 'totalRecords', 0)}
                </div>
                <div>
                    {!get(responseMessageState, 'loading') && 
                      <span
                      margin
                      style={{ cursor: "pointer",marginLeft:'20px'}}
                      title="NEW RESPONSE MESSAGE"
                      onClick={() => {blankResponseMessageHandler(editValues)}}
                    >
                      <i
                        className="fa fa-plus"
                        style={{
                          width: 35,
                          fontSize: 20,
                          padding: 11,
                          color: "rgb(0, 123, 255)",
                        }}
                      ></i>NEW RESPONSE MESSAGE
                    </span>
                    // <div onClick={() => {blankResponseMessageHandler(editValues)}}>NEW RESPONSE MESSAGE</div>
                    }
                </div>
                <div className="card-body">
                    <TableComp
                        loading={get(responseMessageState, 'loading')}
                        data={get(responseMessageState, 'data', [])}
                        columns={columns}
                        onSort={onSort}
                        sortField={get(responseMessageState, 'sorting.sortField', null)}
                        sortOrder={get(responseMessageState, 'sorting.sortOrder', null)}
                    />
                </div>
            </div>
            {editValues.showModal && <EditResponseMessage
                values={editValues.values}
                mopen={true}
                submitHandler={(values) => responseMessageUpdateHandler(values)}
                loading={editValues.updating}
                error={editValues.error}
                title={"Edit Response Message"}
                onCloseModal={() => setEditValues({ showModal: false, values: {} })}
            />}
        </div>
    </>
}

export default EmailTemplatesList