import React, { useState} from "react";
import Modal from "react-responsive-modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import styled from "styled-components";
import { translations } from "../../constants/translations";
import ArrowIcon from '../../../src/assets/images/down-arrow.png'
import { Button } from '../shared'
import { checkMassterProfile } from "../../services/masterProfile";
import { toast } from "react-toastify";

const AddAdminDialog = styled.div`
  select {
      appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
      background: url(${ArrowIcon}) no-repeat right;
      background-position-x: 96.5%;
  }
  & .errorDiv {
    color: red ; 
  }
`

const ModalAddNewMasterProfile = (props) => {
  const {
    mopen,
    onOpenModal,
    onCloseModal,
    submitHandler,
    updating
  } = props;

  const [ showError, setShowError ] = useState( false  )
  const [ disable, setDisable] = useState( true  )
  const [ showErrorUsername, setShowErrorUsername ] = useState( false  )
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    description: '',
  });
  const {
    firstName,
    lastName,
    username,
    description,
  } = formData;
  const onChangeHandler = (e) => {
    const inputValue = e.target.value;
    if (e.target.name === 'username'){
    if(inputValue.includes(' ')) {
      setShowErrorUsername(true);
    } else {
      setShowErrorUsername(false);
    }
  }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  const afterInputFinish = async () => {
    const res = await checkMassterProfile({username});
    if (res.success) {
      if(res.data.message){
        toast.error(res.data.message)
      }else{
      const profile = res.data.profile;
      setFormData({
        ...formData,
        firstName: profile.firstName,
        lastName: profile.lastName,
        username: profile.username,
        description: profile.aboutMe.description,
      });
      setDisable(false)
    }
    } else {
      toast.error('This username does not exist')
      setDisable(true)
    }
  };
  return (
    <div className="btn-popup pull-left">
      <button
        type="button"
        className="btn btn-primary"
        onClick={onOpenModal}
        data-toggle="modal"
        data-original-title="test"
        data-target="#exampleModal"
      >
        {translations.MasterProfile.btnTitle}
      </button>
      <Modal open={mopen} onClose={onCloseModal} focusTrapped={false}>
        <div className="modal-header">
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          {translations.MasterProfile.modalHeading}
          </h5>
        </div>
        <div className="modal-body">
        <Formik
            initialValues={{
              title: "",
            }}
            onSubmit={(values, { resetForm }) => {
              submitHandler({firstName, lastName, username, description});
            }}
          >
            <AddAdminDialog>
              <Form id="frmAddAdmin">
              <div className="form-group">
                  <label htmlFor="username">{translations.MasterProfile.username}<span className="required">*</span></label>
                  <Field
                    className="form-control"
                    id="username"
                    name="username"
                    placeholder="Username"
                    maxLength={40}
                    minLength={8}
                    onChange={onChangeHandler}
                    onBlur={() => {
                      onChangeHandler({ target: { name: 'username', value: username } });
                      afterInputFinish();
                    }}                    value={username}
                  />
                  { showError|| showErrorUsername && ( <div className="errorDiv">{translations.MasterProfile.username} {translations.MasterProfile.errorMessage}</div> )}
                </div>
                <div className="form-group">
                  <label htmlFor="firstName">{translations.MasterProfile.firstName}<span className="required">*</span></label>
                  <Field
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    placeholder="FirstName"
                    maxLength={40}
                    onChange={onChangeHandler}
                    value={firstName}
                  />
                  { showError && ( <div className="errorDiv">{translations.MasterProfile.firstName} {translations.MasterProfile.errorMessage}</div> )}
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">{translations.MasterProfile.lastName}<span className="required">*</span></label>
                  <Field
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    placeholder="LastName"
                    maxLength={40}
                    onChange={onChangeHandler}
                    value={lastName}
                  />
                  { showError  && ( <div className="errorDiv">{translations.MasterProfile.lastName} {translations.MasterProfile.errorMessage}</div> )}
                </div>
                <div className="form-group">
                  <label htmlFor="description">{translations.MasterProfile.description}<span className="required">*</span></label>
                  <Field
                    className="form-control"
                    id="description"
                    name="description"
                    placeholder="About Master"
                    maxLength={40}
                    onChange={onChangeHandler}
                    value={description}
                  />
                  { showError && ( <div className="errorDiv">{translations.MasterProfile.description} {translations.MasterProfile.errorMessage}</div> )}
                </div>

                <Button type="submit" loading={updating} loadingText="Saving" disabled={disable} className="btn btn-primary">
                  Submit
                </Button>
                <Button
                  className="btn btn-light"
                  clicked={() => onCloseModal("VaryingMdo")}
                >
                  Close
                </Button>
              </Form>
            </AddAdminDialog>
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAddNewMasterProfile;
