import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import Sidebar from "./common/sidebar_components/sidebar";
import RightSidebar from "./common/right-sidebar";
import Footer from "./common/footer";
import Header from "./common/header_components/header";
import { PERMISSIONS } from "../constants/rolePermissions";

axios.defaults.xsrfCookieName = "CSRF-TOKEN";
axios.defaults.xsrfHeaderName = "X-CSRF-Token";

export class App extends Component {
  static isAdminAuthenticated = (token) => {
    if (token) return true;
  };

  constructor(props) {
    super(props);
    this.state = {
      ltr: true,
      divName: "RTL",
    };
  }

  render() {
    // cant access logipage while logged in
    if (!App.isAdminAuthenticated(localStorage.getItem("token"))) {
      return <Redirect to="/login" />;
    }

    const pathArray = window.location.pathname.split("/");
    const user = JSON.parse(localStorage.getItem("user"));
    const userPermissions = PERMISSIONS[user.roleName];
    // Cant access other pages like admin
    if (!userPermissions.includes(pathArray[1])) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div>
        <div className="page-wrapper">
          <Header />
          <div className="page-body-wrapper">
            <Sidebar />
            <RightSidebar />
            <ToastContainer autoClose={2000} />
            <div className="page-body">{this.props.children}</div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
