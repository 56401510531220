import axios from 'axios'
import { get } from 'lodash'
import qs from 'qs'

const APIHandler = async (path, method, params = {}, formData = false) => {
  let token = ''
  try {
    token = await localStorage.getItem('token')
  } catch (error) { }
  let parsedPath = path
  if (method === 'get' && Object.keys(params).length) {
    parsedPath = parsedPath + '?' + qs.stringify(params)
  }
  const options = {
    headers: {
      'Content-Type': formData ? 'multipart/form-data' : 'application/json',
      Authorization: token,
    },
    method,
    url: process.env.REACT_APP_API_ENDPOINT + parsedPath,
    ...(method === 'get' ? params && { params: {} } : { data: formData ? params : JSON.stringify(params) }),
  }
  return axios(options)
    .then((data) => ({ data: data?.data?.data, params, success: data?.data?.success }))
    .catch((err) => {
      if (get(err, 'response.status', 500) === 401) {
        localStorage.clear();
        return window.location.href = '/login'
      }
      return ({ err, params })
    })
}

export default APIHandler