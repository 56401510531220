export const initialState = {
  loading: false,
  limit: 20,
  status: null,
  error: null,
  data: [],
  currentPage: 1,
  totalRecords: 0,
  offset: 0,
  sorting: {},
}

export const events = {
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  ADD_SORTING: "ADD_SORTING",
  SET_TO_INITIAL: "SET_TO_INITIAL"
}

const reducer = (state = initialState, { type, data, status, currentPage = state.currentPage, totalRecords = state.totalRecords, offset = state.offset, sorting = {} } = {}) => {
  switch (type) {
    case events.FETCHING:
      return { ...state, loading: true }
    case events.ADD_SORTING:
      return { ...state, sorting: sorting }
    case events.SUCCESS:
      return { ...state, data: data, status, loading: false, currentPage, totalRecords, offset }
    case events.SET_TO_INITIAL:
      return { ...initialState, ...data }
    case events.ERROR:
      return { ...state, error: status, loading: false, currentPage, totalRecords, offset }
    default:
      return state
  }
}

export default reducer