import React from 'react'
import PropTypes from 'prop-types'
import Modal from "react-modal";
import { SectionContentWrap, FollowingList, FollowsNameWrap, FollowingThumb, FollowsName, FollowsWrap } from "../styled";
import { createImageUrl, createResizeImageUrl, imageErrorHandler } from "../../utils/imageHelper";
import defaultThumbnail from '../../assets/images/artmo-default.png'
import { parseUserName } from '../../utils/stringHelper';
import { redirectToClientAppUser } from '../../utils/redirects';

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
    },
};

/**
 * Show list of artworks likes
 */
const LikesModal = ({ likesData, title, isOpen, onCloseModal }) => {

    return (
        <Modal
            isOpen={isOpen}
            onClose={onCloseModal}
            onRequestClose={onCloseModal}
            style={customStyles}
        >
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    {title}
                </h5>
            </div>
            <div className="modal-body">
                <SectionContentWrap>
                    {likesData.map((like) => (
                        <FollowingList key={like._id}>
                            <FollowsWrap>
                                <FollowingThumb>
                                    <img
                                        src={
                                            like.profilePicUrl
                                                ? createResizeImageUrl(like.profilePicUrl, 50, 50, 'profileCover') +
                                                '?' +
                                                new Date(like.dateUpdated).getTime()
                                                : defaultThumbnail
                                        }
                                        onError={(e) => {
                                            const timeSuffix = '?' + new Date(like.dateUpdated).getTime()
                                            imageErrorHandler(e, createImageUrl(like.profilePicUrl), defaultThumbnail, 'profileCover', timeSuffix)
                                        }}
                                        onClick={() => redirectToClientAppUser(like?.username)}
                                        alt=""
                                    />
                                </FollowingThumb>
                                <FollowsNameWrap>
                                    <FollowsName >
                                        {parseUserName(like || {})}
                                    </FollowsName>
                                </FollowsNameWrap>
                            </FollowsWrap>
                        </FollowingList>
                    ))}
                </SectionContentWrap>
            </div>
        </Modal >
    )
}

LikesModal.propTypes = {
    likesData: PropTypes.array,
}

export default LikesModal
