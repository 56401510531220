import React, { useEffect, useReducer, useState } from 'react'
import Breadcrumb from "../common/breadcrumb";
import { TableComp, Button } from '../shared';
import get from 'lodash/get'
import EmailTemplateSearch from './components/search'
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../hooks/apiStateHandler'
import { addEmailTemplate, getEmailTemplates, updateEmailTemplates } from '../../services/emailTemplates'
import moment from "moment";
import { EditEmailTemplateModal } from '../modals';
import { toast } from 'react-toastify';
import { SOMETING_WENT_WRONG, TOTAL_RECORDS } from '../../constants/translations';
import { fetchUniqueIds } from '../../features/email-templates/emailTemplatesSlice'
import { useDispatch, useSelector } from 'react-redux';

const editValuesInitial = { showModal: false, values: {}, updating: false, error: null }
const EmailTemplatesList = (props) => {
    const dispatch = useDispatch()
    const uniqueIds = useSelector(state => get(state, 'emailTemplate.allUniqueIds', []))
    const uniqueIdsLoading = useSelector(state => get(state, 'emailTemplate.loadingUniqeIds', []))
    const [emailTemplatesState, emailTemplatesDipatch] = useReducer(ApiStateReducer, initialState)
    const [editValues, setEditValues] = useState(editValuesInitial)
    const [addValues, setaddValues] = useState(editValuesInitial)
    const [applyFilters, setApplyFilters] = useState({})

    useEffect(() => {
        dispatch(fetchUniqueIds())
        getEmailTemplatesHandler({ offset: 0, limit: get(emailTemplatesState, 'limit', 20), })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        {
            name: "Title",
            selector: "title",
            sortable: true,
        },
        {
            name: "Unique Id",
            sortable: true,
            selector: "uniqueId",
        },
        {
            name: "Subject",
            sortable: true,
            selector: "subject",
        },
        {
            name: "Content",
            selector: "content",
            cellRenderer: (row) => (<div dangerouslySetInnerHTML={{ __html: row.content }} ></div>)
        },
        {
            name: "Date",
            sortable: true,
            selector: "dateUpdated",
            cellRenderer: (row) => (<div >{moment(row.dateUpdated).format("DD MMM, YYYY h:mm:ss a")}</div>)
        },
        {
            name: 'Actions',
            cellRenderer: (row) => (<>
                <Button title="Edit Email Template" clicked={() => setEditValues({ showModal: true, values: row })} className="IconButon"><i
                    className="fa fa-pencil"
                    style={{
                        width: 'auto',
                        fontSize: 18,
                        padding: 0,
                        color: "rgb(40, 167, 69)",
                    }}
                ></i></Button>
            </>)
        }
    ]

    const getEmailTemplatesHandler = async (params) => {
        try {
            emailTemplatesDipatch({ type: ApiStateEvents.FETCHING })
            const res = await getEmailTemplates(params)
            if (res.success) {
                emailTemplatesDipatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.emailTemplates', []), offset: params.offset, totalRecords: get(res, 'data.total') })
            } else {
                toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
                emailTemplatesDipatch({ type: ApiStateEvents.ERROR, data: [], totalRecords: get(res, 'data.total') })
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
            emailTemplatesDipatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }

    const onPageChange = (page) => {
        getEmailTemplatesHandler({
            offset: get(emailTemplatesState, 'limit', 20) * (page - 1),
            limit: get(emailTemplatesState, 'limit', 20),
            filters: applyFilters,
            sortField: get(emailTemplatesState, 'sorting.sortField'),
            sortOrder: get(emailTemplatesState, 'sorting.sortOrder'),
        })
    }

    const onSort = ({ fieldName, sortOrder }) => {
        emailTemplatesDipatch({
            type: ApiStateEvents.ADD_SORTING, sorting: {
                sortField: fieldName,
                sortOrder: sortOrder,
            }
        })
        getEmailTemplatesHandler({
            offset: 0,
            limit: get(emailTemplatesState, 'limit', 20),
            filters: applyFilters,
            sortField: fieldName,
            sortOrder: sortOrder,
        })
    }

    const emailTemplateUpdateHandler = async (values) => {
        try {
            setEditValues({ ...editValues, updating: true })
            const res = await updateEmailTemplates({ ...values })
            if (res.success) {
                const clonned = get(emailTemplatesState, 'data', [])
                const index = clonned.findIndex(o => o._id === values._id)
                if (index !== -1 && res.data.emailTemplate) {
                    clonned[index] = res.data.emailTemplate
                    emailTemplatesDipatch({ type: ApiStateEvents.SUCCESS, data: clonned })
                    setEditValues(editValuesInitial)
                } else {
                    toast.error(SOMETING_WENT_WRONG)
                }
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
        }
    }

    const appliedFiltersHandler = (filtersParams) => {
        getEmailTemplatesHandler({ offset: 0, limit: get(emailTemplatesState, 'limit', 20), filters: { ...filtersParams } })
        setApplyFilters(filtersParams)
    }
    const submitAddHandler = async (values) => {
        const res = await addEmailTemplate({...values})
        if (res && res?.success) {
            let emailTemplates = [...get(emailTemplatesState, 'data', [])];
            // Add the new masterClass to the beginning of the array
            emailTemplates.unshift(res.data.emailTemplate);
            // Dispatch the update to the state
            emailTemplatesDipatch({
                type: ApiStateEvents.SUCCESS,
                data: emailTemplates,
                totalRecords: get(res, 'data.total', 0) + 1
            });
            toast.success(res.data?.message)
            } else {
            toast.error(SOMETING_WENT_WRONG)
        }
    };

    return <>
        <Breadcrumb title='Email Templates' />
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h5 className="BackButtonHeading">
                        Email Templates 
                    </h5>
                    <button
        type="button"
        className="btn btn-primary"
        onClick={() =>setaddValues({ showModal: true, values: {} })}
        data-toggle="modal"
        data-original-title="test"
        data-target="#exampleModal"
      >
        NEW EMAIL TEMPLATE
      </button>
                </div>
                
                <EmailTemplateSearch uniqueIds={uniqueIds} loading={uniqueIdsLoading} applyFilters={(f) => appliedFiltersHandler(f)} allfilters={applyFilters} />
                <div style={{ margin: "0 30px 15px" }}>
                    {!get(emailTemplatesState, 'loading') && TOTAL_RECORDS + get(emailTemplatesState, 'totalRecords', 0)}
                </div>
                
                <div className="card-body">
                    <TableComp
                        onPageChange={(p) => onPageChange(p)}
                        page={(get(emailTemplatesState, 'offset', 0) / get(emailTemplatesState, 'limit', 20) + 1)}
                        limit={get(emailTemplatesState, 'limit', 20)}
                        totalRecords={get(emailTemplatesState, 'totalRecords', 0)}
                        loading={get(emailTemplatesState, 'loading')}
                        data={get(emailTemplatesState, 'data', [])}
                        columns={columns}
                        onSort={onSort}
                        sortField={get(emailTemplatesState, 'sorting.sortField', null)}
                        sortOrder={get(emailTemplatesState, 'sorting.sortOrder', null)}
                    />
                </div>
            </div>
            {(editValues.showModal || addValues.showModal)  && <EditEmailTemplateModal
                values={addValues.showModal?addValues.values:editValues.values}
                mopen={true}
                submitHandler={(values) => addValues.showModal?submitAddHandler(values):emailTemplateUpdateHandler(values)}
                loading={addValues.showModal?addValues.updating:editValues.updating}
                error={addValues.showModal?addValues.error:editValues.error}
                title={addValues.showModal?"Add Email Template":"Edit Email Template"}
                onCloseModal={() => addValues.showModal?setaddValues({ showModal: false, values: {} }):setEditValues({ showModal: false, values: {} })}
                type={addValues.showModal?'add':'edit'}
            />}
        </div>
    </>
}

export default EmailTemplatesList