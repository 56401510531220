import React, { useEffect, useReducer, useState } from 'react'
import ApiReducer, { initialState, events as ApiEvents } from '../../hooks/apiStateHandler'
import { acceptArtwork, getArtwork, updateArtwork, deleteArtwork } from '../../services/artworks'
import { ArtworkCommonWrapper, ArtworkCommonContainer, ArtworkRightContainer, ArtworkDetailWrap } from '../styled'
import ArtworkImageViewer from './components/details/artworkImageViewer'
import ArtworkDetailSection from './components/details/artworkDetails'
import ArtworkTabsComp from './components/details/artworkTabs'
import { get } from 'lodash'
import { LoaderComp } from '../shared'
import { fetchRejectReasons } from '../../features/artworks/artworkSlice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { SOMETING_WENT_WRONG } from '../../constants/translations'

const ArtworkDetails = (props) => {
    const { match } = props
    const dispatch = useDispatch()
    const [artworkState, artworkDispatch] = useReducer(ApiReducer, initialState)
    const rejectReasons = useSelector(state => get(state, 'artwork.allRejectReasons', []))
    const [updating, setUpdating] = useState(false)
    const loadingRejectReasons = useSelector(state => get(state, 'artwork.loadingCounter', false))

    useEffect(() => {
        getArtworkHandler(match.params.artworkId)
    }, [match.params.artworkId])

    useEffect(() => {
        dispatch(fetchRejectReasons())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getArtworkHandler = async (id) => {
        artworkDispatch({ type: ApiEvents.FETCHING })
        const res = await getArtwork({ artworkId: id })
        if (res.data) {
            artworkDispatch({ type: ApiEvents.SUCCESS, data: res?.data?.artwork })
        } else {
            toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
            artworkDispatch({ type: ApiEvents.ERROR, data: [] })
        }
    }

    const rejectArtwork = async (params) => {
        setUpdating(true)
        const res = await acceptArtwork(params)
        if (res.success) {
            toast.success(get(res, 'data.message'))
            artworkDispatch({ type: ApiEvents.SUCCESS, data: res?.data?.artwork })
        } else {
            toast.error(get(res, 'err.response.data.message') || 'Something went wrong')
        }
        setUpdating(false)
    }

    const updateArtworkHandler = async (artwork) => {
        setUpdating(true)
        const res = await updateArtwork(artwork)
        if (res.success) {
            toast.success(get(res, 'data.message'))
            artworkDispatch({ type: ApiEvents.SUCCESS, data: res?.data?.artwork })
        } else {
            toast.error(get(res, 'err.response.data.message') || 'Something went wrong')
        }
        setUpdating(false)
    }

    const deleteArticleHandler = async (artworkId) => {
        const res = await deleteArtwork({ artworkId })
        if (res.success) {
            toast.success(get(res, 'data.message'))
        } else {
            toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
        }
    }


    return <>
        <ArtworkCommonWrapper>
            <ArtworkCommonContainer>
                {/* <ArtworkLeftContainer>
                    <Filters />
                </ArtworkLeftContainer> */}
                <ArtworkRightContainer>
                    {!get(artworkState, 'loading', false) && !loadingRejectReasons ? <ArtworkDetailWrap>
                        <ArtworkImageViewer
                            artwork={get(artworkState, 'data', {})}
                            artPhotos={get(artworkState, 'data.artPhotos', [])}
                            rejectReasons={rejectReasons || []}
                            rejectArtwork={(p) => rejectArtwork(p)}
                            updateArtworkHandler={(a) => updateArtworkHandler(a)}
                            deleteArtwork={(id) => deleteArticleHandler(id)}
                            updating={updating}
                        />
                        <ArtworkDetailSection
                            artwork={get(artworkState, 'data', {})}
                        />
                        <ArtworkTabsComp
                            user={get(artworkState, 'data.userId', null)}
                            artworkId={get(artworkState, 'data._id', null)} />
                    </ArtworkDetailWrap> : <LoaderComp loading />}
                </ArtworkRightContainer>
            </ArtworkCommonContainer>
        </ArtworkCommonWrapper>

    </>
}

export default ArtworkDetails