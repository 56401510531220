import React, { useEffect, useReducer, useState } from 'react'
import Breadcrumb from '../../common/breadcrumb'
import { TableComp, ToolTip } from '../../shared'
import get from 'lodash/get'
import ApiStateReducer, { events as ApiStateEvents, initialState } from '../../../hooks/apiStateHandler'
import { toast } from 'react-toastify';
import { SOMETING_WENT_WRONG, TOTAL_RECORDS, ALREADY_PRESENT } from '../../../constants/translations';
import { getAllGenres, addNewGenre, deleteGenre } from '../../../services/artworks'
import moment from 'moment'
import ModalAddNewGenre from './add_new_genre'

const Genres = () => {
    const [genresState, genresDispatch] = useReducer(ApiStateReducer, initialState)
    const [actionPerforming, setActionPerforming] = useState(null)
    useEffect(() => {
        getGenresHandler({ offset: 0, limit: get(genresState, 'limit', 20), })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getGenresHandler = async (params) => {
        try {
            genresDispatch({ type: ApiStateEvents.FETCHING })
            const res = await getAllGenres(params)
            if (res.success) {
                genresDispatch({ type: ApiStateEvents.SUCCESS, data: get(res, 'data.genres', []), offset: params.offset, totalRecords: get(res, 'data.total') })
            } else {
                toast.error(get(res, 'err.response.data.message') || SOMETING_WENT_WRONG)
                genresDispatch({ type: ApiStateEvents.ERROR, data: [], totalRecords: get(res, 'data.total') })
            }
        } catch (error) {
            toast.error(SOMETING_WENT_WRONG)
            genresDispatch({ type: ApiStateEvents.ERROR, data: [], error })
        }
    }
    const onSort = ({ fieldName, sortOrder }) => {
        genresDispatch({
            type: ApiStateEvents.ADD_SORTING, sorting: {
                sortField: fieldName,
                sortOrder: sortOrder,
            }
        })
        genresDispatch({
            offset: 0,
            limit: get(genresState, 'limit', 20),
            sortField: fieldName,
            sortOrder: sortOrder,
        })
    }
    const onPageChange = (page) => {
        genresDispatch({
            offset: get(genresState, 'limit', 20) * (page - 1),
            limit: get(genresState, 'limit', 20),
            sortField: get(genresState, 'sorting.sortField'),
            sortOrder: get(genresState, 'sorting.sortOrder'),
        })
    }
    const columns = [
        {
            name: "Genre Title",
            selector: "title",
            width: "15%",
            cellRenderer: (row) => (<div dangerouslySetInnerHTML={{ __html: row.title }} ></div>),
            //sortable: true
        },
        // {
        //     name: "Genre Description",
        //     selector: "description",
        //     width: "70%",
        //     cellRenderer: (row) => (<div dangerouslySetInnerHTML={{ __html: row.description }} ></div>),
        //     //sortable: true
        // },
        {
            name: "Date Created",
            selector: "dateCreated",
            width: "15%",
            cellRenderer: (row) => (<div >{moment(row.dateCreated).format("DD MMM, YYYY h:mm:ss a")}</div>),
            //sortable: true
        },
        {
            name: "Delete",
            selector: "delete",
            width: "10%",
            cellRenderer: (row) => (
                <button onClick={() => deleteGenreHandler(row._id)}>
                    Delete
                </button>
            ),
        },
    ]

    const [mopen, setMopen] = useState(false);
    const [updating, setUpdating] = useState(false)
    const onOpenModal = () => {
        setMopen(true);
      };
    const onCloseModal = () => {
        setMopen(false);
    };
    const submitHandler = async (data) => {
        setUpdating(true)
        const res = await addNewGenre(data)
        if (res && res?.success) {
            toast.success(res?.data?.message)
            genresDispatch({ type: ApiStateEvents.SUCCESS})
            getGenresHandler({ offset: 0, limit: get(genresState, 'limit', 20), })
        } else if ( res && res?.err ) {
            toast.error(ALREADY_PRESENT)
        } else {
            toast.error(SOMETING_WENT_WRONG)
            getGenresHandler({ offset: 0, limit: get(genresState, 'limit', 20), })
        }
        setMopen(false)
        setUpdating(false)
    };

    const deleteGenreHandler = async (genreId) => {
        setUpdating(true)
        const res = await deleteGenre({genreId})
        if (res && res?.success) {
            toast.success(res?.data?.message)
            genresDispatch({ type: ApiStateEvents.SUCCESS})
            getGenresHandler({ offset: 0, limit: get(genresState, 'limit', 20), })
        } else if ( res && res?.err ) {
            toast.error(ALREADY_PRESENT)
        } else {
            toast.error(SOMETING_WENT_WRONG)
            getGenresHandler({ offset: 0, limit: get(genresState, 'limit', 20), })
        }
        setUpdating(false)
    };

    return <>
    <Breadcrumb title='Genres' />
    <div className="container-fluid">
        <div className="card">
            <div className="card-header">
                <h5 className="BackButtonHeading">
                    Genres
                </h5>
            </div>
            <div style={{ margin: "0 30px 15px" }}>
                {!get(genresState, 'loading') && TOTAL_RECORDS + get(genresState, 'totalRecords', 0)}
            </div>
            <div className="card-body">
                <ModalAddNewGenre
                    mopen={mopen}
                    submitHandler={submitHandler}
                    onOpenModal={onOpenModal}
                    onCloseModal={onCloseModal}
                    updating={updating}
                />
                <TableComp
                    //onPageChange={(p) => onPageChange(p)}
                    //page={(get(genresState, 'offset', 0) / get(genresState, 'limit', 20) + 1)}
                    //limit={get(genresState, 'limit', 20)}
                    totalRecords={get(genresState, 'totalRecords', 0)}
                    loading={get(genresState, 'loading')}
                    data={get(genresState, 'data', [])}
                    columns={columns}
                    //onSort={onSort}
                    //sortField={get(genresState, 'sorting.sortField', null)}
                   // sortOrder={get(genresState, 'sorting.sortOrder', null)}
                />
            </div>
        </div>
    </div>
</>
}
export default Genres