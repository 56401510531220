import MultiSelect from "react-multi-select-component";
import React from "react";
import PropTypes from "prop-types";

const overrideStrings = {
    allItemsAreSelected: "All items are selected.",
    clearSearch: "Clear Search",
    noOptions: "No options",
    search: "Search",
    selectAll: "Select All",
    selectSomeItems: "Select",
};


const MultiSelectComp = (props) => {
    const { options, values, placeholder, onSubmit, displayNumberOfItems, loading } = props

    const customValueRenderer = (selected, _options) => {
        return selected.length
            ? selected.length > displayNumberOfItems
                ? selected.length + " selected"
                : selected.map(({ label }, index) => `${label}${selected.length !== index + 1 ? ', ' : ''}`)
            : loading ? "Loading" : placeholder;
    };
       const getSlectedValues = () => {
        let selected = [];
        let hasActiveGallerySelected = false;
    
        values.forEach(v => {
            const option = options.find(o => o.value === v);
    
            if (option) {
                if (option.value === "haveActiveGallery") {
                    if(selected.length===0){
                    selected = [{
                        label: 'Gallery Owner',
                        value: 'haveActiveGallery',
                    }];
                }
                    hasActiveGallerySelected = true;
                } else if (!hasActiveGallerySelected) {
                    selected.push(option);
                }
            }
        });
        return selected;
    }
    const submitHandler = (v) => {
        const result = { parsedValues: [], unparsedValues: [] }
        v.forEach(val => {
            result.parsedValues.push(val.value)
            result.unparsedValues.push(val)
        })
        onSubmit(result)
    }
    return <MultiSelect
        overrideStrings={overrideStrings}
        valueRenderer={customValueRenderer}
        options={options}
        value={getSlectedValues()}
        onChange={(v) => submitHandler(v)}
        labelledBy={placeholder}
    />
}

MultiSelectComp.propTypes = {
    options: PropTypes.array.isRequired,
    values: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    displayNumberOfItems: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
}

MultiSelectComp.defaultProps = {
    options: [],
    values: [],
    placeholder: "",
    onSubmit: () => null,
    displayNumberOfItems: 1,
    loading: false,
}

export default MultiSelectComp