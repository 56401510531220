import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import selectarrow from '../../../assets/images/select_arrow.png'

const SelectStyled = styled.select`
  border: 2px solid #eee;
  border-color: ${(props) => (props.hasError ? '#d62d1e' : '#eee')};
  color: #666;
  font-size: 16px;
  font-weight: 400;
  height: 36px;
  margin: 0 0 10px;
  padding: 0 15px;
  width: 100%;
  background: url(${selectarrow}) no-repeat right 10px center;
  appearance: none;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: #666;
    font-weight: 400;
  }
`

const Select = (props) => (
    <SelectStyled hasError={props.hasError} {...props}>
        {props.children}
    </SelectStyled>
)

Select.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    hasError: PropTypes.bool,
}

export default Select
