import React, { useEffect } from 'react'
import { Button } from '../../shared'
import { captalizeFirstChar } from '../../../utils/stringHelper'
import PropTypes from 'prop-types'
import { translations } from "../../../constants/translations";
import moment from "moment";
import { get } from 'lodash';

const UserDetailsViewer = (props) => {
    const { user, parentUser, blockingUser,deleteUserData, blockUserHandler,deleteUserHandler, subscriptionData } = props

    return <div className="tab-pane fade show active">
        <div className="table-responsive profile-table">
            <table className="table table-responsive">
                <tbody>
                    <tr>
                        <td width="120"><strong>{!parentUser ? translations.Profile.FirstName : translations.Profile.InstitutionName}:</strong></td>
                        <td>{user.firstName}</td>
                        {!parentUser && <><td width="120"><strong>{translations.Profile.LastName}:</strong></td>
                            <td>{captalizeFirstChar(user.lastName || 'N/A')}</td></>}
                    </tr>
                    <tr>
                        <td width="120"><strong>Username:</strong></td>
                        <td>{user.username}</td>

                        <td width="120"><strong>Phone Number:</strong></td>
                        <td>{user?.mobile?.value || 'N/A'}</td>

                    </tr>
                    <tr>
                        <td width="120"><strong>{translations.Profile.Email}:</strong></td>
                        <td>{user?.email?.value || 'N/A'}</td>
                        <td width="120"><strong>{translations.Profile.Role}:</strong></td>
                        <td>{captalizeFirstChar(user?.userRoleId?.roleName)}</td>
                    </tr>
                    <tr>
                        <td width="120"><strong>{translations.Profile.DOB}:</strong></td>
                        <td>{user?.dob?.value ? moment(user.dob.value).format("DD MMM, YYYY") : 'N/A'}</td>

                        <td width="120"><strong>Country:</strong></td>
                        <td>{captalizeFirstChar(user?.country?.value || 'N/A')}</td>

                        <td width="120"><strong>{translations.Profile.City}:</strong></td>
                        <td>{captalizeFirstChar(user?.city?.value || 'N/A')}</td>

                    </tr>
                    <tr>
                        <td width="120"><strong>Link:</strong></td>
                        <td><a target="_blank" href={'https://artmo.com/user/' + user.username}>{'https://artmo.com/user/' + user.username}</a></td>
                    </tr>
                    {parentUser && parentUser.email && <tr>
                        <td>{translations.Profile.ParentUserEmail}:</td>
                        <td>{parentUser.email?.value}</td>
                       {parentUser._id&& <td onClick={() => {
                                //history.push(`/users/${DETAILS}/${row._id}`)
                                const win = window.open(`/users/details/${parentUser._id}`, "_blank")
                                win.focus()
                            }} ><Button>Visit personal account</Button></td>}
                    </tr>}

                </tbody>
            </table>
            <div>
            </div>
        </div>
        <div className='btn-group-vertical'>
            <Button loading={blockingUser} loadingText={!user.isBlocked ? 'Suspending' : 'Activating'} clicked={() => blockUserHandler(user._id, !user.isBlocked)} className='btn btn-primary'>{!user.isBlocked ? 'Suspend' : 'Activate'}</Button>
            {/* <Button clicked={() => sendMessageHandler()} >Send Message</Button> */}
        </div>
        <div className='btn-group-vertical'>
            <Button loading={deleteUserData} loadingText={'Suspend +'} clicked={() => deleteUserHandler(user._id)} className='btn btn-primary'>Suspend +</Button>
            {/* <Button clicked={() => sendMessageHandler()} >Send Message</Button> */}
        </div>
        {!parentUser && <div style={{ margin: "15px 0" }}>
            <strong>Subscriptions</strong>
        </div>}
        <div className="tab-pane fade show active">
            <div className="table-responsive profile-table">
                <table className="table table-responsive">
                    <tbody>
                        {!parentUser && subscriptionData && subscriptionData.length ? <>{subscriptionData.map(subscription => {
                            return <>
                                <tr>
                                    <td width="200"><strong>{translations.Subscription.title}:</strong></td>
                                    <td>{captalizeFirstChar(subscription?.subscription?.title || 'N/A')}</td>
                                </tr>
                                <tr>

                                    <td width="200"><strong>{translations.Subscription.period}:</strong></td>
                                    <td>{captalizeFirstChar(subscription?.subscriptionPeriod || 'N/A')}</td>
                                </tr>
                                <tr>

                                    <td width="200"><strong>{translations.Subscription.amount}:</strong></td>
                                    <td>{captalizeFirstChar(get(subscription,`subscription.price[${subscription.subscriptionPeriod}]`,'N/A'))}</td>
                                </tr>
                                <tr>

                                    <td width="200"><strong>{translations.Subscription.subscriptionDate}:</strong></td>
                                    <td>{moment(subscription?.dateCreated).format("DD MMM, YYYY") || 'N/A'}</td>
                                </tr>
                            </>
                        })}</> : <>{!parentUser && "No subscriptions found"} </>}
                    </tbody>
                </table>
            </div>
        </div>
    </div >
}

UserDetailsViewer.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    parentUser: PropTypes.object,
    sendMessageHandler: PropTypes.func.isRequired,
    subscriptionData: PropTypes.array.isRequired,
}

UserDetailsViewer.defaultProps = {
    user: {},
    loading: false,
    subscriptionData: [],
    sendMessageHandler: () => null
}

export default UserDetailsViewer
