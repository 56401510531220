import APIHandler from './apiHandler'

const getUsersList = (params) => APIHandler('artworkdesk/list', 'get', params)

const getChatHistory = (params) => APIHandler('artworkdesk/chat', 'get', params)

const sendMessage = (params) => APIHandler('artworkdesk/sendMessage', 'post', params)

const markRead = (params) => APIHandler('artworkdesk/markRead', 'post', params)

const deleteSingle = ( params ) => APIHandler('artworkdesk/delete-message', 'post', params )


export { getUsersList, getChatHistory, sendMessage, markRead, deleteSingle }