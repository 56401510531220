import APIHandler from './apiHandler'

const getUsersList = (params) => APIHandler('helpdesk/list', 'get', params)

const getChatHistory = (params) => APIHandler('helpdesk/chat', 'get', params)

const sendMessage = (params) => APIHandler('helpdesk/sendMessage', 'post', params)

const markRead = (params) => APIHandler('helpdesk/markRead', 'post', params)

const getReportedMessageList = ( params ) => APIHandler('reported-messages/list', 'get', params )

const deleteReportedMessage = ( params ) => APIHandler('reported-messages/delete', 'delete', params )

const deleteSingle = ( params ) => APIHandler('helpdesk/delete-message', 'post', params )

const getMassMemberMessageList = ( params ) => APIHandler('member-message/list', 'get', params )

const getSingleMemberMessagesList = ( params ) => APIHandler('member-message/messageList', 'post', params)

const allowMoreConversations = ( params ) => APIHandler('member-message/allowMoreConversation', 'post', params)

const deleteMemberMessages = ( params ) => APIHandler('member-message/deleteMemberMessage', 'post', params)

const getUserCommentsList = ( params ) => APIHandler('user-comments/list', 'get', params )

const getSingleCommentsBy = ( params ) => APIHandler('user-comments/commentList', 'post', params)


export { getUsersList, getChatHistory, sendMessage, markRead, getReportedMessageList, deleteReportedMessage, deleteSingle, getMassMemberMessageList, getSingleMemberMessagesList, allowMoreConversations, deleteMemberMessages, getUserCommentsList, getSingleCommentsBy }