import React, { useState } from "react";
import Modal from "react-modal";
import { staticMessageValidationSchema } from '../common/validations'
import { Formik, Field, Form, ErrorMessage } from "formik";
import { get } from "lodash";
import { Button } from '../shared'
import { Editor } from "@tinymce/tinymce-react";


const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
    },
};

const EditStaticMessageModal = (props) => {
    const {
        mopen,
        onCloseModal,
        submitHandler,
        values,
        title,
        loading
    } = props
    const [plainText, setPlainText] = useState(false)	

    return (
        <Modal
            isOpen={mopen}
            onClose={onCloseModal}
            onRequestClose={onCloseModal}
            style={customStyles}
            className="ModalContent"
            overlayClassName="OverlayContent"
        >
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    {title}
                </h5>
            </div>
            <div className="modal-body">
                <Formik
                    initialValues={
                        {
                            //title: get(values, 'title', ''),
                            //subject: get(values, 'subject', ''),
                            content: get(values, 'content', ''),
                            _id: get(values, '_id', null)
                        }
                    }
                    validationSchema={staticMessageValidationSchema}
                    onSubmit={(v) => submitHandler(v)}
                >
                    {({ values: formikValues, setFieldValue }) => (<Form id="emailTemplate">
                        {/* <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <Field
                                className="form-control"
                                id="title"
                                name="title"
                                placeholder="Type your message here"
                            />
                            <ErrorMessage
                                component="div"
                                className="alert alert-danger"
                                name="title"
                            />
                        </div> */}
                        <div className="form-group">
                            <label htmlFor="title">Unique Id</label>
                            <Field
                                className="form-control"
                                id="title"
                                name="title"
                                value={values?.uniqueId}
                                disabled
                            />
                            <ErrorMessage
                                component="div"
                                className="alert alert-danger"
                                name="title"
                            />
                        </div>
                        {/* <div className="form-group">
                            <label htmlFor="subject">Subject</label>
                            <Field
                                className="form-control"
                                id="subject"
                                name="subject"
                                placeholder="Type your message here"
                            />
                            <ErrorMessage
                                component="div"
                                className="alert alert-danger"
                                name="subject"
                            />
                        </div> */}
                        <div className="form-group" >
                            <label>Content</label><br/>
                            <button type="button" onClick={() => {setPlainText(!plainText)}}>Switch to {plainText ? 'rich text editor' : 'plain text editor'}</button>	
                            {                    	
                            plainText ? 	
                            <textarea style={{width: "100%", minHeight: "250px"}} value={formikValues.content} onChange={(e) => {	
                                setFieldValue('content', e.target.value);	
                                //console.log(e.target.value);	
                            }}></textarea>	
                            : <Editor
                                apiKey={process.env.REACT_APP_TINY_MCE}
                                initialValue={values.content}
                                value={formikValues.content}
                                init={{
                                    height: 500,
                                    target: false,
                                    menubar: false,
                                    media_live_embeds: true,
                                    plugins:
                                    'advlist autolink quickbars lists link image charmap print preview anchor searchreplace code fullscreen media paste code placeholder mediaembed',
                                    toolbar:
                                     'bold italic underline h2 h3 removeformat alignleft aligncenter alignright alignjustify numlist blockquote media image link',
                                    quickbars_insert_toolbar: 'quickimage',
                                    quickbars_selection_toolbar: 'bold italic underline h2 h3',
                                }}
                                onEditorChange={(newValue, editor) =>
                                    setFieldValue("content", newValue)
                                }
                            ></Editor>
                            }
                        </div>
                        <Button loading={loading} type="submit" className="btn btn-primary">
                            Update
                        </Button>
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => onCloseModal()}
                        >
                            Close
                        </button>
                    </Form>)}
                </Formik>
            </div>
            <div className="modal-footer"></div>
        </Modal >
    );
};

export default EditStaticMessageModal;