import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form } from "formik";
import { FormInput } from "../common/Form/input";
import { changepassValidationSchema } from "../common/validations";
import { translations } from "../../constants/translations";
import styles from "./accountsetting.module.css";

import {
  changePassAdmin,
  userSelector,
  clearState,
} from "../../features/auth/authSlice";

const TabsetChangePass = (props) => {
  const dispatch = useDispatch();

  const { isFetching, isSuccess, isError } = useSelector(userSelector);
  let { successMessage, errorMessage } = useSelector(userSelector);

  const submitHandler = (data) => {
    successMessage = "";
    errorMessage = "";
    dispatch(changePassAdmin(data));
  };

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
    }

    if (isSuccess) {
      toast.success(successMessage);
      dispatch(clearState());
    }
  }, [isError, isSuccess, dispatch]);

  return (
    <Fragment>
      <ToastContainer autoClose={4000} />
      <Tabs>
        <TabList className="nav nav-tabs tab-coupon">
          <Tab className={`nav-link ${styles.topLinks}`}>
            <User />
            {translations.Profile.ChangePassword}
          </Tab>
        </TabList>

        <TabPanel>
          <Formik
            initialValues={{
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={changepassValidationSchema}
            onSubmit={(values, { resetForm }) => {
              submitHandler(values);
              resetForm({});
            }}
          >
            {({ isSubmitting }) => (
              <Form id="frmLogin" className="form-horizontal auth-form">
                <div className="form-group">
                  <FormInput
                    id="currentPassword"
                    type="password"
                    className="form-control"
                    name="currentPassword"
                    placeholder={translations.Profile.CurrentPassword}
                  />
                </div>
                <div className="form-group">
                  <FormInput
                    id="newPassword"
                    type="password"
                    className="form-control"
                    name="newPassword"
                    placeholder={translations.Profile.NewPassword}
                  />
                </div>
                <div className="form-group">
                  <FormInput
                    id="confirmPassword"
                    type="password"
                    className="form-control"
                    name="confirmPassword"
                    placeholder={translations.Profile.ConfirmPassword}
                  />
                </div>

                {errorMessage && (
                  <div className="form-group">
                    <div className="alert alert-danger">{errorMessage}</div>
                  </div>
                )}

                <div className="form-button">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={isFetching}
                  >
                    {translations.Profile.ChangePassword}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};

export default withRouter(TabsetChangePass);
