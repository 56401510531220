import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import styled from "styled-components";
import { ReactSelect, Button, FormFieldWrapper } from '../../shared'
import { translations } from '../../../constants/translations'
import get from 'lodash-es/get';
import { cloneDeep } from 'lodash'
import Switch from "react-switch";
import { artworkValidationSchema } from '../../common/validations'
import { createImageUrl, createResizeImageUrl } from '../../../utils/imageHelper'
import ImageGallery from 'react-image-gallery';
import { RejectReasonsModal } from '../../modals';
import { useSelector } from 'react-redux';


const AddPhoto = styled.div`
    .big-photo {
        border: 2px solid rgba(0,0,0,.125);
        text-align: center;
        width: 100%;
        box-sizing: border-box;
        min-height: 100px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 6px;
    }
    .small-photo {
        border: 2px solid rgba(0,0,0,.125);
        text-align: center;
        width: 120px;
        box-sizing: border-box;
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bottom-photo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 20px;
        .add-photo {
            padding: 0 10px;
            font-size: 35px;
            font-weight: bold;
            margin-left: 10px;
        }
    }
`

const BtnUser = styled.button`
background: #222;
color: #fff;
padding: 5px 10px;
border: 1px solid #222;
cursor: pointer;
&:hover{
    background: #fff;
color: #222;
}

`

const TIME_PERIOD_OPTIONS = [{ label: translations.Artworks.label.ancient, value: 'ancient' },
{ label: translations.Artworks.label.medieval, value: 'medieval' },
{ label: translations.Artworks.label.renaissance, value: 'renaissance' },
{ label: translations.Artworks.label.romanticism, value: 'romanticism' },
{ label: translations.Artworks.label.modern, value: 'modern' },
{ label: translations.Artworks.label.contemporary, value: 'contemporary' },
]

const getInitialValues = (artworkDetail) => {
    return {
        _id: artworkDetail._id,
        artPhotos:
            artworkDetail && artworkDetail.artPhotos ? artworkDetail.artPhotos : [{ pictureUrl: 'default' }],
        title: artworkDetail && artworkDetail.title ? artworkDetail.title : '',
        country: artworkDetail && artworkDetail.country ? artworkDetail.country : '',
        year: artworkDetail && artworkDetail.year ? artworkDetail.year : '',
        price: artworkDetail && artworkDetail.price ? artworkDetail.price.toString().replace('.', ',') : '',
        priceOnRequest: artworkDetail && artworkDetail.priceOnRequest ? artworkDetail.priceOnRequest : false,
        artworkType: artworkDetail && artworkDetail.artworkType ? artworkDetail.artworkType : '',
        artMaterial: artworkDetail && artworkDetail.artMaterial ? artworkDetail.artMaterial : '',
        description: artworkDetail && artworkDetail.description ? artworkDetail.description : '',
        sizeType: artworkDetail && artworkDetail.sizeType ? artworkDetail.sizeType : '2D',
        artDimensions: {
            height:
                artworkDetail && artworkDetail.artDimensions && artworkDetail.artDimensions.height
                    ? artworkDetail.artDimensions.height
                    : '',
            length:
                artworkDetail && artworkDetail.artDimensions && artworkDetail.artDimensions.length
                    ? artworkDetail.artDimensions.length
                    : '',
            width:
                artworkDetail && artworkDetail.artDimensions && artworkDetail.artDimensions.width
                    ? artworkDetail.artDimensions.width
                    : '',
        },
        imageDetails: {
            imageWidth:
                artworkDetail && artworkDetail.imageDetails && artworkDetail.imageDetails.imageWidth
                    ? artworkDetail.imageDetails.imageWidth
                    : '',
            imageHeight:
                artworkDetail && artworkDetail.imageDetails && artworkDetail.imageDetails.imageHeight
                    ? artworkDetail.imageDetails.imageHeight
                    : '',
            size:
                artworkDetail && artworkDetail.imageDetails && artworkDetail.imageDetails.size
                    ? artworkDetail.imageDetails.size
                    : '',
        },
        isFramed: artworkDetail && artworkDetail.isFramed ? artworkDetail.isFramed : false,
        frameDimensions: {
            length:
                artworkDetail && artworkDetail.frameDimensions && artworkDetail.frameDimensions.length
                    ? artworkDetail.frameDimensions.length
                    : '',
            width:
                artworkDetail && artworkDetail.frameDimensions && artworkDetail.frameDimensions.width
                    ? artworkDetail.frameDimensions.width
                    : '',
        },
        frameMaterial: artworkDetail && artworkDetail.frameMaterial ? artworkDetail.frameMaterial : '',
        artMediumId:
            artworkDetail &&
                artworkDetail.artMediumId &&
                Array.isArray(artworkDetail.artMediumId) &&
                artworkDetail.artMediumId.length > 0
                ? artworkDetail.artMediumId.map((artMedium) => artMedium._id)
                : [],
        genreId:
            artworkDetail &&
                artworkDetail.genreId &&
                Array.isArray(artworkDetail.genreId) &&
                artworkDetail.genreId.length > 0
                ? artworkDetail.genreId.map((artGenre) => artGenre._id)
                : [],
        subjects: artworkDetail && artworkDetail.subjects ? artworkDetail.subjects : [],
        selfNote: artworkDetail && artworkDetail.selfNote ? artworkDetail.selfNote : '',
        isDrafted: artworkDetail && artworkDetail.isDrafted ? artworkDetail.isDrafted : false,
        isPublished: artworkDetail && artworkDetail.isPublished ? artworkDetail.isPublished : false,
        type: artworkDetail && artworkDetail.type ? artworkDetail.type : 'unique',
        editionDetail: {
            version:
                artworkDetail && artworkDetail.editionDetail && artworkDetail.editionDetail.version
                    ? artworkDetail.editionDetail.version
                    : '',
            count:
                artworkDetail && artworkDetail.editionDetail && artworkDetail.editionDetail.count
                    ? artworkDetail.editionDetail.count
                    : '',
        },
        timePeriod: artworkDetail.timePeriod
            ? artworkDetail.timePeriod
            : '',
        isAdmin: artworkDetail.isAdmin,
        nameOfArtist: {
            firstName:
                artworkDetail && artworkDetail.nameOfArtist && artworkDetail.nameOfArtist.firstName
                    ? artworkDetail.nameOfArtist.firstName
                    : '',
            lastName:
                artworkDetail && artworkDetail.nameOfArtist && artworkDetail.nameOfArtist.lastName
                    ? artworkDetail.nameOfArtist.lastName
                    : '',
        },
        partOfSeries: artworkDetail && artworkDetail.partOfSeries ? artworkDetail.partOfSeries : false,
        seriesDetails: artworkDetail && artworkDetail.seriesDetails ? artworkDetail.seriesDetails : '',
        freeShipping: artworkDetail && artworkDetail.freeShipping ? artworkDetail.freeShipping : false,
        userRoleId: artworkDetail && artworkDetail.user && artworkDetail.userId.userRoleId ? artworkDetail.userId.userRoleId : false,
        shippingPrice: {
            domesticPrice:
                artworkDetail && artworkDetail.shippingPrice && artworkDetail.shippingPrice.domesticPrice
                    ? artworkDetail.shippingPrice.domesticPrice.toString().replace('.', ',')
                    : '',
            internationalPrice:
                artworkDetail && artworkDetail.shippingPrice && artworkDetail.shippingPrice.internationalPrice
                    ? artworkDetail.shippingPrice.internationalPrice.toString().replace('.', ',')
                    : '',
        },
        isNotEnhanced: artworkDetail.isNotEnhanced
    }
}
const EditArtworkComp = (props) => {
    const { artwork = {}, mediumOptions, genreOptions, countriesOptions, updateArtwork, rejectArtwork, updating } = props
    const formik = useFormik({
        initialValues: getInitialValues(artwork),
        // validationSchema: () => artworkValidationSchema,
        onSubmit: values => {
            const reqData = cloneDeep(values)
            reqData.price = reqData.price ? parseFloat(reqData.price.toString().replace(',', '.')) : 0
            reqData.shippingPrice.domesticPrice = reqData.shippingPrice.domesticPrice
                ? parseFloat(reqData.shippingPrice.domesticPrice.toString().replace(',', '.'))
                : 0
            reqData.shippingPrice.internationalPrice = reqData.shippingPrice.internationalPrice
                ? parseFloat(reqData.shippingPrice.internationalPrice.toString().replace(',', '.'))
                : 0
            updateArtwork({ ...reqData, accept: 1, isDrafted: false, })
        },
    });
    const [showModal, setShowModal] = useState(false)
    const rejectReasons = useSelector(state => state?.artwork?.allRejectReasons || [])
    const loadingRejectReasons = useSelector(state => state.artwork.loadingCounter)
    const { title, country, description, year, artMaterial, selfNote, timePeriod, type, editionDetail, isAdmin, nameOfArtist, price, priceOnRequest, artworkType, sizeType, artDimensions,imageDetails, isFramed, frameDimensions, artMediumId, genreId, freeShipping, shippingPrice, frameMaterial, userRoleId, isNotEnhanced } = formik.values
    const { title: Etitle,
        country: Ecountry,
        description: Edescription,
        year: Eyear,
        artMaterial: EartMaterial,
        selfNote: EselfNote,
        timePeriod: EtimePeriod,
        type: Etype,
        editionDetail: EeditionDetail,
        isAdmin: EisAdmin,
        nameOfArtist: EnameOfArtist,
        price: Eprice,
        priceOnRequest: EpriceOnRequest,
        sizeType: EsizeType,
        artDimensions: EartDimensions,
        imageDetails:EimageDetails,
        isFramed: EisFramed,
        frameDimensions: EframeDimensions,
        artMediumId: EartMediumId,
        freeShipping: EfreeShipping,
        frameMaterial: EframeMterial,
        shippingPrice: EshippingPrice,
        genreId: EgenreId,
        isNotEnhanced: EisNotEnhanced
    } = formik.errors
    
    const getImages = () => {
        return get(artwork, 'artPhotos', []).map(image => ({ original: createResizeImageUrl(image?.pictureUrl, 400, 'auto', 'mediaLibrary'), thumbnail: createResizeImageUrl(image?.pictureUrl, 400, 'auto', 'mediaLibrary') }))
    }

    const rejectArtworkHandler = (values) => {
        rejectArtwork({ ...values, isDrafted: false, accept: -1, artworkId: artwork._id })
        setShowModal(false)
    }

    const priceParser = (e) => {
        const regex = /^(\d*),{0,1}(\d){0,2}$/
        const value = e.target.value
        if (value === '' || regex.test(value)) {
            return value
        }
        return null
    }
    return <>
        {showModal && <RejectReasonsModal
            mopen={true}
            onCloseModal={() => setShowModal(false)}
            submitHandler={(v) => rejectArtworkHandler(v)}
            artwork={artwork}
            title="Reject Artwork"
            reasons={rejectReasons}
        />}
        <div className="card-body row">
            <div className="col-sm-12 col-lg-4">
                <AddPhoto>
                    <ImageGallery autoPlay={false} showPlayButton={false} items={getImages()} />
                </AddPhoto>
            </div>
            <div className="col-sm-12 col-lg-8">
                <div className="tab-pane fade show active">
                    <div className="mb15 flexDivAs">
                        <h5>Seller Name: {artwork && artwork.userId && artwork.userId.firstName} {artwork && artwork.userId && artwork.userId.lastName}</h5>
                        
                    </div>
                    <div className="mb15">
                        <h5>Seller Type: {(artwork && artwork.userId && artwork.userId.userRoleId === '5fe2d61b3021f7ac96cad271') ? 'Member' : 'Artist'}</h5>
                    </div>
                    <div className="mb15">
                        <h5>Artwork Type: {artworkType}</h5>
                    </div>
                    <div className="mb15">
                        <BtnUser onClick={() => {
                            //history.push(`/users/${UPDATE}/${row._id}`)
                            const win = window.open(`https://artmo.com/user/${artwork && artwork.userId && artwork.userId.username}`, "_blank")
                            win.focus()
                        }}>View User</BtnUser>
                    </div>
                    <div className="mb15">
                        <FormFieldWrapper label="Title" error={get(translations.Artworks, `validationErrors.${Etitle}`, Etitle)} >
                            <input class="form-control" onChange={(e) => formik.handleChange(e)} name="title" value={title} maxLength="250" type="text" />
                        </FormFieldWrapper>

                    </div>
                    <div className="two-col mb15">
                        <div className="column">
                            <FormFieldWrapper label="Country" error={get(translations.Artworks, `validationErrors.${Ecountry}`, Ecountry)} >
                                <ReactSelect
                                    name="country"
                                    options={countriesOptions}
                                    onChange={(v) => formik.setFieldValue('country', get(v, 'value', null))}
                                    values={[country]}
                                />
                            </FormFieldWrapper>
                        </div>
                        <div className="column">
                            <FormFieldWrapper label="Year" error={get(translations.Artworks, `validationErrors.${Eyear}`, Eyear)}   >
                                <input class="form-control" value={year} onChange={(e) => formik.handleChange(e)} name="year" maxLength="4" />
                            </FormFieldWrapper>
                        </div>
                    </div>
                    {artwork.artworkType==='physical'&&
                    <div className="mb15">
                        <FormFieldWrapper label="Material" error={get(translations.Artworks, `validationErrors.${EartMaterial}`, EartMaterial)}  >
                            <textarea class="form-control" value={artMaterial} onChange={(e) => formik.handleChange(e)} name="artMaterial" maxLength="500" />
                        </FormFieldWrapper>
                    </div>}
                    {artwork.artworkType==='physical'?
                    <div className="mb15">
                        <FormFieldWrapper label="Description" error={get(translations.Artworks, `validationErrors.${Edescription}`, Edescription)}  >
                            <textarea class="form-control" value={description} onChange={(e) => formik.handleChange(e)} name="description" maxLength="500" />
                        </FormFieldWrapper>
                    </div>:
                    <>
                    <div className="mb15">
                      <FormFieldWrapper label="Software, Apps and Digital Tools used" error={get(translations.Artworks, `validationErrors.${Edescription}`, Edescription)}  >
                       <textarea class="form-control" value={description} onChange={(e) => formik.handleChange(e)} name="description" maxLength="500" />
                    </FormFieldWrapper>
                    </div>
                    <div className="mb15">
                        <FormFieldWrapper error={get(translations.Artworks, `validationErrors.${EisNotEnhanced}`, EisNotEnhanced)}>
                            <input type="checkbox"
                                onChange={(e) => { formik.handleChange('isNotEnhanced', e.target.checked) }}
                                name="isNotEnhanced"
                                checked={isNotEnhanced} />
                            <span>I confirm that I have not used any AI tools to create or enhance this image.</span>
                        </FormFieldWrapper>
                    </div>
                    </>
}
                    <div className="mb15">
                        <FormFieldWrapper label="Self Note" error={get(translations.Artworks, `validationErrors.${EselfNote}`, EselfNote)} >
                            <textarea class="form-control" value={selfNote} onChange={(e) => formik.handleChange(e)} name="selfNote" maxLength="500" />
                        </FormFieldWrapper>
                    </div>
                    {artwork.artworkType==='physical'&&
                    <div className="mb15">
                        <FormFieldWrapper label="Time Period" error={get(translations.Artworks, `validationErrors.${EtimePeriod}`, EtimePeriod)}   >
                            <ReactSelect
                                name="timePeriod"
                                options={TIME_PERIOD_OPTIONS}
                                onChange={(v) => formik.setFieldValue('timePeriod', get(v, 'value', null))}
                                values={[timePeriod]}
                            />
                        </FormFieldWrapper>
                    </div>}
                    <div className="two-col mb15">
                        <FormFieldWrapper >
                            <div className="column">
                                <input type="radio" classText="removeClass"
                                    name="type"
                                    value={'unique'}
                                    checked={type === 'unique'}
                                    onChange={(e) => formik.handleChange(e)} />
                                <span>{translations.Artworks.label.unique}</span>
                            </div>
                        </FormFieldWrapper>
                        <div className="column">
                            <FormFieldWrapper error={get(translations.Artworks, `validationErrors.${Etype}`, Etype)}   >
                                <input type="radio" classText="removeClass"
                                    name="type"
                                    value={'edition'}
                                    checked={type === 'edition'}
                                    onChange={(e) => formik.handleChange(e)} />
                                <span>{translations.Artworks.label.edition}</span>
                            </FormFieldWrapper>
                        </div>
                    </div>
                    {type === 'edition' && <div className="two-col mb15">
                        <div class="column">
                            <FormFieldWrapper label="Edition Number" error={get(translations.Artworks, `validationErrors.${EeditionDetail?.version}`, EeditionDetail?.version)}  >
                                <input type="number" class="form-control" value={editionDetail?.version} onChange={(e) => formik.setFieldValue('editionDetail', { ...editionDetail, version: e.target.value })} />
                            </FormFieldWrapper>
                        </div>
                        <div class="column">
                            <FormFieldWrapper label="Total Editions" error={get(translations.Artworks, `validationErrors.${EeditionDetail?.count}`, EeditionDetail?.count)} >
                                <input type="number" class="form-control" value={editionDetail?.count} onChange={(e) => formik.setFieldValue('editionDetail', { ...editionDetail, count: e.target.value })} />
                            </FormFieldWrapper>
                        </div>
                    </div>}
                    {artwork && artwork.userId && artwork.userId.userRoleId !== '5fe2d61b3021f7ac96cad271'&&
                    <div className="mb15">
                        <FormFieldWrapper error={get(translations.Artworks, `validationErrors.${EisAdmin}`, EisAdmin)}>
                            <input type="checkbox"
                                onChange={(e) => { formik.handleChange('isAdmin', e.target.checked); formik.setFieldValue('nameOfArtist', { lastName: '', firstName: '' }) }}
                                name="isAdmin"
                                checked={isAdmin} />
                            <span>{translations.Artworks.label.creatorOfArtwork}</span>
                        </FormFieldWrapper>
                    </div>}
                    {(artwork && artwork.userId && artwork.userId.userRoleId === '5fe2d61b3021f7ac96cad271') && <> <div className="two-col mb15"><div class="column">
                        <FormFieldWrapper label="First Name of Artist" error={get(translations.Artworks, `validationErrors.${EnameOfArtist?.firstName}`, EnameOfArtist?.firstName)}>
                            <input
                                class="form-control"
                                value={get(nameOfArtist, 'firstName')}
                                onChange={(e) =>
                                    formik.setFieldValue('nameOfArtist', { ...nameOfArtist, firstName: e.target.value })
                                }
                                maxLength="250"
                                type="text" />
                        </FormFieldWrapper>
                    </div>
                        <div class="column">
                            <FormFieldWrapper label="Last Name of Artist" error={get(translations.Artworks, `validationErrors.${EnameOfArtist?.lastName}`, EnameOfArtist?.lastName)}>
                                <input
                                    class="form-control"
                                    value={get(nameOfArtist, 'lastName', '')}
                                    onChange={(e) =>
                                        formik.setFieldValue('nameOfArtist', { ...nameOfArtist, lastName: e.target.value })
                                    }
                                    maxLength="250"
                                    type="text" />
                            </FormFieldWrapper>
                        </div>
                    </div>
                    </>}
                    <div>
                        {!priceOnRequest && <div className="mb15">
                        <span>Price</span>
                            <FormFieldWrapper error={get(translations.Artworks, `validationErrors.${Eprice}`, Eprice)}>
                                <input
                                    class="form-control"
                                    type="text"
                                    name="price"
                                    value={price || 0}
                                    onChange={(e) => {
                                        const regex = /^(\d*),{0,1}(\d){0,2}$/
                                        const value = e.target.value
                                        if (value === '' || regex.test(value)) formik.setFieldValue('price', e.target.value)
                                    }}
                                />
                            </FormFieldWrapper>
                        </div>}
                        <div className="mb15">
                            <FormFieldWrapper error={get(translations.Artworks, `validationErrors.${EpriceOnRequest}`, EpriceOnRequest)} >
                                <input type="checkbox" onChange={(e) => formik.handleChange(e)} name="priceOnRequest" checked={priceOnRequest} />
                                <span>{translations.Artworks.label.priceOnRequest}</span>
                            </FormFieldWrapper>
                        </div>
                        {artwork.artworkType==='physical'&&
                        <>
                        <div className="mb15">
                            <FormFieldWrapper error={get(translations.Artworks, `validationErrors.${EfreeShipping}`, EfreeShipping)} >
                                <input type="checkbox" onChange={(e) => { formik.handleChange(e); formik.setFieldValue('shippingPrice', { domesticPrice: 0, internationalPrice: 0 }) }} name="freeShipping" checked={freeShipping} />
                                <span>{translations.Artworks.label.freeShipping}</span>
                            </FormFieldWrapper>
                        </div>
                        {!freeShipping && <> <div className="two-col mb15"><div class="column">
                            <FormFieldWrapper label={translations.Artworks.placeholders.internationalPrice} error={get(translations.Artworks, `validationErrors.${EshippingPrice?.internationalPrice}`, EshippingPrice?.internationalPrice)}>
                                <input
                                    class="form-control"
                                    value={get(shippingPrice, 'internationalPrice')}
                                    onChange={(e) => {
                                        const regex = /^(\d*),{0,1}(\d){0,2}$/
                                        const value = e.target.value
                                        if (value === '' || regex.test(value))
                                            formik.setFieldValue('shippingPrice', {
                                                ...shippingPrice,
                                                internationalPrice: e.target.value,
                                            })
                                    }}
                                    type="text" />
                            </FormFieldWrapper>
                        </div>
                            <div class="column">
                                <FormFieldWrapper label={translations.Artworks.placeholders.domesticPrice} error={get(translations.Artworks, `validationErrors.${EshippingPrice?.domesticPrice}`, EshippingPrice?.domesticPrice)}>
                                    <input
                                        class="form-control"
                                        value={get(shippingPrice, 'domesticPrice')}
                                        onChange={(e) => {
                                            const regex = /^(\d*),{0,1}(\d){0,2}$/
                                            const value = e.target.value
                                            if (value === '' || regex.test(value))
                                                formik.setFieldValue('shippingPrice', {
                                                    ...shippingPrice,
                                                    domesticPrice: e.target.value,
                                                })
                                        }}
                                        type="text" />
                                </FormFieldWrapper>
                            </div>
                        </div>
                        </>}
                        </>
                    }
                    </div>
                    <div>
                    {artwork.artworkType==='physical'&&
                        <div className="mb15 dimension">
                            <FormFieldWrapper >
                                <span>{translations.Artworks.label['2D']}</span>
                                <Switch onChange={(e) => { formik.setFieldValue('sizeType', e ? '3D' : '2D'); formik.setFieldValue('isFramed', e === '2D') }} offColor="#888" onColor="#888" uncheckedIcon={false} checkedIcon={false} checked={sizeType === '3D'} />
                                <span>{translations.Artworks.label['3D']}</span>
                            </FormFieldWrapper>
                        </div>
                    }
                        {artwork.artworkType==='physical'?
                        <div className="three-col mb15">
                            <div className="one-third-item">
                                <FormFieldWrapper label="Length" error={get(translations.Artworks, `validationErrors.${EartDimensions?.length}`, EartDimensions?.length)}>
                                    <input
                                        class="form-control"
                                        name="artworkLength"
                                        min={1}
                                        type="number"
                                        value={get(artDimensions, 'length', 1)}
                                        onChange={(e) =>
                                            formik.setFieldValue('artDimensions', { ...artDimensions, length: e.target.value })
                                        }
                                    />
                                </FormFieldWrapper>
                            </div>
                            <div className="one-third-item">
                                <FormFieldWrapper label="Height" error={get(translations.Artworks, `validationErrors.${EartDimensions?.height}`, EartDimensions?.height)}>
                                    <input
                                        class="form-control"
                                        type="number"
                                        name="artworkHeight"
                                        min={1}
                                        value={get(artDimensions, 'height', 1)}
                                        onChange={(e) =>
                                            formik.setFieldValue('artDimensions', { ...artDimensions, height: e.target.value })
                                        }
                                    />
                                </FormFieldWrapper>
                            </div>
                            {sizeType === '3D' && <div className="one-third-item">
                                <FormFieldWrapper label="Width" error={get(translations.Artworks, `validationErrors.${EartDimensions?.width}`, EartDimensions?.width)}>
                                    <input
                                        class="form-control"
                                        name="artworkWidth"
                                        min="1"
                                        type="number"
                                        value={get(artDimensions, 'width', 1)}
                                        onChange={(e) =>
                                            formik.setFieldValue('artDimensions', { ...artDimensions, width: e.target.value })
                                        }
                                    />
                                </FormFieldWrapper>
                            </div>}
                        </div>:
                        <div className="three-col mb15">
                        <div className="one-third-item">
                            <FormFieldWrapper label="Width" error={get(translations.Artworks, `validationErrors.${EimageDetails?.imageWidth}`, EimageDetails?.imageWidth)}>
                                <input
                                    class="form-control"
                                    name="artworkLength"
                                    min={1}
                                    type="number"
                                    value={get(imageDetails, 'imageWidth', 1)}
                                    onChange={(e) =>
                                        formik.setFieldValue('imageDetails', { ...imageDetails, imageWidth: e.target.value })
                                    }
                                />
                            </FormFieldWrapper>
                        </div>
                        <div className="one-third-item">
                            <FormFieldWrapper label="Height" error={get(translations.Artworks, `validationErrors.${EimageDetails?.imageHeight}`, EimageDetails?.imageHeight)}>
                                <input
                                    class="form-control"
                                    type="number"
                                    name="artworkHeight"
                                    min={1}
                                    value={get(imageDetails, 'imageHeight', 1)}
                                    onChange={(e) =>
                                        formik.setFieldValue('imageDetails', { ...imageDetails, imageHeight: e.target.value })
                                    }
                                />
                            </FormFieldWrapper>
                        </div>
                        <div className="one-third-item">
                            <FormFieldWrapper label="Size" error={get(translations.Artworks, `validationErrors.${EimageDetails?.size}`, EimageDetails?.size)}>
                                <input
                                    class="form-control"
                                    type="number"
                                    name="size"
                                    min={1}
                                    value={get(imageDetails, 'size', 1)}
                                    onChange={(e) =>
                                        formik.setFieldValue('imageDetails', { ...imageDetails, size: e.target.value })
                                    }
                                />
                            </FormFieldWrapper>
                        </div>
              
                    </div>}
                        
                    </div>
                    {sizeType === '2D' && <div className="mb15">
                    {artwork.artworkType==='physical'&&
                        <FormFieldWrapper error={get(translations.Artworks, `validationErrors.${EisFramed}`, EisFramed)} >
                            <input type="checkbox" onChange={(e) => formik.handleChange(e)} name="isFramed" checked={isFramed} />
                            <span>{translations.Artworks.label.addFrame}</span>
                        </FormFieldWrapper>}

                    </div>}
                    {isFramed && <><div className="two-col mb15">
                        <div className="column">
                            <FormFieldWrapper label="Length" error={get(translations.Artworks, `validationErrors.${EframeDimensions?.length}`, EframeDimensions?.width)}>
                                <input
                                    class="form-control"
                                    name="frameDimensionsLength"
                                    min="1"
                                    type="number"
                                    value={get(frameDimensions, 'length', 1)}
                                    onChange={(e) =>
                                        formik.setFieldValue('frameDimensions', { ...frameDimensions, length: e.target.value })
                                    }
                                />
                            </FormFieldWrapper>
                        </div>
                        <div className="column">
                            <FormFieldWrapper label="Width" error={get(translations.Artworks, `validationErrors.${EframeDimensions?.width}`, EframeDimensions?.width)}>
                                <input
                                    class="form-control"
                                    name="frameDimensionsWidth"
                                    type="number"
                                    min="1"
                                    value={get(frameDimensions, 'width', 1)}
                                    onChange={(e) =>
                                        formik.setFieldValue('frameDimensions', { ...frameDimensions, width: e.target.value })
                                    }
                                />
                            </FormFieldWrapper>
                        </div>
                    </div>
                        <div className="mb15">
                            <FormFieldWrapper label={translations.Artworks.placeholders.frameMaterial} error={get(translations.Artworks, `validationErrors.${EframeMterial}`, EframeMterial)}  >
                                <textarea
                                    class="form-control"
                                    value={frameMaterial}
                                    maxLength={500}
                                    name="frameMaterial"
                                    onChange={formik.handleChange}
                                />
                            </FormFieldWrapper>
                        </div>
                    </>
                    }
                    {artwork.artworkType==='physical'&&
                    <div className="mb15">
                        <FormFieldWrapper label="Medium" error={get(translations.Artworks, `validationErrors.${EartMediumId}`, EartMediumId)} >
                            <ReactSelect
                                name="medium"
                                isMulti
                                options={mediumOptions}
                                onChange={(v) => formik.setFieldValue('artMediumId', v.map(m => m.value))}
                                values={artMediumId || []}
                            />
                        </FormFieldWrapper>
                    </div>}
                    <div className="mb15">
                        <FormFieldWrapper label="Genre" error={get(translations.Artworks, `validationErrors.${EgenreId}`, EgenreId)}>
                            <ReactSelect
                                name="genre"
                                options={genreOptions}
                                isMulti
                                onChange={(v) => formik.setFieldValue('genreId', v.map(g => g.value))}
                                values={genreId || []}
                            />
                        </FormFieldWrapper>
                    </div>
                    <div className="mb15">
                        <Button loading={updating || loadingRejectReasons} clicked={() => setShowModal(true)} type="button" className="btn btn-primary">Reject</Button>
                        <Button loading={updating} clicked={() => formik.handleSubmit()} type="button" className="btn btn-primary">Save And Accept</Button>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default EditArtworkComp
