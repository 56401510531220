import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getMessageResponse } from '../../services/messageResponse'

const initialState = {
    loadingCounter: 0,
    allMessageResponse: [],
};


/**
 * Fetch Response messages list
 */
export const fetchMessageResponse = createAsyncThunk(
    "messageResponse/list",
    async (params, thunkAPI) => {
        try {
            const response = await getMessageResponse(params)

            const { data, success, err } = response;

            if (success) {
                return data.responses;
            } else {
                return thunkAPI.rejectWithValue(err);
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);


export const messageSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchMessageResponse.pending]: (state, action) => {
            state.loadingCounter = state.loadingCounter + 1
        },
        [fetchMessageResponse.fulfilled]: (state, action) => {
            state.loadingCounter = state.loadingCounter - 1
            state.allMessageResponse = action.payload || [];
        },
        [fetchMessageResponse.rejected]: (state, action) => {
            state.loadingCounter = state.loadingCounter - 1
            state.error = action.error.message;
        },
    },
});

export default messageSlice.reducer;

