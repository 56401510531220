import React from "react";
//import Modal from "react-responsive-modal";
import Modal from "react-modal"
import { messageValidationSchema } from '../common/validations'
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Editor } from '@tinymce/tinymce-react';

const MasterclassPromotionModal = (props) => {
    const {
        mopen,
        onCloseModal,
        submitHandler,
    } = props
    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
        },
    };
    return (
        <Modal id="modalContent" style={customStyles} isOpen={mopen} open={mopen} onClose={onCloseModal} focusTrapped={false}>
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    Masterclass Promotion
                </h5>
            </div>
            <div className="modal-body">
            <Formik
                initialValues={{
                    message: "",
                    noEmail: false, // Adding the noEmail field
                }}
                validationSchema={messageValidationSchema}
                onSubmit={(values) => {
                    submitHandler(values);
                }}
            >
                {({ values: formikValues, setFieldValue }) => (
                    <Form id="messageForm">
                        <div className="form-group">
                            <label htmlFor="message">Article Slug</label>
                            <input
                                class="form-control" 
                                id="message"
                                name="message"
                                value={formikValues.message}
                                onChange={(e) => setFieldValue("message", e.target.value)}
                                placeholder="Type your article slug"
                            />
                            <ErrorMessage
                                component="div"
                                className="alert alert-danger"
                                name="message"
                            />
                        </div>

                        {/* Checkbox for noEmail */}
                        <div className="form-group form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="noEmail"
                                name="noEmail"
                                checked={formikValues.noEmail}
                                onChange={() => setFieldValue('noEmail', !formikValues.noEmail)}
                            />
                            <label className="form-check-label" htmlFor="noEmail">
                                Disable email notification
                            </label>
                        </div>

                        <button type="submit" className="btn btn-primary">
                            Send
                        </button>
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => onCloseModal("VaryingMdo")}
                        >
                            Close
                        </button>
                    </Form>
                )}
            </Formik>
            </div>
            <div className="modal-footer"></div>
        </Modal>
    );
};

export default MasterclassPromotionModal;
